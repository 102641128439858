// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_t-dataPicker-input__dSzVL {
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: "tnum";
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  padding-top: 11px;
  color: var(--dg-primary-color);
  font-size: 14px;
  line-height: 1.5715;
  background-color: var(--dg-input-bg);
  background-image: none;
  border: 1px solid var(--border-color-base);
  transition: all 0.3s;
  border-radius: 6px !important;
  height: 32px;
}

.style_t-dataPicker-input__dSzVL:focus {
  border-color: var(--primary-color) !important;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 var(--primary-color);
}

.style_t-dataPicker-input__dSzVL:hover {
  border-color: var(--primary-color) !important;
}`, "",{"version":3,"sources":["webpack://./src/styles/UI/DatePicker/style/style.module.scss"],"names":[],"mappings":"AAAA;EACI,0BAAA;EACA,gBAAA;EACA,6BAAA;EACA,kBAAA;EACA,qBAAA;EACA,WAAA;EACA,YAAA;EACA,iBAAA;EACA,iBAAA;EACA,8BAAA;EAEA,eAAA;EACA,mBAAA;EACA,oCAAA;EACA,sBAAA;EACA,0CAAA;EACA,oBAAA;EACA,6BAAA;EACA,YAAA;AACJ;;AAEA;EACI,6CAAA;EACA,kCAAA;EACA,UAAA;EACA,sCAAA;AACJ;;AAEA;EACI,6CAAA;AACJ","sourcesContent":[".t-dataPicker-input {\n    font-variant: tabular-nums;\n    list-style: none;\n    font-feature-settings: 'tnum';\n    position: relative;\n    display: inline-block;\n    width: 100%;\n    min-width: 0;\n    padding: 4px 11px;\n    padding-top: 11px;\n    color: var(--dg-primary-color);\n    border-radius: 16px;\n    font-size: 14px;\n    line-height: 1.5715;\n    background-color: var(--dg-input-bg);\n    background-image: none;\n    border: 1px solid var(--border-color-base);\n    transition: all 0.3s;\n    border-radius: 6px !important;\n    height: 32px;\n}\n\n.t-dataPicker-input:focus {\n    border-color: var(--primary-color) !important;\n    border-right-width: 1px !important;\n    outline: 0;\n    box-shadow: 0 0 0 var(--primary-color);\n}\n\n.t-dataPicker-input:hover {\n    border-color: var(--primary-color) !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"t-dataPicker-input": `style_t-dataPicker-input__dSzVL`
};
export default ___CSS_LOADER_EXPORT___;
