import React from 'react';
import { AiOutlineDelete } from 'react-icons/ai';

const DgDeleteIcon = ({ ...props }) => {
	return (
		<span className='inline-block cursor-pointer align-middle' {...props}>
			<AiOutlineDelete className='mx-[5px] text-xl text-dg-red-color' />
		</span>
	);
};

export default DgDeleteIcon;
