import DgSelectRole from 'components/Basic/DgSelectRole';
import useTranslate from 'core/utils/useTranslate';
import React from 'react';
import DgButton from 'styles/UI/Button/DgButton';
import DgForm from 'styles/UI/Form/DgForm';
import DgFormItem from 'styles/UI/FormItem/DgFormItem';
import DgFormWrapper from 'styles/UI/FormWrapper/DgFormWrapper';
import DgInputLabel from 'styles/UI/Input/DgInputLabel';
import DgInputPasswordLabel from 'styles/UI/Input/DgInputPasswordLabel';
import DgRadioButton from 'styles/UI/RadioButton/DgRadioButton';
import DgRadioButtonGroup from 'styles/UI/RadioButton/DgRadioButtonGroup';
import useAdminUserCreate from './hooks/useAdminUserCreate';

const AdminUserCreate = () => {
	const { createUserForm, genderOnChangeHandler, isLoadingCreateUser, onFinishCreateUser, onChangeRoles, roles, onDeselectRole } = useAdminUserCreate();
	const { t } = useTranslate();

	return (
		<DgForm autoComplete='off' onFinish={onFinishCreateUser} form={createUserForm}>
			<DgFormWrapper>
				<DgFormItem
					rules={[
						{
							required: true,
							message: t('first-name-is-required'),
						},
					]}
					name='first_name'
				>
					<DgInputLabel label={t('first-name')} required />
				</DgFormItem>

				<DgFormItem
					rules={[
						{
							required: true,
							message: t('last-name-is-required'),
						},
					]}
					name='last_name'
				>
					<DgInputLabel label={t('last-name')} required />
				</DgFormItem>

				<DgFormItem
					rules={[
						{
							required: true,
							message: t('username-is-required'),
						},
					]}
					name='username'
				>
					<DgInputLabel label={t('username')} required />
				</DgFormItem>

				<DgFormItem
					rules={[
						{
							required: true,
							message: t('email-is-required'),
						},
						{
							type: 'email',
							message: t('email-format-incorrect'),
						},
					]}
					name='email'
				>
					<DgInputLabel autoComplete='new-password' label='email' required />
				</DgFormItem>

				<DgFormItem
					name='password'
					rules={[
						{
							required: true,
							message: t('password-is-required'),
						},
					]}
				>
					<DgInputPasswordLabel label='password' autoComplete='new-password' required />
				</DgFormItem>

				<DgFormItem
					name='password_confirmation'
					dependencies={['password']}
					rules={[
						{
							required: true,
							message: t('confirm-password-is-required'),
						},
						({ getFieldValue }) => ({
							validator(_, value) {
								if (!value || getFieldValue('password') === value) {
									return Promise.resolve();
								}
								return Promise.reject(new Error(t('password-not-same')));
							},
						}),
					]}
				>
					<DgInputPasswordLabel label='confirm-password' required />
				</DgFormItem>

				<DgFormItem name='sponsor_id'>
					<DgInputLabel label='referral-code' />
				</DgFormItem>

				<DgFormItem>
					<DgSelectRole onDeselect={onDeselectRole} customOnChange={onChangeRoles} value={roles.length === 0 ? undefined : roles} />
				</DgFormItem>
			</DgFormWrapper>

			<DgFormWrapper>
				<DgFormItem name='gender'>
					<DgRadioButtonGroup onChange={genderOnChangeHandler}>
						<DgRadioButton value='Male'>{t('male')}</DgRadioButton>
						<DgRadioButton value='Female'>{t('female')}</DgRadioButton>
					</DgRadioButtonGroup>
				</DgFormItem>
			</DgFormWrapper>

			<DgFormItem button>
				<DgButton htmlType='submit' isLoading={isLoadingCreateUser} title='create-user' />
			</DgFormItem>
		</DgForm>
	);
};

export default AdminUserCreate;
