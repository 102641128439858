import { UseQueryResult } from '@tanstack/react-query';
import { endPointUrls } from 'core/constants/endPointUrls';
import api from 'core/services/fetch-api/useApi';
import useQueryHook from 'core/hooks/masterQuery/useQueryHook';
import { ITransactionFilterTypeDetail } from 'core/types/models/transactionType';

export const getTransactionTypesQuery = () => {
	return useQueryHook(
		[endPointUrls.WALLETS_TRANSACTION_TYPES],
		() => api.get(endPointUrls.WALLETS_TRANSACTION_TYPES),
		{
			select: (res: any) => res?.data,
		}
	) as UseQueryResult<ITransactionFilterTypeDetail, Error>;
};
