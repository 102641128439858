import { FC, ReactNode } from 'react';
import DgTooltip from '../Tooltip/DgTooltip';
import { mainFormatNumber } from 'core/utils/utils';

export interface ICountsData {
	title: string;
	count: string | number | ReactNode;
	color: string;
	percentTitle?: string;
	percent?: string;
	titleClassName?: string;
	countClassName?: string;
	lineBarCount?: string | number;
}

interface IDashboardLinePercent {
	data: ICountsData[];
}

const DashboardLinePercent: FC<IDashboardLinePercent> = ({ data }) => {
	const total = data.reduce((current, next) => current + parseInt(Math.abs(next.lineBarCount as number) + ''), 0);
	const getPercentOfTotal = (count: number | string) => {
		return +count / (total * 0.01);
	};
	const getTooltipTitle = () => {
		return (
			<div>
				<p className='mb-0 text-xs'>{'sums:‌' + mainFormatNumber(total)}</p>
				{data.map((item) => {
					return (
						<p className='mb-0 text-xs' key={item.title}>
							{item.title + ':' + getPercentOfTotal(Math.abs(item?.lineBarCount as number) ? Math.abs(item?.lineBarCount as number) : 0).toFixed(2) + '%'}
						</p>
					);
				})}
			</div>
		);
	};

	return (
		<DgTooltip title={getTooltipTitle()} overlayInnerStyle={{ fontSize: '12px', textAlign: 'center' }}>
			<div className='flex w-full h-3 mt-2 overflow-hidden border-4 rounded-full border-transparent opacity-80'>
				{data.map((item) => {
					return (
						<div
							key={item.title}
							className={`${item.color}  h-full last:border-0 border-r-[3px] border-[var(--dg-white-color)] `}
							style={{ width: `${Math.max(getPercentOfTotal(Math.abs(item?.lineBarCount as number) ? Math.abs(item?.lineBarCount as number) : 0), 2)}%` }}
						/>
					);
				})}
			</div>
		</DgTooltip>
	);
};

export default DashboardLinePercent;
