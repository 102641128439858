import { UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { endPointUrls } from 'core/constants/endPointUrls';
import toastHandler from 'core/helpers/toast/toast';
import useMutationHook from 'core/hooks/masterQuery/useMutationHook';
import api from 'core/services/fetch-api/useApi';
import { setApiCatcherFormErrors } from 'core/store/slice/mainInfo/mainInfoSlice';
import { useDispatch } from 'react-redux';

export const useAdminDeletePackage = (callBack?: () => void) => {
	const dispatch = useDispatch();
	const queryClient = useQueryClient();

	return useMutationHook(
		[endPointUrls.ADMIN_DELETE_PACKAGE],
		(data: any) => api.delete(endPointUrls.ADMIN_DELETE_PACKAGE, data),
		{
			onSuccess: (res) => {
				const response = res as AxiosResponse;

				if (response?.status === 200) {
					// toastHandler('success', response?.data.message);
					dispatch(setApiCatcherFormErrors([]));
					queryClient.invalidateQueries([endPointUrls.PACKAGES_LIST]);
					callBack?.();
				}
			},
		}
	) as UseMutationResult;
};
