import { FC, useLayoutEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import DgDatePicker from './DgDatePicker';
import { CalendarProps, DatePickerProps } from 'react-multi-date-picker';
import { useSelector } from 'react-redux';
import { RootState } from 'core/store';

interface IDgDatePickerLabel extends DatePickerProps, CalendarProps {
	label: string;
	ltr?: boolean;
	className?: string;
	required?: boolean;
	value?: any;
}

const DgDatePickerLabel: FC<IDgDatePickerLabel> = ({ ltr = false, label, className, required, ...props }) => {
	const { t } = useTranslation();
	const { lang } = useSelector((store: RootState) => store.settingStore);
	const labelRef = useRef<HTMLLabelElement | null>(null);

	useLayoutEffect(() => {
		if (props.value) labelRef.current?.classList?.add('active-float-label');
		else labelRef.current?.classList?.remove('active-float-label');
	}, [props.value]);

	return (
		<div className='float-group'>
			<DgDatePicker className={`${className}`} {...props} />
			<label ref={labelRef} className={`float-label ${lang === 'fa' ? (ltr ? 'left-3' : 'right-3') : 'left-3'}`}>
				{required && <span className='text-dg-red-color inline-block relative top-1 mx-1'>*</span>}
				{t(label)}
			</label>
		</div>
	);
};

DgDatePickerLabel.displayName = 'DgDatePickerLabel';

export default DgDatePickerLabel;
