import { useWindowSize } from 'core/hooks/useWindowSize';
import Text from 'core/utils/Text';
import DgTableColumnResponsive from 'styles/UI/TableColumnResponsive/DgTableColumnResponsive';
import DgPrice from 'styles/UI/Price/DgPrice';
import DgTimeStamp from 'styles/UI/Timstamp/DgTimeStamp';
import { hiddenText } from 'core/utils/utils';
import DgCopyClipboard from 'styles/UI/CopyClipboard/DgCopyClipboard';
import { ITransaction } from 'core/types/models/transactionType';
import useTranslate from 'core/utils/useTranslate';
import { Link } from 'react-router-dom';
import { routes } from 'core/constants/routes';

const AdminUserTransactionTableColumn = () => {
	const { width } = useWindowSize();
	const { t } = useTranslate();

	const Columns = [
		{
			title: <Text tid='transaction-id' />,
			key: 'id',
			dataIndex: 'id',
			render: (text: any) => {
				const { value, hiddenValue } = hiddenText(text);
				return <DgCopyClipboard hiddenValue={hiddenValue} value={value} />;
			},
			sorter: false,
		},
		{
			title: <Text tid='full-name' />,
			// key: 'ful-name',
			// dataIndex: 'ful-name',

			render: (text: any, record: any) => (
				<Link
					to={routes.ADMIN_USER_Detail.link(record.user?.id)}
					className='flex items-center gap-3 group'
				>
					<Text
						tid={
							`${record?.user_full_name} (${record?.user_member_id})` ||
							record.user?.email ||
							record.user?.username
						}
					/>
				</Link>
			),
		},
		{
			title: <Text tid='old-balance' />,
			key: 'before_balance',
			dataIndex: 'before_balance',
			render: (text: any) => <DgPrice price={text} />,
			sorter: false,
		},
		{
			title: <Text tid='amount' />,
			key: 'amount',
			dataIndex: 'amount',
			render: (text: any) => <DgPrice price={text} colored />,
		},
		{
			title: <Text tid='new-balance' />,
			key: 'after_balance',
			dataIndex: 'after_balance',
			render: (text: any) => <DgPrice price={text} />,
			sorter: false,
		},
		{
			title: <Text tid='type' />,
			key: 'type',
			dataIndex: 'type',
			render: (text: any) => text,
			sorter: false,
		},
		{
			title: <Text tid='description' />,
			key: 'description',
			dataIndex: 'description',
			render: (text: any) => text,
			sorter: false,
		},
		{
			title: <Text tid='date-time' />,
			key: 'created_at',
			dataIndex: 'created_at',
			render: (text: any) => <DgTimeStamp timeStamp={text} />,
		},
	];

	const RColumn = [
		{
			title: '',
			sorter: false,
			render: (_text: any, record: any) => {
				const { value, hiddenValue } = hiddenText(record.id);

				return (
					<>
						<DgTableColumnResponsive
							title={t('full-name')}
							value={
								<>
									<Link
										to={routes.ADMIN_USER_Detail.link(record.user?.id)}
										className='flex items-center gap-3 group'
									>
										<Text
											tid={
												`${record?.user_full_name} (${record?.user_member_id})` ||
												record.user?.email ||
												record.user?.username
											}
										/>
									</Link>
								</>
							}
						/>

						<DgTableColumnResponsive
							title={t('transaction-id')}
							value={<DgCopyClipboard hiddenValue={hiddenValue} value={value} />}
						/>
						<DgTableColumnResponsive
							title={t('old-balance')}
							value={<DgPrice price={record.before_balance} />}
						/>
						<DgTableColumnResponsive
							title={t('amount')}
							value={<DgPrice price={record.amount} colored />}
						/>
						<DgTableColumnResponsive title={t('full-name')} value={<></>} />
						<DgTableColumnResponsive
							title={t('new-balance')}
							value={<DgPrice price={record.after_balance} />}
						/>
						<DgTableColumnResponsive title={t('type')} value={record.type} />
						<DgTableColumnResponsive
							title={t('description')}
							value={record.description}
						/>
						<DgTableColumnResponsive
							title={t('date-time')}
							value={<DgTimeStamp nowrap={false} timeStamp={record.created_at} />}
						/>
					</>
				);
			},
		},
	];

	return width > 769 ? Columns : RColumn;
};

export default AdminUserTransactionTableColumn;
