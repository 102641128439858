import DgButton from 'styles/UI/Button/DgButton';
import ProfileNotificationsTable from './components/ProfileNotificationsTable';
import { useNotificationSeenQuery } from 'components/Profile/hooks/react-query/useNotificationSeenQuery';
import Text from 'core/utils/Text';
import ProfileNotificationFilter from './components/ProfileNotificationFilter';

const ProfileNotifications = () => {
	const { mutate: setSeenNotification, isLoading: seenNotificationIsLoading } = useNotificationSeenQuery();

	return (
		<>
			<div className='mb-5 flex items-center justify-between'>
				<h2>
					<Text tid='messages' />
				</h2>
				<DgButton title='read-all' onClick={() => setSeenNotification({ ids: [] })} isLoading={seenNotificationIsLoading} />
			</div>

			<div className='mt-4'>
				<ProfileNotificationFilter />
				<ProfileNotificationsTable />
			</div>
		</>
	);
};

export default ProfileNotifications;
