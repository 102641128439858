import Text from 'core/utils/Text';
import React, { useEffect, useState } from 'react';
import { BsArrowBarDown } from 'react-icons/bs';
import DgFormWrapper from 'styles/UI/FormWrapper/DgFormWrapper';
import DgForm from 'styles/UI/Form/DgForm';
import DgFormItem from 'styles/UI/FormItem/DgFormItem';
import DgInputLabel from 'styles/UI/Input/DgInputLabel';
import useSelected from 'core/hooks/useSelected';
import UserDepositNetworkItem from './UserDepositNetworkItem';
import DgButton from 'styles/UI/Button/DgButton';
import { ReactComponent as StepOne } from 'assets/images/step-1.svg';
import usdt from '../../../assets/images/usdt.png';
import DgImage from 'styles/UI/Image/DgImage';
import DgSelectDepositCurrencies from './DgSelectDepositCurrencies';
import useDepositStepOne from '../hooks/useDepositStepOne';
import useTranslate from 'core/utils/useTranslate';
import { getCurrentUserInfoQuery } from 'core/hooks/react-query/getCurrentUserInfoQuery';
import Google2faAlert from 'components/HeaderAlert/components/Google2faAlert';
import DgModal from 'styles/UI/Modal/DgModal';
import useModal from 'core/hooks/useModal';
import logoDark from '../../../assets/images/logo/logo-dark.png';
import logoLight from '../../../assets/images/logo/logo-light.png';
import { RootState } from 'core/store';
import { useSelector } from 'react-redux';
import { Checkbox, Statistic, Typography } from 'antd';
import { CountdownProps } from 'antd/lib/statistic/Countdown';

const { Countdown } = Statistic;

const UserDepositStepOneForm = () => {
	const { lang, theme } = useSelector((store: RootState) => store.settingStore);
	const [rulesAccepted, setRulesAccepted] = useState<boolean>(false);

	const [rulesTimer, setRulesTimer] = useState(Date.now());
	const [rulesTimerFinished, setRulesTimerFinished] = useState(false);

	const { modal, showModal, hideModal } = useModal();

	const { t } = useTranslate();

	const { data: currentUserInfo, isFetching } = getCurrentUserInfoQuery();

	const depositSteps = [
		{ id: 0, title: 'ERC20' },
		{ id: 1, title: 'TRC20' },
		{ id: 2, title: 'BEP20' },
	];

	const { selectedType, selectedTypeHandler: selectedNetworkTypeHandler } = useSelected(
		1,
		false
	);

	const { amount, isLoadingDepositWallet, onFinishDeposit, form, onChangeInputHandler } =
		useDepositStepOne();

	// const nodeEnv = process.env.NODE_ENV || '';

	useEffect(() => {
		if (modal) {
			setRulesTimerFinished(false);
			setRulesTimer(Date.now() + 61000);
		}
	}, [modal]);

	const onFinish: CountdownProps['onFinish'] = () => {
		// console.log('finished!');
		setRulesTimerFinished(true);
	};

	return (
		<>
			<DgModal
				title='rules-title-1'
				open={modal}
				closable={false}
				className='faq-modal'
				width={860}
			>
				<div className='flex flex-col gap-4'>
					<div className='flex items-center justify-center'>
						<img src={theme === 'dark' ? logoDark : logoLight} width={'130px'} />
					</div>

					<p className='whitespace-pre-line'>
						<Text className='inline' tid={'deposit-rules-description-1'}></Text>{' '}
						<Text
							className='inline text-lg font-extrabold text-red-600'
							tid={'nobitex'}
						></Text>
						،{' '}
						<Text
							className='inline text-lg font-extrabold text-red-600'
							tid={'wallex'}
						></Text>
						،{' '}
						<Text
							className='inline text-lg font-extrabold text-red-600'
							tid={'excoino'}
						></Text>
						، <Text className='inline' tid={'deposit-rules-description-2'}></Text>
						{'\n'}
						<Text className='inline' tid={'deposit-rules-description-3'}></Text>
						{'\n'}
						<Text className='inline' tid={'deposit-rules-description-4'}></Text>
						{'\n\n\n'}
						<Text
							className='inline text-lg font-extrabold text-red-600'
							tid={'deposit-rules-description-5'}
						></Text>
						{'\n'}
						<Text className='inline' tid={'deposit-rules-description-6'}></Text>
					</p>

					<div className='flex flex-wrap gap-4 items-center justify-end'>
						{/* <a target='_blank' href='/panel/rules.pdf' className=''>
							<Text tid='download-rules' />
						</a> */}
						{!rulesTimerFinished && (
							<Countdown
								valueStyle={{ color: theme === 'dark' ? '#fff' : '#000' }}
								value={rulesTimer}
								onFinish={onFinish}
							/>
						)}

						<DgButton
							disabled={!rulesTimerFinished}
							title='i-agree-the-deposit-rules'
							onClick={() => {
								hideModal();
							}}
						/>
					</div>
				</div>
			</DgModal>

			<span>
				<Text tid='deposit-title-1' />{' '}
				<Text tid='deposit-wallet' className='text-[var(--dg-primary-color)] font-bold' />
			</span>

			<BsArrowBarDown size={50} className='mx-auto' />

			<div className='max-w-md mx-auto'>
				<div className='text-center my-7 flex flex-col gap-3'>
					<Text tid='currencies-and-amount' className='text-lg font-bold' />
					<Text tid='select-currencies-and-amount' />
				</div>

				<DgForm form={form} onFinish={onFinishDeposit}>
					<DgFormWrapper singleColumn>
						<DgFormItem>
							<DgSelectDepositCurrencies label='currency' />
						</DgFormItem>

						<DgFormItem
							name='amount'
							rules={[
								{ required: true, message: t('amount-is-required') },
								{
									message: t('amount-should-be-greater-than-100-usdt'),
									validator: (_, value) => {
										try {
											// console.log({ value }, parseInt(value), parseInt(value) >= 100);

											if (parseInt(value) >= 100) {
												return Promise.resolve();
											} else {
												return Promise.reject('amount-should-be-greater-than-100-usdt');
											}
										} catch (error) {
											return Promise.reject('amount-should-be-greater-than-100-usdt');
										}
									},
								},
							]}
						>
							<DgInputLabel
								// value={amount}
								// onChange={onChangeInputHandler}
								label='amount'
								required
								suffix={
									<DgImage
										src={usdt}
										width={22}
										className='rounded-full relative top-1.5'
									/>
								}
							/>
						</DgFormItem>

						<div className='flex flex-col gap-1'>
							<Text tid='network' />

							<div className='grid grid-flow-col gap-3 overflow-x-auto'>
								{depositSteps.map((item) => (
									<UserDepositNetworkItem
										disabled={selectedType.index !== item.id}
										network={item.title}
										key={item.id}
										selected={selectedType.index === item.id}
										onClick={() =>
											selectedType.index !== item.id
												? null
												: selectedNetworkTypeHandler(item.id)
										}
									/>
								))}
							</div>
						</div>

						{!isFetching && !currentUserInfo?.data?.google2fa_enable ? (
							<Google2faAlert />
						) : (
							<>
								<Checkbox
									checked={rulesAccepted}
									onChange={(e) => {
										// // console.log({ rulesAccepted, modal });

										if (!rulesAccepted) {
											showModal();
										}
										setRulesAccepted(e.target.checked);
									}}
								>
									{t('i-agree-the-deposit-rules')}
								</Checkbox>

								{!!rulesAccepted && (
									<DgFormItem
										name='one_time_password'
										rules={[
											{
												required: true,
												message: t('totp-verification-code-is-required'),
											},
										]}
									>
										<DgInputLabel label='totp-verification-code' required />
									</DgFormItem>
								)}

								<DgButton
									isLoading={isLoadingDepositWallet}
									disabled={isLoadingDepositWallet || !rulesAccepted}
									title='next'
									className='mt-5 w-full'
								/>
							</>
						)}

						<StepOne className='mx-auto mt-5' />
					</DgFormWrapper>
				</DgForm>
			</div>
		</>
	);
};

export default UserDepositStepOneForm;
