import { useWindowSize } from 'core/hooks/useWindowSize';
import Text from 'core/utils/Text';
import DgTableColumnResponsive from 'styles/UI/TableColumnResponsive/DgTableColumnResponsive';
import DgPrice from 'styles/UI/Price/DgPrice';
import { IWallet } from 'core/types/models/walletsType';
import DgShowInfo from 'styles/UI/ShowInfo/DgShowInfo';
import DgButton from 'styles/UI/Button/DgButton';
import { BiTransfer } from 'react-icons/bi';
import { routes } from 'core/constants/routes';
import { useNavigate } from 'react-router-dom';
import useTranslate from 'core/utils/useTranslate';

const AdminUserWalletsTableColumn = (userId?: string) => {
	const { width } = useWindowSize();
	const navigate = useNavigate();
	const { t } = useTranslate();

	const Columns = [
		{
			title: <Text tid='name' />,
			key: 'name',
			dataIndex: 'name',
			render: (text: any) => text,
			sorter: false,
		},
		{
			title: <Text tid='balance' />,
			key: 'balance',
			dataIndex: 'balance',
			render: (text: any) => <DgPrice price={text} colored />,
			sorter: false,
		},
		{
			title: <Text tid='total_received' />,
			key: 'total_received',
			dataIndex: 'total_received',
			render: (text: any) => <DgPrice price={text} colored />,
			sorter: false,
		},
		{
			title: <Text tid='total_spent' />,
			key: 'total_spent',
			dataIndex: 'total_spent',
			render: (text: any) => <DgPrice price={text} colored />,
			sorter: false,
		},
		{
			title: <Text tid='total_transfer' />,
			key: 'total_transfer',
			dataIndex: 'total_transfer',
			render: (text: any) => <DgPrice price={text} colored />,
			sorter: false,
		},
		{
			title: <Text tid='counts' />,
			key: 'counts',
			dataIndex: 'counts',
			render: (_text: any, record: IWallet) => (
				<div>
					<DgShowInfo
						fullWith
						title={t('transactions_count')}
						value={<DgPrice suffix='' price={record.transactions_count} />}
					/>
					<DgShowInfo
						fullWith
						title={t('transactions_received_count')}
						value={<DgPrice suffix='' price={record.transactions_received_count} />}
					/>
					<DgShowInfo
						fullWith
						title={t('transactions_spent_count')}
						value={<DgPrice suffix='' price={record.transactions_spent_count} />}
					/>
					<DgShowInfo
						fullWith
						title={t('transfers_count')}
						value={<DgPrice suffix='' price={record.transfers_count} />}
					/>
				</div>
			),
			sorter: false,
		},
		{
			title: '',
			render: (_text: any, record: IWallet) => {
				return (
					<div className='gap-y-4 flex flex-col'>
						{/* <DgButton
							type='default'
							className='text-white hover:bg-dg-wallet-button-hover hover:border-dg-wallet-button-hover bg-dg-wallet-button border-dg-wallet-button'
							customTitle={
								<div className='flex items-center gap-x-2'>
									<FaArrowAltCircleUp size={20} className='relative bottom-0.5 text-dg-green-color' />
									<Text tid='deposit' />
								</div>
							}
						/>

						<DgButton
							type='default'
							className='text-white hover:bg-dg-wallet-button-hover hover:border-dg-wallet-button-hover bg-dg-wallet-button border-dg-wallet-button'
							customTitle={
								<div className='flex items-center gap-x-2'>
									<FaArrowAltCircleDown size={20} className='relative bottom-0.5 text-dg-red-color' />
									<Text tid='withdraw' />
								</div>
							}
						/> */}
						{record?.name === 'Deposit Wallet' && (
							<DgButton
								type='default'
								className='text-white hover:bg-dg-wallet-button-hover hover:border-dg-wallet-button-hover bg-dg-wallet-button border-dg-wallet-button'
								customTitle={
									<div className='flex items-center gap-x-2'>
										<BiTransfer
											size={20}
											className='relative bottom-0.5 text-[var(--dg-primary-color)]'
										/>
										<Text tid='transfer' />
									</div>
								}
								onClick={() =>
									navigate(
										routes.ADMIN_USER_Detail_PAGE.link(userId, 'increase-wallet-balance')
									)
								}
							/>
						)}
					</div>
				);
			},
			sorter: false,
		},
	];

	const RColumn = [
		{
			title: '',
			sorter: false,
			render: (_text: any, record: IWallet) => {
				return (
					<>
						<DgTableColumnResponsive title={t('name')} value={record?.name} />
						<DgTableColumnResponsive
							title={t('balance')}
							value={<DgPrice price={record?.balance} colored />}
						/>
						<DgTableColumnResponsive
							title={t('total_received')}
							value={<DgPrice price={record?.total_received} colored />}
						/>
						<DgTableColumnResponsive
							title={t('total_spent')}
							value={<DgPrice price={record?.total_spent} colored />}
						/>
						<DgTableColumnResponsive
							title={t('total_transfer')}
							value={<DgPrice price={record?.total_transfer} colored />}
						/>

						<DgTableColumnResponsive
							title={t('transactions_count')}
							value={<DgPrice suffix='' price={record.transactions_count} />}
						/>
						<DgTableColumnResponsive
							title={t('transactions_received_count')}
							value={<DgPrice suffix='' price={record.transactions_received_count} />}
						/>
						<DgTableColumnResponsive
							title={t('transactions_spent_count')}
							value={<DgPrice suffix='' price={record.transactions_spent_count} />}
						/>
						<DgTableColumnResponsive
							title={t('transfers_count')}
							value={<DgPrice suffix='' price={record.transactions_count} />}
						/>

						<DgTableColumnResponsive
							title={t('operation')}
							value={
								<div className='gap-y-4 flex flex-col items-end'>
									{/* <DgButton
										type='default'
										className='text-white min-w-[110px] hover:bg-dg-wallet-button-hover hover:border-dg-wallet-button-hover bg-dg-wallet-button border-dg-wallet-button'
										customTitle={
											<div className='flex items-center gap-x-2'>
												<FaArrowAltCircleUp size={20} className='relative bottom-0.5 text-dg-green-color' />
												<Text tid='deposit' />
											</div>
										}
									/>

									<DgButton
										type='default'
										className='text-white min-w-[110px] hover:bg-dg-wallet-button-hover hover:border-dg-wallet-button-hover bg-dg-wallet-button border-dg-wallet-button'
										customTitle={
											<div className='flex items-center gap-x-2'>
												<FaArrowAltCircleDown size={20} className='relative bottom-0.5 text-dg-red-color' />
												<Text tid='withdraw' />
											</div>
										}
									/> */}

									{record?.name === 'Deposit Wallet' && (
										<DgButton
											type='default'
											className='text-white min-w-[110px] hover:bg-dg-wallet-button-hover hover:border-dg-wallet-button-hover bg-dg-wallet-button border-dg-wallet-button'
											customTitle={
												<div className='flex items-center gap-x-2'>
													<BiTransfer
														size={20}
														className='relative bottom-0.5 text-[var(--dg-primary-color)]'
													/>
													<Text tid='transfer' />
												</div>
											}
											onClick={() =>
												navigate(
													routes.ADMIN_USER_Detail_PAGE.link(
														userId,
														'increase-wallet-balance'
													)
												)
											}
										/>
									)}
								</div>
							}
						/>
					</>
				);
			},
		},
	];

	return width > 769 ? Columns : RColumn;
};

export default AdminUserWalletsTableColumn;
