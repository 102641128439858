import { RootState } from 'core/store';
import { setApiCatcherFormErrors } from 'core/store/slice/mainInfo/mainInfoSlice';
import { removeArrayElement } from 'core/utils/utils';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateUserInfoQuery } from '../../UserInfo/hooks/react-query/updateUserInfoQuery';
import toastHandler from 'core/helpers/toast/toast';
import useTranslate from 'core/utils/useTranslate';
import { useQueryClient } from '@tanstack/react-query';
import { endPointUrls } from '../../../../../../core/constants/endPointUrls';
import useModal from 'core/hooks/useModal';

const useUserRole = () => {
	//hooks
	const dispatch = useDispatch();
	const { t } = useTranslate();
	const queryClient = useQueryClient();
	const { modal, hideModal, showModal } = useModal();

	// store
	const { userDetail } = useSelector((store: RootState) => store.userInfoStore);

	// state
	const [roles, setRoles] = useState<number[]>([]);

	//query
	const { mutate: updateUserRole, isLoading: isLoadingUserRole } = updateUserInfoQuery(
		() => {
			hideModal();
			queryClient.invalidateQueries([endPointUrls.ADMIN_USER()]);
		}
	);

	useEffect(() => {
		let fourHiArray: number[] = [];

		if (userDetail?.data.roles?.length) {
			for (let i = 0; i < userDetail?.data.roles?.length; i++) {
				fourHiArray = [...fourHiArray, userDetail?.data.roles?.[i]?.id];
			}
		}

		setRoles(fourHiArray);
	}, [userDetail]);

	const onChangeRoles = (roleId: number) => {
		setRoles((prevState: number[]) => [...prevState, roleId]);
	};

	const onDeselectRole = (roleId: number) => {
		setRoles([...removeArrayElement(roles, roleId)]);
	};

	const onFinishUpdateUser = () => {
		if (roles.length === 0) return toastHandler('error', t('select-one-role'));
		updateUserRole({ role_ids: roles, user_id: userDetail?.data.id });
	};

	const hideModalHandler = () => {
		hideModal();
		dispatch(setApiCatcherFormErrors([]));
	};

	return {
		modal,
		hideModalHandler,
		showModal,
		isLoadingUserRole,
		onFinishUpdateUser,
		onDeselectRole,
		onChangeRoles,
		roles,
	};
};

export default useUserRole;
