import useTranslate from 'core/utils/useTranslate';
import DgSelectLabel from 'styles/UI/Select/DgSelectLabel';

const DgSelectPlanCommissionType = ({ label = '', ...props }) => {
	const { t } = useTranslate();
	const options = [
		{ label: t('invest-profit'), value: 'invest-profit-commission', filter: t('invest-profit') },
		{ label: t('marketing-profit'), value: 'marketing-profit-commission', filter: t('marketing-profit') },
		{ label: t('team-profit'), value: 'team-profit-commission', filter: t('team-profit') },
		{ label: t('flush'), value: 'flush-commission', filter: t('flush') },
		{ label: t('cooperation-profit'), value: 'cooperation-profit-commission', filter: t('cooperation-profit') },
	];

	return <DgSelectLabel label={label} options={options} {...props} />;
};

export default DgSelectPlanCommissionType;
