import { UseQueryResult } from '@tanstack/react-query';
import { endPointUrls } from 'core/constants/endPointUrls';
import api from 'core/services/fetch-api/useApi';
import useQueryHook from 'core/hooks/masterQuery/useQueryHook';
import useQueryUrlParam from 'core/hooks/useQueryUrlParam';
import { useParams } from 'react-router-dom';
import { IEmailVerificationHistoryList } from 'core/types/models/userHistoryType';

export const useUserEmailVerificationHistoryQuery = () => {
	const { filterParams } = useQueryUrlParam();
	const { userId } = useParams();

	return useQueryHook(
		[endPointUrls.ADMIN_USER_EMAIL_VERIFICATION_HISTORY(userId), filterParams],
		() =>
			api.get(endPointUrls.ADMIN_USER_EMAIL_VERIFICATION_HISTORY(userId, filterParams)),
		{
			select: (res: any) => res?.data,
		}
	) as UseQueryResult<IEmailVerificationHistoryList, Error>;
};
