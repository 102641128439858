import { Checkbox, Form } from 'antd';
import UserWalletHeader from 'components/UserWallets/components/UserWalletHeader';
import DgSelectWithdrawCurrencies from 'components/UserWithdraw/components/DgSelectWithdrawCurrencies';
import { useRunAfterUpdate } from 'core/hooks/useRunAfterUpdate';
import Text from 'core/utils/Text';
import { onInputValueChangeUtil } from 'core/utils/useInputValueChange';
import { FormEvent, useEffect, useState } from 'react';
import { BiRecycle } from 'react-icons/bi';
import DgCard from 'styles/UI/Card/DgCard';
import DgForm from 'styles/UI/Form/DgForm';
import DgFormItem from 'styles/UI/FormItem/DgFormItem';
import DgFormWrapper from 'styles/UI/FormWrapper/DgFormWrapper';
import DgImage from 'styles/UI/Image/DgImage';
import DgInputLabel from 'styles/UI/Input/DgInputLabel';
import usdt from '../../assets/images/usdt.png';
import useTranslate from 'core/utils/useTranslate';
import DgButton from 'styles/UI/Button/DgButton';
import useProfile from 'components/Profile/hooks/useProfile';
import { useInternalTransferQuery } from './hooks/react-query/useInteralTransferQuery';
import { extractNumberFromString } from 'core/utils/utils';
import { useInternalTransferPreviewQuery } from './hooks/react-query/useInteralTransferPreviewQuery';
import DgInputPasswordLabel from 'styles/UI/Input/DgInputPasswordLabel';
import UserInternalTransferHistory from 'components/UserInternalTransfer/List/UserInternalTransferHistory';
import { useUserInternalTransferHistoryQuery } from 'components/UserInternalTransfer/List/hooks/react-query/useUserInternalTransferHistoryQuery';
import { getCurrentUserInfoQuery } from 'core/hooks/react-query/getCurrentUserInfoQuery';
import { getUserWalletsQuery } from 'core/hooks/react-query/getUserWalletsQuery';
import Google2faAlert from 'components/HeaderAlert/components/Google2faAlert';
import DgModal from 'styles/UI/Modal/DgModal';
import UserInternalTranferInvoice from './UserInternalTransferInvoice';
import useModal from 'core/hooks/useModal';

const UserInternalTransferPageList = () => {
	const historyQuery = useUserInternalTransferHistoryQuery();
	const walletQuery = getUserWalletsQuery();
	const profileQuery = getCurrentUserInfoQuery();

	const { t } = useTranslate();

	const { userInfo } = useProfile();

	const [emailForm] = Form.useForm();
	const [form] = Form.useForm();
	const runAfterUpdate = useRunAfterUpdate();

	const internalTransfer = useInternalTransferQuery();
	const internalTransferPreview = useInternalTransferPreviewQuery();

	const [amount, setAmount] = useState<string>('');
	const [type, setType] = useState<number>(0);
	const [step, setStep] = useState<number>(1);
	const [rulesAccepted, setRulesAccepted] = useState<boolean>(false);

	const { data: currentUserInfo, isFetching } = getCurrentUserInfoQuery();

	const types = [
		{ title: 'transfer-to-exchange', value: 'out' },
		{ title: 'deposit-from-exchange', value: 'in' },
	];

	const activeType = types[type];

	useEffect(() => {
		emailForm.resetFields();
	}, [userInfo?.data?.email]);

	useEffect(() => {
		// profileQuery.refetch();
		// walletQuery.refetch();
	}, []);

	const onFinish = async (values: any) => {
		if (step === 1) {
			await internalTransferPreview.mutateAsync({
				...values,
				amount: extractNumberFromString(values.amount),
				currency: 'USDT',
				type: activeType.value,
			});

			showModal();

			// setStep(2);
		} else if (step === 2) {
			await internalTransfer.mutateAsync({
				...values,
				amount: extractNumberFromString(values.amount),
				currency: 'USDT',
				type: activeType.value,
			});

			historyQuery.refetch();
			walletQuery.refetch();
			setStep(1);
			form.resetFields();
		}
	};

	const { modal, showModal, hideModal } = useModal();

	return (
		<>
			<DgModal open={modal} closable={false} className='wallet-invoice' width={1000}>
				<UserInternalTranferInvoice
					callback={() => {
						setStep(2);
						hideModal();
					}}
					isLoading={false}
					data={{
						...form.getFieldsValue(true),
						email: userInfo?.data?.email || '',
						currency: 'USDT',
						type: types[type].title,
					}}
					onCancel={hideModal}
				/>
			</DgModal>

			<div className='flex flex-col gap-5'>
				<UserWalletHeader showActions={false} />

				<DgCard
					hasBackground={false}
					className='border-2 border-dashed border-[var(--dg-opposite-color)] max-w-2xl mx-auto'
				>
					<span>
						<Text
							tid='internal-transfer'
							className='text-[var(--dg-primary-color)] font-bold'
						/>
					</span>

					<BiRecycle size={50} className='mx-auto' />

					<div className='px-5 mx-auto flex flex-col gap-4'>
						<div className='text-center my-7 flex flex-col gap-3'>
							<Text tid='internal-transfer-title' className='text-lg font-bold' />
							<Text tid='internal-transfer-select-currencies-and-amount' />
						</div>

						<div className='gap-3 flex items-center flex-wrap'>
							{types.map((item, index) => {
								const isActive = type === index;
								const isDisabled = internalTransferPreview.isLoading || step === 2;

								return (
									<DgButton
										htmlType='button'
										height='h-[60px]'
										className={`grow ${
											isDisabled ? 'pointer-events-none opacity-50' : ''
										}`}
										onClick={() => setType(index)}
										ghost={!isActive}
										key={index}
										title={t(item.title)}
									/>
								);
							})}
						</div>

						<DgForm
							shouldUpdate={true}
							disabled={internalTransferPreview.isLoading || step === 2}
							form={form}
							onFinish={onFinish}
							initialValues={{ email: userInfo?.data?.email || '' }}
						>
							<DgFormWrapper singleColumn>
								<DgFormItem>
									<DgSelectWithdrawCurrencies label='currency' />
								</DgFormItem>

								<DgFormItem
									rules={[{ required: true, message: t('amount-is-required') }]}
									name='amount'
								>
									<DgInputLabel
										value={amount}
										onChange={(e: FormEvent<HTMLInputElement>) => {
											setAmount(onInputValueChangeUtil(e, runAfterUpdate));
										}}
										label='amount'
										suffix={
											<DgImage
												src={usdt}
												width={22}
												className='rounded-full relative top-1.5'
											/>
										}
									/>
								</DgFormItem>

								<DgForm form={emailForm} disabled={true}>
									<DgFormItem
										// rules={[
										// 	{ required: true, message: t('destination-email-is-required') },
										// ]}
										name='destination-email'
										initialValue={userInfo?.data?.email || ''}
									>
										<DgInputLabel label='destination-email' />
									</DgFormItem>
								</DgForm>

								{step === 1 && (
									<DgButton
										isLoading={internalTransferPreview.isLoading}
										disabled={internalTransferPreview.isLoading}
										title='next'
										htmlType='submit'
										className='mt-5 w-full'
									/>
								)}
							</DgFormWrapper>
						</DgForm>

						{step === 2 && (
							<>
								<DgForm
									disabled={internalTransfer.isLoading}
									form={form}
									onFinish={onFinish}
								>
									<DgFormWrapper singleColumn>
										<DgFormItem
											full
											name='transaction_password'
											rules={[
												{
													required: true,
													message: t('transaction-password-is-required'),
												},
											]}
										>
											<DgInputPasswordLabel
												label='transaction-password'
												autoComplete='new-password'
												required
											/>
										</DgFormItem>

										{!isFetching && !currentUserInfo?.data?.google2fa_enable ? (
											<Google2faAlert />
										) : (
											<>
												<DgFormItem
													name='one_time_password'
													rules={[
														{
															required: true,
															message: t('totp-verification-code-is-required'),
														},
													]}
												>
													<DgInputLabel label='totp-verification-code' required />
												</DgFormItem>
											</>
										)}

										<Checkbox
											checked={rulesAccepted}
											onChange={(e) => {
												setRulesAccepted(e.target.checked);
											}}
										>
											{t('i-agree-the-rules')}
										</Checkbox>

										<div className='gap-3 flex items-center flex-wrap'>
											<DgButton
												isLoading={internalTransfer.isLoading}
												disabled={internalTransfer.isLoading}
												onClick={() => setStep(1)}
												title='back'
												ghost={true}
												htmlType='button'
												// className='grow'
											/>

											<DgButton
												isLoading={internalTransfer.isLoading}
												disabled={internalTransfer.isLoading || !rulesAccepted}
												title='submit'
												htmlType='submit'
												className='grow'
											/>
										</div>
									</DgFormWrapper>
								</DgForm>
							</>
						)}
					</div>
				</DgCard>

				<UserInternalTransferHistory />
			</div>
		</>
	);
};

export default UserInternalTransferPageList;
