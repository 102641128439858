export function slugify(str) {
	// Handle Persian characters
	const persianMap = {
		آ: 'a',
		ا: 'a',
		ب: 'b',
		پ: 'p',
		ت: 't',
		ث: 'th',
		ج: 'j',
		چ: 'ch',
		ح: 'h',
		خ: 'kh',
		د: 'd',
		ذ: 'z',
		ر: 'r',
		ز: 'z',
		ژ: 'zh',
		س: 's',
		ش: 'sh',
		ص: 's',
		ض: 'z',
		ط: 't',
		ظ: 'z',
		ع: 'e',
		غ: 'gh',
		ف: 'f',
		ق: 'q',
		ک: 'k',
		گ: 'g',
		ل: 'l',
		م: 'm',
		ن: 'n',
		و: 'v',
		ه: 'h',
		ی: 'y',
		ئ: 'y',
	};

	const combinedMap = { ...persianMap };

	// Handle English characters
	for (let i = 0; i < 26; i++) {
		const char = String.fromCharCode(65 + i); // Uppercase
		const lowerChar = String.fromCharCode(97 + i); // Lowercase
		combinedMap[char] = lowerChar;
	}

	// Replace characters based on the map
	const slug = str.replace(/[آ-یA-Za-z]/g, (char) => combinedMap[char] || '');

	// Remove any non-word characters and trim
	return slug
		.replace(/\W+/g, '-')
		.replace(/^-+|-+$/g, '')
		.toLowerCase();
}
