import DgTable from 'styles/UI/Table/DgTable';
import DgPagination from 'styles/UI/Pagination/DgPagination';
import useQueryUrlParam from 'core/hooks/useQueryUrlParam';
import userUserActivity from '../hooks/userUserActivity';
import UserActivityTableColumn from './Column/UserActivityTableColumn';

const UserActivityTable = () => {
	const { userActivities, userActivityIsFetching } = userUserActivity();
	const { query, handlePagination } = useQueryUrlParam();

	return (
		<>
			<DgTable
				rowKey='id'
				columns={UserActivityTableColumn()}
				dataSource={userActivities?.data?.list}
				count={userActivities?.data.pagination.total}
				loading={userActivityIsFetching}
				current={userActivities?.data.pagination.current_page}
				per_page={query?.per_page}
			/>
			<DgPagination
				onPaginationHandler={handlePagination}
				responsive={true}
				per_page={query?.per_page}
				current={userActivities?.data.pagination.current_page}
				total={userActivities?.data.pagination.total}
			/>
		</>
	);
};

export default UserActivityTable;
