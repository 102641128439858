import React, { Fragment, useEffect, useState } from 'react';
import DgButton from 'styles/UI/Button/DgButton';
import DgForm from 'styles/UI/Form/DgForm';
import DgFormItem from 'styles/UI/FormItem/DgFormItem';
import DgFormWrapper from 'styles/UI/FormWrapper/DgFormWrapper';
import DgInputLabel from 'styles/UI/Input/DgInputLabel';
import useAdminGroupUpdate from './useAdminGroupUpdate';
import DgImage from 'styles/UI/Image/DgImage';
import usdt from '../../../../assets/images/usdt.png';
import useTranslate from 'core/utils/useTranslate';
import { HintBox } from 'components/hint-box';
// import {
// 	useAdminGroupCommissions,
// 	useAdminGroupCommissionsList,
// } from './hooks/react-query/useAdminGroupCommissions';
import { useSelector } from 'react-redux';
import { RootState } from 'core/store';
// import { GroupCommissions } from '../group-commissions';
import DgSpinner from 'styles/UI/Spinner/DgSpinner';
// import {
// 	useAdminGroupCommissions,
// 	useAdminGroupCommissionsList,
// } from 'components/Admin/Group/Update/hooks/react-query/useAdminGroupCommissions';

const AdminGroupsUpdate = ({ hideModalHandler }: { hideModalHandler: () => void }) => {
	const { groupDetail } = useSelector((store: RootState) => store.groupsStore);

	const { updateGroupForm, isLoadingUpdateGroup, onFinishUpdateGroup } =
		useAdminGroupUpdate(hideModalHandler);
	const { t } = useTranslate();

	const onFinish = (values: any) => {
		onFinishUpdateGroup(values);
	};

	return (
		<>
			<DgForm
				shouldUpdate={true}
				autoComplete='off'
				onFinish={onFinish}
				form={updateGroupForm}
			>
				<DgFormWrapper singleColumn>
					<DgFormItem
						name='name'
						rules={[
							{
								required: true,
								message: t('name-is-required'),
							},
						]}
					>
						<DgInputLabel label='name' />
					</DgFormItem>
				</DgFormWrapper>

				<DgFormItem button>
					<DgButton
						htmlType='submit'
						isLoading={isLoadingUpdateGroup}
						title='update-group'
					/>
				</DgFormItem>
			</DgForm>
		</>
	);
};

export default AdminGroupsUpdate;
