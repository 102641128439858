import DgSelectLabel from 'styles/UI/Select/DgSelectLabel';

const DgSelectBoolean = ({ label = '', ...props }) => {
	const options = [
		{ label: 'Yes', value: 1, filter: 'Yes' },
		{ label: 'No', value: 0, filter: 'No' },
	];

	return <DgSelectLabel label={label} options={options} {...props} />;
};

export default DgSelectBoolean;
