import { Breadcrumb } from 'antd';
import { routes } from 'core/constants/routes';
import useMasterQueryUrlParam from 'core/hooks/useMasterQueryUrlParam';
import { breadcrumbText } from 'core/utils/utils';
import React from 'react';
import { AiOutlineHome } from 'react-icons/ai';
import { Link } from 'react-router-dom';

const DgBreadcrumb = () => {
	const rootProfile = ['package'];
	const { pathnameArray, pathnameLastPart } = useMasterQueryUrlParam();
	const pathNames = pathnameArray.slice(2, pathnameArray.length - 1);
	let url = '';

	const newPathNames = pathNames.filter((item) => !rootProfile.includes(item));

	return (
		<Breadcrumb>
			<Breadcrumb.Item>
				<Link to={routes.DASHBOARD}>
					<AiOutlineHome className='inline-block' size={20} />
				</Link>
			</Breadcrumb.Item>

			{newPathNames.map((item) => {
				url += `/${item}`;
				return (
					<Breadcrumb.Item key={item}>
						<Link to={`/panel${url}`}>{breadcrumbText(item)}</Link>
					</Breadcrumb.Item>
				);
			})}

			<Breadcrumb.Item className='text-[var(--dg-disabled-color-light)]'>{breadcrumbText(pathnameLastPart || '')}</Breadcrumb.Item>
		</Breadcrumb>
	);
};

export default DgBreadcrumb;
