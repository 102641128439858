import { MenuProps } from 'antd';
import { routes } from 'core/constants/routes';
import Text from 'core/utils/Text';
import React from 'react';
import { Link } from 'react-router-dom';

const UserTableQuickAccess = ({ userId }: { userId: number }) => {
	const items: MenuProps['items'] = [
		{
			key: '1',
			label: (
				<Link
					className='py-2 px-3 w-full hover:bg-[var(--dg-card-bg-2)] bg-[var(--dg-card-bg-1)] border-b border-[var(--dg-border-color)] inline-block'
					to={routes.ADMIN_USER_Detail_PAGE.link(userId, 'user-status')}
				>
					<Text tid='verify-user' />
				</Link>
			),
		},
		{
			key: '2',
			label: (
				<Link
					className='py-2 px-3 w-full hover:bg-[var(--dg-card-bg-2)] bg-[var(--dg-card-bg-1)] border-b border-[var(--dg-border-color)] inline-block'
					to={routes.ADMIN_USER_Detail_PAGE.link(userId, 'user-wallets')}
				>
					<Text tid='user-wallets' />
				</Link>
			),
		},
		{
			key: '3',
			label: (
				<Link
					className='py-2 px-3 w-full hover:bg-[var(--dg-card-bg-2)] bg-[var(--dg-card-bg-1)] border-b border-[var(--dg-border-color)] inline-block'
					to={routes.ADMIN_USER_Detail_PAGE.link(userId, 'user-security')}
				>
					<Text tid='user-security' />
				</Link>
			),
		},
	];
	return items;
};

export default UserTableQuickAccess;
