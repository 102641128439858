import DgCard from 'styles/UI/Card/DgCard';
import DgCardHeader from 'styles/UI/CardHeader/DgCardHeader';
import UserEarningWalletTable from './components/UserEarningWalletTable';

const UserEarningWalletTransactions = () => {
	return (
		<DgCard>
			<DgCardHeader title='earning-wallet-history' tabTitle='earning-wallet' />

			<UserEarningWalletTable />
		</DgCard>
	);
};

export default UserEarningWalletTransactions;
