import { Form } from 'antd';
import { useEffect } from 'react';
import useQueryUrlParam from 'core/hooks/useQueryUrlParam';
import DgFilter from 'styles/UI/Filter/DgFilter';
import DgDatePickerLabel from 'styles/UI/DatePicker/DgDatePickerLabel';
import { filterOptionsType } from 'core/types/components/filterType';
import DgInputLabel from 'styles/UI/Input/DgInputLabel';
import { useWindowSize } from 'core/hooks/useWindowSize';

const UserTransferFilter = () => {
	const { query, resetQuery } = useQueryUrlParam();
	const { width } = useWindowSize();

	const filterOptions: filterOptionsType[] = [
		...(width < 769
			? [
					{
						filterTitle: 'fee',
						filterNames: ['type'],
						formAttribute: Form.useForm(),
						sortNames: ['fee'],
						query,
					},
			  ]
			: [{ filterTitle: '', formAttribute: Form.useForm(), query, filterNames: [] }]),
		{
			filterTitle: 'date-time',
			filterNames: ['created_at_from', 'created_at_to'],
			filterItems: [<DgDatePickerLabel key='date-from' label='date-from' />, <DgDatePickerLabel key='date-to' label='date-to' />],
			formAttribute: Form.useForm(),
			query,
			sortNames: ['created_at'],
		},
		{
			filterTitle: 'destination',
			filterNames: ['from_first_name', 'from_last_name', 'from_member_id'],
			filterItems: [
				<DgInputLabel key='destination-first-name' label='destination-first-name' />,
				<DgInputLabel key='destination-last-name' label='destination-last-name' />,
				<DgInputLabel key='destination-referral-code' label='destination-referral-code' />,
			],
			formAttribute: Form.useForm(),
			query,
		},
		{
			filterTitle: 'origin',
			filterNames: ['to_first_name', 'to_last_name', 'to_member_id'],
			filterItems: [
				<DgInputLabel key='origin-first-name' label='origin-first-name' />,
				<DgInputLabel key='origin-last-name' label='origin-last-name' />,
				<DgInputLabel key='origin-referral-code' label='origin-referral-code' />,
			],
			formAttribute: Form.useForm(),
			query,
		},
	];

	useEffect(() => {
		filterOptions.map((item) => {
			const { formAttribute } = item;
			const [form] = formAttribute;

			form.setFieldsValue({ ...query });
		});
	}, []);

	return <DgFilter width={width} filterOptions={filterOptions} resetQuery={() => resetQuery(undefined, { tab: query?.tab }, filterOptions)} />;
};

export default UserTransferFilter;
