import { Form } from 'antd';
import { useEffect } from 'react';
import useQueryUrlParam from 'core/hooks/useQueryUrlParam';
import DgFilter from 'styles/UI/Filter/DgFilter';
import { filterOptionsType } from 'core/types/components/filterType';
import DgInputLabel from 'styles/UI/Input/DgInputLabel';

const UserActivityFilter = () => {
	const { query, resetQuery } = useQueryUrlParam();

	const filterOptions: filterOptionsType[] = [
    {
			filterTitle: 'first-name',
			filterNames: ['first_name'],
			filterItems: [<DgInputLabel key='first-name' label='first-name' />],
			formAttribute: Form.useForm(),
			query,
		},
    {
			filterTitle: 'last-name',
			filterNames: ['last_name'],
			filterItems: [<DgInputLabel key='last-name' label='last-name' />],
			formAttribute: Form.useForm(),
			query,
		},
		{
			filterTitle: 'ip',
			filterNames: ['ip'],
			filterItems: [<DgInputLabel key='ip' label='ip' />],
			formAttribute: Form.useForm(),
			query,
		},
	];

	useEffect(() => {
		filterOptions.map((item) => {
			const { formAttribute } = item;
			const [form] = formAttribute;

			form.setFieldsValue({ ...query });
		});
	}, []);

	return <DgFilter filterOptions={filterOptions} resetQuery={() => resetQuery(undefined, { tab: query?.tab }, filterOptions)} />;
};

export default UserActivityFilter;
