import { Badge, DatePicker } from 'antd';
import DgCard from 'styles/UI/Card/DgCard';
import Text from 'core/utils/Text';
import { BsCalculator } from 'react-icons/bs';
import { useState } from 'react';
import dayjs from 'dayjs';
import DgButton from 'styles/UI/Button/DgButton';
import {
	useAdminSumInvestQuery,
	useAdminSumCommissionsQuery,
	useAdminAccountingQueries,
} from './hooks/react-query/useAdminAccountingQuery';
import DgDataCard from 'styles/UI/DataCard/DgDataCard';
import DashboardTitle from 'components/Dashboard/components/DashboardTitle/DashboardTitle';
import DgPrice from 'styles/UI/Price/DgPrice';

const { RangePicker } = DatePicker;

const AdminAccounting = () => {
	const [startDate, setStartDate] = useState(undefined);
	const [endDate, setEndDate] = useState(undefined);

	const {
		bestLeastSeller,
		sumCommissions,
		sumIncomes,
		sumInvest,
		sumOrders,
		sumUsers,
		withdrawCounts,
	} = useAdminAccountingQueries();

	const {
		isLoading,
		data,
		mutate: mutateAdminAccountingQuery,
	} = useAdminSumInvestQuery();

	const adminSumCommissions = useAdminSumCommissionsQuery();

	const submit = () => {
		const data = {
			from_date: dayjs(startDate).format('YYYY-MM-DD'),
			to_date: dayjs(endDate).format('YYYY-MM-DD'),
		};
		// mutateAdminAccountingQuery(data);
		// adminSumCommissions.mutate(data);
		bestLeastSeller.mutate(data);
		sumCommissions.mutate(data);
		sumIncomes.mutate(data);
		sumInvest.mutate(data);
		sumOrders.mutate(data);
		sumUsers.mutate(data);
		withdrawCounts.mutate(data);
	};

	// console.log({
	// 	a: { startDate, endDate, isLoading, data },
	// 	bestLeastSeller: bestLeastSeller.data?.data?.data,
	// 	sumCommissions: sumCommissions.data?.data?.data,
	// 	sumIncomes: sumIncomes.data?.data?.data,
	// 	sumInvest: sumInvest.data?.data?.data,
	// 	sumOrders: sumOrders.data?.data?.data,
	// 	sumUsers: sumUsers.data?.data?.data,
	// 	withdrawCounts: withdrawCounts.data?.data?.data,
	// });

	return (
		<>
			<div className='grid grid-cols-1 lg:grid-cols-3 gap-4 max-w-xl lg:max-w-none m-auto'>
				<div className='col-span-1'>
					<DgCard className='flex flex-col items-center justify-center gap-4'>
						<span>
							<Text
								tid='accounting'
								className='text-[var(--dg-orange-color)] font-bold text-2xl'
							/>
						</span>

						<BsCalculator size={50} className='mx-auto' />

						<div className='flex flex-col gap-4 w-full max-w-sm'>
							<div className='text-center flex flex-col gap-3'>
								{/* <Text tid='withdraw-title' className='text-lg font-bold' /> */}
								<Text tid='accounting-info' />
							</div>

							<RangePicker
								onChange={(value) => {
									if (!!value?.length) {
										setStartDate(dayjs(value[0]));
										setEndDate(dayjs(value[1]));
									} else {
										setStartDate(undefined);
										setEndDate(undefined);
									}
								}}
								className='w-full'
							/>

							<DgButton
								title='calculate'
								className='w-full'
								htmlType='button'
								type='default'
								loading={isLoading}
								disabled={!startDate || !endDate}
								onClick={() => submit()}
							/>
						</div>
					</DgCard>
				</div>

				<div className='lg:col-span-2 col-span-1 grid lg:grid-cols-2 grid-cols-1 gap-4'>
					{[
						{
							label: 'sumCommissions',
							component: () => {
								return (
									<div dir='ltr'>
										<DgPrice price={sumCommissions?.data?.data?.data} />
									</div>
								);
							},
							queryObject: sumCommissions,
						},
						{
							label: 'sumIncomes',
							component: () => {
								return (
									<div dir='ltr'>
										<DgPrice price={sumIncomes?.data?.data?.data?.income_sum} />
									</div>
								);
							},
							queryObject: sumIncomes,
						},
						// {
						// 	label: 'sumInvest',
						// 	component: () => {
						// 		return (
						// 			<div dir='ltr'>
						// 				<DgPrice price={sumInvest?.data?.data?.data as any} />
						// 			</div>
						// 		);
						// 	},
						// 	queryObject: sumInvest,
						// },
						{
							label: 'sumOrders',
							component: () => {
								return (
									<div dir='ltr'>
										<DgPrice
											price={sumOrders?.data?.data?.data?.paid_orders_sum as any}
										/>
									</div>
								);
							},
							queryObject: sumOrders,
						},
						{
							label: 'sumUsers',
							component: () => {
								return (
									<div>
										<Text tid={(sumUsers?.data?.data?.data?.users_sum as any) || 0} />{' '}
										<Text tid={'users'} />
									</div>
								);
							},
							queryObject: sumUsers,
						},
						{
							label: 'bestLeastSeller',
							component: () => {
								const data = bestLeastSeller?.data?.data?.data;

								return (
									<div>
										{!!data?.length && (
											<div className='flex flex-col gap-2'>
												<Badge count={data[0]?.name} color='var(--dg-green-color)' />
												<Badge
													count={data[data.length - 1]?.name}
													color='var(--dg-red-color)'
												/>
											</div>
										)}
									</div>
								);
							},
							queryObject: bestLeastSeller,
						},
						// {
						// 	label: 'withdraw_count_all_requests',
						// 	component: () => {
						// 		return (
						// 			<div>
						// 				<Text
						// 					tid={
						// 						(withdrawCounts?.data?.data?.data?.count_all_requests as any) || 0
						// 					}
						// 				/>{' '}
						// 				<Text tid={'requests'} />
						// 			</div>
						// 		);
						// 	},
						// 	queryObject: withdrawCounts,
						// },
						// {
						// 	label: 'withdraw_pending_requests',
						// 	component: () => {
						// 		return (
						// 			<div className='flex flex-col items-center justify-center gap-2'>
						// 				<div>
						// 					<Text
						// 						tid={
						// 							(withdrawCounts?.data?.data?.data
						// 								?.count_pending_requests as any) || 0
						// 						}
						// 					/>{' '}
						// 					<Text tid={'requests'} />
						// 				</div>

						// 				<DgPrice
						// 					price={
						// 						withdrawCounts?.data?.data?.data?.sum_amount_pending_requests
						// 					}
						// 				/>
						// 			</div>
						// 		);
						// 	},
						// 	queryObject: withdrawCounts,
						// },
						// {
						// 	label: 'withdraw_postponed_requests',
						// 	component: () => {
						// 		return (
						// 			<div className='flex flex-col items-center justify-center gap-2'>
						// 				<div>
						// 					<Text
						// 						tid={
						// 							(withdrawCounts?.data?.data?.data
						// 								?.count_postponed_requests as any) || 0
						// 						}
						// 					/>{' '}
						// 					<Text tid={'requests'} />
						// 				</div>

						// 				<DgPrice
						// 					price={
						// 						withdrawCounts?.data?.data?.data?.sum_amount_postponed_requests
						// 					}
						// 				/>
						// 			</div>
						// 		);
						// 	},
						// 	queryObject: withdrawCounts,
						// },
						{
							label: 'withdraw_processed_requests',
							component: () => {
								return (
									<div className='flex flex-col items-center justify-center gap-2'>
										<div>
											<Text
												tid={
													(withdrawCounts?.data?.data?.data
														?.count_processed_requests as any) || 0
												}
											/>{' '}
											<Text tid={'requests'} />
										</div>

										<DgPrice
											price={
												withdrawCounts?.data?.data?.data?.sum_amount_processed_requests
											}
										/>
									</div>
								);
							},
							queryObject: withdrawCounts,
						},
						// {
						// 	label: 'withdraw_rejected_requests',
						// 	component: () => {
						// 		return (
						// 			<div className='flex flex-col items-center justify-center gap-2'>
						// 				<div>
						// 					<Text
						// 						tid={
						// 							(withdrawCounts?.data?.data?.data
						// 								?.count_rejected_requests as any) || 0
						// 						}
						// 					/>{' '}
						// 					<Text tid={'requests'} />
						// 				</div>

						// 				<DgPrice
						// 					price={
						// 						withdrawCounts?.data?.data?.data?.sum_amount_rejected_requests
						// 					}
						// 				/>
						// 			</div>
						// 		);
						// 	},
						// 	queryObject: withdrawCounts,
						// },
					].map((item) => {
						const { queryObject, label, component } = item;

						return (
							<DgDataCard
								key={label}
								loading={queryObject.isLoading}
								className={'col-span-1 flex flex-col gap-4 justify-between items-center'}
							>
								<DashboardTitle>
									<Text tid={label} />
								</DashboardTitle>
								<div className={'px-4'}>{component()}</div>
							</DgDataCard>
						);
					})}
				</div>
			</div>
		</>
	);
};

export default AdminAccounting;
