import React from 'react';
import DgCard from 'styles/UI/Card/DgCard';
import DgCardHeader from 'styles/UI/CardHeader/DgCardHeader';
import AdminUserWalletsTable from './components/AdminUserWalletsTable';
import UserChargeWallets from '../UserChargeWallets/UserChargeWallets';

const UserWallets = () => {
	return (
		<DgCard>
			<DgCardHeader title='user-wallets' />

			<AdminUserWalletsTable />

			<UserChargeWallets />
		</DgCard>
	);
};

export default UserWallets;
