import DgTable from 'styles/UI/Table/DgTable';
import { useAdminProductQuery } from '../hooks/react-query/useAdminProductQuery';
import AdminProductTableColumn from './AdminProductTableColumn';

const AdminProductTable = ({
	onEditSettingsHandler,
	onDeleteProductHandler,
}: {
	onEditSettingsHandler?: (val: any) => void;
	onDeleteProductHandler?: (val: any) => void;
}) => {
	const { data: products, isFetching: productIsFetching } = useAdminProductQuery();

	return (
		<DgTable
			rowKey='id'
			columns={AdminProductTableColumn(onEditSettingsHandler, onDeleteProductHandler)}
			dataSource={products?.data}
			count={products?.data.length}
			loading={productIsFetching}
		/>
	);
};

export default AdminProductTable;
