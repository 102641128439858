

export const questions = [
    {
        title: 'faq-title-1',
        desc: 'faq-description-1'
    },
    {
        title: 'faq-title-2',
        desc: 'faq-description-2'
    },
    {
        title: 'faq-title-3',
        desc: 'faq-description-3'
    },
    {
        title: 'faq-title-4',
        desc: 'faq-description-4'
    },
    {
        title: 'faq-title-5',
        desc: 'faq-description-5'
    },
    {
        title: 'faq-title-6',
        desc: 'faq-description-6'
    },
    {
        title: 'faq-title-7',
        desc: 'faq-description-7'
    },
    {
        title: 'faq-title-8',
        desc: 'faq-description-8'
    },
    {
        title: 'faq-title-9',
        desc: 'faq-description-9'
    },
];
