import DgCard from '../../../styles/UI/Card/DgCard';
import DgCardHeader from '../../../styles/UI/CardHeader/DgCardHeader';
import WithdrawsTable from '../../../components/Admin/Withdraws/WithdrawsTable';


const AdminWithdraws = () => {

    return (
        <DgCard>
            <DgCardHeader title='withdraws' />
            <WithdrawsTable />
        </DgCard>
    );
};

export default AdminWithdraws;
