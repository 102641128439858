import { userDetail } from 'core/types/models/userInfo';
import { FC } from 'react';
import DgButton from 'styles/UI/Button/DgButton';
import DgImage from 'styles/UI/Image/DgImage';
import DgSpinner from 'styles/UI/Spinner/DgSpinner';
import { twMerge } from 'tailwind-merge';

interface IUserAvatar {
	avatarRef?: any;
	isLoadingUpdateAvatar?: boolean;
	onUpdateAvatarClicked?: any;
	userInfo?: userDetail;
	className?: string;
	showUploadButton?: boolean;
}

const UserAvatar: FC<IUserAvatar> = ({
	isLoadingUpdateAvatar,
	userInfo,
	avatarRef,
	onUpdateAvatarClicked,
	showUploadButton = true,
	className,
}) => {
	return (
		<div className='flex flex-col gap-y-5 justify-center items-center'>
			<div
				className={twMerge(
					`w-40 h-40 overflow-hidden rounded-full bg-[var(--dg-card-bg-2)] flex items-center justify-center text-5xl border-2 border-[var(--dg-primary-color)] mx-auto ${className}`
				)}
			>
				{isLoadingUpdateAvatar ? (
					<DgSpinner />
				) : userInfo?.data.avatar ? (
					<DgImage
						preview={true}
						src={`data:image/png;base64, ${userInfo?.data.avatar}`}
						className='object-cover h-full'
						alt='user avatar'
					/>
				) : (
					<span>{`${userInfo?.data?.first_name?.substr(0, 1).toUpperCase() || ''}.${
						userInfo?.data?.last_name?.substr(0, 1).toUpperCase() || ''
					}`}</span>
				)}
			</div>

			<input
				onChange={onUpdateAvatarClicked}
				type='file'
				ref={avatarRef}
				accept='image/jpeg, image/jpg'
				className='hidden'
			/>
			{showUploadButton && (
				<div className='flex justify-center'>
					<DgButton
						isLoading={isLoadingUpdateAvatar}
						size='small'
						htmlType='button'
						onClick={() => avatarRef?.current?.click()}
						title='upload-avatar'
					/>
				</div>
			)}
		</div>
	);
};

export default UserAvatar;
