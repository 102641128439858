import { RootState } from 'core/store';
import { setApiCatcherFormErrors } from 'core/store/slice/mainInfo/mainInfoSlice';
import { setHiddenModal, setShowModal } from 'core/store/slice/modal/modalSlice';
import { useDispatch, useSelector } from 'react-redux';

const useAdminUser = () => {
	const dispatch = useDispatch();
	const { modal } = useSelector((store: RootState) => store.modalStore);

	const hideModal = () => {
		dispatch(setHiddenModal());
		dispatch(setApiCatcherFormErrors([]));
	};

	const showModal = () => {
		dispatch(setShowModal());
	};

	return { modal, hideModal, showModal };
};

export default useAdminUser;
