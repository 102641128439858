import useTranslate from 'core/utils/useTranslate';
import { useEffect, useMemo, useState } from 'react';
import DgFormItem from 'styles/UI/FormItem/DgFormItem';
import DgInputLabel from 'styles/UI/Input/DgInputLabel';
import { Button, Checkbox } from 'antd';
import Text from 'core/utils/Text';
import DgImage from 'styles/UI/Image/DgImage';
import usdt from '../../../assets/images/usdt.png';
import { act } from '@testing-library/react';

export const PackageCommissions = ({ defaultValue, onChange = (value) => {} }) => {
	const { t } = useTranslate();

	const types = ['fixed', 'percentage'];

	const [isLevel1Active, setIsLevel1Active] = useState(true);
	const [isLevel2Active, setIsLevel2Active] = useState(false);
	const [isLevel3Active, setIsLevel3Active] = useState(false);

	const [level1Value, setLevel1Value] = useState(defaultValue?.[0]?.value || '');
	const [level2Value, setLevel2Value] = useState(defaultValue?.[1]?.value || '');
	const [level3Value, setLevel3Value] = useState(defaultValue?.[2]?.value || '');

	const [level1Type, setLevel1Type] = useState(defaultValue?.[0]?.type || types[0]);
	const [level2Type, setLevel2Type] = useState(defaultValue?.[1]?.type || types[0]);
	const [level3Type, setLevel3Type] = useState(defaultValue?.[2]?.type || types[0]);

	const finalValues = useMemo(() => {
		const values = [];

		if (isLevel1Active) values[0] = { value: level1Value, type: level1Type };
		else values[0] = { value: 0, type: 'fixed' };

		if (isLevel2Active) values[1] = { value: level2Value, type: level2Type };
		else values[1] = { value: 0, type: 'fixed' };

		if (isLevel3Active) values[2] = { value: level3Value, type: level3Type };
		else values[2] = { value: 0, type: 'fixed' };

		return values;
	}, [
		isLevel1Active,
		isLevel2Active,
		isLevel3Active,
		level1Value,
		level2Value,
		level3Value,
		level1Type,
		level2Type,
		level3Type,
		// defaultValue,
	]);

	useEffect(() => onChange(finalValues), [finalValues]);

	useEffect(() => {
		if (defaultValue?.length) {
			setIsLevel2Active(
				!!defaultValue?.[1]?.type?.length &&
					!['0.00', '', null, undefined].includes(defaultValue?.[1]?.value)
			);
			setIsLevel3Active(
				!!defaultValue?.[2]?.type?.length &&
					!['0.00', '', null, undefined].includes(defaultValue?.[2]?.value)
			);

			setLevel1Value(defaultValue?.[0]?.value || '');
			setLevel2Value(defaultValue?.[1]?.value || '');
			setLevel3Value(defaultValue?.[2]?.value || '');

			setLevel1Type(defaultValue?.[0]?.type || types[0]);
			setLevel2Type(defaultValue?.[1]?.type || types[0]);
			setLevel3Type(defaultValue?.[2]?.type || types[0]);
		}
	}, [defaultValue]);

	// // console.log({ defaultValue, finalValues, level1Value, level2Value, level3Value });

	return (
		<>
			{[
				{
					active: isLevel1Active,
					setActive: setIsLevel1Active,
					value: level1Value,
					setValue: setLevel1Value,
					type: level1Type,
					setType: setLevel1Type,
					label: 'value',
					key: 'direct_percentage_1',
				},
				{
					active: isLevel2Active,
					setActive: setIsLevel2Active,
					value: level2Value,
					setValue: setLevel2Value,
					type: level2Type,
					setType: setLevel2Type,
					label: 'value',
					key: 'direct_percentage_2',
				},
				{
					active: isLevel3Active,
					setActive: setIsLevel3Active,
					value: level3Value,
					setValue: setLevel3Value,
					type: level3Type,
					setType: setLevel3Type,
					label: 'value',
					key: 'direct_percentage_3',
				},
			].map((item, index) => {
				const { label, active, setActive, key, value, setValue, type, setType } = item;

				return (
					<div
						className='flex items-stretch justify-start flex-col gap-2'
						key={key + type + active}
					>
						<div className='flex items-center justify-between gap-4 flex-wrap'>
							<Checkbox
								checked={active}
								disabled={false}
								onChange={(e) => setActive(e.target.checked)}
								className='text-black dark:text-white'
							>
								<Text tid={key} />
							</Checkbox>

							{active && (
								<div className='flex items-center justify-start gap-1'>
									{types.map((item, index) => {
										return (
											<Button
												key={item}
												type={item === type ? 'primary' : 'default'}
												onClick={() => setType(item)}
												size='small'
												htmlType='button'
											>
												<Text tid={item} />
											</Button>
										);
									})}
								</div>
							)}
						</div>

						<DgFormItem
							key={key + type + active}
							className='grow'
							shouldUpdate={true}
							name={key}
							disabled={!active}
							initialValue={value}
							onChange={(e, ...rest) => {
								// // console.log('changing');
								setValue(e.target.value);
							}}
							rules={[
								{
									required: active,
									message: t('direct_percentage-is-required'),
								},

								{
									message: t('validation_error'),

									validator: (_, value) => {
										if (!active) return Promise.resolve();

										if (type === 'percentage') {
											if (value <= 100 && value >= 0) {
												return Promise.resolve();
											} else {
												return Promise.reject('invalid-percentage');
											}
										} else {
											if (isNaN(value)) {
												return Promise.reject('invalid-amount');
											}
											return Promise.resolve();
										}
									},
								},
							]}
						>
							<DgInputLabel
								disabled={!active}
								label={label}
								value={value}
								defaultValue={value}
								suffix={
									<>
										{!!active && (
											<>
												{type === 'percentage' && '%'}
												{type === 'fixed' && (
													<DgImage
														src={usdt}
														width={22}
														className='rounded-full relative top-1.5'
													/>
												)}
											</>
										)}
									</>
								}
							/>
						</DgFormItem>
					</div>
				);
			})}
		</>
	);
};
