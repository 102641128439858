import { Input, InputProps, InputRef } from 'antd';
import { forwardRef } from 'react';

export interface IInput extends InputProps {
	className?: string;
	value?: any;
	setValue?: (val: any) => void;
}

const DgInput = forwardRef<InputRef, IInput>(({ className, value, setValue, ...props }, ref) => {
	return <Input value={value} onChange={setValue ? (e) => setValue(e.currentTarget.value) : () => {}} ref={ref} className={`rounded-md px-3 pt-[10px] ${className}`} {...props} />;
});

DgInput.displayName = 'DgInput';

export default DgInput;
