import { BinaryTree } from 'components/BinaryTree';

const BinaryTreePage = () => {
	return (
		<div className='responsive-layout'>
			<BinaryTree />
		</div>
	);
};

export default BinaryTreePage;
