import { Select, SelectProps } from 'antd';
import { FC, useEffect, useRef } from 'react';
import DgEmpty from '../Empty/DgEmpty';

export interface IDgSelect extends SelectProps {
	isSort?: boolean;
	placeholderTitle?: string;
	getAllDataForFilter?: boolean;
	customOnChange?: (value?: any, allProperty?: any) => void;
}

const DgSelect: FC<IDgSelect> = ({
	getAllDataForFilter = false,
	onChange = () => {},
	isSort = false,
	options = [],
	customOnChange,
	...props
}) => {
	const ref: any = useRef();

	const newItems = isSort
		? options?.sort((a: any, b: any) => (a.label > b.label ? 1 : -1))
		: options;

	useEffect(() => {
		if (ref?.current) {
			ref.current.focus();
			ref.current.blur();
		}
	}, [ref]);

	return (
		<Select
			ref={ref}
			showSearch
			notFoundContent={<DgEmpty />}
			options={newItems}
			optionFilterProp='filter'
			filterOption={(input: any, option: any) =>
				(option?.filter as string).toLowerCase().includes(input.toLowerCase())
			}
			onSelect={(value, allProperty) => {
				customOnChange && customOnChange(value, allProperty);
				return onChange(getAllDataForFilter ? allProperty : value, []);
			}}
			{...props}
		/>
	);
};

export default DgSelect;
