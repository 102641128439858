import { userDetailPage } from 'core/types/global';

const wordpressDomain = 'https://back.mlm.datest.ir';

export const routes = Object.freeze({
	MAIN: '/',
	DASHBOARD: '/panel/dashboard',
	HIJACK: {
		route: '/panel/hijack/:user_id',
		link: (user_id: string | number) => `/panel/hijack/${user_id}`,
	},
	REGISTER_SIGNING: '/panel/login-register',

	// profile
	PROFILE: '/panel/profile',
	PROFILE_ACTIVITY: '/panel/profile/activity',
	PRODUCTS: '/panel/products',
	NOTIFICATIONS: '/panel/messages',
	PROFILE_SECURITY: '/panel/profile/security',
	PROFILE_ADDRESSES: '/panel/profile/addresses',
	PROFILE_SECURITY_ACTIVE_2FA: '/panel/profile/security/active-2fa',
	PROFILE_MLM: '/panel/profile/new-member-position',

	// wallets
	WALLETS: '/panel/my-wallet',
	ACCOUNTING: '/panel/accounting',
	WALLETS_DEPOSIT: '/panel/my-wallet/deposit',
	WALLETS_WITHDRAW: '/panel/my-wallet/withdraw',
	WALLETS_DEPOSIT_TRANSFER: '/panel/my-wallet/inter-user-transfer',

	// order
	ORDER_SUBMIT_STEPS: {
		route: '/panel/package/:orderSteps',
		link: (step?: string | number) => `/panel/package${step ? `/${step}` : ''}`,
		key: '/panel/package',
	},
	ORDERS_LIST: '/panel/my-invest',
	ORDERS_HISTORY: '/panel/my-invest/orders-history',

	// transaction
	TRANSACTION: '/panel/my-wallet/transaction-history',

	// transaction
	TRANSFER: '/panel/inter-user-transfer',
	INTERNAL_TRANSFER: '/panel/internal-transfer',

	// admin user
	ADMIN_USER: '/panel/admin-user',
	ADMIN_ORDER: '/panel/admin-order',
	ADMIN_ROLE: '/panel/admin-role',
	ADMIN_ACCOUNTING: '/panel/admin-accounting',
	ADMIN_USERS_ACTIVITY: '/panel/admin-users-activity',
	ADMIN_USERS_HISTORY: '/panel/admin-users-history',
	ADMIN_USERS_TRANSACTIONS: '/panel/admin-users-transactions',
	ADMIN_PLANS_COMMISSIONS: (query?: string) =>
		`/panel/admin-invest-profits${query ? `?${query}` : ''}`,
	ADMIN_USER_Detail: {
		route: '/panel/admin-user/:userId',
		link: (userId: string | number) => `/panel/admin-user${userId ? `/${userId}` : ''}`,
	},
	ADMIN_USER_Detail_PAGE: {
		route: '/panel/admin-user/:userId/:page',
		link: (userId?: string | number, page?: userDetailPage) =>
			`/panel/admin-user${userId ? `/${userId}` : ''}${page ? `/${page}` : ''}`,
	},

	// admin package
	ADMIN_PACKAGE: '/panel/admin-package',
	ADMIN_PRODUCTS: '/panel/admin-product',
	ADMIN_GROUPS: '/panel/admin-groups',

	// admin invoice
	ADMIN_INVOICE: '/panel/admin-invoice',

	// admin banners
	ADMIN_BANNERS: '/panel/admin-banners',

	// admin setting
	ADMIN_SETTINGS: '/panel/admin-settings',
	ADMIN_BONUS: '/panel/admin-bonus',
	ADMIN_BALANCE_PROFIT: '/panel/admin-balance-profit',

	ADMIN_WALLET_SETTINGS: '/panel/admin-wallet-settings',

	ADMIN_WITHDRAWS: '/panel/admin-withdraws',
	USER_WITHDRAW_LIST: '/panel/user-withdraw-list',

	// binary tree
	BINARY_TREE: '/panel/binary-tree',

	// plans commissions
	PLANS_COMMISSIONS: (query?: string) => `/panel/my-profits${query ? `?${query}` : ''}`,

	// wordpress
	BLOG: wordpressDomain + '/blog',
	ABOUT: wordpressDomain + '/about',
	TERMS: wordpressDomain + '/rules',

	BUY_PRODUCT: () => '/panel/products/buy/',
});
