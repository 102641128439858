import useQueryUrlParam from 'core/hooks/useQueryUrlParam';
import { FC, ReactNode, memo } from 'react';
import DgFilterButtons from '../Button/DgFilterButtons';
import DgForm from '../Form/DgForm';
import DgFormItem from '../FormItem/DgFormItem';
import { FormInstance } from 'antd';
import DgFormWrapper from '../FormWrapper/DgFormWrapper';
import DgDevider from '../Devider/DgDevider';
import Text from 'core/utils/Text';
import DgSortItem from './DgSortItem';
import { HiOutlineSortAscending, HiOutlineSortDescending } from 'react-icons/hi';

interface IDgFilterItem {
	formAttribute: [FormInstance<any>];
	query: any;
	filterNames: string[];
	filterItems?: ReactNode[];
	isDesktop?: boolean;
	width: number;
	sortNames?: string[];
	hideAllDropdownHandler: () => void;
}

const DgFilterItem: FC<IDgFilterItem> = ({ formAttribute, query, filterNames, width, isDesktop, sortNames, filterItems, hideAllDropdownHandler }) => {
	const [form] = formAttribute;
	const { setUrlParam, filterValuesUpdate } = useQueryUrlParam();

	const onSubmitHandler = (values: any) => {
		hideAllDropdownHandler();

		values = filterValuesUpdate(values);
		setUrlParam({ ...query, ...values });
	};

	const depositSteps: { id: number; title: 'ASC' | 'DESC'; Icon: any }[] = [
		{ id: 0, title: 'ASC', Icon: <HiOutlineSortAscending size={20} /> },
		{ id: 1, title: 'DESC', Icon: <HiOutlineSortDescending size={20} /> },
	];

	return (
		<div className='bg-dg-filter-body-bg p-2 pt-5 rounded-lg text-dg-primary-color border border-dg-opposite-primary-color shadow-[0px_0px_2px] shadow-dg-opposite-primary-color'>
			<DgForm onFinish={onSubmitHandler} form={form} autoComplete='new-password'>
				<DgFormWrapper singleColumn>
					{filterItems?.map((item, index) => (
						<DgFormItem noMargin key={index} name={filterNames[index]}>
							{item}
						</DgFormItem>
					))}
				</DgFormWrapper>

				{(((sortNames?.length || 0) > 0 && width < 769) || isDesktop) && (
					<>
						<DgDevider orientation='center' className={(sortNames?.length || 0) > 0 ? 'mt-0' : ''} title='sort' />

						<DgFormWrapper singleColumn>
							{sortNames?.map((sortName) => {
								// const { selectedSort, selectedSortHandler } = useSelected(undefined, false, true, true);
								return (
									<div key={sortName} className={`flex items-center justify-between ${(filterItems?.length || 0) === 0 ? 'gap-10' : ''}`}>
										<Text tid={sortName} />

										<div className='grid grid-flow-col gap-3 grid-cols-2 overflow-x-auto'>
											{depositSteps.map((item) => (
												<DgSortItem
													Icon={item.Icon}
													title={item.title}
													key={item.id}
													sortName={sortName}
												/>
											))}
										</div>
									</div>
								);
							})}
						</DgFormWrapper>
					</>
				)}

				{(filterItems?.length || 0) > 0 ? <DgFilterButtons /> : null}
			</DgForm>
		</div>
	);
};

export default memo(DgFilterItem);
