import { RootState } from 'core/store';
import Text from 'core/utils/Text';
import { useSelector } from 'react-redux';
import DgButton from 'styles/UI/Button/DgButton';
import DgPrice from 'styles/UI/Price/DgPrice';
import { FC } from 'react';
import { routes } from 'core/constants/routes';

interface IUserWithdrawInvoice {
	onCancel: () => void;
	onWithdrawHandler: () => void;
	withdrawIsLoading: boolean;
	invoicePreviewDetails: {
		currency: string;
		network: string;
		amount: string;
		address: string;
		type?: string;
	} | null;
}

const UserWithdrawInvoice: FC<IUserWithdrawInvoice> = ({
	onCancel,
	invoicePreviewDetails,
	onWithdrawHandler,
	withdrawIsLoading,
}) => {
	const { isEnglish } = useSelector((store: RootState) => store.settingStore);

	return (
		<>
			<div className='sm:w-96 w-full sm:p-0 px-3 h-16 bg-[var(--dg-primary-color-fixed)] relative top-5 mb-10'>
				<div className='flex items-center justify-center w-full h-full gap-3'>
					<span className='text-[var(--dg-primary-color)] font-bold'>
						{isEnglish
							? process.env.REACT_APP_BRAND_NAME_EN
							: process.env.REACT_APP_BRAND_NAME_FA}
					</span>
					<Text tid='withdraw-invoice' className='text-xl text-dg-white-color' />
				</div>
			</div>

			<h2 className='text-xl text-[var(--dg-primary-color-fixed)] font-bold text-center my-5'>
				<Text tid='withdraw-details' />
			</h2>

			<div className='bg-[var(--dg-gray-color-2)] text-[var(--dg-primary-color-fixed)] px-5'>
				<div className='flex items-center justify-between px-2 py-4 border-b border-[var(--dg-border-color)]'>
					<Text tid='type' />
					<span
						className={`xs:max-w-full max-w-[20ch] ${isEnglish ? 'ml-auto' : 'mr-auto'}`}
					>
						<Text tid={invoicePreviewDetails?.type || ''} />
					</span>
				</div>
				<div className='flex items-center justify-between px-2 py-4 border-b border-[var(--dg-border-color)]'>
					<Text tid='amount' />
					<DgPrice
						price={invoicePreviewDetails?.amount}
						suffixColor='text-[var(--dg-primary-color-pale)]'
					/>
				</div>
				<div className='flex items-center justify-between px-2 py-4 border-b border-[var(--dg-border-color)]'>
					<Text tid='address' />
					<span
						className={`xs:max-w-full max-w-[20ch] ${isEnglish ? 'ml-auto' : 'mr-auto'}`}
					>
						{invoicePreviewDetails?.address}
					</span>
				</div>
				<div className='flex items-center justify-between px-2 py-4 border-b border-[var(--dg-border-color)]'>
					<Text tid='currency' />
					<span>{invoicePreviewDetails?.currency}</span>
				</div>
				<div className='flex items-center justify-between px-2 py-4 border-b border-[var(--dg-border-color)]'>
					<Text tid='network' />
					<span>{invoicePreviewDetails?.network}</span>
				</div>
			</div>

			<div
				className={`flex xs:flex-row flex-col pb-5 gap-5 items-center justify-between ${
					isEnglish ? 'mr-5' : 'ml-5'
				}`}
			>
				<div className='font-bold p-5 flex w-full justify-start xs:block xs:w-auto pl-8 before:border-l-[16px] relative before:absolute before:h-4/5 before:-translate-y-1/2 ltr:before:left-0 rtl:before:right-0 before:top-1/2 before:border-[var(--dg-primary-color)]'>
					<a
						rel='noreferrer'
						href={routes.TERMS}
						target='_blank'
						className='text-[var(--dg-primary-color-fixed)]'
					>
						<Text tid='terms' className='text-lg font-bold' />
					</a>
				</div>

				<div className='flex w-full xs:w-auto items-center gap-5'>
					<DgButton
						className='w-full xs:w-auto'
						type='ghost'
						title='cancel'
						onClick={onCancel}
					/>
					<DgButton
						className='w-full xs:w-auto'
						title='confirm'
						onClick={onWithdrawHandler}
						isLoading={withdrawIsLoading}
					/>
				</div>
			</div>
		</>
	);
};

export default UserWithdrawInvoice;
