import { useUpdateInvoiceQuery } from '../hooks/react-query/useUpdateInvoiceQuery';
import useQueryUrlParam from 'core/hooks/useQueryUrlParam';
import { useSelector } from 'react-redux';
import { RootState } from 'core/store';
import DgButton from 'styles/UI/Button/DgButton';
import { useState, useEffect } from 'react';
import { convertTimeStampToCountDown } from 'core/utils/utils';

const UserDepositWithdrawUpdateInvoice = ({ timeStamp }: { timeStamp: number }) => {
	const [val, setVal] = useState('00:00:00');
	const { mutate: updateInvoice, isLoading: isLoadingUpdateInvoice } = useUpdateInvoiceQuery();
	const { query } = useQueryUrlParam();
	const { socketConnectionStatus } = useSelector((store: RootState) => store.socketStore);

	useEffect(() => {
		convertTimeStampToCountDown(timeStamp, undefined, (val) => setVal(val), 'detailResult');
	}, []);

	return (
		<>
			{(!socketConnectionStatus || val === 'EXPIRED' || timeStamp - new Date().getTime() / 1000 < 1200) && (
				<DgButton ghost title='update-invoice' className='w-full' isLoading={isLoadingUpdateInvoice} onClick={() => updateInvoice({ transaction_id: query?.transaction_id })} />
			)}
		</>
	);
};

export default UserDepositWithdrawUpdateInvoice;
