import Text from 'core/utils/Text';
import { FC, ReactNode } from 'react';

interface IDgTitle2 {
	children: string;
	className?: string;
	customTitle?: ReactNode;
}

const DgTitle2: FC<IDgTitle2> = ({ children, customTitle, className }) => {
	return <h2 className={`font-bold text-lg text-dg-primary-color ${className}`}>{customTitle ? customTitle : <Text tid={children} />}</h2>;
};

export default DgTitle2;
