import DgTable from 'styles/UI/Table/DgTable';
import { UserOrdersQuery } from '../hooks/react-query/useUserOrdersQuery';
import { FC } from 'react';
import UserOrderTableColumn from './UserOrderTableColumn';
import { IOrder } from 'core/types/models/orderType';
import DgPrice from 'styles/UI/Price/DgPrice';

interface IUserOrdersTable {
	showMoreColumn?: boolean;
	limit?: number;
	scroll?: any;
}

const UserOrdersTable: FC<IUserOrdersTable> = ({
	showMoreColumn = false,
	limit,
	...props
}) => {
	const { data: userOrders, isFetching: userOrderIsFetching } = UserOrdersQuery();

	let data: IOrder[] = [];
	if (limit && userOrders?.data) {
		data = userOrders?.data.slice(0, limit);
	}
	return (
		<DgTable
			rowKey='id'
			columns={UserOrderTableColumn({ showMoreColumn })}
			dataSource={data.length > 0 ? data : userOrders?.data}
			// count={
			// 	<DgPrice
			// 		price={userOrders?.data
			// 			?.filter((item) => item.canceled_at === null)
			// 			?.map((item) => item.total_cost_in_pf)
			// 			?.reduce((prev, current) => prev + current, 0)}
			// 	/>
			// }
			// countTitle='total-active-investment'
			loading={userOrderIsFetching}
			{...props}
		/>
	);
};

export default UserOrdersTable;
