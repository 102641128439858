import { getUserEarningWalletQuery } from 'core/hooks/react-query/getUserEarningWalletQuery';

const useUserEarningWallet = () => {
	const { data: earningWallet, isFetching: userEarningWalletIsFetching } =
		getUserEarningWalletQuery();

	return { earningWallet, userEarningWalletIsFetching };
};

export default useUserEarningWallet;
