import React from 'react';
import DgButton from 'styles/UI/Button/DgButton';
import DgForm from 'styles/UI/Form/DgForm';
import DgFormItem from 'styles/UI/FormItem/DgFormItem';
import DgFormWrapper from 'styles/UI/FormWrapper/DgFormWrapper';
import DgInputLabel from 'styles/UI/Input/DgInputLabel';

import DgTextAreaLabel from 'styles/UI/TextArea/DgTextAreaLabel';
import useAdminWalletSettingsUpdate from '../hooks/useAdminWalletSettingsUpdate';

const AdminWalletSettingsUpdate = () => {
	const { isLoadingUpdateSetting, onFinishUpdateSetting, updateSettingForm } =
		useAdminWalletSettingsUpdate();

	return (
		<DgForm autoComplete='off' onFinish={onFinishUpdateSetting} form={updateSettingForm}>
			<DgFormWrapper>
				<DgFormItem name='name'>
					<DgInputLabel label='name' />
				</DgFormItem>
				<DgFormItem name='title'>
					<DgInputLabel label='title' />
				</DgFormItem>

				<DgFormItem name='value'>
					<DgInputLabel label='value' />
				</DgFormItem>

				{/* <DgFormItem name='category'>
					<DgInputLabel label='category' />
				</DgFormItem> */}

				<DgFormItem name='description'>
					<DgTextAreaLabel label='description' />
				</DgFormItem>
			</DgFormWrapper>

			<DgFormItem button>
				<DgButton
					htmlType='submit'
					isLoading={isLoadingUpdateSetting}
					title='update-setting'
				/>
			</DgFormItem>
		</DgForm>
	);
};

export default AdminWalletSettingsUpdate;
