import { UseQueryResult } from '@tanstack/react-query';
import { endPointUrls } from 'core/constants/endPointUrls';
import api from 'core/services/fetch-api/useApi';
import useQueryUrlParam from 'core/hooks/useQueryUrlParam';
import useQueryHook from 'core/hooks/masterQuery/useQueryHook';
import { ITransactionList } from 'core/types/models/transactionType';

export const useUserTransactionQuery = () => {
	const { filterParams } = useQueryUrlParam();

	return useQueryHook(
		[endPointUrls.WALLETS_DEPOSIT_TRANSACTION(), filterParams],
		(data: any) => api.get(endPointUrls.WALLETS_DEPOSIT_TRANSACTION(filterParams), data),
		{
			select: (res: any) => res?.data,
			keepPreviousData: true,
		}
	) as UseQueryResult<ITransactionList, Error>;
};
