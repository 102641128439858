import React, { Fragment, useEffect, useState } from 'react';
import DgButton from 'styles/UI/Button/DgButton';
import DgForm from 'styles/UI/Form/DgForm';
import DgFormItem from 'styles/UI/FormItem/DgFormItem';
import DgFormWrapper from 'styles/UI/FormWrapper/DgFormWrapper';
import DgInputLabel from 'styles/UI/Input/DgInputLabel';
import useAdminPackageUpdate from './hooks/useAdminPackageUpdate';
import DgImage from 'styles/UI/Image/DgImage';
import usdt from '../../../../assets/images/usdt.png';
import useTranslate from 'core/utils/useTranslate';
import { HintBox } from 'components/hint-box';
import {
	useAdminPackageCommissions,
	useAdminPackageCommissionsList,
} from './hooks/react-query/useAdminPackageCommissions';
import { useSelector } from 'react-redux';
import { RootState } from 'core/store';
import { PackageCommissions } from '../package-commissions';
import DgSpinner from 'styles/UI/Spinner/DgSpinner';

const AdminPackageUpdate = ({ hideModalHandler }: { hideModalHandler: () => void }) => {
	const { packageDetail } = useSelector((store: RootState) => store.packageStore);

	const { updatePackageForm, isLoadingUpdatePackage, onFinishUpdatePackage } =
		useAdminPackageUpdate(hideModalHandler);
	const { t } = useTranslate();

	const [categoryKey, setCategoryKey] = useState('');
	const isGold = categoryKey === 'Advance';

	const [commissions, setCommissions] = useState([]);

	const packageCommissionsMutation = useAdminPackageCommissions();
	const packageCommissionsQuery = useAdminPackageCommissionsList(packageDetail?.id);

	const packageCommissions =
		packageCommissionsQuery?.data?.data?.data?.indirect_percentages;

	useEffect(() => {
		setCategoryKey(updatePackageForm.getFieldsValue(true)?.category?.key);
	}, []);

	const onFinish = (values: any) => {
		const commissionsPromises: any = [];

		commissions.forEach((item: any, index) => {
			if (item?.type) {
				commissionsPromises.push(
					async () =>
						await packageCommissionsMutation
							.mutateAsync({
								package_id: packageDetail?.id,
								level: index + 1,
								...item,
							})
							.catch((e) => {
								// console.log({ e });
							})
				);
			}
		});

		if (commissionsPromises?.length) {
			Promise.all(commissionsPromises.map((item: any) => item()));
		}

		onFinishUpdatePackage(values);
	};

	return (
		<>
			{!!isGold && (
				<DgForm
					disabled={true}
					shouldUpdate={true}
					key={categoryKey}
					autoComplete='off'
					onFinish={onFinish}
					form={updatePackageForm}
				>
					<DgFormWrapper key={categoryKey} singleColumn>
						<Fragment key={categoryKey}>
							<DgFormItem
								name='name'
								rules={[
									{
										required: true,
										message: t('name-is-required'),
									},
								]}
							>
								<DgInputLabel label='name' />
							</DgFormItem>

							<DgFormItem
								rules={[
									{
										required: true,
										message: t('amount-is-required'),
									},
								]}
								noMargin
								name='price'
							>
								<DgInputLabel
									suffix={
										<DgImage
											src={usdt}
											width={22}
											className='rounded-full relative top-1.5'
										/>
									}
									label='amount'
								/>
							</DgFormItem>

							<DgFormItem
								name='validity_in_days'
								rules={[
									{
										required: true,
										message: t('expire-time-is-required'),
									},
								]}
							>
								<DgInputLabel suffix={t('days')} label='expire-time' />
							</DgFormItem>
						</Fragment>
					</DgFormWrapper>
				</DgForm>
			)}
			<DgForm
				shouldUpdate={true}
				key={categoryKey}
				autoComplete='off'
				onFinish={onFinish}
				form={updatePackageForm}
			>
				<DgFormWrapper key={categoryKey} singleColumn>
					{!isGold && (
						<Fragment key={categoryKey}>
							<DgFormItem
								name='name'
								rules={[
									{
										required: true,
										message: t('name-is-required'),
									},
								]}
							>
								<DgInputLabel label='name' />
							</DgFormItem>

							<DgFormItem
								rules={[
									{
										required: true,
										message: t('amount-is-required'),
									},
								]}
								noMargin
								name='price'
							>
								<DgInputLabel
									suffix={
										<DgImage
											src={usdt}
											width={22}
											className='rounded-full relative top-1.5'
										/>
									}
									label='amount'
								/>
							</DgFormItem>

							<DgFormItem
								name='validity_in_days'
								rules={[
									{
										required: true,
										message: t('expire-time-is-required'),
									},
								]}
							>
								<DgInputLabel suffix={t('days')} label='expire-time' />
							</DgFormItem>
						</Fragment>
					)}

					{/* <DgFormItem
						name='direct_percentage'
						rules={[
							{
								required: true,
								message: t('direct_percentage-is-required'),
							},
						]}
					>
						<DgInputLabel label='direct_percentage' />
					</DgFormItem> */}
					<DgFormItem
						name='roi_percentage'
						rules={[
							{
								required: true,
								message: t('roi_percentage-is-required'),
							},
						]}
					>
						<DgInputLabel
							suffix={
								<HintBox
									hintTitle='roi_percentage'
									hintDescription='roi_percentage_info'
								/>
							}
							label='roi_percentage'
						/>
					</DgFormItem>

					{!!packageCommissionsQuery?.isFetching ? (
						<DgSpinner />
					) : (
						<PackageCommissions
							key={JSON.stringify(packageCommissions)}
							defaultValue={packageCommissions}
							onChange={(value) => setCommissions(value)}
						/>
					)}
				</DgFormWrapper>

				<DgFormItem button>
					<DgButton
						htmlType='submit'
						isLoading={isLoadingUpdatePackage}
						title='update-package'
					/>
				</DgFormItem>
			</DgForm>
		</>
	);
};

export default AdminPackageUpdate;
