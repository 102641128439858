import DgCard from 'styles/UI/Card/DgCard';
import DgCardHeader from 'styles/UI/CardHeader/DgCardHeader';
import useAdminPackage from './List/hooks/useAdminPackage';
import DgModal from 'styles/UI/Modal/DgModal';
import AdminPackageTable from './List/components/AdminPackageTable';
import AdminPackageUpdate from './Update/AdminPackageUpdate';
import { useState } from 'react';
import Text from '../../../core/utils/Text';
import { useAdminDeletePackage } from './Update/hooks/react-query/useAdminDeletePackage';
import { DotLoader } from 'react-spinners';
import CreatePackageModal from './List/components/CreatePackageModal';

const AdminPackage = () => {
	const { hideModalHandler, modal, onEditPackageHandler } = useAdminPackage();

	/*
	 * delete package
	 */
	const deleteInitial = { show: false, id: null };
	const [deleteModal, setDeleteModal] = useState(deleteInitial);
	const closeDeleteModal = () => setDeleteModal(deleteInitial);
	const onDeletePackageHandler = (record: any) => {
		setDeleteModal({ show: true, id: record?.id });
	};

	const { mutate: deletePackage, isLoading: deleting } = useAdminDeletePackage(() => {
		closeDeleteModal();
	});
	const onSubmitDelete = () => {
		if (deleting) return;
		deletePackage({ data: { package_id: deleteModal.id } });
	};

	/*
	 * create package
	 */
	const [createModal, setCreateModal] = useState(false);
	const showCreateModal = () => setCreateModal(true);
	const hideCreateModal = () => setCreateModal(false);

	return (
		<>
			{!!modal && (
				<DgModal title='update-package' open={modal} onCancel={hideModalHandler}>
					<AdminPackageUpdate hideModalHandler={hideModalHandler} />
				</DgModal>
			)}

			{createModal && (
				<DgModal title={'create-package'} open={createModal} onCancel={hideCreateModal}>
					<CreatePackageModal hideCreateModal={hideCreateModal} />
				</DgModal>
			)}

			{deleteModal.show && (
				<DgModal
					title={'delete-package'}
					open={deleteModal.show}
					onCancel={closeDeleteModal}
				>
					<div>
						<Text tid={'delete-package-desc'} />
					</div>
					<div className={'w-full flex justify-around items-center mt-5'}>
						<div
							className={
								'w-[120px] h-[42px] bg-green-700 rounded flex justify-center items-center cursor-pointer'
							}
							onClick={onSubmitDelete}
						>
							{deleting ? (
								<DotLoader size={16} color={'white'} />
							) : (
								<Text tid={'submitt'} />
							)}
						</div>
						<div
							className={
								'w-[120px] h-[42px] bg-gray-600 rounded flex justify-center items-center cursor-pointer'
							}
							onClick={closeDeleteModal}
						>
							<Text tid={'cancel'} />
						</div>
					</div>
				</DgModal>
			)}

			<DgCard>
				<div className={'flex w-full justify-between'}>
					<DgCardHeader title='package-management' />
					<div
						className={
							'bg-[#58a0f8] rounded-md px-8 h-[42px] flex justify-center items-center text-black cursor-pointer'
						}
						onClick={showCreateModal}
					>
						<Text tid={'create-package'} />
					</div>
				</div>

				<AdminPackageTable
					onEditSettingsHandler={onEditPackageHandler}
					onDeletePackageHandler={onDeletePackageHandler}
				/>
			</DgCard>
		</>
	);
};

export default AdminPackage;
