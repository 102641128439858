import { useWindowSize } from 'core/hooks/useWindowSize';
import Text from 'core/utils/Text';
import DgTableColumnResponsive from 'styles/UI/TableColumnResponsive/DgTableColumnResponsive';
import DgPrice from 'styles/UI/Price/DgPrice';
// import { IGroup } from 'core/types/models/groupType';
import useTranslate from 'core/utils/useTranslate';
import DgEditIcon from 'styles/UI/EditIcon/DgEditIcon';
import DgDeleteIcon from '../../../../../styles/UI/Icon/DgDeleteIcon';

const AdminGroupTableColumn = (
	onEditGroupHandler?: (val: any) => void,
	onDeleteGroupHandler?: (val: any) => void
) => {
	const { width } = useWindowSize();
	const { t } = useTranslate();

	const Columns = [
		{
			title: <Text tid='name' />,
			render: (text: any, record: any) => record?.name,
			sorter: false,
		},
		...(onEditGroupHandler
			? [
					{
						title: '',
						render: (_text: any, record: any) => (
							<div className={'flex fw justify-end items-center'}>
								<DgEditIcon onClick={() => onEditGroupHandler?.(record)} />
								<DgDeleteIcon onClick={() => onDeleteGroupHandler?.(record)} />
							</div>
						),
						sorter: false,
					},
			  ]
			: []),
	];

	const RColumn = [
		{
			title: '',
			sorter: false,
			render: (_text: any, record: any) => {
				return (
					<>
						<DgTableColumnResponsive title={t('name')} value={record.name} />
						{onEditGroupHandler && (
							<DgTableColumnResponsive
								title={t('operation')}
								value={
									<>
										<DgEditIcon
											className='flex justify-end'
											onClick={() => onEditGroupHandler(record)}
										/>
										<DgDeleteIcon onClick={() => onDeleteGroupHandler?.(record)} />
									</>
								}
							/>
						)}
					</>
				);
			},
		},
	];

	return width > 769 ? Columns : RColumn;
};

export default AdminGroupTableColumn;
