import { useInvoiceDetailQuery } from './react-query/useInvoiceDetailQuery';
import { RootState } from 'core/store';
import { useDispatch, useSelector } from 'react-redux';
import useCopyToClipboard from 'core/hooks/useCopyToClipboard';
import { useEffect } from 'react';
import { clearDepositDetail } from 'core/store/slice/deposit/depositSlice';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { endPointUrls } from 'core/constants/endPointUrls';
import { clearWithdrawDetail } from 'core/store/slice/withdraw/withdrawSlice';

const useDepositStepTwo = () => {
	// query
	const { data: invoice, isFetching: isFetchingInvoice } = useInvoiceDetailQuery();

	// store
	const { depositDetail } = useSelector((store: RootState) => store.depositStore);

	// hooks
	const queryClient = useQueryClient();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { copyHandler } = useCopyToClipboard();

	// const [percentage, setPercentage] = useState(0);

	// useEffect(() => {
	// 	convertTimeStampToCountDown(new Date().getTime() / 1000 + 1200, new Date().getTime() / 1000, (val, prc) => setPercentage(prc || 0));
	// }, []);

	useEffect(() => {
		return () => {
			dispatch(clearDepositDetail());
			dispatch(clearWithdrawDetail());
			queryClient.setQueryData([endPointUrls.PAYMENT_INVOICES()], { data: undefined });
		};
	}, []);

	return { invoice, isFetchingInvoice, depositDetail, copyHandler, navigate };
};

export default useDepositStepTwo;
