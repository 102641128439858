import { UseQueryResult } from '@tanstack/react-query';
import { endPointUrls } from 'core/constants/endPointUrls';
import api from 'core/services/fetch-api/useApi';
import useQueryHook from 'core/hooks/masterQuery/useQueryHook';
import { useDispatch } from 'react-redux';
import { setUserDetail } from 'core/store/slice/userInfo/userInfoSlice';
import { IMlmInfoDetail } from 'core/types/models/mlmType';

export const getMlmInfoQuery = () => {
	const dispatch = useDispatch();

	return useQueryHook(
		[endPointUrls.MLM_INFO],
		(data: any) => api.get(endPointUrls.MLM_INFO, data),
		{
			select: (res: any) => res?.data,
			onSuccess: (res: any) => {
				dispatch(setUserDetail(res));
			},
		}
	) as UseQueryResult<IMlmInfoDetail, Error>;
};
