import Text from 'core/utils/Text';
import useTranslate from 'core/utils/useTranslate';
import { FC } from 'react';
import DgButton from 'styles/UI/Button/DgButton';
import DgPopConfirm from 'styles/UI/PopConfirm/DgPopConfirm';
import useUserResetPassword from '../hooks/useUserResetPassword';

const AdminUserResetPassword: FC = () => {
	const { onConfirm, resetPasswordIsFetching } = useUserResetPassword();
	const { t } = useTranslate();

	return (
		<>
			<div className='border-b border-b-[var(--dg-border-color)] py-5'>
				<div className='flex justify-between items-center flex-wrap'>
					<Text tid='reset-password' />

					<DgPopConfirm title={t('are-you-sure-to-reset-password')} onConfirm={onConfirm}>
						<DgButton
							htmlType='button'
							isLoading={resetPasswordIsFetching}
							title='reset-password'
						/>
					</DgPopConfirm>
				</div>
			</div>
		</>
	);
};

export default AdminUserResetPassword;
