import DgTable from '../../styles/UI/Table/DgTable';
import DgPagination from '../../styles/UI/Pagination/DgPagination';
import useQueryUrlParam from '../../core/hooks/useQueryUrlParam';
import { useUserWithdrawList } from './useUserWithdrawList';
import WithdrawTableColumns from '../Admin/Withdraws/WithdrawTableColumns';

const UserWithdrawTable = () => {
	const { data: withdraws, isFetching } = useUserWithdrawList();
	const { query, handlePagination } = useQueryUrlParam();

	return (
		<>
			<DgTable
				rowKey='transaction_id'
				columns={WithdrawTableColumns('user')}
				dataSource={withdraws?.data?.list}
				count={withdraws?.data.pagination.total}
				loading={isFetching}
				current={withdraws?.data.pagination.current_page}
				per_page={query?.per_page}
			/>

			<DgPagination
				onPaginationHandler={handlePagination}
				responsive={true}
				per_page={query?.per_page}
				current={withdraws?.data.pagination.current_page}
				total={withdraws?.data.pagination.total}
			/>
		</>
	);
};

export default UserWithdrawTable;
