import useModal from 'core/hooks/useModal';
import { setGroupDetail } from 'core/store/slice/groups/reducer';
import { setApiCatcherFormErrors } from 'core/store/slice/mainInfo/mainInfoSlice';
// import { IGroup } from 'core/types/models/groupType';
import { useDispatch } from 'react-redux';

const useAdminGroups = () => {
	// hooks
	const dispatch = useDispatch();
	const { hideModal, modal, showModal } = useModal();

	const hideModalHandler = () => {
		hideModal();
		dispatch(setApiCatcherFormErrors([]));
	};

	const onEditGroupHandler = (value: any) => {
		showModal();
		dispatch(setGroupDetail(value));
	};

	return { onEditGroupHandler, hideModalHandler, modal };
};

export default useAdminGroups;
