import { p2e } from './common';

//separates text to 3 digit parts
export const separator = (input, seperater = ',', seperateLength = 3) => {
	if (input === null || input === undefined) return '';
	input = input.toString();
	let result = '';
	let count = 0;
	for (let i = input.length - 1; i > -1; i--) {
		if (count === seperateLength) {
			result = seperater + result;
			count = 0;
		}
		result = input.charAt(i) + result;
		count++;
	}
	return result;
};

export const numberFormat = (e = '') => {
	let value = p2e(e);
	value = value.replace(/[^\d.,]/g, '');

	let newValue = '';
	let hasDot = false;
	for (let i = 0; i < value.length; i++) {
		if (hasDot && value[i] === '.') continue;
		if (value[i] === '.') {
			hasDot = true;
		}
		newValue += value[i];
	}
	value = newValue;

	const numParts = value.split('.');
	numParts[0] = numParts[0].replaceAll(',', '');
	numParts[0] = numParts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

	return numParts.join('.');
};

export const convertPersianNumberToEnglish = (s = '', number) => {
	const elem = s
		.toString()
		.replace(/[۰-۹]/g, (d) => '۰۱۲۳۴۵۶۷۸۹'.indexOf(d))
		.replace(/[٠-٩]/g, (d) => '٠١٢٣٤٥٦٧٨٩'.indexOf(d));
	if (number) return +elem;
	return elem;
};

export const convertAllPropertyToEnNumber = (values) => {
	const newValues = {};
	for (const key in values) {
		if (Object.hasOwnProperty.call(values, key)) {
			const element = values[key];

			if (element && !isNaN(element)) {
				newValues[key] = convertPersianNumberToEnglish(element, true);
			} else if (Array.isArray(element)) {
				newValues[key] = element.map((item) => {
					convertPersianNumberToEnglish(item);
					if (item && !isNaN(item)) return convertPersianNumberToEnglish(item, true);
					else return convertPersianNumberToEnglish(item);
				});
			} else {
				newValues[key] = convertPersianNumberToEnglish(element);
			}
		}
	}

	return newValues;
};

// dates
const today = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 1, 0, 0, 0, 0).toISOString();

const yesterday = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0, 0).toISOString();

const sevenDays = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 6, 0, 0, 0, 0).toISOString();

const fifteenDays = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 14, 0, 0, 0, 0).toISOString();

const oneMonth = new Date(new Date().getFullYear(), new Date().getMonth() - 1, 2, 0).toISOString();

const oneYear = new Date(new Date().getFullYear() - 1, 0, 2, 0).toISOString();

export { today, yesterday, sevenDays, fifteenDays, oneYear, oneMonth };

// paginate data
export const paginateDataHandler = (data = [], page = 1, limit = 10) => data.slice(page === 1 ? 0 : (page - 1) * limit, limit === 1 ? 10 : page * limit);

// custom local filter
export const localFilter = (state, data, filterValues) => {
	state(data.filter((object) => Object.keys(filterValues).every((filterKey) => (filterValues?.[filterKey] ? object?.[filterKey].includes(filterValues?.[filterKey]) : []))));
};

export const separatorNumber = (number = 0, suffix = ' ریال') => {
	let value = number?.toString()?.replace(/,/g, '');
	const isMinusNumber = value?.includes('-');

	if (isMinusNumber) value = value.toString().slice(1, value.length);

	// value = parseInt(value);
	let caret = value?.length - 1;
	while (caret - 3 > -1) {
		caret -= 3;
		value = value?.split('');
		value?.splice(caret + 1, 0, ',');
		value = value?.join('');
	}
	return isMinusNumber ? `-${value}${suffix}` : `${value}${suffix}`;
};

// regex
export const numberRegex = /^[0-9۰-۹]*$/;
export const numberRegexWithCommaFormat = /^[0-9۰-۹,]*$/;
export const numberRegexWithSpaceFormat = /^[0-9۰-۹ ]*$/;
export const mobileNumberRegex = /^(09|۰۹)[0-9۰-۹]{9}$/;
