import DgTable from 'styles/UI/Table/DgTable';
import userAdminSettings from '../hooks/userAdminSettings';
import useQueryNoLocation from 'core/hooks/useNoQueryUrlParam';
import DgPagination from 'styles/UI/Pagination/DgPagination';
import { paginateDataHandler } from 'core/utils/utils';
import DgModal from 'styles/UI/Modal/DgModal';
import AdminSettingsUpdate from './AdminSettingsUpdate';
import AdminSettingsTableColumn from './AdminSettingsTableColumn';

const AdminSettingsTable = () => {
	const {
		settings,
		settingIsLoading,
		onEditSettingsHandler,
		onHiddenModalHandler,
		modal,
	} = userAdminSettings();
	const { handlePagination, page, per_page } = useQueryNoLocation();

	return (
		<>
			<DgModal
				title='setting-update'
				width={1000}
				open={modal}
				onCancel={onHiddenModalHandler}
			>
				<AdminSettingsUpdate />
			</DgModal>
			<DgTable
				rowKey='id'
				columns={AdminSettingsTableColumn(onEditSettingsHandler)}
				dataSource={paginateDataHandler(settings?.data || [], page, per_page)}
				count={settings?.data.length}
				loading={settingIsLoading}
				current={page}
				per_page={per_page}
			/>

			<DgPagination
				onPaginationHandler={handlePagination}
				responsive={true}
				per_page={per_page}
				current={page}
				total={settings?.data.length}
			/>
		</>
	);
};

export default AdminSettingsTable;
