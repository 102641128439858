import { RootState } from 'core/store';
import { convertTimeStampToCountDown } from 'core/utils/utils';
import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

interface IDgCountDown {
	timeStamp: number;
	className?: string;
	type?: 'detailResult' | 'daysResult';
}

const DgCountDown: FC<IDgCountDown> = ({
	timeStamp,
	className,
	type = 'daysResult',
	...props
}) => {
	const [val, setVal] = useState('00:00:00');
	const { isEnglish } = useSelector((store: RootState) => store.settingStore);

	useEffect(() => {
		convertTimeStampToCountDown(timeStamp, undefined, (val) => setVal(val), type);
	}, []);

	return (
		<span
			className={`whitespace-nowrap ${
				val === 'EXPIRED' ? 'text-[var(--dg-red-color-2)]' : ''
			} ${className}`}
			{...props}
		>
			{isEnglish ? val : val.replace(/Day/g, 'روز').replace(/Months/g, 'ماه')}
		</span>
	);
};

export default DgCountDown;
