import { FC } from 'react';

interface ICountItem {
	title: string;
	count: number | string;
	percent?: number | string;
	color?: string;
	percentTitle?: string;
	textSize?: string;
	flatPercent?: boolean;
	notTrend?: boolean;
	percentColor?: string;
	countClassName?: string;
	titleClassName?: string;
}

const CountsItem: FC<ICountItem> = ({ title, count, percent, color, percentTitle, textSize, flatPercent, notTrend, percentColor, countClassName, titleClassName }) => {
	return (
		<div className=''>
			<div className={`flex items-center m-0 text-xs text-t-text-color gap-x-1 whitespace-nowrap ${titleClassName}`}>
				{title} <div className={`w-2 h-2 rounded-full mb-1 opacity-80 flex-shrink-0 flex-grow-0 ${color}`} />
			</div>
			<p className={`mb-0 mt-1 ${textSize ? textSize : 'text-lg'}  font-medium whitespace-nowrap ${countClassName}`}>{count}</p>
			<div className={`${flatPercent ? 'flex items-center gap-x-1' : ''}`}>
				{percent && (
					<p className={`${percentColor ? percentColor : percent > 0 ? 'text-[var(--dg-green-color)]' : 'text-[var(--dg-red-color-2)]'} whitespace-nowrap mb-0 text-xs`}>
						{percent.toString().replace('-', '') + '%'}
						{!notTrend && (
							<>
								{percent > 0 && ' +'}
								{percent < 0 && ' -'}
							</>
						)}
					</p>
				)}
				{percentTitle && <p className='text-[10px] m-0 whitespace-nowrap text-t-secondary-text-color'>{percentTitle}</p>}
			</div>
		</div>
	);
};

export default CountsItem;
