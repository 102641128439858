import useQueryUrlParam from 'core/hooks/useQueryUrlParam';
import DgPagination from 'styles/UI/Pagination/DgPagination';
import DgTable from 'styles/UI/Table/DgTable';
import { useAdminOrderQuery } from '../hooks/react-query/useAdminOrderQuery';
import AdminOrderTableColumn from './AdminOrderTableColumn';

const AdminOrderTable = () => {
	const { data: orders, isFetching: ordersFetching } = useAdminOrderQuery();
	const { query, handlePagination } = useQueryUrlParam();

	return (
		<>
			<DgTable
				rowKey='id'
				columns={AdminOrderTableColumn()}
				dataSource={orders?.data?.list}
				count={orders?.data.pagination.total}
				loading={ordersFetching}
				current={orders?.data.pagination.current_page}
				per_page={query?.per_page}
			/>
			<DgPagination
				onPaginationHandler={handlePagination}
				responsive={true}
				per_page={query?.per_page}
				current={orders?.data.pagination.current_page}
				total={orders?.data.pagination.total}
			/>
		</>
	);
};

export default AdminOrderTable;
