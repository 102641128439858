import { Form } from 'antd';
import { useSponsorIdQuery } from './react-query/useSponsorIdQuery';
import toastHandler from 'core/helpers/toast/toast';
import useTranslate from 'core/utils/useTranslate';

const useSponsorId = () => {
	const [sponsorIdForm] = Form.useForm();
	const { t } = useTranslate();
	const { mutate: setSponsorId, isLoading: sponsorIdIsLoading } = useSponsorIdQuery();

	const onFinishSponsorId = (values: { sponsor_id: string }) => {
		if (!values.sponsor_id) return toastHandler('error', t('referral-code-is-required'));
		setSponsorId(values);
	};

	return { sponsorIdForm, onFinishSponsorId, sponsorIdIsLoading };
};

export default useSponsorId;
