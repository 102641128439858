import { createSlice } from '@reduxjs/toolkit';
// import { IProduct } from 'core/types/models/productType';

const initialState: { items: { id: string; quantity: number }[] } = { items: [] };

export const basketSlice = createSlice({
	name: 'basket',
	initialState,
	reducers: {
		addBasketItem: (state, { payload }) => {
			const alreadyExist = state.items.find((item) => item.id === payload);

			if (alreadyExist) {
				state.items = [...state.items].map((item) => {
					if (item.id === payload) {
						item.quantity = item.quantity + 1;
					}

					return item;
				});
			} else state.items = [...state.items, { id: payload, quantity: 1 }];
		},
		removeBasketItem: (state, { payload }) => {
			const newState = [...state.items]
				.map((item) => {
					if (item.id === payload) {
						item.quantity = item.quantity - 1;
					}

					return item;
				})
				.filter((item) => item.quantity > 0);

			state.items = newState;
		},
		clearBasket: (state) => {
			state.items = [];

			// return initialState;
		},
	},
});

export const { addBasketItem, removeBasketItem, clearBasket } = basketSlice.actions;
export default basketSlice.reducer;
