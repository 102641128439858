import { Collapse, Typography } from 'antd';
import Text from 'core/utils/Text';
import useTranslate from 'core/utils/useTranslate';
import { useEffect } from 'react';
import DgButton from 'styles/UI/Button/DgButton';
import DgModal from 'styles/UI/Modal/DgModal';
import logoDark from '../../assets/images/logo/logo-dark.png';
import logoLight from '../../assets/images/logo/logo-light.png';
import { useSelector } from 'react-redux';
import { RootState } from 'core/store';

const { Panel } = Collapse;

export const RulesModal = ({ useModal }: any) => {
	const { lang, theme } = useSelector((store: RootState) => store.settingStore);

	const { t } = useTranslate();

	const { modal, showModal, hideModal } = useModal;

	const onChange = (key: string | string[]) => {
		// // console.log(key);
	};

	useEffect(() => {});

	return (
		<>
			<DgModal
				title='rules-title-1'
				open={modal}
				closable={false}
				className='faq-modal'
				width={860}
			>
				<div className='flex flex-col gap-4'>
					<div className='flex items-center justify-center'>
						<img src={theme === 'dark' ? logoDark : logoLight} width={'130px'} />
					</div>

					<Typography>
						<Text className='whitespace-pre-line' tid={'rules-description-1'}></Text>
					</Typography>

					<div className='flex flex-wrap gap-4 items-center justify-end'>
						<a target='_blank' href='/panel/rules.pdf' className=''>
							<Text tid='download-rules' />
						</a>

						<DgButton
							title='i-agree-the-rules'
							onClick={() => {
								hideModal();
							}}
						/>
					</div>
				</div>
			</DgModal>
		</>
	);
};
