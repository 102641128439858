import DgSelectRole from 'components/Basic/DgSelectRole';
import useTranslate from 'core/utils/useTranslate';
import DgButton from 'styles/UI/Button/DgButton';
import DgFormItem from 'styles/UI/FormItem/DgFormItem';
import DgForm from 'styles/UI/Form/DgForm';
import DgFormWrapper from 'styles/UI/FormWrapper/DgFormWrapper';
import { FC } from 'react';

interface IUserRoleUpdate {
	onFinishUpdateUser: (val: any) => void;
	onChangeRoles: (val: number) => void;
	onDeselectRole: (val: number) => void;
	roles: number[];
	isLoadingUserRole: boolean;
}

const UserRoleUpdate: FC<IUserRoleUpdate> = ({ onFinishUpdateUser, onChangeRoles, onDeselectRole, roles, isLoadingUserRole }) => {
	const { t } = useTranslate();

	return (
		<DgForm onFinish={onFinishUpdateUser}>
			<DgFormWrapper>
				<DgFormItem
					full
					rules={[
						{
							required: true,
							message: t('user-role-is-required'),
						},
					]}
				>
					<DgSelectRole onDeselect={onDeselectRole} customOnChange={onChangeRoles} value={roles.length === 0 ? undefined : roles} required />
				</DgFormItem>

				<DgFormItem full>
					<DgButton isLoading={isLoadingUserRole} minWidth='100%' title='update-user' htmlType='submit' />
				</DgFormItem>
			</DgFormWrapper>
		</DgForm>
	);
};

export default UserRoleUpdate;
