import { createSlice } from '@reduxjs/toolkit';
// import { IProduct } from 'core/types/models/productType';

const initialState: { productDetail: any | null } = { productDetail: null };

export const productSlice = createSlice({
	name: 'product',
	initialState: initialState,
	reducers: {
		setProductDetail: (state, { payload }) => {
			state.productDetail = payload;
		},
	},
});

export const { setProductDetail } = productSlice.actions;
export default productSlice.reducer;
