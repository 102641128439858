import { FC } from 'react';
import useTranslate from './useTranslate';
import { HintBox } from 'components/hint-box';

interface IText {
	tid: string;
	className?: string;
	hintTitle?: string;
	hintDescription?: string;
}

const Text: FC<IText> = ({
	tid,
	className = '',
	hintDescription,
	hintTitle,
	...rest
}) => {
	const { t } = useTranslate();
	const result = t(tid);

	return (
		<span className={`inline-block ${className}`} {...rest}>
			{result ? result : tid}

			{(hintDescription || hintTitle) && (
				<HintBox hintDescription={hintDescription} hintTitle={hintTitle} />
			)}
		</span>
	);
};

export default Text;
