import {
	UseMutationResult,
	UseQueryResult,
	useMutation,
	useQueryClient,
} from '@tanstack/react-query';
import { endPointUrls } from 'core/constants/endPointUrls';
import api from 'core/services/fetch-api/useApi';
import useQueryUrlParam from 'core/hooks/useQueryUrlParam';
import useQueryHook from 'core/hooks/masterQuery/useQueryHook';
import { IAdminOrderList } from 'core/types/models/orderType';
import useMutationHook from 'core/hooks/masterQuery/useMutationHook';
import toastHandler from 'core/helpers/toast/toast';
import { useDispatch } from 'react-redux';
import { setApiCatcherFormErrors } from 'core/store/slice/mainInfo/mainInfoSlice';
import { AxiosResponse } from 'axios';

export const useAdminOrderQuery = () => {
	const { filterParams } = useQueryUrlParam();

	return useQueryHook(
		[endPointUrls.ADMIN_ORDERS(), filterParams],
		(data: any) => api.get(endPointUrls.ADMIN_ORDERS(filterParams), data),
		{
			select: (res: any) => res?.data,
			keepPreviousData: true,
		}
	) as UseQueryResult<IAdminOrderList, Error>;
};

export const useUpdateAdminOrderQuery = () => {
	const { filterParams } = useQueryUrlParam();
	const dispatch = useDispatch();
	const queryClient = useQueryClient();

	return useMutationHook(
		[endPointUrls.ADMIN_UPDATE_ORDER, filterParams],
		(data: any) => api.patch(endPointUrls.ADMIN_UPDATE_ORDER, data),
		{
			onSuccess: (res) => {
				const response = res as AxiosResponse;

				if (response?.status === 200) {
					toastHandler('success', response?.data.message);
					dispatch(setApiCatcherFormErrors([]));
					queryClient.invalidateQueries([endPointUrls.ADMIN_ORDERS()]);
				}
			},
		}
	) as UseMutationResult<any, Error>;
};

export const usePrintOrderQuery = () => {
	const { filterParams } = useQueryUrlParam();
	const dispatch = useDispatch();
	const queryClient = useQueryClient();

	return useMutationHook(
		[endPointUrls.PRINT_ORDER(), filterParams],
		(id: any) => api.get(endPointUrls.PRINT_ORDER(id)),
		{
			onSuccess: (data: any) => {
				// Create a Blob from the binary data received
				console.log({ data });

				if (data?.data) {
					const blob = new Blob([data?.data], { type: 'application/pdf' });

					// Create a download link and trigger the download
					const downloadLink = document.createElement('a');
					downloadLink.href = window.URL.createObjectURL(blob);
					downloadLink.download = 'document.pdf';
					document.body.appendChild(downloadLink);
					downloadLink.click();
					document.body.removeChild(downloadLink);
				}
			},
		}
	) as UseMutationResult<any, Error>;
};
