import DgCard from 'styles/UI/Card/DgCard';
import DgCardHeader from 'styles/UI/CardHeader/DgCardHeader';
import UserOrdersTable from './components/UserOrdersTable';
import UserOrdersFilter from './components/UserOrdersFilter';
import { routes } from 'core/constants/routes';
import { useNavigate } from 'react-router-dom';
import useQueryUrlParam from 'core/hooks/useQueryUrlParam';
import DgAlert from 'styles/UI/Alert/DgAlert';
import { FiAlertCircle, FiCheckCircle } from 'react-icons/fi';
import Text from 'core/utils/Text';

const UserPlanHistory = () => {
	const navigate = useNavigate();
	const { query, handlePagination } = useQueryUrlParam();
	// console.log({ query });

	return (
		<>
			<DgCard>
				<DgCardHeader
					title='orders-history'
					// buttonText='my-invest'
					// onClick={() => navigate(routes.ORDERS_LIST)}
				/>

				{query?.order === 'success' && (
					<>
						<DgAlert
							className='bg-[var(--dg-green-color)] border-0 lg-600:flex-row flex-col gap-5 mb-4'
							message={
								<p className='flex gap-x-3 m-0 h-10 items-center'>
									<span>
										<FiCheckCircle size={22} color='black' />
									</span>
									<Text tid='order-succeed' className='text-base text-black' />
								</p>
							}
							// action={<SponsorIdCreateForm />}
						/>
					</>
				)}

				<UserOrdersFilter />
				<UserOrdersTable showMoreColumn={true} />
			</DgCard>
		</>
	);
};

export default UserPlanHistory;
