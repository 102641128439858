import DgSelectLabel from 'styles/UI/Select/DgSelectLabel';

const DgSelectBinaryPosition = ({ ...props }) => {
	const options = [
		{ label: 'Left', value: 'left', filter: 'Left' },
		{ label: 'Right', value: 'right', filter: 'Right' },
	];

	return <DgSelectLabel label='member-position' options={options} {...props} />;
};

export default DgSelectBinaryPosition;
