import DgSelectLabel from 'styles/UI/Select/DgSelectLabel';

const DgSelectPlanType = ({ label = '', ...props }) => {
	const options = [
		{ label: 'One Year', value: 'One Year', filter: 'One Year' },
		{ label: 'Two Year', value: 'Two Year', filter: 'Two Year' },
	];

	return <DgSelectLabel label={label} options={options} {...props} />;
};

export default DgSelectPlanType;
