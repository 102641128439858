import DgCard from 'styles/UI/Card/DgCard';
import DgCardHeader from 'styles/UI/CardHeader/DgCardHeader';
import UserTransferTable from './components/UserTransferTable';
import UserTransferFilter from './components/UserTransferFilter';

const UserTransfer = () => {
	return (
		<DgCard>
			<DgCardHeader title='inter-user-transfer' />

			<UserTransferFilter />
			<UserTransferTable />
		</DgCard>
	);
};

export default UserTransfer;
