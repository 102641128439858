import toastHandler from 'core/helpers/toast/toast';
import { setApiCatcherFormErrors } from 'core/store/slice/mainInfo/mainInfoSlice';
import { useDispatch } from 'react-redux';

const useApiCatcher = () => {
	const dispatch = useDispatch();

	return (errorResponse: any, isSHowMessage = true) => {
		let errorMessages: any[] = [];

		// console.log({ errorResponse });

		if (!isSHowMessage) return;

		if (
			errorResponse?.data?.length === 0 ||
			errorResponse?.data === null ||
			!Array.isArray(errorResponse?.data)
		) {
			// console.log('ahaaaa');

			for (const key in errorResponse?.data || errorResponse) {
				// console.log({ key });

				if (Object.hasOwnProperty.call(errorResponse?.data || errorResponse, key)) {
					const element = (errorResponse?.data || errorResponse)[key];
					// console.log({ element });

					toastHandler(
						'error',
						`${key} : ${typeof element === 'string' ? element : element?.[0]}` || element
					);
					errorMessages = [
						...errorMessages,
						{ name: key, errors: [typeof element === 'string' ? element : element?.[0]] },
					];
				}
			}

			((errorResponse?.data &&
				Object.keys(errorResponse?.data).length === 0 &&
				Object.getPrototypeOf(errorResponse?.data) === Object.prototype) ||
				errorResponse?.data?.length === 0 ||
				errorResponse?.data === null) &&
				toastHandler('error', errorResponse?.errors?.subject || errorResponse?.message);
		} else {
			for (const key in errorResponse?.data) {
				if (Object.hasOwnProperty.call(errorResponse?.data, key)) {
					const element = errorResponse?.data[key];
					toastHandler('error', `${key} : ${element?.[0]}` || element);
					errorMessages = [...errorMessages, { name: key, errors: [element?.[0]] }];
				}
			}
		}

		dispatch(setApiCatcherFormErrors(errorMessages));
	};
};

export default useApiCatcher;
