import { RadioChangeEvent } from 'antd';
import useModal from 'core/hooks/useModal';
import { RootState } from 'core/store';
import { setLang, setTheme } from 'core/store/slice/setting/settingSlice';
import { useDispatch, useSelector } from 'react-redux';

const useLanguageAndThemeWidget = () => {
	//hooks
	const dispatch = useDispatch();
	const {
		modal: languageAndThemeDropdown,
		hideModal: hideLanguageAndThemeDropdown,
		showModal: showLanguageAndThemeDropdown,
	} = useModal();

	// store
	const { lang, theme } = useSelector((store: RootState) => store.settingStore);

	const languageOnChangeHandler = (e: RadioChangeEvent): void => {
		dispatch(setLang(e.target.value));
		hideLanguageAndThemeDropdown();
	};

	const themeOnChangeHandler = (e: RadioChangeEvent): void => {
		dispatch(setTheme(e.target.value));
	};

	const onToggleLanguageAndThemeDropdown = () => {
		languageAndThemeDropdown
			? hideLanguageAndThemeDropdown()
			: showLanguageAndThemeDropdown();
	};

	return {
		lang,
		theme,
		languageOnChangeHandler,
		languageAndThemeDropdown,
		onToggleLanguageAndThemeDropdown,
		themeOnChangeHandler,
	};
};

export default useLanguageAndThemeWidget;
