import React from 'react';
import DgCard from 'styles/UI/Card/DgCard';
import DgCardHeader from 'styles/UI/CardHeader/DgCardHeader';
import DgFormItem from 'styles/UI/FormItem/DgFormItem';
import useUserInfo from './hooks/useUserInfo';
import DgForm from 'styles/UI/Form/DgForm';
import DgInputLabel from 'styles/UI/Input/DgInputLabel';
import useTranslate from 'core/utils/useTranslate';
import DgFormWrapper from 'styles/UI/FormWrapper/DgFormWrapper';
import DgRadioButtonGroup from 'styles/UI/RadioButton/DgRadioButtonGroup';
import DgRadioButton from 'styles/UI/RadioButton/DgRadioButton';
import DgButton from 'styles/UI/Button/DgButton';
import DgSpinnerFull from 'styles/UI/Spinner/DgSpinnerFull';
import DgDevider from 'styles/UI/Devider/DgDevider';
import DgDatePickerLabel from 'styles/UI/DatePicker/DgDatePickerLabel';
import DgTextAreaLabel from 'styles/UI/TextArea/DgTextAreaLabel';
import UserAvatar from './components/userAvatar';
import DgSelectCountry from 'components/Basic/DgSelectCountry';
import DgSelectCity from 'components/Basic/DgSelectCity';

const UserInfoUpdate = () => {
	const {
		userUpdateForm,
		genderOnChangeHandler,
		isLoadingUpdateAvatar,
		countrySelectedData,
		onUpdateAvatarClicked,
		isLoadingUpdateUser,
		onFinishUpdateUser,
		userInfo,
		avatarRef,
	} = useUserInfo();

	const { t } = useTranslate();

	return (
		<DgCard isMarginBottom={false}>
			<DgCardHeader title='user-detail' />
			<DgSpinnerFull isLoading={isLoadingUpdateUser}>
				<UserAvatar
					avatarRef={avatarRef}
					isLoadingUpdateAvatar={isLoadingUpdateAvatar}
					onUpdateAvatarClicked={onUpdateAvatarClicked}
					userInfo={userInfo}
				/>

				<DgForm form={userUpdateForm} onFinish={onFinishUpdateUser}>
					<DgDevider orientation='center' title='user-basic-info' />
					<DgFormWrapper>
						<DgFormItem
							rules={[
								{
									required: true,
									message: t('first-name-is-required'),
								},
							]}
							name='first_name'
						>
							<DgInputLabel label={t('first-name')} required />
						</DgFormItem>

						<DgFormItem
							rules={[
								{
									required: true,
									message: t('last-name-is-required'),
								},
							]}
							name='last_name'
						>
							<DgInputLabel label={t('last-name')} required />
						</DgFormItem>

						<DgFormItem
							rules={[
								{
									required: true,
									message: t('username-is-required'),
								},
							]}
							name='username'
						>
							<DgInputLabel label={t('username')} required />
						</DgFormItem>

						<DgFormItem
							rules={[
								{
									required: true,
									message: t('email-is-required'),
								},
								{
									type: 'email',
									message: t('email-format-incorrect'),
								},
							]}
							name='email'
						>
							<DgInputLabel autoComplete='new-password' label='email' required />
						</DgFormItem>

						<DgFormItem name='birthday'>
							<DgDatePickerLabel label='birthday' />
						</DgFormItem>
					</DgFormWrapper>

					<DgFormWrapper>
						<DgFormItem name='gender'>
							<DgRadioButtonGroup onChange={genderOnChangeHandler}>
								<DgRadioButton value='Male'>{t('male')}</DgRadioButton>
								<DgRadioButton value='Female'>{t('female')}</DgRadioButton>
							</DgRadioButtonGroup>
						</DgFormItem>
					</DgFormWrapper>

					<DgDevider orientation='center' title='address-and-contact-info' />

					<DgFormWrapper>
						<DgFormItem name='country_id'>
							<DgSelectCountry label={t('country')} />
						</DgFormItem>

						<DgFormItem name='mobile_number'>
							<DgInputLabel label={t('phone-number')} />
						</DgFormItem>

						<DgFormItem name='zip_code'>
							<DgInputLabel label={t('postal-code')} />
						</DgFormItem>

						<DgFormItem name='address_line1'>
							<DgTextAreaLabel label={t('address')} />
						</DgFormItem>
					</DgFormWrapper>

					<DgFormItem button>
						<DgButton htmlType='submit' isLoading={isLoadingUpdateUser} title='submit' />
					</DgFormItem>
				</DgForm>
			</DgSpinnerFull>
		</DgCard>
	);
};

export default UserInfoUpdate;
