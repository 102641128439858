import useCopyToClipboard from 'core/hooks/useCopyToClipboard';
import React, { FC } from 'react';
import DgTooltip from '../Tooltip/DgTooltip';

export interface IDgCopyClipboard {
	value: string | number;
	hiddenValue?: string | number;
	className?: string;
}

const DgCopyClipboard: FC<IDgCopyClipboard> = ({ value, hiddenValue, className, ...props }) => {
	const { copyHandler } = useCopyToClipboard();

	return (
		<DgTooltip title='copy'>
			<span className={`cursor-pointer ${className}`} onClick={() => copyHandler(value + '')} {...props}>
				{hiddenValue || value}
			</span>
		</DgTooltip>
	);
};

export default DgCopyClipboard;
