import { FC, useEffect } from 'react';
import Text from 'core/utils/Text';
import useQueryUrlParam from 'core/hooks/useQueryUrlParam';
import DgSpinnerFull from 'styles/UI/Spinner/DgSpinnerFull';
import { useOrderSubmitPreviewQuery } from '../hooks/react-query/useOrderSubmitPreviewQuery';
import { useOrderSubmitQuery } from '../hooks/react-query/useOrderSubmitQuery';
import DgPrice from 'styles/UI/Price/DgPrice';
import useTranslate from 'core/utils/useTranslate';

const OrderPayment: FC = () => {
	const { t } = useTranslate();

	const { query, filterParams } = useQueryUrlParam();
	const {
		mutate: submitOrderPreview,
		isLoading: isLoadingSubmitOrderPreview,
		data: orderPreviewData,
	} = useOrderSubmitPreviewQuery();

	const { isLoading: isLoadingSubmitOrder } = useOrderSubmitQuery();

	useEffect(() => {
		if (query?.package_id) {
			submitOrderPreview({ package_id: query?.package_id, one_time_password: '1' });
		}
	}, [filterParams]);

	return (
		<DgSpinnerFull isLoading={isLoadingSubmitOrderPreview || isLoadingSubmitOrder}>
			<div className='max-w-2xl mx-auto grid sm:gap-5 gap-3 w-full sm:px-10 px-5 py-5 rounded-lg border border-[var(--dg-border-color)]'>
				<div className='flex justify-between items-center pb-2'>
					<Text className='font-bold text-base' tid='product-name' />
					<span className='font-bold sm:text-xl text-base'>
						<span>{orderPreviewData?.data?.data.package.name || ''}</span>
					</span>
				</div>

				<div className='flex justify-between items-center pb-2'>
					<Text className='font-bold text-base' tid='invest-amount' />
					<span className='font-bold sm:text-xl text-base'>
						<DgPrice price={orderPreviewData?.data?.data.package_cost_in_pf || 0} />
					</span>
				</div>

				<div className='flex justify-between items-center pb-2'>
					<Text
						className='font-bold text-base'
						tid='invest-profit'
						hintTitle='invest-profit'
						hintDescription='roi_percentage_info'
					/>
					<span className='font-bold sm:text-xl text-base'>
						{orderPreviewData?.data?.data.package.roi_percentage || 0}%
					</span>
				</div>

				<div className='flex justify-between items-center pb-2'>
					<Text className='font-bold text-base' tid='invest-release' />
					<span className='font-bold sm:text-xl text-base'>
						{orderPreviewData?.data?.data.package.validity_in_days || 0} {t('days')}
					</span>
				</div>

				<div className='flex justify-between items-center pb-2'>
					<Text className='font-bold text-base' tid='direct_percentage' />
					<span className='font-bold sm:text-xl text-base'>
						{orderPreviewData?.data?.data.package.direct_percentage || 0}%
					</span>
				</div>

				<div className='flex justify-between items-center pb-2'>
					<Text
						className='font-bold text-base'
						tid='binary_percentage'
						hintTitle='binary_percentage'
						hintDescription='binary_percentage_info'
					/>
					<span className='font-bold sm:text-xl text-base'>
						{orderPreviewData?.data?.data.package.binary_percentage || 0}%
					</span>
				</div>

				<div className='flex justify-between items-center pb-2'>
					<Text className='font-bold text-base' tid='subscription' />
					<span className='font-bold sm:text-xl text-base'>
						<DgPrice price={orderPreviewData?.data?.data.registration_fee_in_pf || 0} />
					</span>
				</div>

				{/* <div className='flex justify-between items-center border-b border-b-[var(--dg-border-color)] pb-2'>
					<Text className='font-bold text-base' tid='payment-type' />
					<span className='font-bold sm:text-xl text-base'>
						{orderPreviewData?.data?.data.payment_type.toUpperCase()}
					</span>
				</div> */}

				<div className='flex justify-between items-center py-2  border-t border-t-[var(--dg-border-color)]'>
					<Text className='font-bold text-base' tid='total-payment' />
					<span className='font-bold sm:text-xl text-base'>
						<DgPrice price={orderPreviewData?.data?.data.total_cost_in_pf || 0} />
					</span>
				</div>
			</div>
		</DgSpinnerFull>
	);
};

export default OrderPayment;
