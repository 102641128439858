import { useWindowSize } from 'core/hooks/useWindowSize';
import Text from 'core/utils/Text';
import DgTableColumnResponsive from 'styles/UI/TableColumnResponsive/DgTableColumnResponsive';
import DgPrice from 'styles/UI/Price/DgPrice';
// import { IProduct } from 'core/types/models/productType';
import useTranslate from 'core/utils/useTranslate';
import DgEditIcon from 'styles/UI/EditIcon/DgEditIcon';
import DgDeleteIcon from '../../../../../styles/UI/Icon/DgDeleteIcon';

const AdminProductTableColumn = (
	onEditProductHandler?: (val: any) => void,
	onDeleteProductHandler?: (val: any) => void
) => {
	const { width } = useWindowSize();
	const { t } = useTranslate();

	const Columns = [
		{
			title: <Text tid='title' />,
			render: (text: any, record: any) => record?.title,
			sorter: false,
		},
		{
			title: <Text tid='price' />,
			key: 'price',
			dataIndex: 'price',
			render: (text: any) => <DgPrice price={text} />,
			sorter: false,
		},
		// {
		// 	title: <Text tid='euro_price' />,
		// 	key: 'euro_price',
		// 	dataIndex: 'euro_price',
		// 	render: (text: any) => <DgPrice price={text} />,
		// 	sorter: false,
		// },
		{
			title: <Text tid='discount' />,
			key: 'discount',
			dataIndex: 'discount',
			render: (text: any) => `${text}%`,
			sorter: false,
		},
		{
			title: <Text tid='roi_percentage' />,
			key: 'roi_percentage',
			dataIndex: 'roi_percentage',
			render: (text: any) => `${text}%`,
			sorter: false,
		},
		{
			title: <Text tid='quantity' />,
			key: 'qty',
			dataIndex: 'qty',
			render: (text: any) => `${text}`,
			sorter: false,
		},
		...(onEditProductHandler
			? [
					{
						title: '',
						render: (_text: any, record: any) => (
							<div className={'flex fw justify-end items-center'}>
								<DgEditIcon onClick={() => onEditProductHandler?.(record)} />
								<DgDeleteIcon onClick={() => onDeleteProductHandler?.(record)} />
							</div>
						),
						sorter: false,
					},
			  ]
			: []),
	];

	const RColumn = [
		{
			title: '',
			sorter: false,
			render: (_text: any, record: any) => {
				return (
					<>
						<DgTableColumnResponsive title={t('title')} value={record?.title} />
						<DgTableColumnResponsive
							title={t('price')}
							value={<DgPrice price={record?.price} />}
						/>
						{/* <DgTableColumnResponsive
							title={t('euro_price')}
							value={<DgPrice price={record?.euro_price} />}
						/> */}
						<DgTableColumnResponsive
							title={t('discount')}
							value={`${record?.discount}%`}
						/>
						<DgTableColumnResponsive
							title={t('roi_percentage-time')}
							value={`${record?.roi_percentage}%`}
						/>
						<DgTableColumnResponsive
							title={t('quantity-time')}
							value={`${record?.quantity}`}
						/>
						{onEditProductHandler && (
							<DgTableColumnResponsive
								title={t('operation')}
								value={
									<>
										<DgEditIcon
											className='flex justify-end'
											onClick={() => onEditProductHandler(record)}
										/>
										<DgDeleteIcon onClick={() => onDeleteProductHandler?.(record)} />
									</>
								}
							/>
						)}
					</>
				);
			},
		},
	];

	return width > 769 ? Columns : RColumn;
};

export default AdminProductTableColumn;
