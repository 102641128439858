import { FC } from 'react';
import DgCard from 'styles/UI/Card/DgCard';
import DgCardHeader from 'styles/UI/CardHeader/DgCardHeader';
import AdminWalletSettingsTable from './components/AdminWalletSettingsTable';

const AdminWalletSettings: FC = () => {
	return (
		<DgCard>
			<DgCardHeader title='wallet-settings' />

			<AdminWalletSettingsTable />
		</DgCard>
	);
};

export default AdminWalletSettings;
