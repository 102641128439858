import { FC, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

interface IDgFormWrapper {
	children: ReactNode;
	twoColumn?: boolean;
	singleColumn?: boolean;
	threeColumn?: boolean;
	className?: string;
}

const DgFormWrapper: FC<IDgFormWrapper> = ({
	twoColumn,
	singleColumn,
	threeColumn,
	children,
	className,
}) => {
	return (
		<div
			className={twMerge(
				`items-start md:grid ltr:text-left rtl:text-right ${
					twoColumn ? 'grid-cols-2' : 'grid-cols-1'
				} gap-x-8 md:gap-y-4 md:space-y-0 space-y-4 mb-4 ${
					!singleColumn ? 'md:grid-cols-2' : ''
				} ${threeColumn ? 'md:grid-cols-3' : ''} ${className}`
			)}
		>
			{children}
		</div>
	);
};

export default DgFormWrapper;
