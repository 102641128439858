import Text from 'core/utils/Text';
import useTranslate from 'core/utils/useTranslate';
import React from 'react';
import DgDevider from 'styles/UI/Devider/DgDevider';
import DgPopConfirm from 'styles/UI/PopConfirm/DgPopConfirm';
import DgShowInfo from 'styles/UI/ShowInfo/DgShowInfo';
import DgSwitch from 'styles/UI/Switch/DgSwitch';
import DgTooltip from 'styles/UI/Tooltip/DgTooltip';
import useUserActiveDeActive from '../hooks/useUserActiveDeActive';

const UserActiveDeActiveStatus = () => {
	const { block, onConfirm, activeDeActiveIsLoading } = useUserActiveDeActive();
	const { t } = useTranslate();

	return (
		<>
			<DgDevider orientation='center' title='active-deactive-user' />

			<DgShowInfo
				title={t('activate-deactivate-user-account')}
				value={
					<DgTooltip title={block ? 'deactivate' : 'activate '}>
						<DgPopConfirm onConfirm={onConfirm} title={<Text tid={block ? 'are-you-sure-to-deactivate-user' : 'are-you-sure-to-activate-user'} />}>
							<DgSwitch checked={block} loading={activeDeActiveIsLoading} />
						</DgPopConfirm>
					</DgTooltip>
				}
			/>
		</>
	);
};

export default UserActiveDeActiveStatus;
