import { FC } from 'react';
import DgCard from 'styles/UI/Card/DgCard';
import DgCardHeader from 'styles/UI/CardHeader/DgCardHeader';
import AdminUserResetPassword from './components/AdminUserResetPassword';
import AdminUserDisable2FA from './components/AdminUserDisable2FA';

const UserSecurity: FC = () => {
	return (
		<DgCard isMarginBottom={false}>
			<DgCardHeader title='user-security' />

			<AdminUserResetPassword />
			<AdminUserDisable2FA />
		</DgCard>
	);
};

export default UserSecurity;
