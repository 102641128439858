import DgCard from 'styles/UI/Card/DgCard';
import DgCardHeader from 'styles/UI/CardHeader/DgCardHeader';
import AdminUsersTable from './components/AdminUsersTable';
import useAdminUser from './hooks/useAdminUser';
import DgModal from 'styles/UI/Modal/DgModal';
import AdminUserCreate from '../Create/AdminUserCreate';
import AdminUsersFilter from './components/AdminUsersFilter';

const AdminUsers = () => {
	const { hideModal, modal, showModal } = useAdminUser();

	return (
		<>
			<DgModal title='new-user' width={1000} open={modal} onCancel={hideModal}>
				<AdminUserCreate />
			</DgModal>
			<DgCard>
				<DgCardHeader title='user-management' onClick={showModal} buttonText='new-user' />

				<AdminUsersFilter />
				<AdminUsersTable />
			</DgCard>
		</>
	);
};

export default AdminUsers;
