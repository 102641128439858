import { createSlice } from '@reduxjs/toolkit';
import { INotificationSocketData } from 'core/types/models/notificationType';

const initialState: { notification: INotificationSocketData | null; socketConnectionStatus: boolean } = {
	notification: null,
	socketConnectionStatus: true,
};

export const socketSlice = createSlice({
	name: 'socket',
	initialState: initialState,
	reducers: {
		setNotificationSocketData: (state, { payload }) => {
			state.notification = payload;
		},
		setSocketConnectionStatus: (state, { payload }) => {
			state.socketConnectionStatus = payload;
		},
	},
});

export const { setNotificationSocketData, setSocketConnectionStatus } = socketSlice.actions;
export default socketSlice.reducer;
