import { useAdminCreateProduct } from '../../Update/hooks/react-query/useAdminCreateProduct';
import DgForm from '../../../../../styles/UI/Form/DgForm';
import DgFormWrapper from '../../../../../styles/UI/FormWrapper/DgFormWrapper';
import React, { useState } from 'react';
import DgFormItem from '../../../../../styles/UI/FormItem/DgFormItem';
import DgInputLabel from '../../../../../styles/UI/Input/DgInputLabel';
import DgButton from '../../../../../styles/UI/Button/DgButton';
import { Form } from 'antd';
import useTranslate from '../../../../../core/utils/useTranslate';
import Text from '../../../../../core/utils/Text';
import DgImage from '../../../../../styles/UI/Image/DgImage';
import usdt from '../../../../../assets/images/usdt.png';
import { HintBox } from 'components/hint-box';
import { ProductCommissions } from '../../product-commissions';
import { useAdminProductCommissions } from '../../Update/hooks/react-query/useAdminProductCommissions';
import DgTextAreaLabel from 'styles/UI/TextArea/DgTextAreaLabel';
import DgSelectLabel from 'styles/UI/Select/DgSelectLabel';
import { useAdminUserWalletsQuery } from 'components/Admin/User/Detail/UserWallets/hooks/useAdminUserWalletsQuery';
import { useAdminGroupQuery } from 'components/Admin/Groups/List/hooks/react-query/useAdminGroupsQuery';
import Dragger from 'antd/lib/upload/Dragger';
import { useImageUpload } from 'core/hooks/react-query/images';
import { FaPlus, FaPlusCircle, FaUpload } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { routes } from 'core/constants/routes';

const CreateProductModal = ({ hideCreateModal }) => {
	const [form] = Form.useForm();
	const { t } = useTranslate();

	const [category, setCategory] = useState(1);

	const {
		mutate: createProduct,
		mutateAsync: createProductAsync,
		isLoading: creating,
	} = useAdminCreateProduct(() => {});

	const productCommissionsMutation = useAdminProductCommissions();

	const imageMutation = useImageUpload();

	const [selectedGroup, setSelectedGroup] = useState();
	const [commissions, setCommissions] = useState([]);

	const { data: groups, isFetching: isFetchingGroups } = useAdminGroupQuery();
	const [file, setFile] = useState(undefined);

	const options = groups?.data?.map((item) => ({
		label: item.name,
		value: item.name,
		filter: item.name,
		allItems: { ...item },
	}));

	// // console.log({ selectedGroup });

	const onFinish = async () => {
		try {
			// return console.log({ image });
			const payload = { ...form.getFieldsValue() };
			payload.category_id = category;
			payload.price = parseInt(payload.price);
			payload.roi_percentage = parseInt(payload.roi_percentage);
			// payload.direct_percentage = parseInt(payload.direct_percentage);
			// payload.validity_in_days = parseInt(payload.validity_in_days);

			payload.status = 'approved';

			delete payload.direct_percentage_1;
			delete payload.direct_percentage_2;
			delete payload.direct_percentage_3;

			if (selectedGroup?.allItems?.id) {
				payload.group_id = selectedGroup.allItems.id;
				// payload.files_id = selectedGroup.allItems.id;
			}

			if (file) {
				const image = await imageMutation.mutateAsync({ 'files[]': file }).catch((e) => {
					console.log({ e });
				});

				if (image?.data?.data?.length) {
					payload.files_id = [`${image.data.data[0].id}`];
					// payload.images = [`${image.data.data[0].id}`];
				}
			}

			const { data } = await createProductAsync(payload);

			const commissionsPromises = [];

			if (commissions?.length) {
				commissions.forEach((item, index) => {
					commissionsPromises.push(
						async () =>
							await productCommissionsMutation
								.mutateAsync({
									product_id: data?.data?.id,
									level: index + 1,
									...item,
								})
								.catch((e) => {
									// console.log({ e });
								})
					);
				});
			}

			// console.log({ payload, data, commissions });

			await Promise.all(commissionsPromises.map((item) => item()));

			hideCreateModal();
		} catch (e) {
			// console.log({ e });
		}
	};

	// console.log({ commissions, selectedGroup });

	return (
		<>
			<DgForm shouldUpdate={true} autoComplete='off' onFinish={onFinish} form={form}>
				<DgFormWrapper singleColumn>
					<Dragger
						{...{
							// key: index,
							name: 'file',
							multiple: false,
							maxCount: 1,
							accept: 'image/*',
							disabled: creating,
							beforeUpload: () => false,
							onChange(info) {
								// const { status } = info.file;

								setFile(info.file);
							},
							onRemove() {
								setFile(undefined);
							},
							className: 'w-full',
						}}
					>
						<div className='flex  flex-col gap-2 items-center justify-center'>
							<span className='ant-upload-drag-icon'>
								<FaUpload size={'2.5rem'} />
							</span>
							<p className='ant-upload-text'>Click or drag file to this area to upload</p>
							<p className='ant-upload-hint'>
								Support for a single or bulk upload. Strictly prohibited from uploading
								company data or other banned files.
							</p>
						</div>
					</Dragger>

					<DgFormItem
						name='title'
						rules={[
							{
								required: true,
								message: t('title-is-required'),
							},
						]}
					>
						<DgInputLabel label='title' />
					</DgFormItem>

					<DgFormItem
						rules={[
							{
								required: true,
								message: t('description-is-required'),
							},
						]}
						name='description'
					>
						<DgTextAreaLabel label='description' />
					</DgFormItem>

					<DgFormItem
						name='code'
						rules={[
							{
								required: true,
								message: t('code-is-required'),
							},
						]}
					>
						<DgInputLabel label='code' />
					</DgFormItem>

					<div className='flex items-center gap-4'>
						<DgSelectLabel
							label='group'
							options={options}
							loading={isFetchingGroups}
							onChange={(value) =>
								setSelectedGroup(options.find((item) => item.label === value))
							}
							value={selectedGroup}
						/>

						<Link to={routes.ADMIN_GROUPS}>
							<FaPlusCircle size={'1.5rem'} />
						</Link>
					</div>

					<DgFormItem
						rules={[
							{
								required: true,
								message: t('price-is-required'),
							},
						]}
						noMargin
						name='price'
					>
						<DgInputLabel
							suffix={
								<DgImage
									src={usdt}
									width={22}
									className='rounded-full relative top-1.5'
								/>
							}
							label='price'
						/>
					</DgFormItem>

					{/* <DgFormItem
						rules={[
							{
								required: true,
								message: t('euro_price-is-required'),
							},
						]}
						noMargin
						name='euro_price'
					>
						<DgInputLabel
							suffix={
								<DgImage
									src={usdt}
									width={22}
									className='rounded-full relative top-1.5'
								/>
							}
							label='euro_price'
						/>
					</DgFormItem> */}

					<DgFormItem name='discount'>
						<DgInputLabel suffix={t('%')} label='discount' />
					</DgFormItem>

					<DgFormItem
						rules={[
							{
								required: true,
								message: t('quantity-is-required'),
							},
						]}
						noMargin
						name='qty'
					>
						<DgInputLabel label='quantity' />
					</DgFormItem>

					<DgFormItem
						name='weight'
						rules={[
							{
								required: true,
								message: t('weight-is-required'),
							},
						]}
					>
						<DgInputLabel suffix={t('KG')} label='weight' />
					</DgFormItem>
					<DgFormItem
						name='roi_percentage'
						rules={[
							{
								required: true,
								message: t('roi_percentage-is-required'),
							},
						]}
					>
						<DgInputLabel
							suffix={
								<HintBox
									hintTitle='roi_percentage'
									hintDescription='roi_percentage_info'
								/>
							}
							label='roi_percentage'
						/>
					</DgFormItem>

					<ProductCommissions onChange={(value) => setCommissions(value)} />
				</DgFormWrapper>

				<DgFormItem button>
					<DgButton htmlType='submit' isLoading={creating} title='create-product' />
				</DgFormItem>
			</DgForm>
		</>
	);
};

export default CreateProductModal;
