import DgTable from 'styles/UI/Table/DgTable';
import { FC } from 'react';
import BasketTableColumn from './BasketTableColumn';
import { useBasket } from 'core/hooks/basket';

const BasketTable: FC = ({ ...props }) => {
	const { basket, basketCount, basketItems, basketPrice } = useBasket();

	return (
		<DgTable
			rowKey='id'
			columns={BasketTableColumn()}
			dataSource={basketItems}
			// count={
			// 	<DgPrice
			// 		price={userOrders?.data
			// 			?.filter((item) => item.canceled_at === null)
			// 			?.map((item) => item.total_cost_in_pf)
			// 			?.reduce((prev, current) => prev + current, 0)}
			// 	/>
			// }
			// countTitle='total-active-investment'
			loading={false}
			{...props}
		/>
	);
};

export default BasketTable;
