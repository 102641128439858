import { createSlice } from '@reduxjs/toolkit';
import { IWithdrawType } from 'core/types/models/withdrawType';

const initialState: { withdrawDetail: IWithdrawType | null } = {
	withdrawDetail: null,
};

export const withdrawSlice = createSlice({
	name: 'withdraw',
	initialState: initialState,
	reducers: {
		setWithdrawDetail: (state, { payload }: { payload: IWithdrawType | null }) => {
			state.withdrawDetail = payload;
		},
		clearWithdrawDetail: (state) => {
			state.withdrawDetail = null;
		},
	},
});

export const { setWithdrawDetail, clearWithdrawDetail } = withdrawSlice.actions;
export default withdrawSlice.reducer;
