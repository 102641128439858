import useQueryUrlParam from 'core/hooks/useQueryUrlParam';
import DgPagination from 'styles/UI/Pagination/DgPagination';
import DgTable, { IDgTable } from 'styles/UI/Table/DgTable';
import UserInvoiceTableColumn from './UserEarningWalletTableColumn';
import { FC } from 'react';
import { useUserEarningWalletTableQuery } from '../hook/react-query/useUserEarningWalletTableQuery';

interface IUserInvoiceTable extends IDgTable {
	limit?: number;
}

const UserEarningWalletTable: FC<IUserInvoiceTable> = () => {
	const { data: invoices, isFetching: isFetchingInvoices } =
		useUserEarningWalletTableQuery();

	const { query, handlePagination } = useQueryUrlParam();

	return (
		<>
			<DgTable
				rowKey='transaction_id'
				columns={UserInvoiceTableColumn()}
				dataSource={invoices?.data?.list}
				count={invoices?.data.pagination.total}
				loading={isFetchingInvoices}
				current={invoices?.data.pagination.current_page}
				per_page={query?.per_page}
			/>

			<DgPagination
				onPaginationHandler={handlePagination}
				responsive={true}
				per_page={query?.per_page}
				current={invoices?.data.pagination.current_page}
				total={invoices?.data.pagination.total}
			/>
		</>
	);
};

export default UserEarningWalletTable;
