import { combineReducers } from 'redux';

import mainInfoStore from './slice/mainInfo/mainInfoSlice';
import settingStore from './slice/setting/settingSlice';
import userInfoStore from './slice/userInfo/userInfoSlice';
import modalStore from './slice/modal/modalSlice';
import countryStore from './slice/country/countrySlice';
import submitOrderCategoryPriceStore from './slice/submitOrderCategoryPrice/submitOrderCategoryPriceSlice';
import walletStore from './slice/wallet/walletReducer';
import socketStore from './slice/socket/socketSlice';
import notificationStore from './slice/notification/notificationSlice';
import depositStore from './slice/deposit/depositSlice';
import withdrawStore from './slice/withdraw/withdrawSlice';
import packageStore from './slice/package/packageReducer';
import productStore from './slice/product/reducer';
import groupsStore from './slice/groups/reducer';
import basketStore from './slice/basket/reducer';
import submitOrderStore from './slice/submitOrder/submitOrderSlice';

import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

const rootReducer = combineReducers({
	mainInfoStore,
	settingStore,
	userInfoStore,
	modalStore,
	countryStore,

	submitOrderStore,
	submitOrderCategoryPriceStore,
	walletStore,
	socketStore,
	notificationStore,
	depositStore,
	withdrawStore,
	packageStore,
	groupsStore,
	productStore,
	basketStore,
});

export const persistedReducer = persistReducer(
	{
		key: 'root',
		storage,
		whitelist: ['basketStore'],
	},
	rootReducer
);

export default rootReducer;
