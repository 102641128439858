import { UseQueryResult } from '@tanstack/react-query';
import { endPointUrls } from 'core/constants/endPointUrls';
import api from 'core/services/fetch-api/useApi';
import useQueryHook from 'core/hooks/masterQuery/useQueryHook';
import { IPackageList } from '../../../../../../core/types/models/packageType';

export const useAdminPackageQuery = () => {
	return useQueryHook(
		[endPointUrls.PACKAGES_LIST],
		(data: any) =>
			api.get(endPointUrls.ADMIN_PACKAGES_LIST, {
				...data,
				params: {
					...(data?.params || {}),
					package_exactly: true,
				},
			}),
		{
			select: (res: any) => res?.data,
			keepPreviousData: true,
		}
	) as UseQueryResult<IPackageList, Error>;
};
