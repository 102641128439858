import useProfileSecurity from 'components/Profile/hooks/useProfileSecurity';
import Text from 'core/utils/Text';
import { FC } from 'react';
import DgButton from 'styles/UI/Button/DgButton';
import DgModal from 'styles/UI/Modal/DgModal';
import ChangePasswordForm from './ChangePasswordForm';
import clsx from 'clsx';

const TransactionPassword: FC<{ type: 'default' | 'raw' }> = ({ type = 'default' }) => {
	const {
		hideTransactionPasswordModal,
		modalTransactionPassword,
		showTransactionPasswordModal,
		onFinishTransactionPassword,
		transactionPasswordForm,
		transactionPasswordIsLoading,
		onFinishUserPassword,
		userPasswordForm,
		userPasswordIsLoading,
	} = useProfileSecurity();

	return (
		<>
			<DgModal
				title='transaction-password'
				open={modalTransactionPassword}
				onCancel={hideTransactionPasswordModal}
			>
				<ChangePasswordForm
					onFinishTransactionPassword={onFinishTransactionPassword}
					transactionPasswordForm={transactionPasswordForm}
					transactionPasswordIsLoading={transactionPasswordIsLoading}
					onFinishUserPassword={onFinishUserPassword}
					userPasswordForm={userPasswordForm}
					userPasswordIsLoading={userPasswordIsLoading}
					isUserPassword={false}
				/>
			</DgModal>
			<div
				className={clsx(
					type === 'default' && 'border-b border-b-[var(--dg-border-color)] pb-3'
				)}
			>
				<div className='flex justify-between items-center flex-wrap'>
					{type === 'default' && <Text tid='transaction-password' />}

					<DgButton
						title='set-transaction-password'
						onClick={showTransactionPasswordModal}
					/>
				</div>
			</div>
		</>
	);
};

export default TransactionPassword;
