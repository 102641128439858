import { createSlice } from '@reduxjs/toolkit';
import { IDepositType } from 'core/types/models/depositType';

const initialState: { depositDetail: IDepositType | null } = {
	depositDetail: null,
};

export const depositSlice = createSlice({
	name: 'deposit',
	initialState: initialState,
	reducers: {
		setDepositDetail: (state, { payload }: { payload: IDepositType | null }) => {
			state.depositDetail = payload;
		},
		clearDepositDetail: (state) => {
			state.depositDetail = null;
		},
	},
});

export const { setDepositDetail, clearDepositDetail } = depositSlice.actions;
export default depositSlice.reducer;
