import { useWindowSize } from 'core/hooks/useWindowSize';
import Text from 'core/utils/Text';
import DgTableColumnResponsive from 'styles/UI/TableColumnResponsive/DgTableColumnResponsive';
import DgPrice from 'styles/UI/Price/DgPrice';
import DgTimeStamp from 'styles/UI/Timstamp/DgTimeStamp';
import useTranslate from 'core/utils/useTranslate';
import { Link } from 'react-router-dom';
import { routes } from 'core/constants/routes';
import { FaEdit, FaEye, FaPrint } from 'react-icons/fa';
import useModal from 'core/hooks/useModal';
import DgModal from 'styles/UI/Modal/DgModal';
import DgSelectLabel from 'styles/UI/Select/DgSelectLabel';
import { useState } from 'react';
import { Form } from 'antd';
import DgForm from 'styles/UI/Form/DgForm';
import DgButton from 'styles/UI/Button/DgButton';
import {
	usePrintOrderQuery,
	useUpdateAdminOrderQuery,
} from '../hooks/react-query/useAdminOrderQuery';

const AdminOrderTableColumn = () => {
	const { width } = useWindowSize();
	const { t } = useTranslate();

	const Columns = [
		{
			title: <Text tid='id' />,
			render: (text: any, record: any) => <span className={''}>{record?.id}</span>,
			sorter: false,
		},
		{
			title: <Text tid='products' />,
			render: (text: any, record: any) => (
				<div className='flex items-center justify-start gap-2 flex-wrap'>
					{record?.items?.length}
				</div>
			),
			sorter: false,
		},
		{
			title: <Text tid='price' />,
			render: (text: any, record: any) => <DgPrice price={record?.total_cost_in_pf} />,
			sorter: false,
		},
		{
			title: <Text tid='user' />,
			render: (text: any, record: any) => {
				return (
					<Link
						to={routes.ADMIN_USER_Detail.link(record.user?.id)}
						className='flex items-center gap-3 group'
					>
						<Text
							tid={record.user?.email || record?.user_full_name || record.user?.username}
						/>
					</Link>
				);
			},
			sorter: false,
		},
		{
			title: <Text tid='invest-date' />,
			key: 'created_at',
			dataIndex: 'created_at',
			render: (text: any) => <DgTimeStamp hasTime={false} timeStamp={text} />,
			sorter: false,
		},

		{
			title: <Text tid='status' />,
			render: (text: any, record: any) => (
				<>
					{/* return canceled_at ? (
									<span className='text-[var(--dg-red-color-2)]'>
										<DgTimeStamp timeStamp={canceled_at || 0} />
									</span>
								) : (
									<span className='text-[var(--dg-green-color)]'>
										<Text tid='active' />
									</span>
								); */}
				</>
			),
			sorter: false,
		},
		{
			title: '',
			sorter: false,
			render: (text: any, record: any) => {
				return (
					<div className='flex items-center gap-2'>
						<EditStatus record={record} />
						<PrintOrder record={record} />
						<ViewDetails record={record} />
					</div>
				);
			},
		},
	];

	const RColumn = [
		{
			title: '',
			sorter: false,
			render: (_text: any, record: any) => {
				return (
					<>
						<DgTableColumnResponsive
							title={t('id')}
							value={<span className={''}>{record?.id}</span>}
						/>
						<DgTableColumnResponsive
							title={t('products')}
							value={
								<div className='flex items-center justify-start gap-2 flex-wrap'>
									{record?.items?.length}
								</div>
							}
						/>
						<DgTableColumnResponsive
							title={t('price')}
							value={<DgPrice price={record?.total_cost_in_pf} />}
						/>
						<DgTableColumnResponsive
							title={t('payment-date')}
							value={<DgTimeStamp timeStamp={record?.is_paid_at} />}
						/>
						<DgTableColumnResponsive
							title={t('user')}
							value={
								<Link
									to={routes.ADMIN_USER_Detail.link(record.user?.id)}
									className='flex items-center gap-3 group'
								>
									<Text
										tid={
											record.user?.email ||
											record?.user_full_name ||
											record.user?.username
										}
									/>
								</Link>
							}
						/>
						<DgTableColumnResponsive
							title={t('status')}
							value={
								record.canceled_at ? (
									<span className='text-[var(--dg-red-color-2)]'>
										<DgTimeStamp timeStamp={record.canceled_at || 0} />
									</span>
								) : (
									<span className='text-[var(--dg-green-color)]'>
										<Text tid='active' />
									</span>
								)
							}
						/>
						<DgTableColumnResponsive
							title={''}
							value={
								<div className='flex items-center gap-2'>
									<EditStatus record={record} />
									<PrintOrder record={record} />
									<ViewDetails record={record} />
								</div>
							}
						/>
					</>
				);
			},
		},
	];

	return width > 769 ? Columns : RColumn;
};

export const PrintOrder = ({ record }: any) => {
	const printOrder = usePrintOrderQuery();

	return (
		<>
			<FaPrint
				size='1rem'
				className='cursor-pointer'
				onClick={() => printOrder.mutate(record?.id)}
			/>
		</>
	);
};

const EditStatus = ({ record }: any) => {
	const { modal, showModal, hideModal } = useModal();

	const [status, setStatus] = useState('');
	const [form] = Form.useForm();

	const updateOrderMutation = useUpdateAdminOrderQuery();

	console.log({ status, record });

	return (
		<>
			<DgModal title='update-status' open={modal} onCancel={hideModal}>
				<DgForm
					form={form}
					onFinish={async (values) => {
						try {
							await updateOrderMutation.mutateAsync({
								[status]: new Date().toISOString(),
								order_id: record?.id,
							});

							hideModal();
						} catch (error) {
							//
						}
					}}
					className='flex flex-col gap-4'
				>
					<DgSelectLabel
						onChange={(value) => {
							setStatus(value);
						}}
						value={status}
						label='status'
						options={[
							{ label: 'delivered', value: 'delivered_at' },
							{ label: 'sent', value: 'sent_at' },
						]}
						disabled={updateOrderMutation.isLoading}
						loading={updateOrderMutation.isLoading}
					/>

					<DgButton
						loading={updateOrderMutation.isLoading}
						disabled={updateOrderMutation.isLoading}
						htmlType='submit'
						className='w-full'
						title='submit'
					/>
				</DgForm>
			</DgModal>
			<div className='flex justify-between items-center flex-wrap'>
				<FaEdit size='1rem' className='cursor-pointer' onClick={showModal} />
			</div>
		</>
	);
};

const ViewDetails = ({ record }: any) => {
	const { modal, showModal, hideModal } = useModal();

	const {
		user_full_name,
		user,
		user_member_id,
		items,
		is_paid_at,
		canceled_at,
		user_address = {},
	} = record || {};

	return (
		<>
			<DgModal title='details' open={modal} onCancel={hideModal}>
				<div className='flex flex-col gap-4 py-4'>
					{[
						{
							label: 'user',
							component: () => (
								<Link
									to={routes.ADMIN_USER_Detail.link(user?.id)}
									className='w-full flex items-center gap-3 group'
								>
									<Text
										tid={
											`${user_full_name} (${user_member_id})` ||
											user?.email ||
											user?.username
										}
									/>
								</Link>
							),
						},
						{
							label: 'products',
							component: () => {
								return (
									<div className='flex flex-col gap-2'>
										{!!record?.items?.length &&
											record.items.map((item: any) => (
												<span
													key={item.title}
													className={'flex items-center justify-start gap-4'}
												>
													{item?.title}{' '}
													<DgPrice className='[direction="ltr"]' price={item?.price} />
												</span>
											))}
									</div>
								);
							},
						},
						{
							label: 'date',
							component: () => {
								return <DgTimeStamp timeStamp={is_paid_at} />;
							},
						},
						{
							label: 'address',
							component: () => {
								return (
									<div className='flex flex-col gap-2'>
										<div className='flex items-center justify-start flex-wrap'>
											<Text tid={'name'} />: <div>{user_address?.name}</div>
										</div>

										<div className='flex items-center justify-start flex-wrap'>
											<Text tid={'mobile'} />: <div>{user_address?.mobile}</div>
										</div>

										<div className='flex items-center justify-start flex-wrap'>
											<Text tid={'postal_code'} />: <div>{user_address?.postal_code}</div>
										</div>

										<pre className='whitespace-pre-line'>
											{user_address?.country} - {user_address?.province} -{' '}
											{user_address?.city} - {user_address?.address}
										</pre>
									</div>
								);
							},
						},
						{
							label: 'status',
							component: () => {
								<>
									{/* return canceled_at ? (
									<span className='text-[var(--dg-red-color-2)]'>
										<DgTimeStamp timeStamp={canceled_at || 0} />
									</span>
								) : (
									<span className='text-[var(--dg-green-color)]'>
										<Text tid='active' />
									</span>
								); */}
								</>;
							},
						},
					].map((item) => {
						return (
							<div
								key={JSON.stringify(item)}
								className='flex items-start py-4 justify-between gap-6 flex-wrap border-b'
							>
								<Text tid={item.label} />

								<div>{item.component()}</div>
							</div>
						);
					})}
				</div>
			</DgModal>
			<div className='flex justify-between items-center flex-wrap'>
				<FaEye size='1rem' className='cursor-pointer' onClick={showModal} />
			</div>
		</>
	);
};

export default AdminOrderTableColumn;
