import { FormInstance } from 'antd';
import useTranslate from 'core/utils/useTranslate';
import React, { FC } from 'react';
import DgButton from 'styles/UI/Button/DgButton';
import DgForm from 'styles/UI/Form/DgForm';
import DgFormItem from 'styles/UI/FormItem/DgFormItem';
import DgFormWrapper from 'styles/UI/FormWrapper/DgFormWrapper';
import DgInputLabel from 'styles/UI/Input/DgInputLabel';

interface IDisableGoogle2FAForm {
	onFinishGoogle2FA: (val: any) => void;
	isLoadingGoogle2FA: boolean;
	disableGoogle2FAForm: FormInstance;
}

const DisableGoogle2FAForm: FC<IDisableGoogle2FAForm> = ({
	onFinishGoogle2FA,
	isLoadingGoogle2FA,
	disableGoogle2FAForm,
}) => {
	const { t } = useTranslate();

	return (
		<DgForm form={disableGoogle2FAForm} onFinish={onFinishGoogle2FA}>
			<DgFormWrapper singleColumn>
				<DgFormItem
					name='one_time_password'
					rules={[
						{
							required: true,
							message: t('totp-verification-code-is-required'),
						},
					]}
				>
					<DgInputLabel label='totp-verification-code' required />
				</DgFormItem>

				<DgButton title='submit' isLoading={isLoadingGoogle2FA} />
			</DgFormWrapper>
		</DgForm>
	);
};

export default DisableGoogle2FAForm;
