import { UseQueryResult } from '@tanstack/react-query';
import { endPointUrls } from 'core/constants/endPointUrls';
import api from 'core/services/fetch-api/useApi';
import useQueryHook from 'core/hooks/masterQuery/useQueryHook';
import { ISettingsList } from 'core/types/models/settingsType';

export const useAdminSettingsQuery = () => {
	return useQueryHook(
		[endPointUrls.ADMIN_SETTINGS],
		(data: any) => api.get(endPointUrls.ADMIN_SETTINGS, data),
		{
			select: (res: any) => res?.data,
		}
	) as UseQueryResult<ISettingsList, Error>;
};
