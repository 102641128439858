import DgCard from 'styles/UI/Card/DgCard';
import DgCardHeader from 'styles/UI/CardHeader/DgCardHeader';
import AdminUserTransactionFilter from './components/AdminUserTransactionFilter';
import AdminUserTransactionTable from './components/AdminUserTransactionTable';

const AdminUserTransaction = () => {
	return (
		<DgCard>
			<DgCardHeader title='users-transactions' />

			<AdminUserTransactionFilter />
			<AdminUserTransactionTable />
		</DgCard>
	);
};

export default AdminUserTransaction;
