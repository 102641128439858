import { UseMutationResult, UseQueryResult, useQueryClient } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { endPointUrls } from 'core/constants/endPointUrls';
import useMutationHook from 'core/hooks/masterQuery/useMutationHook';
import useQueryHook from 'core/hooks/masterQuery/useQueryHook';
import api from 'core/services/fetch-api/useApi';
import { setApiCatcherFormErrors } from 'core/store/slice/mainInfo/mainInfoSlice';
import { useDispatch } from 'react-redux';

export const useAdminProductCommissions = (callBack?: () => void) => {
	const dispatch = useDispatch();
	const queryClient = useQueryClient();

	return useMutationHook(
		[endPointUrls.UPDATE_ADMIN_PRODUCT_COMMISSIONS],
		(data: any) => api.post(endPointUrls.UPDATE_ADMIN_PRODUCT_COMMISSIONS, data),
		{
			onSuccess: (res) => {
				const response = res as AxiosResponse;

				if (response?.status === 200) {
					// toastHandler('success', response?.data.message);
					dispatch(setApiCatcherFormErrors([]));
					queryClient.invalidateQueries([endPointUrls.PRODUCTS_LIST]);
					callBack?.();
				}
			},
		}
	) as UseMutationResult;
};

export const useAdminProductCommissionsList = (id: string | number | undefined) => {
	const dispatch = useDispatch();
	const queryClient = useQueryClient();

	return useQueryHook(
		[endPointUrls.ADMIN_PRODUCT_COMMISSIONS(id)],
		(data: any) => api.get(endPointUrls.ADMIN_PRODUCT_COMMISSIONS(id), data),
		{
			onSuccess: (res) => {
				const response = res as AxiosResponse;

				if (response?.status === 200) {
					// toastHandler('success', response?.data.message);
					dispatch(setApiCatcherFormErrors([]));
					queryClient.invalidateQueries([endPointUrls.PRODUCTS_LIST]);
					// callBack?.();
				}
			},
		}
	) as UseQueryResult;
};
