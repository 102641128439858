import { UseMutationResult } from '@tanstack/react-query';
import { endPointUrls } from 'core/constants/endPointUrls';
import api from 'core/services/fetch-api/useApi';
import useMutationHook from 'core/hooks/masterQuery/useMutationHook';
import toastHandler from 'core/helpers/toast/toast';
import { useDispatch } from 'react-redux';
import { setApiCatcherFormErrors } from 'core/store/slice/mainInfo/mainInfoSlice';

export const useCancelOrdersQuery = () => {
	const dispatch = useDispatch();

	return useMutationHook(
		[endPointUrls.ORDER_CANCEL],
		(data: any) => api.post(endPointUrls.ORDER_CANCEL, data),
		{
			onSuccess: (res: any) => {
				if (res?.status === 200) {
					toastHandler('success', res?.data.message);
					dispatch(setApiCatcherFormErrors([]));
				}
			},
		}
	) as UseMutationResult;
};
