import useQueryUrlParam from '../../core/hooks/useQueryUrlParam';
import useQueryHook from '../../core/hooks/masterQuery/useQueryHook';
import { endPointUrls } from '../../core/constants/endPointUrls';
import api from '../../core/services/fetch-api/useApi';

export const useUserWithdrawList = () => {
	const { filterParams } = useQueryUrlParam();

	return useQueryHook(
		[endPointUrls.WITHDRAW_LIST(), filterParams],
		(data) => api.get(endPointUrls.WITHDRAW_LIST(filterParams), data),
		{
			select: (res) => res?.data,
			keepPreviousData: true,
		}
	);
};
