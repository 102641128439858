import { useAdminCreateGroup } from '../../Update/hooks/react-query/useAdminCreateGroup';
import DgForm from '../../../../../styles/UI/Form/DgForm';
import DgFormWrapper from '../../../../../styles/UI/FormWrapper/DgFormWrapper';
import { useState } from 'react';
import DgFormItem from '../../../../../styles/UI/FormItem/DgFormItem';
import DgInputLabel from '../../../../../styles/UI/Input/DgInputLabel';
import DgButton from '../../../../../styles/UI/Button/DgButton';
import { Form } from 'antd';
import useTranslate from '../../../../../core/utils/useTranslate';
import Text from '../../../../../core/utils/Text';
import { slugify } from 'core/helpers/slug';

const CreateGroupModal = ({ hideCreateModal }) => {
	const [form] = Form.useForm();
	const { t } = useTranslate();

	const {
		mutate: createGroup,
		mutateAsync: createGroupAsync,
		isLoading: creating,
	} = useAdminCreateGroup(() => {});

	// const groupCommissionsMutation = useAdminGroupCommissions();

	const [commissions, setCommissions] = useState([]);

	const onFinish = async () => {
		try {
			const payload = { ...form.getFieldsValue() };

			payload.slug = slugify(payload.name);

			const { data } = await createGroupAsync(payload);

			hideCreateModal();
		} catch (e) {
			// console.log({ e });
		}
	};

	// // console.log({ commissions });

	return (
		<>
			<DgForm shouldUpdate={true} autoComplete='off' onFinish={onFinish} form={form}>
				<DgFormWrapper singleColumn>
					<DgFormItem
						name='name'
						rules={[
							{
								required: true,
								message: t('name-is-required'),
							},
						]}
					>
						<DgInputLabel label='name' />
					</DgFormItem>

					{/* <GroupCommissions onChange={(value) => setCommissions(value)} /> */}
				</DgFormWrapper>

				<DgFormItem button>
					<DgButton htmlType='submit' isLoading={creating} title='create-group' />
				</DgFormItem>
			</DgForm>
		</>
	);
};

export default CreateGroupModal;
