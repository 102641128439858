import UserAvatar from 'components/Admin/User/Detail/UserInfo/components/userAvatar';
import { routes } from 'core/constants/routes';
import { getCurrentUserInfoQuery } from 'core/hooks/react-query/getCurrentUserInfoQuery';
import { useLogoutQuery } from 'core/hooks/react-query/useLogoutQuery';
import { RootState } from 'core/store';
import Text from 'core/utils/Text';
import { AiOutlineSetting } from 'react-icons/ai';
import { BiLogOutCircle } from 'react-icons/bi';
import { ImTree } from 'react-icons/im';
import { IoWalletOutline } from 'react-icons/io5';
import { VscLayersActive } from 'react-icons/vsc';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import DgPrice from 'styles/UI/Price/DgPrice';
import DgReferralCode from 'styles/UI/RefferalCode/DgReferralCode';
import DgSpinnerFull from 'styles/UI/Spinner/DgSpinnerFull';

const ProfileWidget = () => {
	//store
	// const { currentUserInfo } = useSelector((store: RootState) => store.userInfoStore);
	const { userWallet } = useSelector((store: RootState) => store.walletStore);
	const { data: currentUserInfo, isFetching } = getCurrentUserInfoQuery();

	// hooks
	const navigate = useNavigate();

	// query
	const { refetch: logout, isFetching: isFetchingLogout } = useLogoutQuery('profile');

	return (
		<div className='py-6 bg-[var(--dg-card-bg-1)] rounded-lg border border-[var(--dg-border-color)]'>
			<div className='flex items-center justify-between gap-6 px-4'>
				<UserAvatar
					showUploadButton={false}
					className='w-12 h-12 text-base'
					userInfo={currentUserInfo}
				/>

				<Link to={routes.PROFILE} className='flex flex-col'>
					<span className=''>{currentUserInfo?.data.full_name}</span>
					<span className='text-xs text-[var(--dg-disabled-color-light)] break-all'>
						{currentUserInfo?.data.email}
					</span>
				</Link>

				{!!currentUserInfo?.data?.google2fa_enable && (
					<AiOutlineSetting
						size={20}
						className='cursor-pointer hover:text-[var(--dg-primary-color)]'
						onClick={() => navigate(routes.PROFILE_SECURITY)}
					/>
				)}
			</div>

			<hr className='border-[var(--dg-border-color)] my-4' />

			<div className='px-2'>
				<DgReferralCode member_id={currentUserInfo?.data?.member_id || ''} />
			</div>

			<hr className='border-[var(--dg-border-color)] my-4' />

			<div className='flex flex-col px-4'>
				<Text
					tid='my-balance'
					className='text-[var(--dg-primary-color)] mb-1 font-bold'
				/>

				<DgPrice price={userWallet?.balance} className='text-xl' />
			</div>

			{!!currentUserInfo?.data?.google2fa_enable && (
				<>
					<hr className='border-[var(--dg-border-color)] my-4' />

					<div className='flex flex-col gap-5 px-4'>
						<Link
							to={routes.ORDERS_LIST}
							className='w-full flex items-center gap-3 group'
						>
							<VscLayersActive
								color='var(--dg-opposite-color)'
								className='relative bottom-0.5 group-hover:text-[var(--dg-primary-color)]'
								size={20}
							/>
							<Text tid='my-invest' />
						</Link>

						<Link to={routes.WALLETS} className='w-full flex items-center gap-3 group'>
							<IoWalletOutline
								color='var(--dg-opposite-color)'
								className='relative bottom-0.5 group-hover:text-[var(--dg-primary-color)]'
								size={20}
							/>
							<Text tid='wallets' />
						</Link>

						{/* <Link to={routes.BINARY_TREE} className='w-full flex items-center gap-3 group'>
					<ImTree
						color='var(--dg-opposite-color)'
						className='relative bottom-0.5 group-hover:text-[var(--dg-primary-color)]'
						size={20}
					/>
					<Text tid='binary-tree' />
				</Link> */}
					</div>
				</>
			)}

			<hr className='border-[var(--dg-border-color)] my-4' />

			<DgSpinnerFull isLoading={isFetchingLogout}>
				<div
					className='w-full flex items-center gap-3 group px-4 hover:text-[var(--dg-primary-color)] cursor-pointer'
					onClick={() => logout()}
				>
					<BiLogOutCircle
						color='var(--dg-opposite-color)'
						className='relative bottom-0.5 group-hover:text-[var(--dg-primary-color)]'
						size={20}
					/>
					<Text tid='sign-out' />
				</div>
			</DgSpinnerFull>
		</div>
	);
};

export default ProfileWidget;
