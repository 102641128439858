import { SpinProps, Table, TableProps } from 'antd';
import useQueryUrlParam from 'core/hooks/useQueryUrlParam';
import { tableRowIndex } from 'core/utils/tableRowIndex';
import { FC, ReactNode, useMemo } from 'react';
import { AiOutlineReload } from 'react-icons/ai';
import DgEmpty from '../Empty/DgEmpty';
import DgSkeleton from '../Skeleton/DgSkeleton';
import DgSpinner from '../Spinner/DgSpinner';
import { mainFormatNumber } from 'core/utils/utils';
import useTranslate from 'core/utils/useTranslate';
import Text from 'core/utils/Text';

export interface IDgTable extends TableProps<any> {
	loading?: boolean;
	per_page?: number;
	rowKey?: string;
	countTitle?: string;
	overflow?: boolean;
	current?: number;
	count?: number | ReactNode;
	rowCount?: number;
	refetch?: any;
}

interface ITableColumn {
	title: string;
	dataIndex?: string;
	key?: number | string;
	sort?: boolean | null;
	render?: (
		text?: string | undefined,
		record?: object | undefined,
		index?: number | undefined
	) => any;
	width?: number;
	[x: string | number | symbol]: unknown;
}

const DgTable: FC<IDgTable> = ({
	rowKey = 'id',
	loading = false,
	overflow = true,
	className,
	current = 1,
	per_page = 10,
	countTitle = 'total',
	columns,
	count,
	rowCount = 15,
	dataSource = [],
	refetch,
	...props
}) => {
	const { query, setUrlParam, resetSort } = useQueryUrlParam();
	const { t } = useTranslate();

	//status
	const defaultColumns = [
		{
			width: 35,
			title: '',
			render: (_text: any, _record: any, index: number) =>
				tableRowIndex(index, current, per_page),
		},
	];

	const generateEmptyRow = useMemo(() => {
		return [...Array(rowCount)].map((_, index) => ({
			[rowKey]: `key${index}`,
		}));
	}, []);

	const tableColumns: ITableColumn[] = [
		...defaultColumns,
		...(columns
			? columns.map((col: any) => {
					if (col?.sorter === false) {
						return {
							...col,
							dataIndex: col.key,
						};
					}

					if (col?.sort === 'customString') {
						return {
							...col,
							dataIndex: col.key,
							sorter: (a: any, b: any) =>
								(a[col.key] + '')
									.toLowerCase()
									.localeCompare((b[col.key] + '').toLowerCase()),
						};
					}

					if (col?.sort === 'customNumber') {
						return {
							...col,
							dataIndex: col.key,
							sorter: (a: any, b: any) => +a[col.key] - +b[col.key],
						};
					}

					if (query.sort === col.key && query.sort && col.key) {
						return {
							...col,
							dataIndex: col.key,
							defaultSortOrder: query.sort_way == 'ASC' ? 'ascend' : 'descend',
							sorter: true,
						};
					}

					return {
						...col,
						dataIndex: col.key,
						sorter: true,
					};
			  })
			: []),
	];

	const handleTableChange = (_pagination: any, _filters: any, sorter: any) => {
		if (
			sorter?.column?.sort === 'customString' ||
			sorter?.column?.sort === 'customNumber'
		)
			return;

		if (!sorter?.order) {
			return resetSort();
		}

		const sort = sorter?.field;
		const sort_way = sorter?.order === 'ascend' ? 'ASC' : 'DESC';

		setUrlParam({ ...query, sort, sort_way });
	};

	const tableLoading: boolean | SpinProps | undefined = {
		spinning: dataSource?.length > 0 ? loading : false,
		indicator: <DgSpinner />,
	};

	// // console.log(dataSource, loading);

	return (
		<div className='grid mb-4'>
			<div className='flex items-center justify-end mb-1'>
				<div className='flex gap-x-3'>
					{refetch && (
						<div
							className='flex items-center text-xs cursor-pointer gap-x-1 text-t-secondary-text-color inherit-color w-fit'
							onClick={() => {
								!loading && refetch();
							}}
						>
							<AiOutlineReload className={`${loading && 'animate-spin'}`} />
							<span>بروز رسانی</span>
						</div>
					)}
					{!!count && count > 0 && typeof count === 'number' && (
						<span className='text-sm mx-3'>
							{`${t(countTitle)} :` +
								(typeof count === 'number' ? mainFormatNumber(count) : count)}
						</span>
					)}
					{!!count && typeof count !== 'number' && (
						<span className='flex items-center'>
							<Text tid={countTitle} className='text-sm mx-3' />
							{count}
						</span>
					)}
				</div>
			</div>
			<div className={`${overflow && 'overflow-x-scroll rounded-2xl'}`}>
				<Table
					rowClassName={(record, index) => (index % 2 === 0 ? 'striped' : '')}
					locale={{ emptyText: <DgEmpty /> }}
					rowKey={rowKey}
					loading={tableLoading}
					size='small'
					pagination={false}
					className={`${className}`}
					dataSource={
						dataSource?.length > 0 ? dataSource : loading ? generateEmptyRow : []
					}
					columns={
						dataSource?.length > 0
							? tableColumns
							: tableColumns.map((column) => {
									return {
										...column,
										render: () => loading && <DgSkeleton />,
									};
							  })
					}
					onChange={handleTableChange}
					{...props}
				/>
			</div>
		</div>
	);
};

export default DgTable;
