import { useWindowSize } from 'core/hooks/useWindowSize';
import Text from 'core/utils/Text';
import DgTableColumnResponsive from 'styles/UI/TableColumnResponsive/DgTableColumnResponsive';
import { IEmailVerificationHistory } from 'core/types/models/userHistoryType';
import DgTimeStamp from 'styles/UI/Timstamp/DgTimeStamp';
import useTranslate from 'core/utils/useTranslate';

const UserEmailVerificationHistoryTableColumn = () => {
	const { width } = useWindowSize();
	const { t } = useTranslate();

	const Columns = [
		{
			title: <Text tid='type' />,
			key: 'type',
			dataIndex: 'type',
			render: (text: any) => text,
			sorter: false,
		},
		{
			title: <Text tid='user' />,
			render: (_text: any, record: IEmailVerificationHistory) => (
				<div className='flex flex-col'>
					<span>{record.user.full_name}</span>
					<span>{record.user.email}</span>
				</div>
			),
			sorter: false,
		},
		{
			title: <Text tid='ip' />,
			render: (_text: any, record: IEmailVerificationHistory) => (
				<div className='flex flex-col'>
					<span className='whitespace-nowrap'>{record.ip.ip}</span>
					<span className='whitespace-nowrap'>
						{record.ip.country} - {record.ip.city}
					</span>
				</div>
			),
			sorter: false,
		},
		{
			title: <Text tid='device' />,
			render: (_text: any, record: IEmailVerificationHistory) => (
				<div className='flex flex-col'>
					<span className='whitespace-nowrap'>
						{record.agent.browser} - {record.agent.browser_version}
					</span>
					<span>{record.agent.device_type}</span>
					<span className='whitespace-nowrap'>
						{record.agent.is_desktop === '1' ? 'desktop' : record.agent.is_phone === '1' ? 'phone' : record.agent.is_robot === '1' ? 'robot' : 'unknown'} - {record.agent.platform}
					</span>
				</div>
			),
			sorter: false,
		},
		{
			title: <Text tid='date-time' />,
			key: 'created_at',
			dataIndex: 'created_at',
			render: (text: any) => <DgTimeStamp timeStamp={text} />,
			sorter: false,
		},
	];

	const RColumn = [
		{
			title: '',
			sorter: false,
			render: (_text: any, record: IEmailVerificationHistory) => {
				return (
					<>
						<DgTableColumnResponsive title={t('type')} value={record?.type} />
						<DgTableColumnResponsive
							title={t('user')}
							value={
								<div className='flex flex-col'>
									<span>{record.user.full_name}</span>
									<span>{record.user.email}</span>
								</div>
							}
						/>
						<DgTableColumnResponsive
							title={t('ip')}
							value={
								<div className='flex flex-col'>
									<span>{record.ip.ip}</span>
									<span>
										{record.ip.country} - {record.ip.city}
									</span>
								</div>
							}
						/>
						<DgTableColumnResponsive
							title={t('device')}
							value={
								<div className='flex flex-col'>
									<span>
										{record.agent.browser} - {record.agent.browser_version}
									</span>
									<span>{record.agent.device_type}</span>
									<span>
										{record.agent.is_desktop === '1' ? 'desktop' : record.agent.is_phone === '1' ? 'phone' : record.agent.is_robot === '1' ? 'robot' : 'unknown'} -{' '}
										{record.agent.platform}
									</span>
								</div>
							}
						/>
						<DgTableColumnResponsive title={t('date-time')} value={<DgTimeStamp nowrap={false} timeStamp={record?.created_at} />} />
					</>
				);
			},
		},
	];

	return width > 769 ? Columns : RColumn;
};

export default UserEmailVerificationHistoryTableColumn;
