import { routes } from 'core/constants/routes';
import { useWindowSize } from 'core/hooks/useWindowSize';
import { user } from 'core/types/models/userInfo';
import Text from 'core/utils/Text';
import useTranslate from 'core/utils/useTranslate';
import React from 'react';
import { FiMoreVertical } from 'react-icons/fi';
import DgDetailIcon from 'styles/UI/DetailIcon/DgDetailIcon';
import DgDropDown from 'styles/UI/DropDown/DgDropDown';
import DgReferralCode from 'styles/UI/RefferalCode/DgReferralCode';
import DgTableColumnResponsive from 'styles/UI/TableColumnResponsive/DgTableColumnResponsive';
import DgTooltip from 'styles/UI/Tooltip/DgTooltip';
import UserTableQuickAccess from './UsertableQuickAccess';
import DgPrice from 'styles/UI/Price/DgPrice';
import DgConnectIcon from 'styles/UI/ConnectIcon/DgConnectIcon';

const AdminUserTableColumn = () => {
	const { width } = useWindowSize();
	const { t } = useTranslate();

	const Columns = [
		{
			title: <Text tid='full-name' />,
			key: 'first_name',
			dataIndex: 'first_name',
			render: (text: any, record: user) => record.full_name,
			sorter: true,
		},
		{
			title: <Text tid='referral-code' />,
			key: 'member_id',
			dataIndex: 'member_id',
			render: (text: any) => <DgReferralCode hasTitle={false} member_id={text} />,
			sorter: false,
		},
		{
			title: <Text tid='username' />,
			key: 'username',
			dataIndex: 'username',
			render: (text: any) => text,
			sorter: false,
		},
		{
			title: <Text tid='sum-investments' />,
			key: 'wallet_balance',
			dataIndex: 'wallet_balance',
			render: (text: any) => <DgPrice price={text ? +text : 0} />,
			sorter: false,
		},
		{
			title: <Text tid='email' />,
			render: (_text: any, record: user) =>
				record.email_verified_at ? (
					<DgTooltip title='email-verified'>
						<span className='text-[var(--dg-green-color)]'>{record.email}</span>
					</DgTooltip>
				) : (
					<DgTooltip title='email-not-verified'>
						<span className='text-[var(--dg-red-color-2)]'>{record.email}</span>
					</DgTooltip>
				),
			sorter: false,
		},
		{
			title: <Text tid='status' />,
			render: (_text: any, record: user) =>
				record.is_deactivate ? (
					<Text className='text-[var(--dg-red-color-2)]' tid='deactive' />
				) : record.is_freeze ? (
					<Text className='text-[var(--dg-red-color-2)]' tid='freez' />
				) : record.block_type ? (
					<Text className='text-[var(--dg-red-color-2)]' tid='block' />
				) : (
					<Text className='text-[var(--dg-green-color)]' tid='active' />
				),
			sorter: false,
		},
		{
			title: '',
			sorter: false,
			render: (text: any, record: user) => {
				return (
					<div className='flex justify-between items-center flex-wrap'>
						<DgDetailIcon to={routes.ADMIN_USER_Detail.link(record.id)} />

						<DgDropDown
							trigger={['hover']}
							menu={{ items: UserTableQuickAccess({ userId: record.id }) }}
							placement='bottomRight'
						>
							<FiMoreVertical className='cursor-pointer' size={25} />
						</DgDropDown>

						<DgConnectIcon title='hijack' to={routes.HIJACK.link(record.id)} />
					</div>
				);
			},
		},
	];

	const RColumn = [
		{
			title: '',
			sorter: false,
			render: (_text: any, record: user) => {
				return (
					<>
						<DgTableColumnResponsive title={t('full-name')} value={record.full_name} />
						<DgTableColumnResponsive
							title={t('referral-code')}
							value={<DgReferralCode hasTitle={false} member_id={record.member_id} />}
						/>
						<DgTableColumnResponsive title={t('username')} value={record.username} />
						<DgTableColumnResponsive
							title={t('email')}
							value={
								record.email_verified_at ? (
									<DgTooltip title='email-verified'>
										<span className='text-[var(--dg-green-color)]'>{record.email}</span>
									</DgTooltip>
								) : (
									<DgTooltip title='email-not-verified'>
										<span className='text-[var(--dg-red-color-2)]'>{record.email}</span>
									</DgTooltip>
								)
							}
						/>
						<DgTableColumnResponsive
							title={t('sum-investments')}
							value={<DgPrice price={record.sum_orders} />}
						/>
						<DgTableColumnResponsive
							title={t('status')}
							value={
								record.is_deactivate ? (
									<Text className='text-[var(--dg-red-color-2)]' tid='deactive' />
								) : record.is_freeze ? (
									<Text className='text-[var(--dg-red-color-2)]' tid='freez' />
								) : record.block_type ? (
									<Text className='text-[var(--dg-red-color-2)]' tid='block' />
								) : (
									<Text className='text-[var(--dg-green-color)]' tid='active' />
								)
							}
						/>
						<DgTableColumnResponsive
							title={t('operation')}
							value={
								<div className='flex justify-end gap-5'>
									<DgDetailIcon to={routes.ADMIN_USER_Detail.link(record.id)} />

									<DgDropDown
										trigger={['hover']}
										menu={{ items: UserTableQuickAccess({ userId: record.id }) }}
										placement='bottomRight'
									>
										<FiMoreVertical className='cursor-pointer' size={25} />
									</DgDropDown>

									<DgConnectIcon title='hijack' to={routes.HIJACK.link(record.id)} />
								</div>
							}
						/>
					</>
				);
			},
		},
	];

	return width > 769 ? Columns : RColumn;
};

export default AdminUserTableColumn;
