import { UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { endPointUrls } from 'core/constants/endPointUrls';
import api from 'core/services/fetch-api/useApi';
import { useDispatch } from 'react-redux';
import useQueryHook from '../masterQuery/useQueryHook';
import { setCurrentUserDetail } from 'core/store/slice/userInfo/userInfoSlice';
import { userDetail } from '../../types/models/userInfo';

export const getCurrentUserInfoQuery = (queryOptions: UseQueryOptions = {}) => {
	const dispatch = useDispatch();

	return useQueryHook(
		[endPointUrls.PROFILE_MANAGEMENT],
		(data: any) => api.get(endPointUrls.PROFILE_MANAGEMENT, data),
		{
			select: (res: any) => res?.data,
			staleTime: 30 * 60 * 1000,
			cacheTime: 30 * 60 * 1000,
			onSuccess: (res: any) => {
				dispatch(setCurrentUserDetail(res));
			},
			...queryOptions,
		},
		false
	) as UseQueryResult<userDetail, Error>;
};
