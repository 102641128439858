import React, { FC, FormEvent, useEffect, useState } from 'react';
import GoldOrderPrice from './GoldOrderPrice';
import useSelected from 'core/hooks/useSelected';
import Text from 'core/utils/Text';
import DgSelectPackageCategory from '../../Basic/DgSelectPackageCategory';
import DgForm from 'styles/UI/Form/DgForm';
import DgFormItem from 'styles/UI/FormItem/DgFormItem';
import { RadioChangeEvent } from 'antd';
import useQueryUrlParam from 'core/hooks/useQueryUrlParam';
import DgRadioButtonGroup from 'styles/UI/RadioButton/DgRadioButtonGroup';
import DgRadioButton from 'styles/UI/RadioButton/DgRadioButton';
import useTranslate from 'core/utils/useTranslate';
import DgInputLabel from 'styles/UI/Input/DgInputLabel';
import DgSpinnerFull from 'styles/UI/Spinner/DgSpinnerFull';
import { useOrderSubmitPreviewQuery } from '../hooks/react-query/useOrderSubmitPreviewQuery';
import { useOrderSubmitQuery } from '../hooks/react-query/useOrderSubmitQuery';
import { useDispatch } from 'react-redux';
import { setSubmitOrderPayload } from 'core/store/slice/submitOrder/submitOrderSlice';
import { FormInstance } from 'antd/es/form/Form';
import DgPrice from 'styles/UI/Price/DgPrice';
import DgImage from 'styles/UI/Image/DgImage';
import usdt from '../../../assets/images/usdt.png';

interface IOrderPayment {
	orderConfigForm: FormInstance;
}

const GoldOrderPayment: FC<IOrderPayment> = ({ orderConfigForm }) => {
	const { selectedTypeHandler, selectedType } = useSelected(0, false);
	const { query, filterParams, setUrlParam } = useQueryUrlParam();
	const { t } = useTranslate();
	const {
		mutate: submitOrderPreview,
		isLoading: isLoadingSubmitOrderPreview,
		data: orderPreviewData,
	} = useOrderSubmitPreviewQuery();

	const [priceOption, setPriceOption] = useState('default_price');
	const [priceInput, setPriceInput] = useState('');
	const { isLoading: isLoadingSubmitOrder } = useOrderSubmitQuery();
	const dispatch = useDispatch();

	useEffect(() => {
		orderConfigForm.setFieldsValue({ priceOption: priceOption, priceInput });
		dispatch(
			setSubmitOrderPayload({
				price:
					priceOption === 'default_price'
						? selectedType?.data?.value || 100
						: +priceInput,
			})
		);
	}, [priceOption]);

	const genderOnChangeHandler = (e: RadioChangeEvent): void => {
		setPriceOption(e.target.value);
	};

	const data = [
		{ id: 1, value: 100 },
		{ id: 2, value: 250 },
		{ id: 3, value: 500 },
		{ id: 4, value: 1000 },
		{ id: 5, value: 2500 },
	];

	const onValuesChange = (value: any) => {};

	useEffect(() => {
		selectedTypeHandler(0, data[0]);
	}, []);

	useEffect(() => {
		const delayDebounceFn = setTimeout(() => {
			// if (priceInput && parseFloat(priceInput) >= 200) {
			if (priceInput) {
				submitOrderPreview({
					price: priceInput,
				});
				dispatch(
					setSubmitOrderPayload({
						price:
							priceOption === 'default_price'
								? selectedType?.data?.value || 100
								: +priceInput,
					})
				);
			}
		}, 700);

		return () => clearTimeout(delayDebounceFn);
	}, [priceInput]);

	useEffect(() => {
		dispatch(
			setSubmitOrderPayload({
				price:
					priceOption === 'default_price'
						? selectedType?.data?.value || 100
						: +priceInput,
			})
		);
		if (selectedType?.data?.value) {
			submitOrderPreview({
				price: selectedType?.data?.value,
			});
		}
	}, [selectedType?.data?.value, filterParams]);

	return (
		<DgForm form={orderConfigForm} onValuesChange={onValuesChange}>
			<DgSpinnerFull isLoading={isLoadingSubmitOrderPreview || isLoadingSubmitOrder}>
				<div className='grid sm:grid-cols-2 grid-cols-1 gap-10'>
					<div className='grid grid-cols-1 gap-y-10'>
						<div className='flex flex-col gap-4'>
							<DgFormItem name='type'>
								<DgSelectPackageCategory className='w-full' />
							</DgFormItem>
						</div>

						<div className='flex flex-col gap-4'>
							<DgFormItem name='priceOption'>
								<DgRadioButtonGroup onChange={genderOnChangeHandler}>
									<DgRadioButton value='default_price'>
										{t('invest-amount')}
									</DgRadioButton>
									<DgRadioButton value='custom_price'>{t('custom')}</DgRadioButton>
								</DgRadioButtonGroup>
							</DgFormItem>

							{priceOption === 'default_price' && (
								<div className='flex flex-wrap gap-3 overflow-x-auto'>
									{data.map((item, index) => (
										<GoldOrderPrice
											key={item.id}
											onClick={() => selectedTypeHandler(index, item)}
											price={item.value}
											selected={selectedType.index === index}
										/>
									))}
								</div>
							)}

							{priceOption === 'custom_price' && (
								<DgFormItem
									rules={[
										{
											required: true,
											message: t('amount-is-required'),
										},
										{
											message: t('amount-should-be-greater-than-100-usdt'),
											validator: (_, value) => {
												if (value >= 100) {
													return Promise.resolve();
												} else {
													return Promise.reject('amount-should-be-greater-than-100-usdt');
												}
											},
										},
									]}
									noMargin
									name='price'
								>
									<DgInputLabel
										// defaultValue={200}
										suffix={
											<DgImage
												src={usdt}
												width={22}
												className='rounded-full relative top-1.5'
											/>
										}
										onChange={(e: FormEvent<HTMLInputElement>) =>
											setPriceInput(e.currentTarget.value)
										}
										label='amount'
									/>
								</DgFormItem>
							)}
						</div>
					</div>

					<div>
						<div className='grid sm:gap-5 gap-3 w-full sm:px-10 px-5 py-5 rounded-lg border border-[var(--dg-border-color)]'>
							<div className='flex justify-between items-center pb-2'>
								<Text className='font-bold text-base' tid='invest-amount' />
								<span className='font-bold sm:text-xl text-base'>
									<DgPrice price={orderPreviewData?.data?.data.package_cost_in_pf || 0} />
								</span>
							</div>

							<div className='flex justify-between items-center pb-2'>
								<Text className='font-bold text-base' tid='subscription' />
								<span className='font-bold sm:text-xl text-base'>
									<DgPrice
										price={orderPreviewData?.data?.data.registration_fee_in_pf || 0}
									/>
								</span>
							</div>

							<div className='flex justify-between items-center border-b border-b-[var(--dg-border-color)] pb-2'>
								<Text className='font-bold text-base' tid='payment-type' />
								<span className='font-bold sm:text-xl text-base'>
									{orderPreviewData?.data?.data.payment_type || '--'}
								</span>
							</div>

							<div className='flex justify-between items-center pb-2'>
								<Text className='font-bold text-base' tid='total-payment' />
								<span className='font-bold sm:text-xl text-base'>
									<DgPrice price={orderPreviewData?.data?.data.total_cost_in_pf || 0} />
								</span>
							</div>
						</div>
					</div>
				</div>
			</DgSpinnerFull>
		</DgForm>
	);
};

export default GoldOrderPayment;
