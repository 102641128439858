import { useUsersActivityQuery } from './react-query/useUsersActivityQuery';

const userUsersActivity = () => {
	// query
	const { data: userActivities, isFetching: userActivityIsFetching } = useUsersActivityQuery();

	return { userActivities, userActivityIsFetching };
};

export default userUsersActivity;
