import { AutoComplete, Input, InputProps, InputRef } from 'antd';
import { forwardRef, useState } from 'react';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';

export interface IInput extends InputProps {
	className?: string;
	value?: any;
	setValue?: (val: any) => void;
}

const DgInputPassword = forwardRef<InputRef, IInput>(({ className, value, setValue, ...props }, ref) => {
	const [hidden, setHidden] = useState(true);

	return (
		<AutoComplete>
			<Input
				ref={ref}
				value={value}
				onChange={setValue ? (e) => setValue(e.currentTarget.value) : () => {}}
				className={`rounded-md px-3 pt-[10px] ${hidden ? 'dg-input-password' : ''} ${className}`}
				autoComplete='off'
				suffix={hidden ? <AiOutlineEyeInvisible size={20} onClick={() => setHidden(false)} /> : <AiOutlineEye size={20} onClick={() => setHidden(true)} />}
				{...props}
			/>
		</AutoComplete>
	);
});

DgInputPassword.displayName = 'DgInputPassword';

export default DgInputPassword;
