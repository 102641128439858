import { FC, ReactNode } from 'react';
import DgShowInfo from '../ShowInfo/DgShowInfo';

interface IDgTableColumnResponsive {
	title: string | ReactNode;
	value: string | number | ReactNode;
	isHorizontalValue?: boolean;
}

const DgTableColumnResponsive: FC<IDgTableColumnResponsive> = ({ value, title, isHorizontalValue }) => {
	return <DgShowInfo isHorizontal={isHorizontalValue} className='mb-4' fullWith margin='mr-2' customWith='min-w-0' isColon={false} title={title} value={value} />;
};

export default DgTableColumnResponsive;
