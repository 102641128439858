import { setIsHijack } from 'core/store/slice/mainInfo/mainInfoSlice';
import Cookies from 'js-cookie';
import { useDispatch, useSelector } from 'react-redux';
import DgButton from 'styles/UI/Button/DgButton';
import { useEffect } from 'react';
import { RootState } from 'core/store';

const HijackLayout = () => {
	// hooks
	const dispatch = useDispatch();

	// store
	const { isHijack } = useSelector((store: RootState) => store.mainInfoStore);

	const onExitHijackHandler = () => {
		Cookies.remove('refresh-jwt');
		dispatch(setIsHijack(false));
		window.location.reload();
	};

	useEffect(() => {
		window.addEventListener('storage', () => console.log('object'));
	}, []);

	return (
		<DgButton
			title='exit-hijack'
			className='animate-bounce'
			onClick={onExitHijackHandler}
		/>
	);
};

export default HijackLayout;
