import { Modal, ModalProps } from 'antd';
import useTranslate from 'core/utils/useTranslate';
import { FC, MouseEvent } from 'react';
import { useDispatch } from 'react-redux';
import { setApiCatcherFormErrors } from 'core/store/slice/mainInfo/mainInfoSlice';

interface IDgModal extends ModalProps {
	title?: string;
	footerContent?: string;
}

const DgModal: FC<IDgModal> = ({ children, title = '', width, footer = null, open = false, onCancel, footerContent, ...props }) => {
	//hooks
	const dispatch = useDispatch();
	const { t } = useTranslate();

	const onCancelModal = (e: MouseEvent<HTMLElement, globalThis.MouseEvent>) => {
		dispatch(setApiCatcherFormErrors([]));
		onCancel && onCancel(e);
	};
	return (
		<Modal width={width} footer={footer} open={open} onCancel={(e) => onCancelModal(e)} centered destroyOnClose {...props} title={<p className='mb-0 text-inherit'>{t(title)}</p>}>
			<div className='p-6'>{children}</div>
			{footerContent && <div className='px-6 py-4 border-t'>{footerContent}</div>}
		</Modal>
	);
};

export default DgModal;
