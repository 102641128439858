import { RootState } from 'core/store';
import useTranslate from 'core/utils/useTranslate';
import { FC, ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { twMerge } from 'tailwind-merge';

interface IDgShowInfo {
	title: string | ReactNode;
	value: string | number | ReactNode;
	nowrap?: boolean;
	full?: boolean;
	vertical?: boolean;
	notNormal?: boolean;
	isItemCenter?: boolean;
	className?: string;
	margin?: string;
	customWith?: string;
	fullWith?: boolean;
	isColon?: boolean;
	isHorizontal?: boolean;
	isMarginButton?: boolean;
}

const DgShowInfo: FC<IDgShowInfo> = ({
	isItemCenter,
	isColon = true,
	title,
	fullWith = false,
	value,
	className,
	full,
	nowrap = true,
	notNormal = true,
	isMarginButton = true,
	isHorizontal = true,
	margin,
	customWith,
	vertical,
}) => {
	const { t } = useTranslate();
	const { isEnglish } = useSelector((store: RootState) => store.settingStore);
	return isEnglish ? (
		<div
			className={`${isItemCenter && 'items-center'} ${isMarginButton ? 'mb-2' : ''} ${
				!vertical && 'flex'
			}  ${full && 'col-span-full w-full'} items-start ${className}`}
		>
			<div
				className={twMerge(
					`mb-0 ${notNormal ? 'mr-6 text-t-secondary-text-color' : `${margin}`} ${
						nowrap && 'whitespace-nowrap'
					} min-w-[120px] ${customWith} text-left`
				)}
			>
				{title}
				{isColon ? ' : ' : ''}
			</div>
			<div
				className={`${
					fullWith ? 'flex-1 flex justify-end text-right' : ''
				} text-t-text-color ${isHorizontal ? '' : 'flex-col'}`}
			>
				{!value && value !== 0 ? t('no-data-available') : value}
			</div>
		</div>
	) : (
		<div
			className={`${isItemCenter && 'items-center'} ${isMarginButton ? 'mb-2' : ''} ${
				!vertical && 'flex'
			}  ${full && 'col-span-full w-full'} items-start ${className}`}
		>
			<div
				className={twMerge(
					`mb-0 ${notNormal ? 'ml-6 text-t-secondary-text-color' : `${margin}`} ${
						nowrap && 'whitespace-nowrap'
					} min-w-[120px] ${customWith} text-right`
				)}
			>
				{title}
				{isColon ? ' : ' : ''}
			</div>
			<div
				className={`${
					fullWith ? 'flex-1 flex justify-end text-left' : ''
				} text-t-text-color ${isHorizontal ? '' : 'flex-col'}`}
			>
				{!value && value !== 0 ? t('no-data-available') : value}
			</div>
		</div>
	);
};

export default DgShowInfo;
