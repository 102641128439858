import { createSlice } from '@reduxjs/toolkit';
import { IPackage } from 'core/types/models/packageType';

const initialState: { groupDetail: any | null } = { groupDetail: null };

export const groupsSlice = createSlice({
	name: 'group',
	initialState: initialState,
	reducers: {
		setGroupDetail: (state, { payload }) => {
			state.groupDetail = payload;
		},
	},
});

export const { setGroupDetail } = groupsSlice.actions;
export default groupsSlice.reducer;
