import { UseMutationResult } from '@tanstack/react-query';
import { endPointUrls } from 'core/constants/endPointUrls';
import toastHandler from 'core/helpers/toast/toast';
import useMutationHook from 'core/hooks/masterQuery/useMutationHook';
import useMasterQueryUrlParam from 'core/hooks/useMasterQueryUrlParam';
import api from 'core/services/fetch-api/useApi';

export const useRegisterVerificationQuery = () => {
	//hooks
	const { setUrlParam } = useMasterQueryUrlParam();

	return useMutationHook(
		[endPointUrls.VERIFY_EMAIL_TOKEN],
		(data: any) => api.post(endPointUrls.VERIFY_EMAIL_TOKEN, data),
		{
			onSuccess: (res: any) => {
				if (res?.status === 200) {
					toastHandler('success', res?.data.message);
					setUrlParam({ page: 1 });
				}
			},
		}
	) as UseMutationResult;
};
