import useModal from 'core/hooks/useModal';
import Text from 'core/utils/Text';
import { FC, useEffect, useState, memo } from 'react';
import { AiFillFilter } from 'react-icons/ai';
import { TiDeleteOutline } from 'react-icons/ti';
import DgDropDown from '../DropDown/DgDropDown';
import DgFilterItems from './DgFilterItems';
import DgFilterResult from './DgFilterResult';
import { filterOptionsType } from 'core/types/components/filterType';
import { MenuProps } from 'antd';

interface IDgFilter {
	resetQuery: () => void;
	width?: number;
	filterOptions: filterOptionsType[];
}

const DgFilter: FC<IDgFilter> = ({ resetQuery, filterOptions = [], width = 1000 }) => {
	//state
	const [dropdownStatus, setDropdownStatus] = useState({});
	const [selected, setSelected] = useState<{
		index: number | null;
		data: any | null;
		dropdown: any | null;
	}>({ index: null, data: null, dropdown: null });
	const {
		modal: openDropDownMaster,
		hideModal: hideDropdownMaster,
		showModal: showDropdownMaster,
	} = useModal();

	// utils
	const isFiltered: boolean = filterOptions.some((item) =>
		item?.filterNames?.some(
			(filterName) =>
				!!(item.query[filterName] === 0
					? item.query[filterName] + ''
					: item.query[filterName])
		)
			? true
			: false
	);

	useEffect(() => {
		setSelected((prevState) => ({ ...prevState, dropdown: dropdownStatus }));
	}, [dropdownStatus]);

	useEffect(() => {
		if (openDropDownMaster) {
			setSelected({ index: null, data: null, dropdown: null });
			setDropdownStatus({});
		}
	}, [openDropDownMaster]);

	const selectedFilterHandler = (data: any, index: number) => {
		setSelected({ index, data, dropdown: dropdownStatus });
	};

	const onOpenDropdownMasterChangeHandler = () => {
		openDropDownMaster ? hideDropdownMaster() : showDropdownMaster();
	};

	const getDropdownStatus = (
		openDropdown: boolean,
		hideDropdown: () => void,
		showDropdown: () => void
	) => {
		setDropdownStatus({ openDropdown, hideDropdown, showDropdown });
	};

	// filterOptions.map((item) => (item.filterName === item.query[item.filterName] ? // console.log('hi') : // console.log(item.filterName, item.query[item.filterName], item.query, item)));

	const handlers: {
		filterOptions: any[];
		selectedFilterHandler: (data: any, index: number) => void;
		selected: { index: number | null; data: any | null; dropdown: any | null };
		getDropdownStatus: (
			openDropdown: boolean,
			hideDropdown: () => void,
			showDropdown: () => void
		) => void;
		hideDropdownMaster: () => void;
	} = {
		filterOptions,
		selectedFilterHandler,
		selected,
		getDropdownStatus,
		hideDropdownMaster,
	};

	const items: MenuProps['items'] = [
		{
			label: <DgFilterItems width={width} handlers={handlers} />,
			key: '0',
		},
	];

	return (
		<>
			<div className='flex flex-wrap gap-6'>
				<DgDropDown
					open={openDropDownMaster}
					onOpenChange={onOpenDropdownMasterChangeHandler}
					menu={{ items }}
				>
					<span className='mb-3 cursor-pointer flex items-center bg-dg-orange-color text-dg-opposite-primary-color hover:bg-dg-orange-color-hover transition-all w-28 justify-center py-2 px-4 border rounded-lg'>
						<AiFillFilter />
						<Text tid='filters' className='mx-2' />
					</span>
				</DgDropDown>

				{isFiltered && (
					<span
						onClick={resetQuery}
						className='mb-3 cursor-pointer flex items-center bg-dg-red-color-2 text-dg-white-color hover:bg-dg-red-color-hover transition-all w-48 justify-center py-2 px-4 border rounded-lg'
					>
						<TiDeleteOutline size={22} />
						<Text tid='clear-all-filter' className='mx-2' />
					</span>
				)}
			</div>

			<DgFilterResult
				width={width}
				filterOptions={filterOptions}
				isFiltered={isFiltered}
			/>
		</>
	);
};

export default memo(DgFilter);
