import { Form } from 'antd';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'core/store';
import { useAdminUpdateGroup } from './hooks/react-query/useAdminUpdateGroup';
import { setHiddenModal } from 'core/store/slice/modal/modalSlice';

const useAdminGroupUpdate = (hideModalHandler: () => void) => {
	//hooks
	const dispatch = useDispatch();
	const [updateGroupForm] = Form.useForm();

	// store
	const { apiCatcherFormErrors } = useSelector((store: RootState) => store.mainInfoStore);
	const { groupDetail } = useSelector((store: RootState) => store.groupsStore);

	// query
	const { mutate: updateGroup, isLoading: isLoadingUpdateGroup } = useAdminUpdateGroup(
		() => {
			dispatch(setHiddenModal());
		}
	);

	const onFinishUpdateGroup = (values: any) => {
		updateGroup(
			{ ...values, id: groupDetail?.id },
			{ onSuccess: () => hideModalHandler() }
		);
	};

	useEffect(() => {
		updateGroupForm.setFields(apiCatcherFormErrors);
	}, [apiCatcherFormErrors]);

	useEffect(() => {
		updateGroupForm.setFieldsValue({ ...groupDetail });
	}, [groupDetail]);

	return {
		updateGroupForm,
		isLoadingUpdateGroup,
		onFinishUpdateGroup,
	};
};

export default useAdminGroupUpdate;
