import DgSelectBinaryPosition from 'components/Basic/DgSelectBinaryPosition';
import useProfileMlmSetting from 'components/Profile/hooks/useProfileMlmSetting';
import Text from 'core/utils/Text';
import React from 'react';
import DgButton from 'styles/UI/Button/DgButton';
import DgDevider from 'styles/UI/Devider/DgDevider';
import DgForm from 'styles/UI/Form/DgForm';
import DgFormItem from 'styles/UI/FormItem/DgFormItem';
import DgFormWrapper from 'styles/UI/FormWrapper/DgFormWrapper';

const ProfileMlmSetting = () => {
	const { binaryPositionForm, mlmInfoIsFetching, binaryPositionIsLoading, onFinishUpdateBinaryPosition } = useProfileMlmSetting();
	return (
		<>
			<h2>
				<Text tid='new-member-position' />
			</h2>

			<DgDevider title='member-position' orientation='center' />
			<DgForm form={binaryPositionForm} onFinish={onFinishUpdateBinaryPosition}>
				<div className='mb-10'>
					<Text tid='dear-user' />
					<Text tid='mlm-setting-description' />
				</div>

				<DgFormWrapper>
					<DgFormItem name='default_binary_position' rules={[{ required: true, message: 'binary-position-is-required' }]}>
						<DgSelectBinaryPosition loading={mlmInfoIsFetching} required />
					</DgFormItem>

					<DgFormItem button>
						<DgButton isLoading={binaryPositionIsLoading} title='submit' />
					</DgFormItem>
				</DgFormWrapper>
			</DgForm>
		</>
	);
};

export default ProfileMlmSetting;
