import { UseQueryResult } from '@tanstack/react-query';
import { endPointUrls } from 'core/constants/endPointUrls';
import api from 'core/services/fetch-api/useApi';
import useQueryHook from 'core/hooks/masterQuery/useQueryHook';
import { IAdminOrdersCountsDetail } from 'core/types/models/orderType';
import useACL from 'core/services/role/useACL';

export const useAdminOrdersCountsQuery = () => {
	const { roles, hasAccessTo } = useACL();

	return useQueryHook(
		[endPointUrls.ADMIN_DASHBOARD_ORDERS_COUNTS],
		(data: any) => api.get(endPointUrls.ADMIN_DASHBOARD_ORDERS_COUNTS, data),
		{
			select: (res: any) => res?.data,
			keepPreviousData: true,
			enabled: hasAccessTo(roles.order?.admin),
		}
	) as UseQueryResult<IAdminOrdersCountsDetail, Error>;
};
