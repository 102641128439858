import { useWindowSize } from 'core/hooks/useWindowSize';
import Text from 'core/utils/Text';
import DgTableColumnResponsive from 'styles/UI/TableColumnResponsive/DgTableColumnResponsive';
import { ILoginHistory } from 'core/types/models/userHistoryType';
import DgTimeStamp from 'styles/UI/Timstamp/DgTimeStamp';
import useTranslate from 'core/utils/useTranslate';

const UserLoginHistoryTableColumn = () => {
	const { width } = useWindowSize();
	const { t } = useTranslate();

	const Columns = [
		{
			title: <Text tid='login_status' />,
			key: 'login_status',
			dataIndex: 'login_status',
			render: (text: any) => text,
			sorter: false,
		},
		{
			title: <Text tid='from-new-device' />,
			key: 'is_from_new_device',
			dataIndex: 'is_from_new_device',
			render: (text: any) => (text === 1 ? 'Yes' : 'No'),
			sorter: false,
		},
		{
			title: <Text tid='ip' />,
			render: (_text: any, record: ILoginHistory) => (
				<div className='flex flex-col'>
					<span>{record.ip.ip}</span>
					<span>
						{record.ip.country} - {record.ip.city}
					</span>
				</div>
			),
			sorter: false,
		},
		{
			title: <Text tid='device' />,
			render: (_text: any, record: ILoginHistory) => (
				<div className='flex flex-col'>
					<span>
						{record.agent.browser} - {record.agent.browser_version} - {record.agent.device_type}
					</span>
					<span>
						{record.agent.is_desktop === '1' ? t('desktop') : record.agent.is_phone === '1' ? t('phone') : record.agent.is_robot === '1' ? t('robot') : t('unknown')} - {record.agent.platform}
					</span>
				</div>
			),
			sorter: false,
		},
		{
			title: <Text tid='date-time' />,
			key: 'created_at',
			dataIndex: 'created_at',
			render: (text: any) => <DgTimeStamp timeStamp={text} />,
			sorter: false,
		},
	];

	const RColumn = [
		{
			title: '',
			sorter: false,
			render: (_text: any, record: ILoginHistory) => {
				return (
					<>
						<DgTableColumnResponsive title={t('login_status')} value={record?.login_status} />
						<DgTableColumnResponsive title={t('from-new-device')} value={record.is_from_new_device === 1 ? 'Yes' : 'No'} />
						<DgTableColumnResponsive
							title={t('ip')}
							value={
								<div className='flex flex-col'>
									<span>{record.ip.ip}</span>
									<span>
										{record.ip.country} - {record.ip.city}
									</span>
								</div>
							}
						/>
						<DgTableColumnResponsive
							title={t('device')}
							value={
								<div className='flex flex-col'>
									<span>
										{record.agent.browser} - {record.agent.browser_version} - {record.agent.device_type}
									</span>
									<span>
										{record.agent.is_desktop === '1' ? 'desktop' : record.agent.is_phone === '1' ? 'phone' : record.agent.is_robot === '1' ? 'robot' : 'unknown'} -{' '}
										{record.agent.platform}
									</span>
								</div>
							}
						/>
						<DgTableColumnResponsive title={t('date-time')} value={<DgTimeStamp nowrap={false} timeStamp={record?.created_at} />} />
					</>
				);
			},
		},
	];

	return width > 769 ? Columns : RColumn;
};

export default UserLoginHistoryTableColumn;
