import DgCard from 'styles/UI/Card/DgCard';
import DgCardHeader from 'styles/UI/CardHeader/DgCardHeader';
import useAdminGroups from './List/hooks/useAdminGroups';
import DgModal from 'styles/UI/Modal/DgModal';
import AdminGroupTable from './List/components/AdminGroupTable';
import AdminGroupsUpdate from './Update/AdminGroupsUpdate';
import { useState } from 'react';
import Text from '../../../core/utils/Text';
import { useAdminDeleteGroup } from './Update/hooks/react-query/useAdminDeleteGroup';
import { DotLoader } from 'react-spinners';
import CreateGroupModal from './List/components/CreateGroupModal';

const AdminGroups = () => {
	const { hideModalHandler, modal, onEditGroupHandler } = useAdminGroups();

	/*
	 * delete group
	 */
	const deleteInitial = { show: false, id: null };
	const [deleteModal, setDeleteModal] = useState(deleteInitial);
	const closeDeleteModal = () => setDeleteModal(deleteInitial);
	const onDeleteGroupHandler = (record: any) => {
		setDeleteModal({ show: true, id: record?.id });
	};

	const { mutate: deleteGroup, isLoading: deleting } = useAdminDeleteGroup(() => {
		closeDeleteModal();
	});
	const onSubmitDelete = () => {
		if (deleting) return;
		deleteGroup({ id: deleteModal.id });
	};

	/*
	 * create group
	 */
	const [createModal, setCreateModal] = useState(false);
	const showCreateModal = () => setCreateModal(true);
	const hideCreateModal = () => setCreateModal(false);

	return (
		<>
			{!!modal && (
				<DgModal title='update-group' open={modal} onCancel={hideModalHandler}>
					<AdminGroupsUpdate hideModalHandler={hideModalHandler} />
				</DgModal>
			)}

			{createModal && (
				<DgModal title={'create-group'} open={createModal} onCancel={hideCreateModal}>
					<CreateGroupModal hideCreateModal={hideCreateModal} />
				</DgModal>
			)}

			{deleteModal.show && (
				<DgModal
					title={'delete-group'}
					open={deleteModal.show}
					onCancel={closeDeleteModal}
				>
					<div>
						<Text tid={'delete-group-desc'} />
					</div>
					<div className={'w-full flex justify-around items-center mt-5'}>
						<div
							className={
								'w-[120px] h-[42px] bg-green-700 rounded flex justify-center items-center cursor-pointer'
							}
							onClick={onSubmitDelete}
						>
							{deleting ? (
								<DotLoader size={16} color={'white'} />
							) : (
								<Text tid={'submitt'} />
							)}
						</div>
						<div
							className={
								'w-[120px] h-[42px] bg-gray-600 rounded flex justify-center items-center cursor-pointer'
							}
							onClick={closeDeleteModal}
						>
							<Text tid={'cancel'} />
						</div>
					</div>
				</DgModal>
			)}

			<DgCard>
				<div className={'flex w-full justify-between'}>
					<DgCardHeader title='group-management' />
					<div
						className={
							'bg-[#58a0f8] rounded-md px-8 h-[42px] flex justify-center items-center text-black cursor-pointer'
						}
						onClick={showCreateModal}
					>
						<Text tid={'create-group'} />
					</div>
				</div>

				<AdminGroupTable
					onEditSettingsHandler={onEditGroupHandler}
					onDeleteGroupHandler={onDeleteGroupHandler}
				/>
			</DgCard>
		</>
	);
};

export default AdminGroups;
