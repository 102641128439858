import { FC, ReactNode, useEffect, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import { useWindowSize } from '../../core/hooks/useWindowSize';
import Sidebar from './Sidebar';
import MasterHeader from './MasterHeader';
import { TABLET_SIZE } from '../../core/constants/common';
import { useLocation, useNavigate } from 'react-router-dom';
import { getMainTheme } from '../../core/utils/theme';
import BottomBar from './BottomBar';
import SupportChat from './crisp/components/SupportChat';
import { routes } from 'core/constants/routes';
import { useSelector } from 'react-redux';
import { RootState } from 'core/store';
import { getCurrentUserInfoQuery } from '../../core/hooks/react-query/getCurrentUserInfoQuery';
import DgBreadcrumb from 'styles/UI/Breadcrumb/DgBreadcrumb';
import HeaderAlert from 'components/HeaderAlert/HeaderAlert';
import { getUserWalletsQuery } from 'core/hooks/react-query/getUserWalletsQuery';
import HijackLayout from './HijackLayout';
import Google2faAlert from 'components/HeaderAlert/components/Google2faAlert';
import ActiveGoogle2FA from 'components/Profile/components/ProfileSecurity/components/ActiveGoogle2FA';
import UserGoogleAuthPageList from 'pages/UserGoogleAuth/UserGoogleAuthPageList';
import DgSkeleton from 'styles/UI/Skeleton/DgSkeleton';

interface IPrivateLayout {
	children: ReactNode;
}

const PrivateLayout: FC<IPrivateLayout> = ({ children }) => {
	// query
	getCurrentUserInfoQuery();
	getUserWalletsQuery();

	// hooks
	const navigate = useNavigate();
	const location = useLocation();
	const windowSize = useWindowSize();
	const { width } = useWindowSize();

	const { data: currentUserInfo, isFetching } = getCurrentUserInfoQuery();

	// store
	const { isAuthenticated, isHijack } = useSelector(
		(store: RootState) => store.mainInfoStore
	);
	const { lang, theme, isEnglish } = useSelector(
		(store: RootState) => store.settingStore
	);

	// state
	const [isMenuOpen, setIsMenuOpen] = useState(false);

	useEffect(() => {
		if (!isAuthenticated && location.pathname !== routes.REGISTER_SIGNING) {
			navigate(routes.REGISTER_SIGNING);
		}
	}, []);

	useEffect(() => {
		setIsMenuOpen(false);
	}, [location]);

	// const has2FA = !!currentUserInfo?.data?.google2fa_enable
	const has2FA = true;

	console.log({ isMenuOpen, has2FA, windowSize });

	return (
		<ThemeProvider theme={getMainTheme(theme, lang)}>
			<MasterHeader isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />

			<div className='min-h-screen flex lg-969:flex-row flex-col'>
				<>
					<Sidebar
						windowSize={windowSize}
						isOpen={isMenuOpen}
						setIsMenuOpen={setIsMenuOpen}
					/>
				</>
				<div
					className={
						'flex-1 mt-7 mb-28 sm:mb-7 xs:px-7 px-3 lg-969:mx-auto mx-0 max-w-[1350px] w-full min-w-0'
					}
				>
					<div className={'mb-5 mx-auto flex flex-col gap-y-5'}>
						<div className='flex xs:items-center items-start justify-between xs:flex-row flex-col xs:space-y-0 space-y-5'>
							{!!has2FA && <DgBreadcrumb />}
							{isHijack && <HijackLayout />}
						</div>

						<HeaderAlert />
					</div>

					{!isFetching && !isHijack && !has2FA && <UserGoogleAuthPageList />}

					{isHijack ? (
						children
					) : isFetching ? (
						<div className='grid md:grid-cols-7 grid-cols-1 gap-12'>
							<div className='md:col-span-2 col-span-1 flex flex-col gap-6'>
								<DgSkeleton />
								<DgSkeleton />
								<DgSkeleton />
								<DgSkeleton />
							</div>
							<div className='md:col-span-5 col-span-1 flex flex-col gap-6'>
								<DgSkeleton height={50} />
								<DgSkeleton height={50} />

								<div className='grid md:grid-cols-2 grid-cols-1 gap-6'>
									<DgSkeleton className='col-span-1' height={125} />
									<DgSkeleton className='col-span-1' height={125} />
								</div>

								<DgSkeleton />
								<DgSkeleton height={75} />
								<DgSkeleton />
							</div>
						</div>
					) : (
						!!has2FA && children
					)}
					{/* {children} */}
				</div>
			</div>

			{width < TABLET_SIZE && <BottomBar />}

			<SupportChat />
		</ThemeProvider>
	);
};

export default PrivateLayout;
