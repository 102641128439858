import { UseMutationResult } from '@tanstack/react-query';
import { endPointUrls } from 'core/constants/endPointUrls';
import useMutationHook from 'core/hooks/masterQuery/useMutationHook';
import useQueryUrlParam from 'core/hooks/useQueryUrlParam';
import api from 'core/services/fetch-api/useApi';
import { setApiCatcherFormErrors } from 'core/store/slice/mainInfo/mainInfoSlice';
import { IWalletDepositResponse } from 'core/types/models/walletDepositType';
import { useDispatch } from 'react-redux';

export const useAccountingQueries = () => {
	const dispatch = useDispatch();
	const { setUrlParam } = useQueryUrlParam();

	return {
		TOTAL_WITHDRAWS_COUNT: useMutationHook(
			[endPointUrls.TOTAL_WITHDRAWS_COUNT],
			(data: any) => api.get(endPointUrls.TOTAL_WITHDRAWS_COUNT, { params: data }),
			{
				onSuccess: (res) => {
					const response = res as any;

					if (response?.status === 200) {
						// setUrlParam({ tab: 2, transaction_id: response?.data?.data?.transaction_id });
						// toastHandler('success', response.data.message);
						dispatch(setApiCatcherFormErrors([]));
					}
				},
			}
		) as UseMutationResult<any, Error>,
		SUM_COMMISSIONS: useMutationHook(
			[endPointUrls.SUM_COMMISSIONS],
			(data: any) => api.get(endPointUrls.SUM_COMMISSIONS, { params: data }),
			{
				onSuccess: (res) => {
					const response = res as any;

					if (response?.status === 200) {
						// setUrlParam({ tab: 2, transaction_id: response?.data?.data?.transaction_id });
						// toastHandler('success', response.data.message);
						dispatch(setApiCatcherFormErrors([]));
					}
				},
			}
		) as UseMutationResult<any, Error>,
		SUM_ORDERS: useMutationHook(
			[endPointUrls.SUM_ORDERS],
			(data: any) => api.get(endPointUrls.SUM_ORDERS, { params: data }),
			{
				onSuccess: (res) => {
					const response = res as any;

					if (response?.status === 200) {
						// setUrlParam({ tab: 2, transaction_id: response?.data?.data?.transaction_id });
						// toastHandler('success', response.data.message);
						dispatch(setApiCatcherFormErrors([]));
					}
				},
			}
		) as UseMutationResult<any, Error>,
		INVEST_INFO: useMutationHook(
			[endPointUrls.INVEST_INFO],
			(data: any) => api.get(endPointUrls.INVEST_INFO, { params: data }),
			{
				onSuccess: (res) => {
					const response = res as any;

					if (response?.status === 200) {
						// setUrlParam({ tab: 2, transaction_id: response?.data?.data?.transaction_id });
						// toastHandler('success', response.data.message);
						dispatch(setApiCatcherFormErrors([]));
					}
				},
			}
		) as UseMutationResult<any, Error>,
		ORDER_INCOME_OUTCOME: useMutationHook(
			[endPointUrls.ORDER_INCOME_OUTCOME],
			(data: any) => api.get(endPointUrls.ORDER_INCOME_OUTCOME, { params: data }),
			{
				onSuccess: (res) => {
					const response = res as any;

					if (response?.status === 200) {
						// setUrlParam({ tab: 2, transaction_id: response?.data?.data?.transaction_id });
						// toastHandler('success', response.data.message);
						dispatch(setApiCatcherFormErrors([]));
					}
				},
			}
		) as UseMutationResult<any, Error>,
	};
};
