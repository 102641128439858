import { FC, useLayoutEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './inputLabel.css';
import DgInputPassword from './DgInputPassword';
import { PasswordProps } from 'antd/lib/input';
import { RootState } from 'core/store';
import { useSelector } from 'react-redux';

interface IDgInputPasswordLabel extends PasswordProps {
	label: string;
	ltr?: boolean;
	className?: string;
	required?: boolean;
	value?: any;
}

const DgInputPasswordLabel: FC<IDgInputPasswordLabel> = ({ ltr = false, label, className, required, ...props }) => {
	const { t } = useTranslation();
	const { lang } = useSelector((store: RootState) => store.settingStore);
	const labelRef = useRef<HTMLLabelElement | null>(null);
	const [value, setValue] = useState<any>('');

	useLayoutEffect(() => {
		if (props.value || value) labelRef.current?.classList?.add('active-float-label');
		else labelRef.current?.classList?.remove('active-float-label');
	}, [props.value, value, lang]);

	return (
		<div className='float-group password-input'>
			<DgInputPassword value={value} setValue={setValue} className={`h-[50px] ${className}`} {...props} />
			<label ref={labelRef} className={`float-label ${lang === 'fa' ? (ltr ? 'left-3' : 'right-3') : 'left-3'}`}>
				{required && <span className='text-dg-red-color inline-block relative top-1 mx-1'>*</span>}
				{t(label)}
			</label>
		</div>
	);
};

export default DgInputPasswordLabel;
