import { FC } from 'react';
import DatePicker, { CalendarProps, DatePickerProps } from 'react-multi-date-picker';
import classes from './style/style.module.scss';
// import transition from 'react-element-popper/animations/transition';
import 'react-multi-date-picker/styles/backgrounds/bg-dark.css';
import { useSelector } from 'react-redux';
import { RootState } from 'core/store';

interface ITcDatePicker extends DatePickerProps, CalendarProps {
	placeholder?: string;
	className?: string;
}

const DgDatePicker: FC<ITcDatePicker> = ({ className, ...props }) => {
	const { theme } = useSelector((store: RootState) => store.settingStore);

	return (
		<DatePicker
			// animations={[
			// 	transition({
			// 		from: 35,
			// 		transition: 'all 400ms cubic-bezier(0.335, 0.010, 0.030, 1.360)',
			// 	}),
			// ]}
			format='YYYY-MM-DD'
			className={`shadow-lg ${theme === 'dark' ? 'bg-dark' : ''} ${className}`}
			inputClass={`${classes['t-dataPicker-input']}`}
			containerStyle={{ backgroundColor: 'red !important' }}
			calendarPosition='bottom-right'
			{...props}
		/>
	);
};

export default DgDatePicker;
