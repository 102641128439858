import { Form } from 'antd';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'core/store';
import { setHiddenModal } from 'core/store/slice/modal/modalSlice';
import { useAdminSettingsUpdateQuery } from './react-query/useAdminSettingsUpdateQuery';
import { ISettingPayload } from 'core/types/models/settingsType';

const useAdminSettingsUpdate = () => {
	//hooks
	const dispatch = useDispatch();

	// store
	const { modalData } = useSelector((store: RootState) => store.modalStore);

	// login
	const { apiCatcherFormErrors } = useSelector((store: RootState) => store.mainInfoStore);
	const [updateSettingForm] = Form.useForm();
	const { mutate: adminUpdateSetting, isLoading: isLoadingUpdateSetting } = useAdminSettingsUpdateQuery(() => {
		dispatch(setHiddenModal());
	});

	const onFinishUpdateSetting = (values: ISettingPayload) => {
		adminUpdateSetting(values);
	};

	useEffect(() => {
		updateSettingForm.setFieldsValue({ ...modalData });
	}, [modalData]);

	useEffect(() => {
		updateSettingForm.setFields(apiCatcherFormErrors);
	}, [apiCatcherFormErrors]);

	return {
		updateSettingForm,
		onFinishUpdateSetting,
		isLoadingUpdateSetting,
	};
};

export default useAdminSettingsUpdate;
