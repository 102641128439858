import { UseMutationResult } from '@tanstack/react-query';
import { endPointUrls } from 'core/constants/endPointUrls';
import api from 'core/services/fetch-api/useApi';
import useMutationHook from 'core/hooks/masterQuery/useMutationHook';
import { userDetailResponse } from 'core/types/models/userInfo';
import { useDispatch } from 'react-redux';
import toastHandler from 'core/helpers/toast/toast';
import { setApiCatcherFormErrors } from 'core/store/slice/mainInfo/mainInfoSlice';

export const useUserResetPasswordQuery = () => {
	const dispatch = useDispatch();

	return useMutationHook(
		[endPointUrls.ADMIN_USER_RESET_PASSWORD],
		(data: any) => api.patch(endPointUrls.ADMIN_USER_RESET_PASSWORD, data),
		{
			onSuccess: (res: any) => {
				if (res?.status === 200) {
					toastHandler('success', res?.data.message);
					dispatch(setApiCatcherFormErrors([]));
				}
			},
		}
	) as UseMutationResult<userDetailResponse, Error>;
};
