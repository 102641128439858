import useModal from 'core/hooks/useModal';
import { setApiCatcherFormErrors } from 'core/store/slice/mainInfo/mainInfoSlice';
import { setProductDetail } from 'core/store/slice/product/reducer';
// import { IProduct } from 'core/types/models/productType';
import { useDispatch } from 'react-redux';

const useAdminProduct = () => {
	// hooks
	const dispatch = useDispatch();
	const { hideModal, modal, showModal } = useModal();

	const hideModalHandler = () => {
		hideModal();
		dispatch(setApiCatcherFormErrors([]));
	};

	const onEditProductHandler = (value: any) => {
		showModal();
		dispatch(setProductDetail(value));
	};

	return { onEditProductHandler, hideModalHandler, modal };
};

export default useAdminProduct;
