import useQueryHook from '../../../core/hooks/masterQuery/useQueryHook';
import { endPointUrls } from '../../../core/constants/endPointUrls';
import useQueryUrlParam from '../../../core/hooks/useQueryUrlParam';
import api from '../../../core/services/fetch-api/useApi';

export const useAdminWithdraws = () => {
	const { filterParams } = useQueryUrlParam();

	return useQueryHook(
		[endPointUrls.ADMIN_WITHDRAWS(), filterParams],
		(data) => api.get(endPointUrls.ADMIN_WITHDRAWS(filterParams), data),
		{
			select: (res) => res?.data,
			keepPreviousData: true,
		}
	);
};
