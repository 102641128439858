import { Form } from 'antd';
import { useState } from 'react';
import { useLoginQuery } from './react-query/useLoginQuery';
import toastHandler from 'core/helpers/toast/toast';
import { loginPayload } from '../types/loginType';
import useTranslate from 'core/utils/useTranslate';
import useMasterQueryUrlParam from 'core/hooks/useMasterQueryUrlParam';
import { stringToBoolean } from 'core/utils/utils';
import { useAdd2FAOnTokenQuery } from './react-query/useAdd2FAOnTokenQuery';

const useLogin = () => {
	const isShowCaptcha = stringToBoolean(process.env.REACT_APP_IS_RECAPTCHA_SHOW);
	const [captcha, setCaptcha] = useState(isShowCaptcha ? false : 'true');

	const [loginForm] = Form.useForm();
	const { mutate: login, isLoading: isLoadingLogin } = useLoginQuery();
	const { mutate: add2FAOnToken, isLoading: isLoadingAdd2FAOnToken } =
		useAdd2FAOnTokenQuery();
	const { setUrlParam } = useMasterQueryUrlParam();
	const { t } = useTranslate();

	const onFinishLogin = (values: loginPayload) => {
		if (!captcha) {
			return toastHandler('error', t('captcha-required'));
		}

		login(values);
	};

	const onChangeCaptcha = (value: any) => {
		setCaptcha(value);
	};

	// forget password
	const forgetPasswordForm = () => {
		setUrlParam({ page: 4 });
	};

	// 2fa
	const onFinishAdd2FAOnToken = (values: { one_time_password: string }) => {
		if (!captcha) {
			return toastHandler('error', t('captcha-required'));
		}

		add2FAOnToken({ one_time_password: values.one_time_password });
	};

	return {
		loginForm,
		onFinishAdd2FAOnToken,
		isLoadingAdd2FAOnToken,
		onFinishLogin,
		isLoadingLogin,
		forgetPasswordForm,
		onChangeCaptcha,
		isShowCaptcha,
	};
};

export default useLogin;
