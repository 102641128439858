import { useParams } from 'react-router-dom';
import { useUserDisable2FAQuery } from './react-query/useUserDisable2FAQuery';

const useDisable2FA = () => {
	// hooks
	const { userId } = useParams();

	// query
	const { mutate: resetPassword, isLoading: resetPasswordIsFetching } = useUserDisable2FAQuery();

	const onConfirm = () => {
		resetPassword({ user_id: userId });
	};

	return { onConfirm, resetPasswordIsFetching };
};

export default useDisable2FA;
