import { UseQueryResult } from '@tanstack/react-query';
import { endPointUrls } from 'core/constants/endPointUrls';
import api from 'core/services/fetch-api/useApi';
import useQueryHook from 'core/hooks/masterQuery/useQueryHook';
import { IWalletList } from 'core/types/models/walletsType';
import { useSelector } from 'react-redux';
import { RootState } from 'core/store';

export const useAdminUserWalletsQuery = () => {
	const { userDetail } = useSelector((store: RootState) => store.userInfoStore);
	return useQueryHook(
		[endPointUrls.ADMIN_USER_WALLETS()],
		() =>
			api.get(
				endPointUrls.ADMIN_USER_WALLETS(`member_id=${userDetail?.data?.member_id}`)
			),
		{
			select: (res: any) => res?.data,
			enabled: !!userDetail?.data?.member_id,
		}
	) as UseQueryResult<IWalletList, Error>;
};
