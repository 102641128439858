import { Button } from 'antd';
import { PrintOrder } from 'components/Admin/Orders/List/components/AdminOrderTableColumn';
import { useBasket } from 'core/hooks/basket';
import { useWindowSize } from 'core/hooks/useWindowSize';
import { addBasketItem, removeBasketItem } from 'core/store/slice/basket/reducer';
import Text from 'core/utils/Text';
import useTranslate from 'core/utils/useTranslate';
import { TbMinus, TbPlus } from 'react-icons/tb';
import { useDispatch } from 'react-redux';
import DgPrice from 'styles/UI/Price/DgPrice';
import DgTableColumnResponsive from 'styles/UI/TableColumnResponsive/DgTableColumnResponsive';

const BasketTableColumn = () => {
	const { width } = useWindowSize();
	const { t } = useTranslate();
	const dispatch = useDispatch();
	const { basketCount } = useBasket();

	const Columns = [
		{
			title: <Text tid='product' />,
			render: (text: any, record: any) => (
				<span className={''}>{record.product.title}</span>
			),
			sorter: false,
		},
		{
			title: <Text tid='price' />,
			sorter: false,
			render: (text: any, record: any) => {
				return (
					<div className='flex items-center gap-2'>
						<DgPrice price={record.product.offPrice} />
					</div>
				);
			},
		},
		{
			title: <Text tid='quantity' />,
			sorter: false,
			render: (text: any, record: any) => {
				return (
					<>
						<div dir='rtl' className='flex items-center justify-start gap-2'>
							<Button
								className='flex items-center justify-center'
								icon={<TbPlus />}
								onClick={() => dispatch(addBasketItem(record.id))}
								disabled={record.quantity >= record.product.qty}
							/>

							{record.quantity}

							<Button
								className='flex items-center justify-center'
								icon={<TbMinus />}
								onClick={() => dispatch(removeBasketItem(record.id))}
								// disabled={record.quantity <= 1}
							/>
						</div>
					</>
				);
			},
		},
		{
			title: <Text tid='total-price' />,
			sorter: false,
			render: (text: any, record: any) => {
				return (
					<div className='flex items-center gap-2'>
						<DgPrice price={record.product.offPrice * record.quantity} />
					</div>
				);
			},
		},
	];

	const RColumn = [
		{
			title: '',
			sorter: false,
			render: (_text: any, record: any) => {
				return (
					<>
						<DgTableColumnResponsive
							title={t('product')}
							value={<span className={''}>{record.product.title}</span>}
						/>
						<DgTableColumnResponsive
							title={t('price')}
							value={
								<div className='flex items-center gap-2'>
									<DgPrice price={record.product.offPrice} />
								</div>
							}
						/>
						<DgTableColumnResponsive
							title={t('quantity')}
							value={
								<>
									<div dir='rtl' className='flex items-center justify-start gap-2'>
										<Button
											className='flex items-center justify-center'
											icon={<TbPlus />}
											onClick={() => dispatch(addBasketItem(record.id))}
											disabled={record.quantity >= record.product.qty}
										/>

										{record.quantity}

										<Button
											className='flex items-center justify-center'
											icon={<TbMinus />}
											onClick={() => dispatch(removeBasketItem(record.id))}
											// disabled={record.quantity <= 1}
										/>
									</div>
								</>
							}
						/>
						<DgTableColumnResponsive
							title={t('total-price')}
							value={
								<div className='flex items-center gap-2'>
									<DgPrice price={record.product.offPrice * record.quantity} />
								</div>
							}
						/>
					</>
				);
			},
		},
	];

	return width > 400 ? Columns : RColumn;
};

export default BasketTableColumn;
