import DgCard from 'styles/UI/Card/DgCard';
import DgCardHeader from 'styles/UI/CardHeader/DgCardHeader';
import UserInternalTransferTable from './components/UserInternalTransferTable';

const UserInternalTransferHistory = () => {
	return (
		<DgCard>
			<DgCardHeader title='internal-transfer-history' />

			<UserInternalTransferTable />
		</DgCard>
	);
};

export default UserInternalTransferHistory;
