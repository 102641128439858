import { UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { endPointUrls } from 'core/constants/endPointUrls';
import api from 'core/services/fetch-api/useApi';
import { userList } from 'core/types/models/userInfo';
import useQueryUrlParam from 'core/hooks/useQueryUrlParam';
import useMutationHook from 'core/hooks/masterQuery/useMutationHook';
import { AxiosResponse } from 'axios';
import { useDispatch } from 'react-redux';
import { setApiCatcherFormErrors } from 'core/store/slice/mainInfo/mainInfoSlice';

export const useAdminUserMutation = (
	callBack: any = () => {},
	keys: Array<string> = [],
	searchQuery = ''
) => {
	const { filterParams } = useQueryUrlParam();

	const dispatch = useDispatch();
	const queryClient = useQueryClient();

	return useMutationHook(
		[endPointUrls.ADMIN_USERS(searchQuery), searchQuery, ...keys],
		(data: any) => api.get(endPointUrls.ADMIN_USERS(searchQuery), data),
		{
			onSuccess: (res) => {
				const response = res as AxiosResponse;

				if (response?.status === 200) {
					// toastHandler('success', response?.data.message);
					dispatch(setApiCatcherFormErrors([]));
					queryClient.invalidateQueries([endPointUrls.PACKAGES_LIST]);
					callBack?.();
				}
			},
		}
	) as UseMutationResult<any>;
};
