import { Form } from 'antd';
import { useEffect } from 'react';
import useQueryUrlParam from 'core/hooks/useQueryUrlParam';
import DgFilter from 'styles/UI/Filter/DgFilter';
import { filterOptionsType } from 'core/types/components/filterType';
import DgInputLabel from 'styles/UI/Input/DgInputLabel';
import DgDatePickerLabel from 'styles/UI/DatePicker/DgDatePickerLabel';
import { useWindowSize } from 'core/hooks/useWindowSize';
import DgSelectTransactionType from 'components/Basic/DgSelectTransactionType';

const UserTransactionFilter = () => {
	const { query, resetQuery } = useQueryUrlParam();
	const { width } = useWindowSize();

	const filterOptions: filterOptionsType[] = [
		{
			filterTitle: 'type',
			filterNames: ['type'],
			filterItems: [<DgSelectTransactionType key='type' />],
			formAttribute: Form.useForm(),
			query,
		},
		...(width < 769
			? [
					{
						filterTitle: 'amount',
						filterNames: ['amount'],
						formAttribute: Form.useForm(),
						sortNames: ['amount'],
						query,
					},
			  ]
			: [{ filterTitle: '', formAttribute: Form.useForm(), query, filterNames: [] }]),
		{
			filterTitle: 'transaction-id',
			filterNames: ['transaction_id'],
			filterItems: [<DgInputLabel key='transaction-id' label='transaction-id' />],
			formAttribute: Form.useForm(),
			query,
		},
		{
			filterTitle: 'date-time',
			filterNames: ['created_at_from', 'created_at_to'],
			filterItems: [<DgDatePickerLabel key='date-from' label='date-from' />, <DgDatePickerLabel key='date-to' label='date-to' />],
			formAttribute: Form.useForm(),
			query,
			sortNames: ['created_at'],
		},
	];

	useEffect(() => {
		filterOptions.map((item) => {
			const { formAttribute } = item;
			const [form] = formAttribute;

			form.setFieldsValue({ ...query });
		});
	}, []);

	return <DgFilter width={width} filterOptions={filterOptions} resetQuery={() => resetQuery(undefined, { tab: query?.tab }, filterOptions)} />;
};

export default UserTransactionFilter;
