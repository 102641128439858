import { CFlexCenter, FlexCenter, FlexStart } from '../../../styles/CommonStyles';
import { WizardIcon, RWizardWrapper, Check } from '../../../styles/responsive/Authentication';
import { RText } from '../../../styles/responsive/Common';
import Text from '../../../core/utils/Text';

const RWizard = ({ items, active }) => {
	return (
		<RWizardWrapper>
			<CFlexCenter>
				{items.map((item, idx) => (
					<FlexStart key={idx} width='100%' height='40px'>
						<FlexCenter width='10%' height='40px'>
							<WizardIcon active={idx <= active} last={idx === items.length - 1} lineActive={idx <= active - 1}>
								<FlexCenter width='100%' height='100%'>
									<Check active={idx <= active - 1} size={12} />
								</FlexCenter>
							</WizardIcon>
						</FlexCenter>
						<RText>
							<Text tid={item.title} />
						</RText>
					</FlexStart>
				))}
			</CFlexCenter>
		</RWizardWrapper>
	);
};

export default RWizard;
