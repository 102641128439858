import React from 'react';
import DgDevider from 'styles/UI/Devider/DgDevider';
import DgShowInfo from 'styles/UI/ShowInfo/DgShowInfo';
import DgSwitch from 'styles/UI/Switch/DgSwitch';
import useUserBlockUnBlock from '../hooks/useUserBlockUnBlock';
import DgTooltip from 'styles/UI/Tooltip/DgTooltip';
import DgForm from 'styles/UI/Form/DgForm';
import DgFormWrapper from 'styles/UI/FormWrapper/DgFormWrapper';
import DgFormItem from 'styles/UI/FormItem/DgFormItem';
import DgTextAreaLabel from 'styles/UI/TextArea/DgTextAreaLabel';
import DgButton from 'styles/UI/Button/DgButton';
import useTranslate from 'core/utils/useTranslate';

const UserBlockUnBlockStatus = () => {
	const { onChangeSwitchHandler, block, onFinishBlockUnBlockForm, blockUnBlockForm, blockUnBlockIsLoading } = useUserBlockUnBlock();
	const { t } = useTranslate();

	return (
		<>
			<DgDevider orientation='center' title='block-unblock-user' />

			<DgForm form={blockUnBlockForm} onFinish={onFinishBlockUnBlockForm}>
				<DgFormWrapper>
					<DgShowInfo
						title={t('block-unblock-user-account')}
						value={
							<DgTooltip title={block ? 'unblock' : 'block'}>
								<DgSwitch checked={block} onChange={onChangeSwitchHandler} />
							</DgTooltip>
						}
					/>

					<DgFormItem name=''>
						<DgTextAreaLabel label='reason' />
					</DgFormItem>

					<DgFormItem button>
						<DgButton title='submit' isLoading={blockUnBlockIsLoading} />
					</DgFormItem>
				</DgFormWrapper>
			</DgForm>
		</>
	);
};

export default UserBlockUnBlockStatus;
