import { useSetTransactionPasswordQuery } from './react-query/useSetTransactionPasswordQuery';
import { Form } from 'antd';
import { ITransitionPasswordPayload } from 'core/types/models/transactionType';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'core/store';
import { IUserPasswordPayload } from 'core/types/models/userInfo';
import { useSetUserPasswordQuery } from './react-query/useSetUserPasswordQuery';
import useModal from 'core/hooks/useModal';

const useProfileSecurity = () => {
	// hooks
	const [transactionPasswordForm] = Form.useForm();
	const [userPasswordForm] = Form.useForm();
	const {
		hideModal: hideUserPasswordModal,
		showModal: showUserPasswordModal,
		modal: modalUserPassword,
	} = useModal();
	const {
		hideModal: hideTransactionPasswordModal,
		showModal: showTransactionPasswordModal,
		modal: modalTransactionPassword,
	} = useModal();

	// query
	const {
		mutate: setTransactionPassword,
		isLoading: transactionPasswordIsLoading,
		isSuccess: isSuccessTransactionPassword,
	} = useSetTransactionPasswordQuery();
	const {
		mutate: setUserPassword,
		isLoading: userPasswordIsLoading,
		isSuccess: isSuccessUserPassword,
	} = useSetUserPasswordQuery();

	// store
	const { apiCatcherFormErrors } = useSelector((store: RootState) => store.mainInfoStore);

	useEffect(() => {
		transactionPasswordForm.setFields(apiCatcherFormErrors);
		userPasswordForm.setFields(apiCatcherFormErrors);
	}, [apiCatcherFormErrors]);

	const onFinishTransactionPassword = (values: ITransitionPasswordPayload) => {
		setTransactionPassword(values, {
			onSuccess: () => {
				hideTransactionPasswordModal();
				transactionPasswordForm.resetFields();
			},
		});
	};

	const onFinishUserPassword = (values: IUserPasswordPayload) => {
		setUserPassword(values, {
			onSuccess: () => {
				hideUserPasswordModal();
				userPasswordForm.resetFields();
			},
		});
	};

	useEffect(() => {
		// // console.log(isSuccessUserPassword, 4, hideUserPasswordModal);
		isSuccessUserPassword && hideUserPasswordModal();
		isSuccessTransactionPassword && hideTransactionPasswordModal();
	}, [isSuccessTransactionPassword, isSuccessUserPassword]);

	return {
		modalUserPassword,
		modalTransactionPassword,
		showTransactionPasswordModal,
		showUserPasswordModal,
		hideTransactionPasswordModal,
		hideUserPasswordModal,
		transactionPasswordForm,
		onFinishTransactionPassword,
		transactionPasswordIsLoading,
		userPasswordIsLoading,
		onFinishUserPassword,
		userPasswordForm,
	};
};

export default useProfileSecurity;
