import { FC, useEffect, useMemo, useState } from 'react';
import DgCard from 'styles/UI/Card/DgCard';
import DgCardHeader from 'styles/UI/CardHeader/DgCardHeader';
import { Checkbox, Form } from 'antd';
import DgForm from 'styles/UI/Form/DgForm';
import DgFormWrapper from 'styles/UI/FormWrapper/DgFormWrapper';
import DgFormItem from 'styles/UI/FormItem/DgFormItem';
import useTranslate from 'core/utils/useTranslate';
import DgInputLabel from 'styles/UI/Input/DgInputLabel';
import DgImage from 'styles/UI/Image/DgImage';
import usdt from '../../../assets/images/usdt.png';
import Text from 'core/utils/Text';
import DgButton from 'styles/UI/Button/DgButton';
import { useAdminSettingsUpdateQuery } from '../Settings/hooks/react-query/useAdminSettingsUpdateQuery';
import userAdminSettings from '../Settings/hooks/userAdminSettings';

const AdminBalanceProfit: FC = () => {
	const { t } = useTranslate();

	const [form] = Form.useForm();

	const [active, setActive] = useState(false);

	const { settings } = userAdminSettings();

	const { mutate: adminUpdateSetting, isLoading: isLoadingUpdateSetting } =
		useAdminSettingsUpdateQuery(() => {
			// dispatch(setHiddenModal());
		});

	const balanceProfitSetting: any = useMemo(() => {
		if (!settings?.data?.length) return undefined;

		return settings.data.find((item) => item.key === 'WALLET_BALANCE_INVEST');
	}, [settings]);

	useEffect(() => {
		setActive(balanceProfitSetting > 0);
		form.setFieldValue('amount', balanceProfitSetting);
	}, [balanceProfitSetting]);

	return (
		<div className='flex justify-center items-center'>
			<div className='w-full max-w-md'>
				<DgCard>
					<DgCardHeader title='balance-profit' />

					<DgForm
						form={form}
						onFinish={(values) => {
							adminUpdateSetting({
								key: 'WALLET_BALANCE_INVEST',
								value: values.amount || 0,
							});
						}}
					>
						<DgFormWrapper singleColumn>
							<Checkbox
								checked={active}
								onChange={(e) => {
									setActive(e.target.checked);
								}}
								className='text-black dark:text-white'
							>
								<Text tid={'enable'} />
							</Checkbox>

							<DgFormItem
								name='amount'
								rules={[{ required: active, message: t('amount-is-required') }]}
							>
								<DgInputLabel
									// value={amount}
									// onChange={onChangeInputHandler}
									label='amount'
									required
									disabled={!active}
									suffix={
										<DgImage
											src={usdt}
											width={22}
											className='rounded-full relative top-1.5'
										/>
									}
								/>
							</DgFormItem>

							<DgButton
								disabled={isLoadingUpdateSetting}
								loading={isLoadingUpdateSetting}
								title='save'
								className='mt-5 w-full'
							/>
						</DgFormWrapper>
					</DgForm>
				</DgCard>
			</div>
		</div>
	);
};

export default AdminBalanceProfit;
