import { Image, ImageProps } from 'antd';
import { FC, SyntheticEvent } from 'react';
import { twMerge } from 'tailwind-merge';

interface IDgImage extends ImageProps {
	className?: string;
}

const DgImage: FC<IDgImage> = ({ className, ...props }) => {
	const onErrorHandler = (e: SyntheticEvent<HTMLImageElement, Event>) => {
		e.currentTarget.src = require('../../../assets/images/default.png');
	};
	return <Image preview={false} onError={onErrorHandler} className={twMerge(`w-full ${className}`)} {...props} />;
};

export default DgImage;
