import LoginRegister from 'components/LoginSignUp/LoginRegister';

const LoginRegisterPage = () => {
	return (
		<div className='md:grid flex h-auto xs:h-[calc(100vh_-_90px)] lg:grid-cols-2 grid-cols-1 items-center justify-center'>
			<LoginRegister />

			<div className='h-auto xs:h-[calc(100vh_-_90px)] lg:block hidden'>
				<img
					src={require('assets/images/pic.jpg')}
					className='h-full w-full object-cover'
					alt=''
				/>
			</div>
		</div>
	);
};

export default LoginRegisterPage;
