import { Form, Steps } from 'antd';
import { routes } from 'core/constants/routes';
import useQueryUrlParam from 'core/hooks/useQueryUrlParam';
import useTranslate from 'core/utils/useTranslate';
import { extractNumberFromString } from 'core/utils/utils';
import { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DgButton from 'styles/UI/Button/DgButton';
import OrderPayment from './components/GoldOrderPayment';
import toastHandler from 'core/helpers/toast/toast';
import { useOrderSubmitQuery } from './hooks/react-query/useOrderSubmitQuery';
import { useSelector } from 'react-redux';
import { RootState } from 'core/store';
import OrderSuccessFull from './components/GoldOrderSuccessfull';
import useModal from 'core/hooks/useModal';
import DgModal from 'styles/UI/Modal/DgModal';
import DgFormWrapper from 'styles/UI/FormWrapper/DgFormWrapper';
import DgInputLabel from 'styles/UI/Input/DgInputLabel';
import DgForm from 'styles/UI/Form/DgForm';
import DgFormItem from 'styles/UI/FormItem/DgFormItem';
import { useQueryClient } from '@tanstack/react-query';
import { endPointUrls } from 'core/constants/endPointUrls';

const GoldOrderSubmit = () => {
	const { pathnameLastPart, query, filterParams } = useQueryUrlParam();
	const navigate = useNavigate();
	const { t } = useTranslate();
	const queryClient = useQueryClient();
	const [form] = Form.useForm();
	const { modal, showModal, hideModal } = useModal();

	const { mutate, isLoading: isLoadingSubmitOrder } = useOrderSubmitQuery(() => next());
	const [orderConfigForm] = Form.useForm();

	const { price: orderAmount } = orderConfigForm.getFieldsValue();

	const { currentSubmitOrder } = useSelector(
		(store: RootState) => store.submitOrderStore
	);

	// // console.log(parseFloat(orderAmount) > 100, orderAmount, parseFloat(orderAmount));

	const steps = [
		// {
		// 	title: t('select-package'),
		// 	content: <PackageList />,
		// 	key: extractNumberFromString(pathnameLastPart ? pathnameLastPart : ''),
		// },
		{
			title: t('package-payment'),
			content: <OrderPayment orderConfigForm={orderConfigForm} />,
			key: extractNumberFromString(pathnameLastPart ? pathnameLastPart : ''),
		},
		{
			title: t('invest-successful'),
			content: <OrderSuccessFull />,
			key: extractNumberFromString(pathnameLastPart ? pathnameLastPart : ''),
		},
	];

	const [current, setCurrent] = useState(
		+extractNumberFromString(pathnameLastPart ? pathnameLastPart : '')
	);

	const next = () => {
		if (current < steps?.length) {
			queryClient.invalidateQueries([endPointUrls.WALLETS]);
			setCurrent(current + 1);
			navigate(
				routes.ORDER_SUBMIT_STEPS.link(`package-buy-step-${current + 1}?${filterParams}`)
			);
		} else {
			navigate(routes.ORDERS_LIST);
		}

		hideModal();
	};

	const prev = () => {
		setCurrent(current - 1);
		navigate(
			routes.ORDER_SUBMIT_STEPS.link(`package-buy-step-${current - 1}?${filterParams}`)
		);
	};
	const items = steps.map((item) => ({ key: item.key, title: item.title }));

	const onSubmitOrder = (values: any) => {
		if (!currentSubmitOrder.price) return toastHandler('error', t('enter-invest-amount'));
		mutate({ ...currentSubmitOrder, one_time_password: values.one_time_password });
	};

	const onFinishOrder = (values: any) => {
		orderConfigForm.submit();
		onSubmitOrder(values);
	};

	useEffect(() => {
		setCurrent(1);
	}, []);

	//  console.log(
	// 	{ orderConfigForm },
	// 	orderConfigForm.getFieldsValue(true),
	// 	currentSubmitOrder
	// );

	return (
		<Fragment key={steps[current - 1].key}>
			<DgModal title='totp-verification-code' open={modal} onCancel={hideModal}>
				<DgForm form={form} onFinish={onFinishOrder}>
					<DgFormWrapper singleColumn>
						<DgFormItem
							name='one_time_password'
							rules={[
								{ required: true, message: t('totp-verification-code-is-required') },
							]}
						>
							<DgInputLabel label='totp-verification-code' required />
						</DgFormItem>
						<DgButton title='submit' isLoading={isLoadingSubmitOrder} />
					</DgFormWrapper>
				</DgForm>
			</DgModal>

			<Steps
				current={+extractNumberFromString(pathnameLastPart ? pathnameLastPart : '') - 1}
				items={items}
			/>

			<div className='steps-content'>
				<div className='my-10'>{steps[current - 1].content}</div>
			</div>

			<div className='steps-action my-5 text-center flex flex-col gap-5 xs:flex-row justify-center'>
				{current - 1 === 0 && (
					<DgButton
						className='min-w-[200px]'
						title='next-step'
						type='primary'
						onClick={() => next()}
						disabled={(currentSubmitOrder?.price as any) < 100}
					/>
				)}
				{current - 1 === 1 && (
					<DgButton
						disabled={orderAmount < 100}
						className='min-w-[200px]'
						title='confirm-invest'
						type='primary'
						onClick={showModal}
					/>
				)}
				{current - 1 === steps.length - 1 && (
					<DgButton
						className='min-w-[200px]'
						type='primary'
						title='order-list'
						onClick={() => navigate(routes.ORDERS_LIST)}
					/>
				)}
				{current - 1 !== steps.length - 1 && current - 1 > 0 && (
					<DgButton
						className='min-w-[200px]'
						title='previous-step'
						type='default'
						onClick={() => prev()}
					/>
				)}
			</div>
		</Fragment>
	);
};

export default GoldOrderSubmit;
