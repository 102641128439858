import { Form, RadioChangeEvent } from 'antd';
import { useParams } from 'react-router-dom';
import { getUserInfoQuery } from '../../../../../../core/hooks/react-query/getUserInfoQuery';
import { FormEvent, useEffect, useRef, useState } from 'react';
import { convertDatePicker } from '../../../../../../core/utils/utils';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'core/store';
import { userUpdateInfoPayload } from 'core/types/models/userInfo';
import toastHandler from 'core/helpers/toast/toast';
import useTranslate from 'core/utils/useTranslate';
import { updateUserInfoQuery } from './react-query/updateUserInfoQuery';
import { useUpdateProfileAvatar } from './react-query/updateUserAvatar';
import { setCountrySelectedData } from 'core/store/slice/country/countrySlice';

const useUserInfo = () => {
	// hooks
	const [userUpdateForm] = Form.useForm();
	const dispatch = useDispatch();
	const { userId } = useParams();
	const { t } = useTranslate();
	const avatarRef = useRef<HTMLInputElement>(null);

	// query
	const {
		data: userInfo,
		isLoading: isLoadingGetUser,
		refetch: refetchUserInfo,
	} = getUserInfoQuery();
	const { mutate: updateUserInfo, isLoading: isLoadingUpdateUser } =
		updateUserInfoQuery();
	const { mutate: updateAvatar, isLoading: isLoadingUpdateAvatar } =
		useUpdateProfileAvatar();

	// state
	const [genderType, setGenderType] = useState(userInfo?.data.gender);

	// store
	const { apiCatcherFormErrors } = useSelector((store: RootState) => store.mainInfoStore);
	const { countrySelectedData } = useSelector((store: RootState) => store.countryStore);

	useEffect(() => {
		userUpdateForm.setFieldsValue({ gender: genderType });
	}, [genderType]);

	useEffect(() => {
		userUpdateForm.setFieldsValue({ ...userInfo?.data, gender: userInfo?.data.gender });
		dispatch(setCountrySelectedData({ id: userInfo?.data.country_id }));
	}, [userInfo?.data]);

	useEffect(() => {
		userUpdateForm.setFields(apiCatcherFormErrors);
	}, [apiCatcherFormErrors]);

	const genderOnChangeHandler = (e: RadioChangeEvent): void => {
		setGenderType(e.target.value);
	};

	const onFinishUpdateUser = (values: userUpdateInfoPayload) => {
		updateUserInfo({
			...values,
			user_id: userId,
			birthday: convertDatePicker(values.birthday),
		});
	};

	// upload avatar
	const onUpdateAvatarClicked = (e: FormEvent<HTMLInputElement>) => {
		if (e.currentTarget?.files?.[0]?.size && e.currentTarget?.files?.[0]?.size > 350000) {
			toastHandler('error', t('image-size-error'));
		} else {
			const files = new FormData();
			if (e.currentTarget?.files?.[0]) {
				files.append('avatar', e.currentTarget?.files?.[0]);
				files.append('user_id', userId + '');
				updateAvatar(files as any);
			}
		}
	};

	return {
		onUpdateAvatarClicked,
		userUpdateForm,
		userInfo,
		avatarRef,
		isLoadingUpdateAvatar,
		genderOnChangeHandler,
		onFinishUpdateUser,
		countrySelectedData,
		isLoadingUpdateUser: isLoadingGetUser || isLoadingUpdateUser,
		isLoadingGetUser,
		refetchUserInfo,
	};
};

export default useUserInfo;
