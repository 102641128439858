import React, { FC } from 'react';
import DgTooltip from '../Tooltip/DgTooltip';
import { Link } from 'react-router-dom';
import { TbPlugConnected } from 'react-icons/tb';

interface IDgConnectIcon {
	to?: string | number;
	isTargetBlank?: boolean;
	onClick?: () => void;
	className?: string;
	title: string;
}

const DgConnectIcon: FC<IDgConnectIcon> = ({ onClick, to, title, className, isTargetBlank }) => {
	return (
		<DgTooltip title={title}>
			{to ? (
				<Link to={to + ''} target={isTargetBlank ? '_blank' : undefined} className={className}>
					<TbPlugConnected size={25} />
				</Link>
			) : (
				<span className={`cursor-pointer ${className}`} onClick={onClick}>
					<TbPlugConnected size={25} />
				</span>
			)}
		</DgTooltip>
	);
};

export default DgConnectIcon;
