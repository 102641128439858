import { UseQueryResult } from '@tanstack/react-query';
import { endPointUrls } from 'core/constants/endPointUrls';
import api from 'core/services/fetch-api/useApi';
import useQueryHook from 'core/hooks/masterQuery/useQueryHook';
import { roleList } from 'core/types/models/role';

export const useAdminRoleQuery = () => {
	return useQueryHook(
		[endPointUrls.ADMIN_ROLES],
		() => api.get(endPointUrls.ADMIN_ROLES),
		{
			select: (res: any) => res?.data,
		}
	) as UseQueryResult<roleList, Error>;
};
