import { RootState } from 'core/store';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useUserActiveDeActiveQuery } from './react-query/useUserActiveDeActiveQuery';

const useUserActiveDeActive = () => {
	// hooks
	const { userId } = useParams();

	// state
	const [block, setBlock] = useState(false);

	// query
	const { mutate: userActiveDeActive, isLoading: activeDeActiveIsLoading } =
		useUserActiveDeActiveQuery();

	// store
	const { userDetail } = useSelector((store: RootState) => store.userInfoStore);

	useEffect(() => {
		setBlock(!userDetail?.data.is_deactivate);
	}, [userDetail?.data]);

	const onConfirm = () => {
		userActiveDeActive({ user_id: userId, status: block ? 'deactivate' : 'activate' });
		setBlock(!block);
	};

	return { block, onConfirm, activeDeActiveIsLoading };
};

export default useUserActiveDeActive;
