import { useWindowSize } from 'core/hooks/useWindowSize';
import Text from 'core/utils/Text';
import DgTableColumnResponsive from 'styles/UI/TableColumnResponsive/DgTableColumnResponsive';
import DgPrice from 'styles/UI/Price/DgPrice';
import DgTimeStamp from 'styles/UI/Timstamp/DgTimeStamp';
import { hiddenText } from 'core/utils/utils';
import DgCopyClipboard from 'styles/UI/CopyClipboard/DgCopyClipboard';
import { ITransfer } from 'core/types/models/transferType';
import useTranslate from 'core/utils/useTranslate';

const UserTransferTableColumn = () => {
	const { width } = useWindowSize();
	const { t } = useTranslate();

	const Columns = [
		{
			title: <Text tid='transaction-id' />,
			key: 'id',
			dataIndex: 'id',
			render: (text: any) => {
				const { value, hiddenValue } = hiddenText(text);
				return <DgCopyClipboard hiddenValue={hiddenValue} value={value} />;
			},
			sorter: false,
		},
		{
			title: <Text tid='origin-referral-code-table' />,
			render: (text: any, record: ITransfer) => (
				<span>
					{record.from_user_full_name || ''} ({record.from_user_member_id || ''})
				</span>
			),
			sorter: false,
		},
		{
			title: <Text tid='destination-referral-code-table' />,
			render: (text: any, record: ITransfer) => (
				<span>
					{record.to_user_full_name || ''} ({record.to_user_member_id || ''})
				</span>
			),
			sorter: false,
		},
		{
			title: <Text tid='amount' />,
			key: 'amount',
			dataIndex: 'amount',
			render: (text: any) => <DgPrice price={text} />,
			sorter: false,
		},
		{
			title: <Text tid='fee' />,
			key: 'fee',
			dataIndex: 'fee',
			render: (text: any) => <DgPrice price={text} />,
		},
		{
			title: <Text tid='date-time' />,
			key: 'created_at',
			dataIndex: 'created_at',
			render: (text: any) => <DgTimeStamp timeStamp={text} />,
		},
	];

	const RColumn = [
		{
			title: '',
			sorter: false,
			render: (_text: any, record: ITransfer) => {
				const { value, hiddenValue } = hiddenText(record.id);

				return (
					<>
						<DgTableColumnResponsive title={t('transaction-id')} value={<DgCopyClipboard hiddenValue={hiddenValue} value={value} />} />

						<DgTableColumnResponsive
							title={t('origin-referral-code-table')}
							value={
								<span>
									{record.from_user_full_name || ''} ({record.from_user_member_id || ''})
								</span>
							}
						/>
						<DgTableColumnResponsive
							title={t('destination-referral-code-table')}
							value={
								<span>
									{record.to_user_full_name || ''} ({record.to_user_member_id || ''})
								</span>
							}
						/>

						<DgTableColumnResponsive title={t('amount')} value={<DgPrice price={record.amount} />} />
						<DgTableColumnResponsive title={t('fee')} value={<DgPrice price={record.fee} />} />
						<DgTableColumnResponsive title={t('date-time')} value={<DgTimeStamp nowrap={false} timeStamp={record.created_at} />} />
					</>
				);
			},
		},
	];

	return width > 769 ? Columns : RColumn;
};

export default UserTransferTableColumn;
