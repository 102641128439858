import DgSelectLabel from 'styles/UI/Select/DgSelectLabel';

const DgSelectNotificationStatus = ({ label = '', ...props }) => {
	const options = [
		{ label: 'read', value: 1, filter: 'read' },
		{ label: 'unread', value: 0, filter: 'unread' },
	];

	return <DgSelectLabel label={label} options={options} {...props} />;
};

export default DgSelectNotificationStatus;
