import toastHandler from 'core/helpers/toast/toast';
import useTranslate from 'core/utils/useTranslate';
import { useCallback, useState, useEffect } from 'react';

const useCopyToClipboard = () => {
	const [copyContent, setCopyContent] = useState('');
	const { t } = useTranslate();

	const copyToClipboard = (str: string) => {
		const el = document.createElement('textarea');
		el.value = str;
		el.setAttribute('readonly', '');
		el.style.position = 'absolute';
		el.style.left = '-9999px';
		document.body.appendChild(el);
		const selected = document?.getSelection?.()?.rangeCount as number > 0 ? document?.getSelection?.()?.getRangeAt(0) : false;
		el.select();
		const success = document.execCommand('copy');
		document.body.removeChild(el);
		if (selected) {
			document?.getSelection?.()?.removeAllRanges();
			document?.getSelection?.()?.addRange(selected);
		}
		return success;
	};

	const [copied, setCopied] = useState(false);

	const copy = useCallback(
		(text: string) => {
			setCopied(copyToClipboard(text));
		},
		[copyContent, copied]
	);

	useEffect(() => () => setCopied(false), [copyContent]);

	const copyHandler = (text: string, toastMessage = 'copy-success') => {
		setCopyContent(text);

		copy(text);

		toastHandler('success', t(toastMessage));
	};

	return { copyHandler };
};
export default useCopyToClipboard;
