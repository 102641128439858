import DgCard from 'styles/UI/Card/DgCard';
import { FaArrowAltCircleDown, FaArrowAltCircleUp } from 'react-icons/fa';
import DgButton from 'styles/UI/Button/DgButton';
import walletIcon from 'assets/images/Wallet-amico.svg';
import Text from 'core/utils/Text';
import DgSpinnerFull from 'styles/UI/Spinner/DgSpinnerFull';
import useUserEarningWallet from '../hook/useUserEarningWallet';
import { mainFormatNumber } from 'core/utils/utils';
import { BiTransfer } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import { routes } from 'core/constants/routes';

const UserEarningWalletHeader = () => {
	const { earningWallet, userEarningWalletIsFetching } = useUserEarningWallet();
	const navigate = useNavigate();

	return (
		<div className='w-full'>
			<div className='grid grid-cols-1'>
				<div className=''>
					<DgCard
						className='bg-dg-info-color dark:bg-dg-card-bg-3 px-6 py-5 !m-0'
						isMarginBottom={false}
					>
						<DgSpinnerFull isLoading={userEarningWalletIsFetching}>
							<div className='flex lg:flex-row flex-col-reverse flex-wrap justify-center lg:justify-between items-center gap-7'>
								<div className='rtl:text-right ltr:text-left sm:self-center self-start xs:w-auto w-full'>
									<div className='flex flex-col gap-3'>
										<Text
											tid={'earning-wallet'}
											className='font-bold text-lg text-dg-white-color'
										/>
										<span className='ltr text-xl text-dg-white-color'>
											{mainFormatNumber(earningWallet?.data?.balance || 0)}{' '}
											<span className='dark:text-dg-primary-color-light-hover-2 text-dg-primary-color-light'>
												USDT
											</span>
										</span>

										<div className='gap-4 flex xs:flex-row flex-col'>
											<DgButton
												responsive={false}
												onClick={() => navigate(`${routes.WALLETS_WITHDRAW}?type=1`)}
												type='default'
												className='text-white hover:bg-dg-wallet-button-hover hover:border-dg-wallet-button-hover bg-dg-wallet-button border-dg-wallet-button'
												customTitle={
													<div className='flex items-center gap-x-2 justify-around'>
														<FaArrowAltCircleDown
															size={20}
															className='relative bottom-0.5 text-dg-red-color'
														/>
														<Text tid='withdraw' />
													</div>
												}
											/>
										</div>
									</div>
								</div>

								<div className='flex flex-grow lg:justify-end justify-center lg:w-auto w-full gap-x-12 items-center'>
									<img src={walletIcon} width={150} />
								</div>
							</div>

							<div className='grid lg-1300:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 mt-7 sm:gap-y-3 gap-y-5 grid-flow-row gap-x-7'>
								<div className='flex ltr:text-left rtl:text-right flex-col'>
									<Text
										className='dark:text-dg-primary-color-light-hover-2 text-dg-primary-color-light'
										tid='total_received'
									/>
									<div className='text-dg-white-color relative top-[2px]'>
										{' '}
										{mainFormatNumber(earningWallet?.data?.total_received || 0)}
									</div>
								</div>

								<div className='flex ltr:text-left rtl:text-right flex-col'>
									<Text
										className='dark:text-dg-primary-color-light-hover-2 text-dg-primary-color-light'
										tid='total_spent'
									/>
									<div className='text-dg-white-color relative top-[2px]'>
										{mainFormatNumber(earningWallet?.data?.total_spent || 0)}
									</div>
								</div>

								<div className='flex ltr:text-left rtl:text-right flex-col'>
									<Text
										className='dark:text-dg-primary-color-light-hover-2 text-dg-primary-color-light'
										tid='transactions_count'
									/>
									<div className='text-dg-white-color relative top-[2px]'>
										{' '}
										{mainFormatNumber(earningWallet?.data?.transactions_count || 0)}
									</div>
								</div>

								<div className='flex ltr:text-left rtl:text-right flex-col'>
									<Text
										className='dark:text-dg-primary-color-light-hover-2 text-dg-primary-color-light'
										tid='transactions_received_count'
									/>
									<div className='text-dg-white-color relative top-[2px]'>
										{' '}
										{mainFormatNumber(
											earningWallet?.data?.transactions_received_count || 0
										)}
									</div>
								</div>

								<div className='flex ltr:text-left rtl:text-right flex-col'>
									<Text
										className='dark:text-dg-primary-color-light-hover-2 text-dg-primary-color-light'
										tid='transfers_count'
									/>
									<div className='text-dg-white-color relative top-[2px]'>
										{' '}
										{mainFormatNumber(earningWallet?.data?.transfers_count || 0)}
									</div>
								</div>

								<div className='flex ltr:text-left rtl:text-right flex-col'>
									<Text
										className='dark:text-dg-primary-color-light-hover-2 text-dg-primary-color-light'
										tid='total_transfer'
									/>
									<div className='text-dg-white-color relative top-[2px]'>
										{' '}
										{mainFormatNumber(earningWallet?.data?.total_transfer || 0)}
									</div>
								</div>

								<div className='flex ltr:text-left rtl:text-right flex-col'>
									<Text
										className='dark:text-dg-primary-color-light-hover-2 text-dg-primary-color-light'
										tid='transactions_spent_count'
									/>
									<div className='text-dg-white-color relative top-[2px]'>
										{' '}
										{mainFormatNumber(earningWallet?.data?.transactions_spent_count || 0)}
									</div>
								</div>
							</div>
						</DgSpinnerFull>
					</DgCard>
				</div>
			</div>
		</div>
	);
};

export default UserEarningWalletHeader;
