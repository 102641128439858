import { ITransactionMutationResponse } from 'core/types/models/transferPreviewType';
import Text from 'core/utils/Text';
import React, { FC } from 'react';
import DgButton from 'styles/UI/Button/DgButton';
import DgImage from 'styles/UI/Image/DgImage';
import DgPrice from 'styles/UI/Price/DgPrice';

interface IUserDepositWalletTransferPreview {
	response: ITransactionMutationResponse;
	onCancel: () => void;
	google2faShowModal: () => void;
}

const UserDepositWalletTransferPreview: FC<IUserDepositWalletTransferPreview> = ({ response, onCancel, google2faShowModal }) => {
	const {
		data: {
			data: { receiver_full_name, received_amount, receiver_member_id, transfer_fee },
		},
	} = response;

	return (
		<>
			<div className='flex items-center justify-center'>
				<DgImage src={require('assets/images/invoice.png')} width={300} />
			</div>
			<div className='grid grid-cols-1 gap-5 mt-5'>
				<div className='grid grid-cols-2'>
					<span>
						<Text tid='destination' />
						<span className='mx-2'>:</span>
					</span>
					<span>{receiver_full_name}</span>
				</div>

				<div className='grid grid-cols-2'>
					<span>
						<Text tid='to_member_id' />
						<span className='mx-2'>:</span>
					</span>
					<span>{receiver_member_id}</span>
				</div>

				<div className='grid grid-cols-2'>
					<span>
						<Text tid='amount' />
						<span className='mx-2'>:</span>
					</span>
					<DgPrice price={received_amount} />
				</div>

				<div className='grid grid-cols-2'>
					<span>
						<Text tid='network-fee' />
						<span className='mx-2'>:</span>
					</span>
					<DgPrice price={transfer_fee} />
				</div>

				<div className='grid grid-cols-2'>
					<span>
						<Text tid='deduction' />
						<span className='mx-2'>:</span>
					</span>
					<DgPrice price={received_amount + transfer_fee} />
				</div>

				<hr className='border-[var(--dg-border-color)]' />

				<div className='flex items-center w-full gap-5'>
					<DgButton className='w-full' title='cancel' htmlType='button' type='default' onClick={onCancel} />
					<DgButton className='w-full' title='confirm' htmlType='submit' onClick={google2faShowModal} />
				</div>
			</div>
		</>
	);
};

export default UserDepositWalletTransferPreview;
