import ProfileUserActivity from 'components/Profile/components/ProfileUserActivity/ProfileUserActivity';
import Text from 'core/utils/Text';
import { FC } from 'react';

const ProfileActivityPage: FC = () => {
	return (
		<div>
			<h2><Text tid='activity-page' /></h2>

			<ProfileUserActivity />
		</div>
	);
};

export default ProfileActivityPage;
