import { FC } from 'react';
import DgCard from 'styles/UI/Card/DgCard';
import DgCardHeader from 'styles/UI/CardHeader/DgCardHeader';
import UserChargeDepositWalletTransfer from './components/Transfer/UserChargeDepositWalletTransfer';

const UserChargeWallets: FC = () => {
	return (
		<DgCard>
			<DgCardHeader title='change-wallet-balance' />
			<UserChargeDepositWalletTransfer />
		</DgCard>
	);
};

export default UserChargeWallets;
