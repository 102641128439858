import DgSelectCountry from 'components/Basic/DgSelectCountry';
import { getCurrentUserInfoQuery } from 'core/hooks/react-query/getCurrentUserInfoQuery';
import useTranslate from 'core/utils/useTranslate';
import React, { FC } from 'react';
import DgButton from 'styles/UI/Button/DgButton';
import DgDatePickerLabel from 'styles/UI/DatePicker/DgDatePickerLabel';
import DgForm from 'styles/UI/Form/DgForm';
import DgFormItem from 'styles/UI/FormItem/DgFormItem';
import DgFormWrapper from 'styles/UI/FormWrapper/DgFormWrapper';
import DgInputLabel from 'styles/UI/Input/DgInputLabel';
import DgRadioButton from 'styles/UI/RadioButton/DgRadioButton';
import DgRadioButtonGroup from 'styles/UI/RadioButton/DgRadioButtonGroup';
import DgSpinnerFull from 'styles/UI/Spinner/DgSpinnerFull';
import DgTextAreaLabel from 'styles/UI/TextArea/DgTextAreaLabel';
import useProfile from '../hooks/useProfile';

interface IProfileUpdate {
	isPersonalUserUpdate: boolean;
	modalDetail: string;
}

const ProfileUpdate: FC<IProfileUpdate> = ({ isPersonalUserUpdate, modalDetail }) => {
	const {
		userUpdatePersonalForm,
		onFinishUpdateContactUser,
		onFinishUpdatePersonalUser,
		genderOnChangeHandler,
		userUpdateContactForm,
		isLoadingUpdatePersonalUser,
		isLoadingUpdateContactUser,
	} = useProfile();
	const { isFetching } = getCurrentUserInfoQuery();
	const { t } = useTranslate();

	return (
		<DgSpinnerFull isLoading={isFetching}>
			<DgForm form={isPersonalUserUpdate ? userUpdatePersonalForm : userUpdateContactForm} onFinish={isPersonalUserUpdate ? onFinishUpdatePersonalUser : onFinishUpdateContactUser}>
				{isPersonalUserUpdate && (
					<DgFormWrapper singleColumn>
						{modalDetail === 'firstName' && (
							<DgFormItem
								rules={[
									{
										required: true,
										message: t('first-name-is-required'),
									},
								]}
								name='first_name'
							>
								<DgInputLabel label={t('first-name')} required />
							</DgFormItem>
						)}

						{modalDetail === 'lastName' && (
							<DgFormItem
								rules={[
									{
										required: true,
										message: t('last-name-is-required'),
									},
								]}
								name='last_name'
							>
								<DgInputLabel label={t('last-name')} required />
							</DgFormItem>
						)}

						{modalDetail === 'birthday' && (
							<DgFormItem name='birthday'>
								<DgDatePickerLabel label='date-picker' />
							</DgFormItem>
						)}

						{modalDetail === 'gender' && (
							<DgFormItem name='gender'>
								<DgRadioButtonGroup onChange={genderOnChangeHandler}>
									<DgRadioButton value='Male'>{t('male')}</DgRadioButton>
									<DgRadioButton value='Female'>{t('female')}</DgRadioButton>
								</DgRadioButtonGroup>
							</DgFormItem>
						)}
					</DgFormWrapper>
				)}

				{!isPersonalUserUpdate && (
					<DgFormWrapper singleColumn>
						{modalDetail === 'country' && (
							<DgFormItem name='country_id'>
								<DgSelectCountry label={t('country')} />
							</DgFormItem>
						)}

						{/* <DgFormItem name='state_id'>
						<DgSelectCity disabled={!countrySelectedData.id} label={t('province-id')} />
					</DgFormItem> */}

						{modalDetail === 'phoneNumber' && (
							<DgFormItem name='mobile_number'>
								<DgInputLabel label={t('phone-number')} />
							</DgFormItem>
						)}

						{/* <DgFormItem name='landline_number'>
						<DgInputLabel label={t('landline-number')} />
					</DgFormItem> */}

						{modalDetail === 'postalCode' && (
							<DgFormItem name='zip_code'>
								<DgInputLabel label={t('postal-code')} />
							</DgFormItem>
						)}

						{modalDetail === 'address' && (
							<DgFormItem name='address_line1'>
								<DgTextAreaLabel label={t('address')} />
							</DgFormItem>
						)}
					</DgFormWrapper>
				)}

				<DgFormItem button full>
					<DgButton
						htmlType='button'
						onClick={() => (isPersonalUserUpdate ? userUpdatePersonalForm.submit() : userUpdateContactForm.submit())}
						isLoading={isPersonalUserUpdate ? isLoadingUpdatePersonalUser : isLoadingUpdateContactUser}
						title='submit'
					/>
				</DgFormItem>
			</DgForm>
		</DgSpinnerFull>
	);
};

export default ProfileUpdate;
