import DgCard from 'styles/UI/Card/DgCard';
import DgCardHeader from 'styles/UI/CardHeader/DgCardHeader';
import AdminRolesTable from './components/AdminRolesTable';

const AdminRoleList = () => {
	return (
		<DgCard>
			<DgCardHeader title='roles' />

			<AdminRolesTable />
		</DgCard>
	);
};

export default AdminRoleList;
