import { FC } from 'react';
import DgCard from 'styles/UI/Card/DgCard';
import DgCardHeader from 'styles/UI/CardHeader/DgCardHeader';
import AdminSettingsTable from './components/AdminSettingsTable';

const AdminSettings: FC = () => {
	return (
		<DgCard>
			<DgCardHeader title='settings' />

			<AdminSettingsTable />
		</DgCard>
	);
};

export default AdminSettings;
