import { createSlice } from '@reduxjs/toolkit';
import { IPackage } from 'core/types/models/packageType';

const initialState: { packageDetail: IPackage | null } = { packageDetail: null };

export const packageSlice = createSlice({
	name: 'package',
	initialState: initialState,
	reducers: {
		setPackageDetail: (state, { payload }) => {
			state.packageDetail = payload;
		},
	},
});

export const { setPackageDetail } = packageSlice.actions;
export default packageSlice.reducer;
