import { useWindowSize } from 'core/hooks/useWindowSize';
import Text from 'core/utils/Text';
import DgTableColumnResponsive from 'styles/UI/TableColumnResponsive/DgTableColumnResponsive';
import { ISetting } from 'core/types/models/settingsType';
import DgEditIcon from 'styles/UI/EditIcon/DgEditIcon';
import useTranslate from 'core/utils/useTranslate';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../core/store/index';

const AdminWalletSettingsTableColumn = (onEditSettingsHandler: (val: any) => void) => {
	const { width } = useWindowSize();
	const { t } = useTranslate();
	const { isEnglish } = useSelector((store: RootState) => store.settingStore);

	const Columns = [
		{
			title: <Text tid='title' />,
			key: 'title',
			dataIndex: 'title',
			render: (text: any) => <div className='max-w-[40ch]'>{text}</div>,
			sorter: false,
		},
		{
			title: <Text tid='value' />,
			key: 'value',
			dataIndex: 'value',
			render: (text: any) => text,
			sorter: false,
		},
		// {
		// 	title: <Text tid='category' />,
		// 	key: 'category',
		// 	dataIndex: 'category',
		// 	render: (text: any) => text,
		// 	sorter: false,
		// },
		{
			title: <Text tid='description' />,
			key: 'description',
			dataIndex: 'description',
			render: (text: any) => <div className='max-w-[35ch]'>{text}</div>,
			sorter: false,
		},
		{
			title: '',
			render: (_text: any, record: ISetting) => (
				<DgEditIcon onClick={() => onEditSettingsHandler(record)} />
			),
			sorter: false,
		},
	];

	const RColumn = [
		{
			title: '',
			sorter: false,
			render: (_text: any, record: ISetting) => {
				return (
					<>
						<DgTableColumnResponsive
							title={t('title')}
							value={
								<div className={`max-w-[20ch] ${isEnglish ? 'ml-auto' : 'mr-auto'}`}>
									{record.title}
								</div>
							}
						/>
						<DgTableColumnResponsive title={t('value')} value={record.value} />
						{/* <DgTableColumnResponsive title={t('category')} value={record.category} /> */}
						<DgTableColumnResponsive
							title={t('description')}
							value={
								<div className={`max-w-[20ch] ${isEnglish ? 'ml-auto' : 'mr-auto'}`}>
									{record.description}
								</div>
							}
						/>
						<DgTableColumnResponsive
							title={t('operation')}
							value={
								<DgEditIcon
									className='flex justify-end'
									onClick={() => onEditSettingsHandler(record)}
								/>
							}
						/>
					</>
				);
			},
		},
	];

	return width > 769 ? Columns : RColumn;
};

export default AdminWalletSettingsTableColumn;
