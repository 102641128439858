import { CLOSE_SIDEBAR_SIZE } from 'core/constants/common';
import { useBasket } from 'core/hooks/basket';
import { useWindowSize } from 'core/hooks/useWindowSize';
import { MdShoppingBasket } from 'react-icons/md';
import { Flex } from 'styles/CommonStyles';
import DgBadge from 'styles/UI/Badge/DgBadge';
import DgDropDown from 'styles/UI/DropDown/DgDropDown';

import { useNotificationSeenQuery } from 'components/Profile/hooks/react-query/useNotificationSeenQuery';
import { RootState } from 'core/store';
import Text from 'core/utils/Text';
import { useDispatch, useSelector } from 'react-redux';
import DgEmpty from 'styles/UI/Empty/DgEmpty';
import DgSpinnerFull from 'styles/UI/Spinner/DgSpinnerFull';
import DgPrice from 'styles/UI/Price/DgPrice';
import DgButton from 'styles/UI/Button/DgButton';
import { routes } from 'core/constants/routes';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import { TbMinus, TbPlus } from 'react-icons/tb';
import { addBasketItem, removeBasketItem } from 'core/store/slice/basket/reducer';

export const Basket = () => {
	const { width } = useWindowSize();
	const { basket, basketCount } = useBasket();

	return (
		<>
			<Flex style={{ position: 'relative' }}>
				<DgDropDown
					menu={{
						items: [
							{
								label: <BasketDropdown />,
								key: '0',
							},
						],
					}}
					placement='bottomLeft'
				>
					<DgBadge count={basketCount} offset={[5, -5]}>
						<MdShoppingBasket
							color='var(--dg-opposite-color)'
							className='cursor-pointer hover:text-dg-primary-color'
							size={width < CLOSE_SIDEBAR_SIZE ? 20 : 25}
						/>
					</DgBadge>
				</DgDropDown>
			</Flex>
		</>
	);
};

const BasketDropdown = () => {
	const dispatch = useDispatch();

	const { basket, basketCount, basketItems, basketPrice } = useBasket();

	const { mutate: setSeenNotification, isLoading: seenNotificationIsLoading } =
		useNotificationSeenQuery();
	const { notificationList } = useSelector((store: RootState) => store.notificationStore);

	return (
		<div className='px-3 py-5 bg-[var(--dg-card-bg-1)] rounded-lg w-[300px] border border-[var(--dg-border-color)]'>
			<div className='pb-2 border-b flex items-center gap-3 justify-between'>
				<Text className='text-lg' tid='basket' />
				<DgPrice price={basketPrice}></DgPrice>
			</div>

			{basketItems?.length === 0 && <DgEmpty />}

			<div className='max-h-[500px] overflow-y-auto'>
				<DgSpinnerFull isLoading={false}>
					{basketItems.map((item, index) => (
						<div
							key={item.id}
							className='border-b border-[var(--dg-border-color)] last:border-b-0 py-4 px-1 gap-5 flex items-center justify-between'
						>
							<span>
								{index + 1}. {item.product.title}
							</span>
							<span className='flex items-center gap-1'>
								<DgPrice price={item.quantity * item.product.offPrice}></DgPrice>

								<div dir='rtl' className='flex items-center justify-end gap-1'>
									<Button
										size='small'
										className='flex items-center justify-center'
										icon={<TbPlus />}
										onClick={() => dispatch(addBasketItem(item.id))}
										disabled={item.quantity >= item.product.qty}
									/>

									{item.quantity}

									<Button
										size='small'
										className='flex items-center justify-center'
										icon={<TbMinus />}
										onClick={() => dispatch(removeBasketItem(item.id))}
										// disabled={record.quantity <= 1}
									/>
								</div>
							</span>
						</div>
					))}
				</DgSpinnerFull>
			</div>

			{!!basketItems?.length && (
				<Link to={routes.BUY_PRODUCT()}>
					<DgButton className='w-full' title='order'></DgButton>
				</Link>
			)}
		</div>
	);
};
