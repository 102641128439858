import DgCard from 'styles/UI/Card/DgCard';
import DgCardHeader from 'styles/UI/CardHeader/DgCardHeader';
import UserTransactionFilter from './components/UserTransactionFilter';
import UserTransactionTable from './components/UserTransactionTable';

const UserTransaction = () => {
	return (
		<DgCard>
			<DgCardHeader title='transactions-history' />

			<UserTransactionFilter />
			<UserTransactionTable />
		</DgCard>
	);
};

export default UserTransaction;
