import { FC } from 'react';
import TransactionPassword from './components/TransactionPassword';
import Text from 'core/utils/Text';
import ChangePassword from './components/ChangePassword';
import ActiveGoogle2FA from './components/ActiveGoogle2FA';
import PreviewLog from './components/PreviewLog';

const ProfileSecurity: FC = () => {
	return (
		<div className='grid gap-y-8'>
			<h2>
				<Text tid='profile-security' />
			</h2>

			<TransactionPassword />
			<ChangePassword />
			<ActiveGoogle2FA />
			<PreviewLog />
		</div>
	);
};

export default ProfileSecurity;
