import Text from 'core/utils/Text';
import { FC, useState } from 'react';
import DgCard from 'styles/UI/Card/DgCard';
import DgCardHeader from 'styles/UI/CardHeader/DgCardHeader';
import UserActivityTable from './components/UserActivityTable';
import UserBlockHistoryTable from './components/UserBlockHistoryTable';
import UserEmailVerificationHistoryTable from './components/UserEmailVerificationHistoryTable';
import UserLoginHistoryTable from './components/UserLoginHistoryTable';
import UserPasswordHistoryTable from './components/UserPasswordHistoryTable';

const UserHistory: FC = () => {
	const tabs = [
		{ key: 'user-activity', label: <Text tid='user-activity' />, children: <UserActivityTable /> },
		{ key: 'email-verification-history', label: <Text tid='email-verification-history' />, children: <UserEmailVerificationHistoryTable /> },
		{ key: 'login-history', label: <Text tid='login-history' />, children: <UserLoginHistoryTable /> },
		{ key: 'block-history', label: <Text tid='block-history' />, children: <UserBlockHistoryTable /> },
		{ key: 'password-history', label: <Text tid='password-history' />, children: <UserPasswordHistoryTable /> },
	];
	const [activeTab, setActiveTab] = useState('user-activity');

	return (
		<DgCard isMarginBottom={false}>
			<DgCardHeader title={activeTab} tabClassName='user-activity' tabOptions={{ tabs, activeTab, onChange: setActiveTab }} />
		</DgCard>
	);
};

export default UserHistory;
