import { useAdminCreatePackage } from '../../Update/hooks/react-query/useAdminCreatePackage';
import DgForm from '../../../../../styles/UI/Form/DgForm';
import DgFormWrapper from '../../../../../styles/UI/FormWrapper/DgFormWrapper';
import React, { useState } from 'react';
import DgFormItem from '../../../../../styles/UI/FormItem/DgFormItem';
import DgInputLabel from '../../../../../styles/UI/Input/DgInputLabel';
import DgButton from '../../../../../styles/UI/Button/DgButton';
import { Form } from 'antd';
import useTranslate from '../../../../../core/utils/useTranslate';
import Text from '../../../../../core/utils/Text';
import DgImage from '../../../../../styles/UI/Image/DgImage';
import usdt from '../../../../../assets/images/usdt.png';
import { HintBox } from 'components/hint-box';
import { PackageCommissions } from '../../package-commissions';
import { useAdminPackageCommissions } from '../../Update/hooks/react-query/useAdminPackageCommissions';

const CreatePackageModal = ({ hideCreateModal }) => {
	const [form] = Form.useForm();
	const { t } = useTranslate();

	const [category, setCategory] = useState(1);

	const {
		mutate: createPackage,
		mutateAsync: createPackageAsync,
		isLoading: creating,
	} = useAdminCreatePackage(() => {});

	const packageCommissionsMutation = useAdminPackageCommissions();

	const [commissions, setCommissions] = useState([]);

	const onFinish = async () => {
		try {
			const payload = { ...form.getFieldsValue() };
			payload.category_id = category;
			payload.price = parseInt(payload.price);
			payload.roi_percentage = parseInt(payload.roi_percentage);
			payload.direct_percentage = parseInt(payload.direct_percentage);
			payload.validity_in_days = parseInt(payload.validity_in_days);

			delete payload.direct_percentage_1;
			delete payload.direct_percentage_2;
			delete payload.direct_percentage_3;

			const { data } = await createPackageAsync(payload);

			const commissionsPromises = [];

			if (commissions?.length) {
				commissions.forEach((item, index) => {
					commissionsPromises.push(
						async () =>
							await packageCommissionsMutation
								.mutateAsync({
									package_id: data?.data?.id,
									level: index + 1,
									...item,
								})
								.catch((e) => {
									// console.log({ e });
								})
					);
				});
			}
			// // console.log({ payload, data, commissions });

			await Promise.all(commissionsPromises.map((item) => item()));

			hideCreateModal();
		} catch (e) {
			// console.log({ e });
		}
	};

	// // console.log({ commissions });

	return (
		<>
			<div className={'flex justify-around fw items-center pb-8'}>
				<div
					className={`rounded-md ${
						category === 1 ? 'bg-[#58a0f8] text-black' : ''
					} p-3 h-[36px] flex justify-center items-center cursor-pointer`}
					onClick={() => setCategory(1)}
				>
					<Text tid={'normal-package'} />
				</div>
				<div
					className={`rounded-md ${
						category === 3 ? 'bg-[#ffc800] text-black' : ''
					} p-3 h-[36px]  flex justify-center items-center cursor-pointer`}
					onClick={() => setCategory(3)}
				>
					<Text tid={'gold-package'} />
				</div>
			</div>
			<DgForm shouldUpdate={true} autoComplete='off' onFinish={onFinish} form={form}>
				<DgFormWrapper singleColumn>
					<DgFormItem
						name='name'
						rules={[
							{
								required: true,
								message: t('name-is-required'),
							},
						]}
					>
						<DgInputLabel label='name' />
					</DgFormItem>

					<DgFormItem
						name='short_name'
						rules={[
							{
								required: true,
								message: t('short-name-is-required'),
							},
						]}
					>
						<DgInputLabel label='short_name' />
					</DgFormItem>

					<DgFormItem
						rules={[
							{
								required: true,
								message: t('amount-is-required'),
							},
						]}
						noMargin
						name='price'
					>
						<DgInputLabel
							suffix={
								<DgImage
									src={usdt}
									width={22}
									className='rounded-full relative top-1.5'
								/>
							}
							label='amount'
						/>
					</DgFormItem>

					<DgFormItem
						name='validity_in_days'
						rules={[
							{
								required: true,
								message: t('expire-time-is-required'),
							},
						]}
					>
						<DgInputLabel suffix={t('days')} label='expire-time' />
					</DgFormItem>
					<DgFormItem
						name='roi_percentage'
						rules={[
							{
								required: true,
								message: t('roi_percentage-is-required'),
							},
						]}
					>
						<DgInputLabel
							suffix={
								<HintBox
									hintTitle='roi_percentage'
									hintDescription='roi_percentage_info'
								/>
							}
							label='roi_percentage'
						/>
					</DgFormItem>

					<PackageCommissions onChange={(value) => setCommissions(value)} />
				</DgFormWrapper>

				<DgFormItem button>
					<DgButton htmlType='submit' isLoading={creating} title='create-package' />
				</DgFormItem>
			</DgForm>
		</>
	);
};

export default CreatePackageModal;
