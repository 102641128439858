import useQueryUrlParam from 'core/hooks/useQueryUrlParam';
import Text from 'core/utils/Text';
import DgDeleteButton from '../Button/DgDeleteButton';
import DgDropDown from '../DropDown/DgDropDown';
import { useSelector } from 'react-redux';
import { RootState } from 'core/store';
import { FC, memo } from 'react';
import { filterOptionsType } from 'core/types/components/filterType';
import { MenuProps } from 'antd';
import DgFormWrapper from '../FormWrapper/DgFormWrapper';
import { HiOutlineSortAscending, HiOutlineSortDescending } from 'react-icons/hi';
import DgSortItem from './DgSortItem';

interface IDgSortResult {
	filterOptions: filterOptionsType[];
}

const DgSortResult: FC<IDgSortResult> = ({ filterOptions }) => {
	const { isEnglish } = useSelector((store: RootState) => store.settingStore);
	const { deleteSort, query } = useQueryUrlParam();

	const depositSteps: { id: number; title: 'ASC' | 'DESC'; Icon: any }[] = [
		{ id: 0, title: 'ASC', Icon: <HiOutlineSortAscending size={20} /> },
		{ id: 1, title: 'DESC', Icon: <HiOutlineSortDescending size={20} /> },
	];

	const items: MenuProps['items'] = [
		{
			label: (
				<div className='bg-dg-filter-body-bg p-2 pt-5 rounded-lg text-dg-primary-color border border-dg-opposite-primary-color shadow-[0px_0px_2px] shadow-dg-opposite-primary-color'>
					<DgFormWrapper singleColumn>
						{filterOptions.map((filterOption) =>
							filterOption?.sortNames?.map((sortName) => {
								return (
									<div key={sortName} className='flex items-center justify-between gap-x-10'>
										<Text tid={sortName} />

										<div className='grid grid-flow-col gap-3 grid-cols-2 overflow-x-auto'>
											{depositSteps.map((item) => (
												<DgSortItem Icon={item.Icon} title={item.title} key={item.id} sortName={sortName} />
											))}
										</div>
									</div>
								);
							})
						)}
					</DgFormWrapper>
				</div>
			),
			key: '0',
		},
	];

	return (
		<div className='flex flex-wrap gap-5 items-start'>
			{(query?.sort && query?.sort_way ? true : false) ? (
				<div className='flex bg-dg-green-color text-dg-opposite-primary-color px-3 py-2 rounded-lg cursor-pointer'>
					<DgDropDown align={{ offset: [!isEnglish ? 10 : -10, 10] }} menu={{ items }}>
						<div className='flex items-center gap-2'>
							<Text tid={query?.sort || '--'} />
							{query?.sort_way === 'ASC' ? <HiOutlineSortAscending size={20} /> : <HiOutlineSortDescending size={20} />}
						</div>
					</DgDropDown>

					<div className='flex flex-col space-y-3'>
						<DgDeleteButton onClick={deleteSort} />
					</div>
				</div>
			) : null}
		</div>
	);
};

export default memo(DgSortResult);
