import DgCard from 'styles/UI/Card/DgCard';
import { FaArrowAltCircleUp, FaArrowAltCircleDown } from 'react-icons/fa';
import DgButton from 'styles/UI/Button/DgButton';
import walletIcon from 'assets/images/Wallet-amico.svg';
import Text from 'core/utils/Text';
import useUserWallet from 'components/UserWallets/hook/useUserWallet';
import DgSpinnerFull from 'styles/UI/Spinner/DgSpinnerFull';
import { BiTransfer } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import { routes } from 'core/constants/routes';
import DgPrice from 'styles/UI/Price/DgPrice';
import { AiOutlineTransaction } from 'react-icons/ai';

const UserWalletHeader = ({ showActions = true }) => {
	const { deposit_Wallet, userWalletIsFetching } = useUserWallet();
	const navigate = useNavigate();

	return (
		<div className='w-full'>
			<div className='grid grid-cols-1'>
				<div className=''>
					<DgCard
						className='bg-dg-info-color dark:bg-dg-card-bg-3 px-6 py-5 !m-0'
						isMarginBottom={false}
					>
						<DgSpinnerFull isLoading={userWalletIsFetching}>
							<div className='flex lg:flex-row flex-col-reverse flex-wrap justify-center lg:justify-between items-center gap-7'>
								<div className='rtl:text-right ltr:text-left sm:self-center self-start xs:w-auto w-full'>
									<div className='flex flex-col gap-3'>
										<Text
											tid={'my-wallet'}
											className='font-bold text-lg text-dg-white-color'
										/>
										<span className='ltr text-xl flex gap-5 items-center text-dg-white-color'>
											<Text
												tid='my-balance'
												className='text-base text-[var(--dg-primary-color)]'
											/>
											<DgPrice price={deposit_Wallet?.balance} />
										</span>

										{showActions && (
											<div className='gap-4 flex xs:flex-row flex-col flex-wrap'>
												<DgButton
													responsive={false}
													onClick={() => navigate(routes.WALLETS_DEPOSIT)}
													type='default'
													className='text-white hover:bg-dg-wallet-button-hover hover:border-dg-wallet-button-hover bg-dg-wallet-button border-dg-wallet-button'
													customTitle={
														<div className='flex items-center justify-center gap-x-2'>
															<FaArrowAltCircleDown
																size={20}
																className='relative bottom-0.5 text-dg-green-color'
															/>
															<Text tid='deposit' />
														</div>
													}
												/>

												<DgButton
													responsive={false}
													onClick={() => navigate(routes.WALLETS_WITHDRAW)}
													type='default'
													className='text-white hover:bg-dg-wallet-button-hover hover:border-dg-wallet-button-hover bg-dg-wallet-button border-dg-wallet-button'
													customTitle={
														<div className='flex items-center justify-center gap-x-2'>
															<FaArrowAltCircleUp
																size={20}
																className='relative bottom-0.5 text-dg-red-color'
															/>
															<Text tid='withdraw' />
														</div>
													}
												/>

												{/* <DgButton
													responsive={false}
													type='default'
													className='text-white hover:bg-dg-wallet-button-hover hover:border-dg-wallet-button-hover bg-dg-wallet-button border-dg-wallet-button'
													customTitle={
														<div className='flex items-center justify-center gap-x-2'>
															<BiTransfer
																size={20}
																className='relative bottom-0.5 text-[var(--dg-primary-color)]'
															/>
															<Text tid='inter-user-transfer' />
														</div>
													}
													onClick={() => navigate(routes.WALLETS_DEPOSIT_TRANSFER)}
												/> */}

												{/* <DgButton
													responsive={false}
													type='default'
													className='text-white hover:bg-dg-wallet-button-hover hover:border-dg-wallet-button-hover bg-dg-wallet-button border-dg-wallet-button'
													customTitle={
														<div className='flex items-center justify-center gap-x-2'>
															<AiOutlineTransaction
																size={20}
																className='relative bottom-0.5 text-[var(--dg-info-color)]'
															/>
															<Text tid='transactions-history' />
														</div>
													}
													onClick={() => navigate(routes.TRANSACTION)}
												/> */}
											</div>
										)}
									</div>
								</div>

								<div className='flex flex-grow lg:justify-end justify-center lg:w-auto w-full gap-x-12 items-center'>
									<img src={walletIcon} width={150} />
								</div>
							</div>

							{/* <div className='grid lg-1300:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 mt-7 sm:gap-y-3 gap-y-5 grid-flow-row gap-x-7'>
								<div className='flex ltr:text-left rtl:text-right flex-col'>
									<Text className='dark:text-dg-primary-color-light-hover-2 text-dg-primary-color-light' tid='total_received' />
									<div className='text-dg-white-color relative top-[2px]'>
										<DgPrice price={deposit_Wallet?.total_received} />
									</div>
								</div>

								<div className='flex ltr:text-left rtl:text-right flex-col'>
									<Text className='dark:text-dg-primary-color-light-hover-2 text-dg-primary-color-light' tid='total_spent' />
									<div className='text-dg-white-color relative top-[2px]'>
										<DgPrice price={deposit_Wallet?.total_spent} />
									</div>
								</div>

								<div className='flex ltr:text-left rtl:text-right flex-col'>
									<Text className='dark:text-dg-primary-color-light-hover-2 text-dg-primary-color-light' tid='transactions_count' />
									<div className='text-dg-white-color relative top-[2px]'>
										<DgPrice price={deposit_Wallet?.transactions_count} suffix='' />
									</div>
								</div>

								<div className='flex ltr:text-left rtl:text-right flex-col'>
									<Text className='dark:text-dg-primary-color-light-hover-2 text-dg-primary-color-light' tid='transactions_received_count' />
									<div className='text-dg-white-color relative top-[2px]'>
										<DgPrice price={deposit_Wallet?.transactions_received_count} suffix='' />
									</div>
								</div>

								<div className='flex ltr:text-left rtl:text-right flex-col'>
									<Text className='dark:text-dg-primary-color-light-hover-2 text-dg-primary-color-light' tid='transfers_count' />
									<div className='text-dg-white-color relative top-[2px]'>
										<DgPrice price={deposit_Wallet?.transfers_count} suffix='' />
									</div>
								</div>

								<div className='flex ltr:text-left rtl:text-right flex-col'>
									<Text className='dark:text-dg-primary-color-light-hover-2 text-dg-primary-color-light' tid='total_transfer' />
									<div className='text-dg-white-color relative top-[2px]'>
										<DgPrice price={deposit_Wallet?.total_transfer} />
									</div>
								</div>

								<div className='flex ltr:text-left rtl:text-right flex-col'>
									<Text className='dark:text-dg-primary-color-light-hover-2 text-dg-primary-color-light' tid='transactions_spent_count' />
									<div className='text-dg-white-color relative top-[2px]'>
										<DgPrice price={deposit_Wallet?.transactions_spent_count} suffix='' />
									</div>
								</div>
							</div> */}
						</DgSpinnerFull>
					</DgCard>
				</div>
			</div>
		</div>
	);
};

export default UserWalletHeader;
