import { UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { endPointUrls } from 'core/constants/endPointUrls';
import api from 'core/services/fetch-api/useApi';
import { useDispatch } from 'react-redux';
import useMutationHook from '../masterQuery/useMutationHook';
import toastHandler from 'core/helpers/toast/toast';
import { setApiCatcherFormErrors } from 'core/store/slice/mainInfo/mainInfoSlice';
import { AxiosResponse } from 'axios';
import { useProductsList } from './products';

export const useAddressCreation = () => {
	const dispatch = useDispatch();
	const queryClient = useQueryClient();

	return useMutationHook(
		[endPointUrls.USER_ADDRESS_CREATION],
		(data: any) => api.post(endPointUrls.USER_ADDRESS_CREATION, data),
		{
			onSuccess: (res) => {
				const response = res as AxiosResponse;

				if (response?.status === 200) {
					toastHandler('success', response?.data.message);
					queryClient.invalidateQueries([endPointUrls.PROFILE_MANAGEMENT]);

					dispatch(setApiCatcherFormErrors([]));
				}
			},
		}
	) as UseMutationResult;
};

export const useAddressRemoval = () => {
	const dispatch = useDispatch();
	const queryClient = useQueryClient();

	// const productsQuery = useProductsList();

	return useMutationHook(
		[endPointUrls.USER_ADDRESS_DELETE],
		(data: any) => api.post(endPointUrls.USER_ADDRESS_DELETE, data, { data }),
		{
			onSuccess: (res) => {
				const response = res as AxiosResponse;

				if (response?.status === 200) {
					toastHandler('success', response?.data.message);
					queryClient.invalidateQueries([endPointUrls.PROFILE_MANAGEMENT]);

					dispatch(setApiCatcherFormErrors([]));
					// productsQuery.refetch();
				}
			},
		}
	) as UseMutationResult;
};
