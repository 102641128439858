import { Popconfirm, PopconfirmProps } from 'antd';
import { FC, ReactNode } from 'react';

interface IDgPopConfirm extends PopconfirmProps {
	children: ReactNode;
}

const DgPopConfirm: FC<IDgPopConfirm> = ({ children, ...props }) => {
	return <Popconfirm {...props}>{children}</Popconfirm>;
};

export default DgPopConfirm;
