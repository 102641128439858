import useWalletDepositTransfer from 'components/UserWallets/hook/useWalletDepositTransfer';
import useTranslate from 'core/utils/useTranslate';
import { FC } from 'react';
import DgButton from 'styles/UI/Button/DgButton';
import DgForm from 'styles/UI/Form/DgForm';
import DgFormItem from 'styles/UI/FormItem/DgFormItem';
import DgFormWrapper from 'styles/UI/FormWrapper/DgFormWrapper';
import DgInputLabel from 'styles/UI/Input/DgInputLabel';
import DgInputPasswordLabel from 'styles/UI/Input/DgInputPasswordLabel';
import DgModal from 'styles/UI/Modal/DgModal';
import UserDepositWalletTransferPreview from './UserDepositWalletTranferPreview';

const UserDepositWalletTransferForm: FC = () => {
	const {
		hideModal,
		modal,
		onFinishWalletDepositTransfer,
		google2faForm,
		google2faModal,
		google2faHideModal,
		onFinishGoogle2fa,
		walletDepositTransferFinalIsLoading,
		walletDepositTransferForm,
		walletDepositTransferPreviewIsLoading,
		walletDepositTransferPreviewResponse,
		google2faShowModal,
	} = useWalletDepositTransfer();
	const { t } = useTranslate();

	// // console.log(t('referral-code-is-required'));

	return (
		<>
			<DgModal open={modal} title='inter-user-transfer-preview' closable={false}>
				{walletDepositTransferPreviewResponse && (
					<UserDepositWalletTransferPreview
						google2faShowModal={google2faShowModal}
						onCancel={hideModal}
						response={walletDepositTransferPreviewResponse}
					/>
				)}
			</DgModal>

			<DgModal
				title='totp-verification-code'
				open={google2faModal}
				onCancel={google2faHideModal}
			>
				<DgForm form={google2faForm} onFinish={onFinishGoogle2fa}>
					<DgFormWrapper singleColumn>
						<DgFormItem
							name='one_time_password'
							rules={[
								{ required: true, message: t('totp-verification-code-is-required') },
							]}
						>
							<DgInputLabel label='totp-verification-code' required />
						</DgFormItem>
						<DgButton title='submit' isLoading={walletDepositTransferFinalIsLoading} />
					</DgFormWrapper>
				</DgForm>
			</DgModal>

			<div className='max-w-2xl w-full'>
				<DgForm
					form={walletDepositTransferForm}
					onFinish={onFinishWalletDepositTransfer}
					autoComplete='off'
				>
					<DgFormWrapper className='lg-1200:grid-cols-2 md:grid-cols-1'>
						<DgFormItem
							name='member_id'
							rules={[
								{
									required: true,
									message: t('referral-code-is-required'),
								},
							]}
						>
							<DgInputLabel label='referral-code' required />
						</DgFormItem>

						<DgFormItem
							name='amount'
							rules={[
								{
									required: true,
									message: t('amount-is-required'),
								},
							]}
						>
							<DgInputLabel label='amount' required />
						</DgFormItem>

						<DgFormItem
							full
							name='transaction_password'
							rules={[
								{
									required: true,
									message: t('transaction-password-is-required'),
								},
							]}
						>
							<DgInputPasswordLabel
								label='transaction-password'
								autoComplete='new-password'
								required
							/>
						</DgFormItem>

						<DgFormItem button>
							<DgButton
								title='preview-transfer'
								htmlType='submit'
								isLoading={walletDepositTransferPreviewIsLoading}
							/>
						</DgFormItem>
					</DgFormWrapper>
				</DgForm>
			</div>
		</>
	);
};

export default UserDepositWalletTransferForm;
