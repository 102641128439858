import { Form } from 'antd';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'core/store';
import { useAdminProductUpdateQuery } from './react-query/useAdminProductUpdateQuery';
import { setHiddenModal } from 'core/store/slice/modal/modalSlice';
import { useImageUpload } from 'core/hooks/react-query/images';

const useAdminProductUpdate = (hideModalHandler: () => void) => {
	//hooks
	const dispatch = useDispatch();
	const [updateProductForm] = Form.useForm();

	// store
	const { apiCatcherFormErrors } = useSelector((store: RootState) => store.mainInfoStore);
	const { productDetail } = useSelector((store: RootState) => store.productStore);

	const imageMutation = useImageUpload();

	// query
	const { mutate: updateProduct, isLoading: isLoadingUpdateProduct } =
		useAdminProductUpdateQuery(() => {
			dispatch(setHiddenModal());
		});

	const onFinishUpdateProduct = async (values: any, file) => {
		delete values.direct_percentage_1;
		delete values.direct_percentage_2;
		delete values.direct_percentage_3;

		if (file) {
			const image: any = await imageMutation
				.mutateAsync({ 'files[]': file })
				.catch((e) => {
					console.log({ e });
				});

			if (image?.data?.data?.length) {
				values.files_id = [`${image.data.data[0].id}`];
				// payload.images = [`${image.data.data[0].id}`];
			}
		}

		updateProduct(
			{ ...values, id: productDetail?.id, price: values?.price + '' },
			{ onSuccess: () => hideModalHandler() }
		);
	};

	useEffect(() => {
		updateProductForm.setFields(apiCatcherFormErrors);
	}, [apiCatcherFormErrors]);

	useEffect(() => {
		updateProductForm.setFieldsValue({ ...productDetail });
	}, [productDetail]);

	return {
		updateProductForm,
		isLoadingUpdateProduct,
		onFinishUpdateProduct,
	};
};

export default useAdminProductUpdate;
