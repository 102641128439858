import { FC, useState } from 'react';
import UserWalletHeader from './components/UserWalletHeader';
import UserInvoice from 'components/UserInvoice/UserInvoice';
import Text from 'core/utils/Text';
import UserEarningWallet from 'components/UserEarningWallet/UserEarningWallet';
import DgTab from 'styles/UI/Tab/DgTab';
import UserWithdrawTable from 'components/UserWithdrawsList/UserWithdrawTable';
import UserTransactionTable from 'components/UserTransAction/components/UserTransactionTable';
import { HintBox } from 'components/hint-box';
// import UserEarnWallets from './components/UserEarnWallets';

const UserWallets: FC = () => {
	const tabs = [
		{
			key: 'withdraw-list',
			label: (
				<>
					<Text tid='withdraw-list' />
					<HintBox hintTitle='withdraw-list' hintDescription='withdraw-list-info' />
				</>
			),
			children: (
				<>
					<UserWithdrawTable />
				</>
			),
		},
		{
			key: 'transactions-history',
			label: (
				<>
					<Text tid='transactions-history' />
					<HintBox
						hintTitle='transactions-history'
						hintDescription='transactions-history-info'
					/>
				</>
			),
			children: (
				<>
					<UserTransactionTable />
				</>
			),
		},
		{
			key: 'invoice-history',
			label: (
				<>
					<Text tid='invoice-history' />
					<HintBox hintTitle='invoice-history' hintDescription='invoice-history-info' />
				</>
			),
			children: (
				<>
					<UserInvoice />
				</>
			),
		},
	];
	const [activeTab, setActiveTab] = useState(tabs[0].key);

	return (
		<>
			<div className='grid gap-5'>
				<UserWalletHeader />
				{/* <UserEarnWallets /> */}

				<DgTab
					// tabPosition='left'
					{...{ tabs, activeTab, onChange: setActiveTab }}
				/>
			</div>
		</>
	);
};

export default UserWallets;
