import React, { FC } from 'react';
import DgTooltip from '../Tooltip/DgTooltip';
import { CgDetailsMore } from 'react-icons/cg';
import { Link } from 'react-router-dom';

interface IDgDetailIcon {
	to?: string | number;
	onClick?: () => void;
	className?: string;
}

const DgDetailIcon: FC<IDgDetailIcon> = ({ onClick, to, className }) => {
	return (
		<DgTooltip title='view-details'>
			{to ? (
				<Link to={to + ''} className={className}>
					<CgDetailsMore size={25} />
				</Link>
			) : (
				<span className={`cursor-pointer ${className}`} onClick={onClick}>
					<CgDetailsMore size={25} />
				</span>
			)}
		</DgTooltip>
	);
};

export default DgDetailIcon;
