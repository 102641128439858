import { FC, ReactNode } from 'react';
import DgDataCard from 'styles/UI/DataCard/DgDataCard';
import DashboardTitle from '../DashboardTitle/DashboardTitle';
import CountsItem from './CountsItem';
import DashboardLinePercent, {
	ICountsData,
} from 'styles/UI/DashboardLinePercent/DashboardLinePercent';

export interface ICountsItemsWrapper {
	role: boolean;
	data: ICountsData[];
	title?: string | ReactNode;
	className?: string;
	wrapperClassName?: string;
	id?: number;
	titleClassName?: string;
	noBar?: boolean;
	loading?: boolean;
	notTrend?: boolean;
}

const CountsItemsWrapper: FC<ICountsItemsWrapper> = ({
	title,
	className,
	wrapperClassName,
	data,
	noBar,
	loading,
	notTrend,
}) => {
	return (
		<DgDataCard loading={loading} className={className}>
			<DashboardTitle>{title}</DashboardTitle>
			<div className={`${wrapperClassName}`}>
				{data &&
					data.map((item: any) => (
						<CountsItem
							percentColor={item?.percentColor}
							titleClassName={item?.titleClassName}
							countClassName={item?.countClassName}
							notTrend={notTrend}
							key={item.title}
							title={item.title}
							count={item.count}
							percent={item.percent}
							percentTitle={item.percentTitle}
							color={item.color}
						/>
					))}
			</div>
			{!noBar && <DashboardLinePercent data={data} />}
		</DgDataCard>
	);
};

export default CountsItemsWrapper;
