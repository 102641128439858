import userUsersActivity from 'components/Admin/UsersActivity/hooks/userUsersActivity';
import ProfileSecurity from 'components/Profile/components/ProfileSecurity/ProfileSecurity';
import ProfileUserActivity from 'components/Profile/components/ProfileUserActivity/ProfileUserActivity';
import { getCurrentUserInfoQuery } from 'core/hooks/react-query/getCurrentUserInfoQuery';
import useQueryUrlParam from 'core/hooks/useQueryUrlParam';
import Text from 'core/utils/Text';
import { FC, useMemo, useState } from 'react';
import DgPagination from 'styles/UI/Pagination/DgPagination';
import DgTable from 'styles/UI/Table/DgTable';
import { useWindowSize } from 'core/hooks/useWindowSize';
import DgTableColumnResponsive from 'styles/UI/TableColumnResponsive/DgTableColumnResponsive';
import { userActivity } from 'core/types/models/userActivityType';
import useTranslate from 'core/utils/useTranslate';
import { Link } from 'react-router-dom';
import { routes } from 'core/constants/routes';
import DgButton from 'styles/UI/Button/DgButton';
import useModal from 'core/hooks/useModal';
import DgModal from 'styles/UI/Modal/DgModal';
import DgForm from 'styles/UI/Form/DgForm';
import { Form } from 'antd';
import DgFormWrapper from 'styles/UI/FormWrapper/DgFormWrapper';
import DgFormItem from 'styles/UI/FormItem/DgFormItem';
import DgInputLabel from 'styles/UI/Input/DgInputLabel';
import DgTextAreaLabel from 'styles/UI/TextArea/DgTextAreaLabel';
import { useAddressCreation, useAddressRemoval } from 'core/hooks/react-query/addresses';
import DgEditIcon from 'styles/UI/EditIcon/DgEditIcon';
import DgDeleteIcon from 'styles/UI/Icon/DgDeleteIcon';
import { DotLoader } from 'react-spinners';

const ProfileAddressesPage: FC = () => {
	const { t } = useTranslate();

	const deleteModal = useModal();

	const [address, setAddress]: any = useState({});

	const removalMutation = useAddressRemoval();

	const {
		data: currentUserInfo,
		isFetching,
		refetch: refetchProfile,
	} = getCurrentUserInfoQuery();
	const { mutateAsync: create } = useAddressCreation();
	const [form] = Form.useForm();

	const { hideModal, showModal, modal } = useModal();

	const addresses = useMemo(() => {
		if (currentUserInfo?.data?.addresses?.length) {
			return currentUserInfo?.data?.addresses;
		}

		return [];
	}, [currentUserInfo]);

	console.log({ currentUserInfo, addresses });

	return (
		<>
			<DgModal open={modal} onCancel={hideModal} title='new-address'>
				<DgForm
					form={form}
					onFinish={async (values) => {
						await create(values);

						refetchProfile();
					}}
				>
					<DgFormWrapper singleColumn>
						<DgFormItem
							name='name'
							rules={[
								{
									required: true,
									message: t('name-is-required'),
								},
							]}
						>
							<DgInputLabel label='name' required />
						</DgFormItem>
						<DgFormItem
							name='mobile'
							rules={[
								{
									required: true,
									message: t('mobile-is-required'),
								},
							]}
						>
							<DgInputLabel label='mobile' required />
						</DgFormItem>
						<DgFormItem
							name='country'
							rules={[
								{
									required: true,
									message: t('country-is-required'),
								},
							]}
						>
							<DgInputLabel label='country' required />
						</DgFormItem>
						<DgFormItem
							name='province'
							rules={[
								{
									required: true,
									message: t('province-is-required'),
								},
							]}
						>
							<DgInputLabel label='province' required />
						</DgFormItem>
						<DgFormItem
							name='city'
							rules={[
								{
									required: true,
									message: t('city-is-required'),
								},
							]}
						>
							<DgInputLabel label='city' required />
						</DgFormItem>
						<DgFormItem
							name='postal_code'
							rules={[
								{
									required: true,
									message: t('postal_code-is-required'),
								},
							]}
						>
							<DgInputLabel label='postal_code' required />
						</DgFormItem>
						<DgFormItem
							rules={[
								{
									required: true,
									message: t('address-is-required'),
								},
							]}
							name='address'
						>
							<DgTextAreaLabel label='address' />
						</DgFormItem>

						<DgButton title='submit' isLoading={false} />
					</DgFormWrapper>
				</DgForm>
			</DgModal>

			<DgModal
				title={'delete-address'}
				open={deleteModal.modal}
				onCancel={deleteModal.hideModal}
			>
				<div>
					<Text tid={'delete-address-desc'} />
				</div>
				<div className={'w-full flex justify-around items-center mt-5'}>
					<div
						className={
							'w-[120px] h-[42px] bg-green-700 rounded flex justify-center items-center cursor-pointer'
						}
						onClick={async () => {
							try {
								await removalMutation.mutateAsync({ address_id: address?.id });

								deleteModal.hideModal();
							} catch (error) {
								console.log(error);
							}
						}}
					>
						{removalMutation.isLoading ? (
							<DotLoader size={16} color={'white'} />
						) : (
							<Text tid={'submitt'} />
						)}
					</div>

					<div
						className={
							'w-[120px] h-[42px] bg-gray-600 rounded flex justify-center items-center cursor-pointer'
						}
						onClick={deleteModal.hideModal}
					>
						<Text tid={'cancel'} />
					</div>
				</div>
			</DgModal>

			<div className='flex flex-col gap-2'>
				<div className='flex justify-between items-center flex-wrap'>
					<h2>
						<Text tid='addresses' />
					</h2>

					<DgButton
						title={'new-address'}
						onClick={() => {
							showModal();
						}}
					/>
				</div>

				<DgTable
					rowKey='id'
					columns={ProfileAddressesTableColumn({
						deleteModal,
						removalMutation,
						address,
						setAddress,
					})}
					dataSource={addresses}
					// count={userActivities?.data.pagination.total}
					loading={isFetching}
					// current={userActivities?.data.pagination.current_page}
					// per_page={query?.per_page}
				/>
				{/* <DgPagination
					onPaginationHandler={handlePagination}
					responsive={true}
					per_page={query?.per_page}
					current={userActivities?.data.pagination.current_page}
					total={userActivities?.data.pagination.total}
				/> */}
			</div>
		</>
	);
};

const ProfileAddressesTableColumn = ({
	deleteModal,
	removalMutation,
	address,
	setAddress,
}: any) => {
	const { width } = useWindowSize();
	const { t } = useTranslate();
	const {
		hideModal: hideDeleteModal,
		modal: modal,
		showModal: showDeleteModal,
	} = deleteModal;

	const { mutateAsync: remove, isLoading: isRemoving } = removalMutation;

	const Columns = [
		{
			title: <Text tid='name' />,
			key: 'name',
			dataIndex: 'name',
			render: (text: any) => text,
			sorter: false,
		},
		{
			title: <Text tid='mobile' />,
			key: 'mobile',
			dataIndex: 'mobile',
			render: (text: any) => text,
			sorter: false,
		},
		{
			title: <Text tid='country' />,
			key: 'country',
			dataIndex: 'country',
			render: (text: any) => text,
			sorter: false,
		},
		{
			title: <Text tid='province' />,
			key: 'province',
			dataIndex: 'province',
			render: (text: any) => text,
			sorter: false,
		},
		{
			title: <Text tid='city' />,
			key: 'city',
			dataIndex: 'city',
			render: (text: any) => text,
			sorter: false,
		},
		{
			title: <Text tid='address' />,
			key: 'address',
			dataIndex: 'address',
			render: (text: any) => text,
			sorter: false,
		},
		{
			title: <Text tid='postal_code' />,
			key: 'postal_code',
			dataIndex: 'postal_code',
			render: (text: any) => text,
			sorter: false,
		},
		{
			title: '',
			render: (_text: any, record: any) => (
				<>
					<div className={'flex fw justify-end items-center'}>
						<DgDeleteIcon
							onClick={() => {
								setAddress(record);
								showDeleteModal();
							}}
						/>
					</div>
				</>
			),
			sorter: false,
		},
	];

	const RColumn = [
		{
			title: '',
			sorter: false,
			render: (_text: any, record: any) => {
				return (
					<>
						<DgTableColumnResponsive title={t('name')} value={record?.name} />
						<DgTableColumnResponsive title={t('mobile')} value={record?.mobile} />
						<DgTableColumnResponsive title={t('country')} value={record?.country} />
						<DgTableColumnResponsive title={t('province')} value={record?.province} />
						<DgTableColumnResponsive title={t('city')} value={record?.city} />
						<DgTableColumnResponsive
							title={t('postal_code')}
							value={record?.postal_code}
						/>
						<DgTableColumnResponsive title={t('address')} value={record?.address} />

						<DgTableColumnResponsive
							title={t('operation')}
							value={
								<>
									<DgDeleteIcon
										onClick={() => {
											setAddress(record);
											showDeleteModal();
										}}
									/>
								</>
							}
						/>
					</>
				);
			},
		},
	];

	return width > 769 ? Columns : RColumn;
};

export default ProfileAddressesPage;
