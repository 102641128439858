import { FC, ReactNode } from 'react';
import ReduxProvider from './redux/ReduxProvider';
import ReactQueryProviders from './react-query/ReactQueryProviders';
import ToastProvider from './toast/ToastProvider';
import AppProvider from './app/AppProvider';

interface IIndexProviders {
	children: ReactNode;
}

const IndexProviders: FC<IIndexProviders> = ({ children }) => {
	return (
		<ReduxProvider>
			<ToastProvider />
			<ReactQueryProviders>
				<AppProvider>{children}</AppProvider>
			</ReactQueryProviders>
		</ReduxProvider>
	);
};

export default IndexProviders;
