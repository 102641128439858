import { useWindowSize } from 'core/hooks/useWindowSize';
import Text from 'core/utils/Text';
import DgTableColumnResponsive from 'styles/UI/TableColumnResponsive/DgTableColumnResponsive';
import DgPrice from 'styles/UI/Price/DgPrice';
import DgTimeStamp from 'styles/UI/Timstamp/DgTimeStamp';
import { hiddenText } from 'core/utils/utils';
import DgCopyClipboard from 'styles/UI/CopyClipboard/DgCopyClipboard';
import { ITransaction } from 'core/types/models/transactionType';
import useTranslate from 'core/utils/useTranslate';

const UserTransactionTableColumn = () => {
	const { width } = useWindowSize();
	const { t } = useTranslate();

	const Columns = [
		{
			title: <Text tid='transaction-id' />,
			key: 'id',
			dataIndex: 'id',
			render: (text: any) => {
				const { value, hiddenValue } = hiddenText(text);
				return <DgCopyClipboard hiddenValue={hiddenValue} value={value} />;
			},
			sorter: false,
		},
		{
			title: <Text tid='amount' />,
			key: 'amount',
			dataIndex: 'amount',
			render: (text: any) => <DgPrice price={text} colored />,
		},
		{
			title: <Text tid='current-balance' />,
			key: 'after_balance',
			dataIndex: 'after_balance',
			render: (text: any) => <DgPrice price={text} />,
			sorter: false,
		},
		{
			title: <Text tid='type' />,
			key: 'type',
			dataIndex: 'type',
			render: (text: any) => text,
			sorter: false,
		},
		{
			title: <Text tid='description' />,
			key: 'description',
			dataIndex: 'description',
			render: (text: any) => text,
			sorter: false,
		},
		{
			title: <Text tid='date-time' />,
			key: 'created_at',
			dataIndex: 'created_at',
			render: (text: any) => <DgTimeStamp timeStamp={text} />,
		},
	];

	const RColumn = [
		{
			title: '',
			sorter: false,
			render: (_text: any, record: ITransaction) => {
				const { value, hiddenValue } = hiddenText(record.id);

				return (
					<>
						<DgTableColumnResponsive title={t('transaction-id')} value={<DgCopyClipboard hiddenValue={hiddenValue} value={value} />} />
						<DgTableColumnResponsive title={t('amount')} value={<DgPrice price={record.amount} colored />} />
						<DgTableColumnResponsive title={t('current-balance')} value={<DgPrice price={record.after_balance} />} />
						<DgTableColumnResponsive title={t('type')} value={record.type} />
						<DgTableColumnResponsive title={t('description')} value={record.description} />
						<DgTableColumnResponsive title={t('date-time')} value={<DgTimeStamp nowrap={false} timeStamp={record.created_at} />} />
					</>
				);
			},
		},
	];

	return width > 769 ? Columns : RColumn;
};

export default UserTransactionTableColumn;
