import emptyIcon from 'assets/images/noData.png';
import { FC } from 'react';

interface IDgEmpty {
	className?: string;
	width?: number;
	text?: string;
}

const DgEmpty: FC<IDgEmpty> = ({ className, width, text = '', ...props }) => {
	return (
		<div className={`my-5 ${className}`} {...props}>
			<div>
				<img className='m-auto' width={width} src={emptyIcon} />
			</div>
			{text && <p className='my-2 text-center'>{text}</p>}
		</div>
	);
};

export default DgEmpty;
