import useTranslate from 'core/utils/useTranslate';
import React, { FC, ReactNode } from 'react';

interface IProfileInfoItem {
	title: string;
	icon: ReactNode;
	value?: string | ReactNode | null;
	onClick?: () => void;
	disabled?: boolean;
}

const ProfileInfoItem: FC<IProfileInfoItem> = ({ title, value, onClick, icon, disabled = false }) => {
	const { t } = useTranslate();
	return (
		<div
			className={`group ${
				disabled ? 'cursor-auto' : 'cursor-pointer'
			} cursor-pointer relative flex items-center md:py-6 md:px-5 py-4 px-3 last:border-b-0 border-b border-b-[var(--dg-border-color)]`}
			onClick={onClick}
		>
			<div className='flex items-start lg-1300:items-center lg-1300:flex-row flex-col w-full break-all'>
				<span className='lg-1300:w-1/2 w-full text-base text-[var(--dg-disabled-color-light)]'>{t(title)}</span>
				<span className='text-[var(--dg-primary-color)] lg-1300:mt-0 mt-1 break-all'>{value || t('not-add-yet')}</span>
			</div>
			<div className='md:max-w-[200px] md:text-right md:justify-end md:flex md:items-center ml-auto flex-grow-0'>
				<span
					className={`${disabled ? '' : 'group-hover:bg-[var(--dg-card-bg-2)]'} flex p-2 group-hover:before:opacity-100 rounded-full transition-colors duration-300 before:opacity-0`}
				>
					{icon}
				</span>
			</div>
		</div>
	);
};

export default ProfileInfoItem;
