import useSelected from 'core/hooks/useSelected';
import { FC, memo } from 'react';
import { twMerge } from 'tailwind-merge';

interface IDgSortItem {
	title: 'ASC' | 'DESC';
	sortName: string;
	Icon?: any;
}

const DgSortItem: FC<IDgSortItem> = ({ title, sortName, Icon }) => {
	const { selectedSort, selectedSortHandler } = useSelected(undefined, false, true, true);

	return (
		<div
			onClick={() => selectedSortHandler(sortName, title)}
			className={twMerge(
				`border border-[var(--dg-primary-color)] whitespace-nowrap justify-center px-2 h-[35px] gap-x-1 flex items-center text-white text-center rounded-lg cursor-pointer hover:shadow-xl transition-all ${
					selectedSort.sort === sortName && selectedSort.sort_way === title ? 'border border-[var(--dg-orange-color)] bg-[var(--dg-card-bg-3)]' : ''
				}`
			)}
		>
			{title}
			{Icon ? Icon : null}
		</div>
	);
};

export default memo(DgSortItem);
