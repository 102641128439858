import Text from 'core/utils/Text';
import React from 'react';
import DgDevider from 'styles/UI/Devider/DgDevider';
import DgPopConfirm from 'styles/UI/PopConfirm/DgPopConfirm';
import DgShowInfo from 'styles/UI/ShowInfo/DgShowInfo';
import DgSwitch from 'styles/UI/Switch/DgSwitch';
import DgTooltip from 'styles/UI/Tooltip/DgTooltip';
import useUserVerifyEmail from '../hooks/useUserVerifyEmail';
import useTranslate from 'core/utils/useTranslate';

const UserEmailVerifyStatus = () => {
	const { verifyEmail, onConfirm, verifyEmailIsLoading } = useUserVerifyEmail();
	const { t } = useTranslate();

	return (
		<>
			<DgDevider orientation='center' title='email-verify-user' />

			<DgShowInfo
				title={t('email-verify-user')}
				value={
					(verifyEmail && !verifyEmailIsLoading) ? (
						<span className='text-[var(--dg-green-color)]'>{t('email-verified')}</span>
					) : (
						<DgTooltip title='verify-email'>
							<DgPopConfirm onConfirm={onConfirm} title={<Text tid='verify-email' />}>
								<DgSwitch checked={verifyEmail} loading={verifyEmailIsLoading} />
							</DgPopConfirm>
						</DgTooltip>
					)
				}
			/>
		</>
	);
};

export default UserEmailVerifyStatus;
