import { FC, memo } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { routes } from 'core/constants/routes';
import { useSelector } from 'react-redux';
import { RootState } from 'core/store';

interface IPrivateRoute {
	role?: boolean;
}

const PrivateRoute: FC<IPrivateRoute> = ({ role }) => {
	const { isAuthenticated } = useSelector((store: RootState) => store.mainInfoStore);

	if (isAuthenticated && !!role) return <Outlet />;
	else return <Navigate to={routes.DASHBOARD} />;
};

export default PrivateRoute;
