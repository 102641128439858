import { UseMutationResult } from '@tanstack/react-query';
import { endPointUrls } from 'core/constants/endPointUrls';
import toastHandler from 'core/helpers/toast/toast';
import useMutationHook from 'core/hooks/masterQuery/useMutationHook';
import api from 'core/services/fetch-api/useApi';

export const useResendEmailVerificationQuery = () => {
	return useMutationHook(
		[endPointUrls.GET_EMAIL_VERIFY_TOKEN],
		(data: any) => api.post(endPointUrls.GET_EMAIL_VERIFY_TOKEN, data),
		{
			onSuccess: (res: any) => {
				if (res?.status === 200) {
					toastHandler('success', res?.data.message);
				}
			},
		}
	) as UseMutationResult;
};
