import DgCard from 'styles/UI/Card/DgCard';
import DgCardHeader from 'styles/UI/CardHeader/DgCardHeader';
import AdminPlanCommissionsTable from './components/AdminPlanCommissionsTable';
import AdminPlansCommissionFilter from './components/AdminPlansCommissionFilter';

const AdminPlanCommissions = () => {
	return (
		<DgCard>
			<DgCardHeader title='profits' />

			<AdminPlansCommissionFilter />
			<AdminPlanCommissionsTable />
		</DgCard>
	);
};

export default AdminPlanCommissions;
