import useTranslate from 'core/utils/useTranslate';
import React from 'react';
import { MdLockOutline } from 'react-icons/md';
import DgModal from 'styles/UI/Modal/DgModal';
import ProfileInfoItem from './components/ProfileInfoItem';
import ProfileUpdate from './components/ProfileUpdate';
import useProfile from './hooks/useProfile';
import Text from 'core/utils/Text';
import { BiEditAlt } from 'react-icons/bi';

const Profile = () => {
	const {
		userInfo,
		showModalContactModal,
		showModalPersonalModal,
		isPersonalModal,
		hideModal,
		modal,
		modalDetail,
	} = useProfile();
	const { t } = useTranslate();

	return (
		<>
			<DgModal
				title={isPersonalModal ? 'update-personal-info' : 'update-contact-info'}
				open={modal}
				onCancel={hideModal}
			>
				<ProfileUpdate modalDetail={modalDetail} isPersonalUserUpdate={isPersonalModal} />
			</DgModal>

			<div className='flex-grow w-full'>
				<div className='relative sm:pb-10 pb-6'>
					<div className='flex justify-between items-center flex-wrap'>
						<h2>
							<Text tid='profile-detail' />
						</h2>
						<div className=' align-self-start d-lg-none'>
							<a
								href='#'
								className='toggle btn btn-icon btn-trigger mt-n1'
								data-target='userAside'
							>
								<em className='icon ni ni-menu-alt-r'></em>
							</a>
						</div>
					</div>
				</div>

				<div>
					<div>
						<div className='bg-[var(--dg-card-bg-2)] py-2 px-5 mb-1 rounded-lg'>
							<h6 className='mb-0 text-base text-[var(--dg-primary-color)]'>
								{t('personal')}
							</h6>
						</div>

						<ProfileInfoItem
							disabled
							icon={<MdLockOutline size={20} />}
							title='referral-code'
							value={`${userInfo?.data.sponsor || '--'}  ( ${
								userInfo?.data.sponsor_id || '--'
							} )`}
						/>
						<ProfileInfoItem
							onClick={() => showModalPersonalModal('firstName')}
							icon={<BiEditAlt size={20} />}
							title='first-name'
							value={userInfo?.data.first_name}
						/>
						<ProfileInfoItem
							onClick={() => showModalPersonalModal('lastName')}
							icon={<BiEditAlt size={20} />}
							title='last-name'
							value={userInfo?.data.last_name}
						/>
						<ProfileInfoItem
							disabled
							icon={<MdLockOutline size={20} />}
							title='username'
							value={userInfo?.data.username}
						/>
						<ProfileInfoItem
							disabled
							icon={<MdLockOutline size={20} />}
							title='email'
							value={userInfo?.data.email}
						/>
						<ProfileInfoItem
							onClick={() => showModalPersonalModal('gender')}
							icon={<BiEditAlt size={20} />}
							title='gender'
							value={userInfo?.data.gender}
						/>
					</div>

					<div className='mt-7'>
						<div className='bg-[var(--dg-card-bg-2)] py-2 px-5 mb-1 rounded-lg'>
							<h6 className='mb-0 text-base text-[var(--dg-primary-color)]'>
								{t('additional-information')}
							</h6>
						</div>

						<ProfileInfoItem
							onClick={() => showModalPersonalModal('birthday')}
							icon={<BiEditAlt size={20} />}
							title='birthday'
							value={userInfo?.data.birthday}
						/>
						<ProfileInfoItem
							onClick={() => showModalContactModal('phoneNumber')}
							icon={<BiEditAlt size={20} />}
							title='phone-number'
							value={
								<div className='flex flex-col'>
									<span>{userInfo?.data.mobile_number || t('not-add-yet')}</span>
									{/* <span>{userInfo?.data.landline_number || t('not-add-yet')}</span> */}
								</div>
							}
						/>
						{/* <ProfileInfoItem
							onClick={() => showModalContactModal('country')}
							icon={<BiEditAlt size={20} />}
							title='country'
							value={
								<div className='flex flex-col'>
									<span>{userInfo?.data.country || t('not-add-yet')}</span>
								</div>
							}
						/>

						<ProfileInfoItem
							onClick={() => showModalContactModal('address')}
							icon={<BiEditAlt size={20} />}
							title='address'
							value={
								<div className='whitespace-pre-line xs:w-[25ch] w-[16ch]'>
									{userInfo?.data.address_line1 || t('not-add-yet')}
								</div>
							}
						/>

						<ProfileInfoItem
							onClick={() => showModalContactModal('postalCode')}
							icon={<BiEditAlt size={20} />}
							title='postal-code'
							value={userInfo?.data.zip_code}
						/> */}
					</div>
				</div>
			</div>
		</>
	);
};

export default Profile;
