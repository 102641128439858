import { userDetailPage } from 'core/types/global';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { routes } from '../../../../../core/constants/routes';
import useQueryUrlParam from 'core/hooks/useQueryUrlParam';

const useAdminUserDetail = () => {
	const { page, userId } = useParams();
	const { pathnameLastPart } = useQueryUrlParam();
	const navigate = useNavigate();
	const [activeTab, setActiveTab] = useState(page || 'user-detail');

	const onChangeTabHandler = (key: string) => {
		setActiveTab(key);
		navigate(
			routes.ADMIN_USER_Detail_PAGE.link(
				userId,
				key === userId ? undefined : (key as userDetailPage)
			)
		);
	};

	useEffect(() => {
		setActiveTab(pathnameLastPart + '');
	}, [pathnameLastPart]);

	return { onChangeTabHandler, activeTab };
};

export default useAdminUserDetail;
