import Text from 'core/utils/Text';
import useTranslate from 'core/utils/useTranslate';
import { FC } from 'react';
import DgButton from 'styles/UI/Button/DgButton';
import DgPopConfirm from 'styles/UI/PopConfirm/DgPopConfirm';
import useDisable2FA from '../hooks/useUserDisable2FA';

const AdminUserDisable2FA: FC = () => {
	const { onConfirm, resetPasswordIsFetching } = useDisable2FA();
	const { t } = useTranslate();

	return (
		<>
			<div className='py-5'>
				<div className='flex justify-between items-center flex-wrap'>
					<Text tid='totp-verification' />

					<DgPopConfirm
						title={t('are-you-sure-to-disable-totp-verification')}
						onConfirm={onConfirm}
					>
						<DgButton
							htmlType='button'
							isLoading={resetPasswordIsFetching}
							title='disable-totp-verification'
						/>
					</DgPopConfirm>
				</div>
			</div>
		</>
	);
};

export default AdminUserDisable2FA;
