import useTranslate from 'core/utils/useTranslate';
import { FC, useMemo, useState } from 'react';
import BasketTable from './BasketTable';
import DgSelectLabel from 'styles/UI/Select/DgSelectLabel';
import DgPopConfirm from 'styles/UI/PopConfirm/DgPopConfirm';
import Google2faAlert from 'components/HeaderAlert/components/Google2faAlert';
import DgFormItem from 'styles/UI/FormItem/DgFormItem';
import DgInputLabel from 'styles/UI/Input/DgInputLabel';
import DgButton from 'styles/UI/Button/DgButton';
import { useProductDetails } from 'core/hooks/react-query/products';
import { useOrderSubmitQuery } from 'components/GoldOrderSubmit/hooks/react-query/useOrderSubmitQuery';
import { getCurrentUserInfoQuery } from 'core/hooks/react-query/getCurrentUserInfoQuery';
import { useBasket } from 'core/hooks/basket';
import DgCard from 'styles/UI/Card/DgCard';
import DgCardHeader from 'styles/UI/CardHeader/DgCardHeader';
import DgPrice from 'styles/UI/Price/DgPrice';
import { Divider, Form } from 'antd';
import DgModal from 'styles/UI/Modal/DgModal';
import DgForm from 'styles/UI/Form/DgForm';
import DgFormWrapper from 'styles/UI/FormWrapper/DgFormWrapper';
import DgTextAreaLabel from 'styles/UI/TextArea/DgTextAreaLabel';
import { useAddressCreation } from 'core/hooks/react-query/addresses';
import useModal from 'core/hooks/useModal';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'core/store';
import DgAlert from 'styles/UI/Alert/DgAlert';
import { AiFillCloseCircle } from 'react-icons/ai';
import Text from 'core/utils/Text';
import { useNavigate } from 'react-router';
import { routes } from 'core/constants/routes';
import { clearBasket } from 'core/store/slice/basket/reducer';

const BuyProductPage: FC = () => {
	const dispatch = useDispatch();
	const { userWallet } = useSelector((store: RootState) => store.walletStore);
	const navigate = useNavigate();
	const { t } = useTranslate();
	const { basketItems, basketPrice } = useBasket();

	const orderQuery = useOrderSubmitQuery();

	const {
		data: currentUserInfo,
		isFetching,
		refetch: refetchProfile,
	} = getCurrentUserInfoQuery();
	const { mutateAsync: create } = useAddressCreation();
	const [form] = Form.useForm();

	const { hideModal, showModal, modal } = useModal();

	const [otp, setOtp] = useState(null);
	const [selectedAddress, setSelectedAddress] = useState(null);

	const addresses = useMemo(() => {
		if (currentUserInfo?.data?.addresses?.length) {
			return currentUserInfo?.data?.addresses;
		}

		return [];
	}, [currentUserInfo]);

	const options = addresses?.map((item: any) => ({
		label: item.address + ' - ' + item.name,
		value: item.id,
		// filter: item.name,
	}));

	const purchase = async () => {
		try {
			await orderQuery.mutateAsync({
				items_id: basketItems.map((item) => item.product.id),
				items_qty: basketItems.map((item) => item.quantity),
				address_id: selectedAddress,
				one_time_password: otp,
				// price: 10000000000000000,
				// package_id: 0,
			});

			dispatch(clearBasket());

			navigate(routes.ORDERS_HISTORY + '?order=success');

			// purchaseModal.hideModal();
		} catch (error) {
			console.log({ error });
		}
	};

	console.log({ userWallet });

	return (
		<>
			<DgModal open={modal} onCancel={hideModal} title='new-address'>
				<DgForm
					form={form}
					onFinish={async (values) => {
						await create(values);

						refetchProfile();
					}}
				>
					<DgFormWrapper singleColumn>
						<DgFormItem
							name='name'
							rules={[
								{
									required: true,
									message: t('name-is-required'),
								},
							]}
						>
							<DgInputLabel label='name' required />
						</DgFormItem>
						<DgFormItem
							name='mobile'
							rules={[
								{
									required: true,
									message: t('mobile-is-required'),
								},
							]}
						>
							<DgInputLabel label='mobile' required />
						</DgFormItem>
						<DgFormItem
							name='country'
							rules={[
								{
									required: true,
									message: t('country-is-required'),
								},
							]}
						>
							<DgInputLabel label='country' required />
						</DgFormItem>
						<DgFormItem
							name='province'
							rules={[
								{
									required: true,
									message: t('province-is-required'),
								},
							]}
						>
							<DgInputLabel label='province' required />
						</DgFormItem>
						<DgFormItem
							name='city'
							rules={[
								{
									required: true,
									message: t('city-is-required'),
								},
							]}
						>
							<DgInputLabel label='city' required />
						</DgFormItem>
						<DgFormItem
							name='postal_code'
							rules={[
								{
									required: true,
									message: t('postal_code-is-required'),
								},
							]}
						>
							<DgInputLabel label='postal_code' required />
						</DgFormItem>
						<DgFormItem
							rules={[
								{
									required: true,
									message: t('address-is-required'),
								},
							]}
							name='address'
						>
							<DgTextAreaLabel label='address' />
						</DgFormItem>

						<DgButton title='submit' isLoading={false} />
					</DgFormWrapper>
				</DgForm>
			</DgModal>

			<DgCard isMarginBottom={false} className='col-span-12'>
				<DgCardHeader
					title='checkout'
					customButton={
						<>
							<DgPrice price={basketPrice} />
						</>
					}
					// buttonText='package-purchase'
					// onClick={() => navigate(routes.ORDER_SUBMIT_STEPS.link(1))}
				/>
				<BasketTable />

				<DgButton href={routes.PRODUCTS} title='add-product' />

				<Divider />

				{basketPrice > (userWallet?.balance as number) ? (
					<DgAlert
						className='bg-[var(--dg-red-color-2)] border-0 lg-600:flex-row flex-col flex-wrap gap-12'
						message={
							<p className='flex gap-x-3 m-0 h-10 items-center'>
								<span>
									<AiFillCloseCircle size={20} />
								</span>
								<Text
									tid='please-charge-your-account'
									className='text-base md:whitespace-nowrap'
								/>
							</p>
						}
						action={
							<DgButton
								title='wallets'
								onClick={() => navigate(routes.WALLETS_DEPOSIT)}
							/>
						}
					/>
				) : (
					<>
						<div className='flex flex-col gap-4 max-w-lg'>
							<div className='flex items-center justify-between gap-4'>
								<DgSelectLabel
									onChange={(value) => {
										// console.log({ value });
										setSelectedAddress(value);
									}}
									value={selectedAddress}
									label='address'
									options={options}
									loading={isFetching}
								/>

								<DgButton
									className='min-w-[12rem]'
									title={'new-address'}
									onClick={() => {
										showModal();
									}}
								/>
							</div>
							{!isFetching && !currentUserInfo?.data?.google2fa_enable ? (
								<Google2faAlert />
							) : (
								<DgFormItem
									name='one_time_password'
									rules={[
										{
											required: true,
											message: t('totp-verification-code-is-required'),
										},
									]}
								>
									<DgInputLabel
										className='min-w-[22rem]'
										label='totp-verification-code'
										required
										onChange={(e) => {
											setOtp(e.target.value);
										}}
									/>
								</DgFormItem>
							)}

							<DgPopConfirm
								zIndex={9999999999999}
								title={t('are-you-sure?')}
								onConfirm={() => purchase()}
								disabled={!selectedAddress}
								okText={!currentUserInfo?.data?.google2fa_enable ? null : t('confirm')}
								// destroyTooltipOnHide={true}
								// placement='top'
								// autoAdjustOverflow={false}
							>
								<DgButton
									loading={orderQuery.isLoading}
									title='purchase'
									disabled={
										!selectedAddress || !currentUserInfo?.data?.google2fa_enable || !otp
									}
									className='w-full'
								/>
							</DgPopConfirm>
						</div>
					</>
				)}
			</DgCard>
		</>
	);
};

export default BuyProductPage;
