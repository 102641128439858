import { useWindowSize } from '../../../core/hooks/useWindowSize';
import useTranslate from '../../../core/utils/useTranslate';
import Text from '../../../core/utils/Text';
import DgCopyAddress from '../../../styles/UI/WalletAddress/DgCopyAddress';
import DgPrice from '../../../styles/UI/Price/DgPrice';
import DgTag from '../../../styles/UI/Tag/DgTag';
import DgTimeStamp from '../../../styles/UI/Timstamp/DgTimeStamp';
import { FiMoreVertical } from 'react-icons/fi';
import DgDropDown from '../../../styles/UI/DropDown/DgDropDown';
import { useAdminWithdrawAction } from './useAdminWithdrawAction';
import DgTableColumnResponsive from '../../../styles/UI/TableColumnResponsive/DgTableColumnResponsive';
import { Link } from 'react-router-dom';
import { routes } from 'core/constants/routes';
import { ConfirmationPopover } from 'components/confirmation-popover';
import DgTextAreaLabel from 'styles/UI/TextArea/DgTextAreaLabel';
import { useState } from 'react';

const WithdrawTableColumns = (type = 'admin') => {
	const { width } = useWindowSize();
	const { t } = useTranslate();

	const { mutate: withdrawAction } = useAdminWithdrawAction();
	const onAction = (record, type, act_reason) => {
		withdrawAction({
			id: record?.id,
			status: type,
			act_reason,
		});
	};

	const Columns = [
		{
			title: <Text tid='wallet-hash' />,
			key: 'wallet_hash',
			dataIndex: 'wallet_hash',
			render: (text) => {
				return <DgCopyAddress hasTitle={false} hasQrCode={false} address={text} />;
			},
			sorter: false,
		},
		{
			title: <Text tid='full-name' />,
			// key: 'ful-name',
			// dataIndex: 'ful-name',

			render: (text, record) => (
				<Link
					to={routes.ADMIN_USER_Detail.link(record.user?.id)}
					className='flex items-center gap-3 group'
				>
					<Text
						tid={
							`${record?.user_full_name} (${record?.user_member_id})` ||
							record.user?.email ||
							record.user?.username
						}
					/>
				</Link>
			),
		},
		{
			title: <Text tid='amount' />,
			key: 'pf_amount',
			dataIndex: 'pf_amount',
			render: (text) => <DgPrice price={text} />,
			sorter: false,
		},
		{
			title: <Text tid='fee' />,
			key: 'fee',
			dataIndex: 'fee',
			render: (text) => <DgPrice price={text} />,
			sorter: false,
		},
		{
			title: <Text tid='status' />,
			render: (text, record) => {
				return <RecordStatus record={record} />;
			},
			sorter: false,
		},
		{
			title: <Text tid='description' />,
			render: (text, record) => {
				return <>{record.act_reason}</>;
			},
			sorter: false,
		},
		{
			title: <Text tid='user' />,
			render: (text, record) => {
				return (
					<Link
						to={routes.ADMIN_USER_Detail.link(record.user?.id)}
						className='flex items-center gap-3 group'
					>
						<Text
							tid={record?.user_full_name || record.user?.email || record.user?.username}
						/>
					</Link>
				);
			},
			sorter: false,
		},
		// {
		//     title: <Text tid='type' />,
		//     key: 'type',
		//     dataIndex: 'type',
		//     render: (text) => text,
		//     sorter: false,
		// },
		{
			title: <Text tid='date-time' />,
			key: 'created_at',
			dataIndex: 'created_at',
			render: (text) => <DgTimeStamp timeStamp={text} />,
			sorter: false,
		},
	];

	if (type === 'admin') {
		Columns.push({
			title: '',
			sorter: false,
			render: (text, record) => {
				return <Operation record={record} onAction={onAction} />;
			},
		});
	}

	const RColumn = [
		{
			title: '',
			sorter: false,
			render: (text, record) => {
				return (
					<>
						<DgTableColumnResponsive
							title={'wallet-hash'}
							value={
								<DgCopyAddress
									hasTitle={false}
									hasQrCode={false}
									address={record?.wallet_hash}
								/>
							}
						/>

						<DgTableColumnResponsive
							title={t('full-name')}
							value={
								<>
									<Link
										to={routes.ADMIN_USER_Detail.link(record.user?.id)}
										className='flex items-center gap-3 group'
									>
										<Text
											tid={
												`${record?.user_full_name} (${record?.user_member_id})` ||
												record.user?.email ||
												record.user?.username
											}
										/>
									</Link>
								</>
							}
						/>

						<DgTableColumnResponsive
							title={'amount'}
							value={<DgPrice price={record?.pf_amount} />}
						/>
						<DgTableColumnResponsive
							title={'fee'}
							value={<DgPrice price={record?.fee} />}
						/>
						<DgTableColumnResponsive
							title={'status'}
							value={<RecordStatus record={record} />}
						/>
						<DgTableColumnResponsive title={'type'} value={record?.type} />
						<DgTableColumnResponsive
							title={'date-time'}
							value={<DgTimeStamp timeStamp={record?.createdAt} />}
						/>
						{type === 'admin' && (
							<DgTableColumnResponsive
								title={t('operation')}
								value={<Operation record={record} onAction={onAction} />}
							/>
						)}
					</>
				);
			},
		},
	];

	return width > 769 ? Columns : RColumn;
};

const RecordStatus = ({ record }) => {
	return (
		<>
			{record?.status === 'Processed' && (
				<DgTag
					color='var(--dg-green-color)'
					className='text-[var(--dg-primary-color-fixed)]'
				>
					<Text tid='success' />
				</DgTag>
			)}
			{record?.status === 'Under review' && (
				<DgTag color='#ffc800'>
					<Text tid='pending' />
				</DgTag>
			)}
			{record?.status === 'confirming' && (
				<DgTag color='var(--dg-input-bg)'>
					<Text tid='confirm' />
				</DgTag>
			)}
			{record?.status === 'Rejected' && (
				<DgTag color='var(--dg-red-color-2)'>
					<Text tid='rejected' />
				</DgTag>
			)}
			{record?.status === 'Postponed' && (
				<DgTag color='var(--dg-info-color)'>
					<Text tid='postponed' />
				</DgTag>
			)}
			{record?.status === 'partiallyPaid' && (
				<DgTag
					color='var(--dg-primary-color)'
					className='text-[var(--dg-primary-color-fixed)]'
				>
					<Text tid='partially-paid' />
				</DgTag>
			)}
			{record?.status === 'refunded' && (
				<DgTag color='var(--dg-info-color)'>
					<Text tid='refunded' />
				</DgTag>
			)}
		</>
	);
};

const Operation = ({ record, onAction }) => {
	const [description, setDescription] = useState('');

	return (
		<DgDropDown
			trigger={['hover']}
			menu={{
				items: [
					{
						key: '1',
						label: (
							<ConfirmationPopover callback={() => onAction(record, 'accept')}>
								<div className='cursor-pointer py-2 px-5 w-full hover:bg-[var(--dg-card-bg-2)] bg-[var(--dg-card-bg-1)] border-b border-[var(--dg-border-color)] inline-block'>
									<Text tid={'accept'} />
								</div>
							</ConfirmationPopover>
						),
					},
					{
						key: '2',
						label: (
							<ConfirmationPopover
								callback={() => onAction(record, 'reject', description)}
								extra={
									<>
										<DgTextAreaLabel
											value={description}
											onChange={(e) => {
												setDescription(e.target.value);
											}}
											label='description'
										/>
									</>
								}
							>
								<div className='cursor-pointer py-2 px-5 w-full hover:bg-[var(--dg-card-bg-2)] bg-[var(--dg-card-bg-1)] border-b border-[var(--dg-border-color)] inline-block'>
									<Text tid={'reject'} />
								</div>
							</ConfirmationPopover>
						),
					},
				],
			}}
			placement='bottomRight'
		>
			<FiMoreVertical className='cursor-pointer' size={25} />
		</DgDropDown>
	);
};

export default WithdrawTableColumns;
