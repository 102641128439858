import React, { Fragment, useEffect, useMemo, useState } from 'react';
import DgButton from 'styles/UI/Button/DgButton';
import DgForm from 'styles/UI/Form/DgForm';
import DgFormItem from 'styles/UI/FormItem/DgFormItem';
import DgFormWrapper from 'styles/UI/FormWrapper/DgFormWrapper';
import DgInputLabel from 'styles/UI/Input/DgInputLabel';
import useAdminProductUpdate from './hooks/useAdminProductUpdate';
import DgImage from 'styles/UI/Image/DgImage';
import usdt from '../../../../assets/images/usdt.png';
import useTranslate from 'core/utils/useTranslate';
import { HintBox } from 'components/hint-box';
import {
	useAdminProductCommissions,
	useAdminProductCommissionsList,
} from './hooks/react-query/useAdminProductCommissions';
import { useSelector } from 'react-redux';
import { RootState } from 'core/store';
import { ProductCommissions } from '../product-commissions';
import DgSpinner from 'styles/UI/Spinner/DgSpinner';
import DgTextAreaLabel from 'styles/UI/TextArea/DgTextAreaLabel';
import DgSelectLabel from 'styles/UI/Select/DgSelectLabel';
import { useAdminGroupQuery } from 'components/Admin/Groups/List/hooks/react-query/useAdminGroupsQuery';
import { useAdminProductDetailsQuery } from '../List/hooks/react-query/useAdminProductQuery';
import Dragger from 'antd/lib/upload/Dragger';
import { FaUpload } from 'react-icons/fa';

const AdminProductUpdate = ({ hideModalHandler }: { hideModalHandler: () => void }) => {
	const { productDetail } = useSelector((store: RootState) => store.productStore);

	const { data: { data: product } = {}, isFetching } = useAdminProductDetailsQuery(
		productDetail?.id
	);

	const { updateProductForm, isLoadingUpdateProduct, onFinishUpdateProduct } =
		useAdminProductUpdate(hideModalHandler);
	const { t } = useTranslate();

	const [selectedGroup, setSelectedGroup] = useState();
	const [commissions, setCommissions] = useState([]);
	const [file, setFile] = useState(undefined);

	const { data: groups, isFetching: isFetchingGroups } = useAdminGroupQuery();

	const options = useMemo(() => {
		return groups?.data?.map((item: any) => ({
			label: item.name,
			value: item.name,
			filter: item.name,
			allItems: { ...item },
		}));
	}, [groups]);

	const productCommissionsMutation = useAdminProductCommissions();
	const productCommissionsQuery = useAdminProductCommissionsList(productDetail?.id);

	const productCommissions =
		productCommissionsQuery?.data?.data?.data?.indirect_percentages;

	const onFinish = (values: any) => {
		const commissionsPromises: any = [];

		commissions.forEach((item: any, index) => {
			if (item?.type) {
				commissionsPromises.push(
					async () =>
						await productCommissionsMutation
							.mutateAsync({
								product_id: productDetail?.id,
								level: index + 1,
								...item,
							})
							.catch((e) => {
								// console.log({ e });
							})
				);
			}
		});

		if (commissionsPromises?.length) {
			Promise.all(commissionsPromises.map((item: any) => item()));
		}

		onFinishUpdateProduct(values, file);
	};

	useEffect(() => {
		if (product?.group?.id && !!options?.length) {
			setSelectedGroup(
				options.find((item: any) => item.allItems?.id === product?.group?.id)
			);
		}
	}, [product?.group?.id, options]);

	console.log(
		{ selectedGroup, productDetail, product, options },
		updateProductForm.getFieldsValue(true)
	);

	return (
		<>
			{!!isFetching ? (
				<DgSpinner />
			) : (
				<DgForm
					shouldUpdate={true}
					autoComplete='off'
					onFinish={onFinish}
					form={updateProductForm}
					// key={productDetail?.id}
				>
					<DgFormWrapper singleColumn>
						{!!product?.images?.length && (
							<img className='w-full' src={product.images[0]?.url} />
						)}

						<Dragger
							{...{
								// key: index,
								name: 'file',
								multiple: false,
								maxCount: 1,
								accept: 'image/*',
								disabled: isLoadingUpdateProduct,
								beforeUpload: () => false,
								onChange(info) {
									// const { status } = info.file;

									setFile(info.file);
								},
								onRemove() {
									setFile(undefined);
								},
								className: 'w-full',
							}}
						>
							<div className='flex  flex-col gap-2 items-center justify-center'>
								<span className='ant-upload-drag-icon'>
									<FaUpload size={'2.5rem'} />
								</span>
								<p className='ant-upload-text'>
									Click or drag file to this area to upload
								</p>
								<p className='ant-upload-hint'>
									Support for a single or bulk upload. Strictly prohibited from uploading
									company data or other banned files.
								</p>
							</div>
						</Dragger>

						<DgFormItem
							name='title'
							rules={[
								{
									required: true,
									message: t('title-is-required'),
								},
							]}
						>
							<DgInputLabel label='title' />
						</DgFormItem>

						<DgFormItem
							rules={[
								{
									required: true,
									message: t('description-is-required'),
								},
							]}
							name='description'
						>
							<DgTextAreaLabel label='description' />
						</DgFormItem>

						<DgFormItem
							name='code'
							rules={[
								{
									required: true,
									message: t('code-is-required'),
								},
							]}
						>
							<DgInputLabel label='code' />
						</DgFormItem>

						<DgSelectLabel
							label='group'
							options={options}
							loading={isFetchingGroups}
							onChange={(value) =>
								setSelectedGroup(options.find((item: any) => item.label === value))
							}
							value={selectedGroup}
						/>

						<DgFormItem
							rules={[
								{
									required: true,
									message: t('price-is-required'),
								},
							]}
							noMargin
							name='price'
						>
							<DgInputLabel
								suffix={
									<DgImage
										src={usdt}
										width={22}
										className='rounded-full relative top-1.5'
									/>
								}
								label='price'
							/>
						</DgFormItem>

						{/* <DgFormItem
							rules={[
								{
									required: true,
									message: t('euro_price-is-required'),
								},
							]}
							noMargin
							name='euro_price'
						>
							<DgInputLabel
								suffix={
									<DgImage
										src={usdt}
										width={22}
										className='rounded-full relative top-1.5'
									/>
								}
								label='euro_price'
							/>
						</DgFormItem> */}

						<DgFormItem name='discount'>
							<DgInputLabel suffix={t('%')} label='discount' />
						</DgFormItem>

						<DgFormItem
							rules={[
								{
									required: true,
									message: t('quantity-is-required'),
								},
							]}
							noMargin
							name='qty'
						>
							<DgInputLabel label='quantity' />
						</DgFormItem>

						<DgFormItem
							name='weight'
							rules={[
								{
									required: true,
									message: t('weight-is-required'),
								},
							]}
						>
							<DgInputLabel suffix={t('KG')} label='weight' />
						</DgFormItem>
						<DgFormItem
							name='roi_percentage'
							rules={[
								{
									required: true,
									message: t('roi_percentage-is-required'),
								},
							]}
						>
							<DgInputLabel
								suffix={
									<HintBox
										hintTitle='roi_percentage'
										hintDescription='roi_percentage_info'
									/>
								}
								label='roi_percentage'
							/>
						</DgFormItem>

						{!!productCommissionsQuery?.isFetching ? (
							<DgSpinner />
						) : (
							<ProductCommissions
								// key={JSON.stringify(productCommissions)}
								defaultValue={productCommissions}
								onChange={(value) => setCommissions(value)}
							/>
						)}
					</DgFormWrapper>

					<DgFormItem button>
						<DgButton
							htmlType='submit'
							isLoading={isLoadingUpdateProduct}
							title='update-product'
						/>
					</DgFormItem>
				</DgForm>
			)}
		</>
	);
};

export default AdminProductUpdate;
