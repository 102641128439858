import { configureStore } from '@reduxjs/toolkit';
import { createLogger } from 'redux-logger';
import rootReducer, { persistedReducer } from './reducers';
import { persistStore } from 'redux-persist';

const store = configureStore({
	reducer: persistedReducer,
	middleware: (getDefaultMiddleware) => {
		const middleware = getDefaultMiddleware({ serializableCheck: false });

		const logger = createLogger({
			collapsed: true,
			duration: true,
		});

		middleware.push(logger);

		return middleware;
	},
	devTools: true,
});

export type RootState = ReturnType<typeof store.getState>;
export const persistor = persistStore(store);

export default store;
