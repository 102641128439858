import { UseQueryResult } from '@tanstack/react-query';
import { endPointUrls } from 'core/constants/endPointUrls';
import api from 'core/services/fetch-api/useApi';
import useQueryUrlParam from 'core/hooks/useQueryUrlParam';
import useQueryHook from 'core/hooks/masterQuery/useQueryHook';
import { IInvoiceList } from 'core/types/models/invoiceType';

export const useUserEarningWalletTableQuery = () => {
	const { filterParams } = useQueryUrlParam();

	return useQueryHook(
		[endPointUrls.EARNING_WALLET_TRANSACTIONS(), filterParams],
		(data: any) => api.get(endPointUrls.EARNING_WALLET_TRANSACTIONS(filterParams), data),
		{
			select: (res: any) => res?.data,
			keepPreviousData: true,
		}
	) as UseQueryResult<IInvoiceList, Error>;
};
