import { useUserActivityQuery } from './react-query/useUserActivityQuery';

const userUserActivity = () => {
	// query
	const { data: userActivities, isFetching: userActivityIsFetching } = useUserActivityQuery();

	return { userActivities, userActivityIsFetching };
};

export default userUserActivity;
