import { createSlice } from '@reduxjs/toolkit';
import { IUserRoleList } from 'core/types/models/role';
import { userDetail } from 'core/types/models/userInfo';

const initialState: { currentUserInfo: userDetail; userDetail: userDetail; userRoles: IUserRoleList } = {
	currentUserInfo: { data: {} },
	userDetail: { data: {} },
	userRoles: { data: {} },
};

export const userInfoSlice = createSlice({
	name: 'userInfo',
	initialState: initialState,
	reducers: {
		setCurrentUserDetail: (state, { payload }) => {
			state.currentUserInfo = payload;
		},
		setUserDetail: (state, { payload }) => {
			state.userDetail = payload;
		},
		setUserRoles: (state, { payload }) => {
			state.userRoles = payload;
		},
	},
});

export const { setCurrentUserDetail, setUserDetail, setUserRoles } = userInfoSlice.actions;
export default userInfoSlice.reducer;
