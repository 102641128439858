import React from 'react';
import DgButton from 'styles/UI/Button/DgButton';
import DgForm from 'styles/UI/Form/DgForm';
import DgFormItem from 'styles/UI/FormItem/DgFormItem';
import DgFormWrapper from 'styles/UI/FormWrapper/DgFormWrapper';
import DgInputLabel from 'styles/UI/Input/DgInputLabel';

import DgTextAreaLabel from 'styles/UI/TextArea/DgTextAreaLabel';
import useAdminSettingsUpdate from '../hooks/useAdminSettingsUpdate';

const AdminSettingsUpdate = () => {
	const { isLoadingUpdateSetting, onFinishUpdateSetting, updateSettingForm } = useAdminSettingsUpdate();

	return (
		<DgForm autoComplete='off' onFinish={onFinishUpdateSetting} form={updateSettingForm}>
			<DgFormWrapper>
				<DgFormItem name='key'>
					<DgInputLabel label='key' />
				</DgFormItem>

				<DgFormItem name='value'>
					<DgInputLabel label='value' />
				</DgFormItem>

				<DgFormItem name='category'>
					<DgInputLabel label='category' />
				</DgFormItem>

				<DgFormItem name='description'>
					<DgTextAreaLabel label='description' />
				</DgFormItem>
			</DgFormWrapper>

			<DgFormItem button>
				<DgButton htmlType='submit' isLoading={isLoadingUpdateSetting} title='update-setting' />
			</DgFormItem>
		</DgForm>
	);
};

export default AdminSettingsUpdate;
