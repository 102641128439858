import { routes } from 'core/constants/routes';
import Text from 'core/utils/Text';
import { useNavigate } from 'react-router-dom';
import DgButton from 'styles/UI/Button/DgButton';

const PreviewLog = () => {
	const navigate = useNavigate();
	return (
		<div className='border-b border-b-[var(--dg-border-color)] pb-3'>
			<div className='flex justify-between items-center flex-wrap'>
				<Text tid='log' />

				<DgButton title='preview-log' onClick={() => navigate(routes.PROFILE_ACTIVITY)} />
			</div>
		</div>
	);
};

export default PreviewLog;
