import { useParams } from 'react-router-dom';
import { useUserResetPasswordQuery } from './react-query/useUserResetPasswordQuery';

const useUserResetPassword = () => {
	// hooks
	const { userId } = useParams();

	// query
	const { mutate: resetPassword, isLoading: resetPasswordIsFetching } = useUserResetPasswordQuery();

	const onConfirm = () => {
		resetPassword({ user_id: userId });
	};

	return { onConfirm, resetPasswordIsFetching };
};

export default useUserResetPassword;
