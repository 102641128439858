import { Collapse, Typography } from 'antd';
import Text from 'core/utils/Text';
import useTranslate from 'core/utils/useTranslate';
import { useEffect } from 'react';
import DgButton from 'styles/UI/Button/DgButton';
import DgModal from 'styles/UI/Modal/DgModal';
import logoDark from '../../assets/images/logo/logo-dark.png';
import logoLight from '../../assets/images/logo/logo-light.png';
import { useSelector } from 'react-redux';
import { RootState } from 'core/store';

const { Panel } = Collapse;

export const AboutUsModal = ({ useModal }: any) => {
	const { lang, theme } = useSelector((store: RootState) => store.settingStore);

	const { t } = useTranslate();

	const { modal, showModal, hideModal } = useModal;

	const onChange = (key: string | string[]) => {
		// // console.log(key);
	};

	useEffect(() => {});

	return (
		<>
			<DgModal
				title='about-us-title-1'
				open={modal}
				closable={false}
				className='faq-modal'
				width={860}
			>
				<div className='flex flex-col gap-4'>
					<div className='flex items-center justify-center'>
						<img src={theme === 'dark' ? logoDark : logoLight} width={'130px'} />
					</div>

					<Typography>
						<Text tid={'about-us-description-1'}></Text>
					</Typography>

					<Collapse defaultActiveKey={['1']} onChange={onChange}>
						{[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13].map((item) => (
							<Panel header={t(`about-us-item-heading-${item}`)} key={item}>
								<Text tid={`about-us-item-description-${item}`}></Text>
							</Panel>
						))}
					</Collapse>

					<div className='flex flex-wrap gap-4 items-center justify-end'>
						{/* <a target='_blank' href='/panel/rules.pdf' className=''>
							<Text tid='download-rules' />
						</a> */}

						<DgButton
							title='i-agree-the-rules'
							onClick={() => {
								hideModal();
							}}
						/>
					</div>
				</div>
			</DgModal>
		</>
	);
};
