import { Form, RadioChangeEvent } from 'antd';
import { useEffect, useState } from 'react';
import toastHandler from 'core/helpers/toast/toast';
import { registerPayload } from '../types/registerType';
import { useRegisterQuery } from './react-query/useRegisterQuery';
import { useSelector } from 'react-redux';
import { RootState } from 'core/store';
import useTranslate from 'core/utils/useTranslate';
import { stringToBoolean } from 'core/utils/utils';

const useRegister = () => {
	// recaptcha
	const isShowCaptcha = stringToBoolean(process.env.REACT_APP_IS_RECAPTCHA_SHOW);
	const [captcha, setCaptcha] = useState(isShowCaptcha ? false : 'true');

	const onChangeCaptcha = (value: any) => {
		setCaptcha(value);
	};

	const { apiCatcherFormErrors } = useSelector((store: RootState) => store.mainInfoStore);
	const [signUpForm] = Form.useForm();
	const { t } = useTranslate();
	const [genderType, setGenderType] = useState('Male');
	const { mutate, isLoading: isLoadingRegister } = useRegisterQuery();

	const onFinishSignUp = (values: registerPayload) => {
		if (!captcha) {
			return toastHandler('error', t('captcha-required'));
		}

		mutate(values);
	};

	const genderOnChangeHandler = (e: RadioChangeEvent): void => {
		setGenderType(e.target.value);
	};

	useEffect(() => {
		signUpForm.setFieldsValue({ gender: genderType });
	}, [genderType]);

	useEffect(() => {
		signUpForm.setFields(apiCatcherFormErrors);
	}, [apiCatcherFormErrors]);

	return {
		signUpForm,
		onFinishSignUp,
		isLoadingRegister,
		onChangeCaptcha,
		isShowCaptcha,
		genderOnChangeHandler,
	};
};

export default useRegister;
