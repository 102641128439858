import DgCard from 'styles/UI/Card/DgCard';
import DgCardHeader from 'styles/UI/CardHeader/DgCardHeader';
import DgSpinnerFull from 'styles/UI/Spinner/DgSpinnerFull';
import UserOrderItem from './components/UserOrderItem';
import DgEmpty from 'styles/UI/Empty/DgEmpty';
import { useUserActiveOrdersQuery } from './hooks/react-query/useUserActiveOrdersQuery';
import useModal from 'core/hooks/useModal';
import { useEffect, useState } from 'react';
import DgModal from 'styles/UI/Modal/DgModal';
import DgButton from 'styles/UI/Button/DgButton';
import { useQueryClient } from '@tanstack/react-query';
import { endPointUrls } from 'core/constants/endPointUrls';
import { useCancelOrdersQuery } from './hooks/react-query/useCancelOrdersQuery';
import { useNavigate } from 'react-router-dom';
import { routes } from 'core/constants/routes';
import DgForm from 'styles/UI/Form/DgForm';
import DgFormWrapper from 'styles/UI/FormWrapper/DgFormWrapper';
import DgInputLabel from 'styles/UI/Input/DgInputLabel';
import DgFormItem from 'styles/UI/FormItem/DgFormItem';
import { Form } from 'antd';
import useTranslate from 'core/utils/useTranslate';
import Text from 'core/utils/Text';
import { MdOutlineMonetizationOn } from 'react-icons/md';
import DgPrice from 'styles/UI/Price/DgPrice';
import { IoHourglassOutline } from 'react-icons/io5';
import { BsCalendar2Check } from 'react-icons/bs';
import DgTimeStamp from 'styles/UI/Timstamp/DgTimeStamp';
import { TbRefreshAlert } from 'react-icons/tb';
import DgCountDown from 'styles/UI/CountDown/DgCountDown';
import { useTopupOrdersQuery } from './hooks/react-query/useTopupOrdersQuery';

const UserActivePlans = () => {
	// query
	const { mutate: cancelOrder, isLoading: cancelOrderIsLoading } = useCancelOrdersQuery();
	const { data: userOrders, isFetching: userOrderIsFetching } =
		useUserActiveOrdersQuery();

	// hooks
	const queryClient = useQueryClient();
	const [form] = Form.useForm();
	const { t } = useTranslate();
	const navigate = useNavigate();

	const [selectedItem, setSelectedItem]: any = useState({});
	const [orderId, setOrderId] = useState();

	const onSubmit = (values: any) => {
		cancelOrder(
			{ ...values, order_id: orderId },
			{
				onSuccess: () => {
					queryClient.refetchQueries([endPointUrls.ORDERS_ACTIVE()]);
					cancelInvestModal.hideModal();
					navigate(routes.ORDERS_HISTORY);
				},
			}
		);
	};

	const cancelInvestModal = useModal();
	const topUpModal = useModal();

	return (
		<>
			{!!selectedItem?.id && (
				<TopUpModal
					selectedItem={selectedItem}
					setSelectedItem={setSelectedItem}
					modal={topUpModal}
				/>
			)}

			<DgModal
				title='cancel-investment'
				open={cancelInvestModal.modal}
				onCancel={cancelInvestModal.hideModal}
			>
				<p className='text-justify border-b mb-4 pb-2'>
					<Text tid='dear-user' className='mb-3' />
					<Text tid='cancel-invest-desc-1' className='mb-3' />
					<Text tid='cancel-invest-desc-2' className='mb-3' />
					<Text tid='cancel-invest-desc-3' />
				</p>

				<DgForm onFinish={onSubmit} form={form}>
					<DgFormWrapper singleColumn>
						<DgFormItem
							name='one_time_password'
							rules={[
								{ required: true, message: t('totp-verification-code-is-required') },
							]}
						>
							<DgInputLabel label='totp-verification-code' required />
						</DgFormItem>

						<DgButton title='cancel-investment' danger isLoading={cancelOrderIsLoading} />
					</DgFormWrapper>
				</DgForm>
			</DgModal>

			<DgCard>
				<DgCardHeader
					title='my-invest'
					buttonText='orders-history'
					onClick={() => navigate(routes.ORDERS_HISTORY)}
				/>

				<DgSpinnerFull isLoading={userOrderIsFetching}>
					<div className='grid lg-1300:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-5'>
						{userOrders?.data?.map((item) => (
							<UserOrderItem
								setOrderId={setOrderId}
								showModal={cancelInvestModal.showModal}
								key={item.id}
								item={item}
								topUpCallback={() => {
									setSelectedItem(item);
									topUpModal.showModal();
								}}
							/>
						))}
					</div>

					{(userOrders?.data?.length === 0 || userOrders?.data === undefined) && (
						<DgEmpty />
					)}
				</DgSpinnerFull>
			</DgCard>
		</>
	);
};

const TopUpModal = ({
	modal = {},
	selectedItem = {},
	setSelectedItem = () => {},
}: any) => {
	// // console.log(selectedItem);
	const queryClient = useQueryClient();

	const { t } = useTranslate();
	const navigate = useNavigate();

	const [form] = Form.useForm();

	const {
		// package: { direct_percentage, roi_percentage, name },
		expires_at,
		package_cost_in_pf,
		total_cost_in_pf,
		is_paid_at,
		id,
	} = selectedItem;

	const { mutate: topupOrder, isLoading: topupOrderIsLoading } = useTopupOrdersQuery();

	const onSubmit = (values: any) => {
		topupOrder(
			{ ...values, order_package_id: id },
			{
				onSuccess: () => {
					queryClient.refetchQueries([endPointUrls.ORDERS_ACTIVE()]);
					modal.hideModal();
					setSelectedItem({});
					navigate(routes.ORDERS_LIST);
				},
			}
		);
	};

	useEffect(() => {
		form.resetFields();
	}, []);

	return (
		<DgModal title='package-topup' onCancel={modal.hideModal} open={modal.modal}>
			<div className='flex flex-col gap-5'>
				{/* <h1 className='text-dg-white-color text-2xl'>{name}</h1> */}

				<div className='grid gap-5 w-full'>
					<div className='flex justify-between items-center border-b border-b-[var(--dg-border-color)] xs:mx-4 mx-3 pb-5'>
						<span className='flex items-center gap-1'>
							<MdOutlineMonetizationOn size={20} />
							<Text
								className='font-bold text-base text-[var(--dg-primary-color-fixed)] dark:text-[var(--dg-primary-color-light-hover-2)]'
								tid='investment-amount'
							/>
						</span>
						<span className='font-bold text-base'>
							<DgPrice price={total_cost_in_pf} />
						</span>
					</div>

					<div className='flex justify-between items-center border-b border-b-[var(--dg-border-color)] xs:mx-4 mx-3 pb-5'>
						<span className='flex items-center gap-1'>
							<IoHourglassOutline size={20} />
							<Text
								className='font-bold text-base text-[var(--dg-primary-color-fixed)] dark:text-[var(--dg-primary-color-light-hover-2)]'
								tid='duration'
							/>
						</span>
						{/* <span className='font-bold text-base'>{name}</span> */}
					</div>

					<div className='flex justify-between items-center border-b border-b-[var(--dg-border-color)] xs:mx-4 mx-3 pb-5'>
						<span className='flex items-center gap-1'>
							<BsCalendar2Check size={20} />
							<Text
								className='font-bold text-base text-[var(--dg-primary-color-fixed)] dark:text-[var(--dg-primary-color-light-hover-2)]'
								tid='invest-date'
							/>
						</span>
						<DgTimeStamp
							hasTime={false}
							timeStamp={is_paid_at}
							nowrap
							className='font-bold text-base'
						/>
					</div>

					<div className='flex justify-between items-center border-b border-b-[var(--dg-border-color)] xs:mx-4 mx-3 pb-5'>
						<span className='flex items-center gap-1'>
							<MdOutlineMonetizationOn size={20} />
							<Text
								className='font-bold text-base text-[var(--dg-primary-color-fixed)] dark:text-[var(--dg-primary-color-light-hover-2)]'
								tid='invest-profit'
								hintTitle='invest-profit'
								hintDescription='roi_percentage_info'
							/>
						</span>
						{/* <span className='font-bold text-base'>{roi_percentage}%</span> */}
					</div>

					<div className='flex justify-between items-center border-b border-b-[var(--dg-border-color)] xs:mx-4 mx-3 pb-5'>
						<span className='flex items-center gap-1'>
							<TbRefreshAlert size={20} />
							<Text
								className='font-bold text-base text-[var(--dg-primary-color-fixed)] dark:text-[var(--dg-primary-color-light-hover-2)]'
								tid='invest-release'
							/>
						</span>
						<DgCountDown timeStamp={expires_at} />
					</div>
				</div>

				<DgForm onFinish={onSubmit} form={form}>
					<DgFormWrapper singleColumn>
						<DgFormItem
							name='amount'
							rules={[{ required: true, message: t('amount-is-required') }]}
						>
							<DgInputLabel label={t('amount-to-increase')} required />
						</DgFormItem>
						<DgFormItem
							name='one_time_password'
							rules={[
								{ required: true, message: t('totp-verification-code-is-required') },
							]}
						>
							<DgInputLabel label='totp-verification-code' required />
						</DgFormItem>

						<DgButton title='increase-amount' isLoading={topupOrderIsLoading} />
					</DgFormWrapper>
				</DgForm>
			</div>
		</DgModal>
	);
};

export default UserActivePlans;
