import { createSlice } from '@reduxjs/toolkit';
import { INotification } from 'core/types/models/notificationType';

const initialState: { notificationCount: number; notificationList: INotification[] | [] } = {
	notificationCount: 0,
	notificationList: [],
};

export const notificationSlice = createSlice({
	name: 'notification',
	initialState: initialState,
	reducers: {
		setNotificationCount: (state, { payload }) => {
			state.notificationCount = payload;
		},
		setNotificationList: (state, { payload }: { payload: INotification[] }) => {
			state.notificationList = [...payload];
		},
	},
});

export const { setNotificationCount, setNotificationList } = notificationSlice.actions;
export default notificationSlice.reducer;
