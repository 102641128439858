import { useWindowSize } from 'core/hooks/useWindowSize';
import Text from 'core/utils/Text';
import DgTableColumnResponsive from 'styles/UI/TableColumnResponsive/DgTableColumnResponsive';
import { IBlockHistory } from 'core/types/models/userHistoryType';
import DgTimeStamp from 'styles/UI/Timstamp/DgTimeStamp';
import useTranslate from 'core/utils/useTranslate';

const UserBlockHistoryTableColumn = () => {
	const { width } = useWindowSize();
	const { t } = useTranslate();

	const Columns = [
		{
			title: <Text tid='block_type' />,
			key: 'block_type',
			dataIndex: 'block_type',
			render: (text: any) => text,
			sorter: false,
		},
		{
			title: <Text tid='block_reason' />,
			key: 'block_reason',
			dataIndex: 'block_reason',
			render: (text: any) => <div className='w-[35ch]'>{text}</div>,
			sorter: false,
		},
		{
			title: <Text tid='actor' />,
			render: (_text: any, record: IBlockHistory) => (
				<div className='flex flex-col'>
					<span>{record.actor.full_name}</span>
					<span>{record.actor.email}</span>
				</div>
			),
			sorter: false,
		},
		{
			title: <Text tid='date-time' />,
			key: 'created_at',
			dataIndex: 'created_at',
			render: (text: any) => <DgTimeStamp timeStamp={text} />,
			sorter: false,
		},
	];

	const RColumn = [
		{
			title: '',
			sorter: false,
			render: (_text: any, record: IBlockHistory) => {
				return (
					<>
						<DgTableColumnResponsive title={t('block_type')} value={record?.block_type} />

						<DgTableColumnResponsive title={t('block_reason')} value={record?.block_reason} />
						<DgTableColumnResponsive
							title={t('actor')}
							value={
								<div className='flex flex-col'>
									<span>{record.actor.full_name}</span>
									<span>{record.actor.email}</span>
								</div>
							}
						/>
						<DgTableColumnResponsive title={t('date-time')} value={<DgTimeStamp nowrap={false} timeStamp={record?.created_at} />} />
					</>
				);
			},
		},
	];

	return width > 769 ? Columns : RColumn;
};

export default UserBlockHistoryTableColumn;
