import styled, { css, keyframes } from 'styled-components';
import { MdNotificationsActive } from 'react-icons/md';
import { FaRegUser, FaCaretDown, FaCaretUp } from 'react-icons/fa';

interface IDropItem {
	active?: boolean;
}

interface ISettingWrapper {
	active?: boolean;
}

interface ISettingCheckBox {
	active?: boolean;
}

interface IProfileItem {
	last?: boolean;
}

interface IDropBody {
	minWidth?: string;
}

interface IMText {
	fontSize?: string;
}

interface INotText {
	fontSize?: string;
}

interface IProfile {
	size?: string;
}

interface IImage {
	size?: string;
}

const Body = styled.div`
	direction: ${(props) => (props.theme.english ? 'ltr' : 'rtl')};
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	z-index: 999;
`;

const Flex = styled.div`
	display: flex;
	align-items: center;
	position: relative;
`;

const FlexColumn = styled.div`
	display: flex;
	align-items: flex-start;
	flex-direction: column;
`;

const MLink = styled.div`
	transition: all 0.3s;
	cursor: pointer;
	padding: 0 1rem;

	&:hover {
		color: ${(props) => props.theme.active};
	}

	@media screen and (max-width: 480px) {
		font-size: 0.8rem;
	}
`;

const MText = styled.div<IMText>`
	color: ${(props) => props.theme.color};
	transition: all 0.3s;
	font-size: ${(props) => props.fontSize || '1rem'};

	&:hover {
		color: ${(props) => props.theme.active};
	}

	@media screen and (max-width: 480px) {
		font-size: 0.75rem;
	}
`;

const NotWrapper = styled.div`
	position: relative;
	margin: 0 15px;
`;

const Notification = styled(MdNotificationsActive)`
	color: #fff;
	cursor: pointer;
	&:hover {
		color: ${(props) => props.theme.active};
	}
`;

const Button = styled.div`
	background-color: ${(props) => props.theme.active};
	color: ${(props) => props.theme.bg};
	border-radius: 5px;
	width: 90px;
	height: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;

	@media screen and (max-width: 480px) {
		font-size: 0.8rem;
		width: 70px;
		height: 30px;
		margin: 0 8px;
	}
`;

const Profile = styled.div<IProfile>`
	display: flex;
	justify-content: center;
	align-items: center;
	width: ${(props) => props.size};
	height: ${(props) => props.size};
	border-radius: 50%;
	overflow: hidden;
	border: 1px solid var(--dg-primary-color-light);
	cursor: pointer;

	@media screen and (max-width: 480px) {
		margin: 0 8px;
	}
`;

const Avatar = styled(FaRegUser)`
	color: ${(props) => props.theme.active};
`;

const DropBody = styled.div<IDropBody>`
	min-width: ${(props) => props.minWidth};
	padding: 10px 0;
	border-radius: 6px;
	direction: ${(props) => (props.theme.english ? 'ltr' : 'rtl')};
	top: 25px;
	left: -13px;
	background-color: var(--dg-card-bg-2);
	position: absolute;
`;

const DropItem = styled.div<IDropItem>`
	min-width: 120px;
	cursor: pointer;
	margin: 4px 0;
	padding: 6px 0;
	transition: all 0.3s;
	position: relative;
	font-size: 0.9rem !important;
	color: ${(props) => (props.active && props.theme.active) || props.theme.color};
	${(props) =>
		props.active &&
		css`
			background-color: ${(props) => props.theme.hover};

			&::after {
				content: '';
				position: absolute;
				left: 0;
				top: 0;
				bottom: 0;
				height: 100%;
				width: 8px;
				background-color: ${(props) => props.theme.color};
			}
		`}

	&:hover {
		background-color: ${(props) => props.theme.hover};
	}
`;

const Balance = styled.div`
	font-size: 1rem;
	color: var(--dg-primary-color-fixed);
	background-color: ${(props) => props.theme.active};
	padding: 8px 12px;
	margin: 2px 0 10px 0;
	/* letter-spacing: 1.2px; */
	font-weight: 600;
	border-radius: 8px;
	text-align: start;
	font-family: AvenirNext;

	@media screen and (max-width: 480px) {
		font-size: 0.8rem;
		/* letter-spacing: 1px; */
	}
`;

const ProfileItem = styled.div<IProfileItem>`
	display: flex;
	padding: 12px 4px;
	${(props) =>
		!props.last &&
		css`
			border-bottom: 1px solid ${(props) => props.theme.primaryBg}80;
		`}
	transition: all 0.3s;

	&:hover {
		cursor: pointer;
		background-color: ${(props) => props.theme.hover};
		border-radius: 8px;
	}

	&:hover div {
		color: var(--dg-orange-color);
	}

	@media screen and (max-width: 480px) {
		padding: 8px 2px;
	}
`;

const Image = styled.img<IImage>`
	width: ${(props) => props.size};
	height: ${(props) => props.size};
	border-radius: 50%;
`;

const NotItem = styled.div`
	border-radius: 8px;
	margin: 10px 0;
	padding: 8px 12px;
	background-color: var(--dg-secondary-color);
	color: ${(props) => props.theme.color};
	display: flex;
	align-items: center;
`;

const NotText = styled.div<INotText>`
	font-size: ${(props) => props.fontSize};
	color: ${(props) => props.color || props.theme.color};
`;

const SettingWrapper = styled.div<ISettingWrapper>`
	width: 100%;
	margin: 10px 0;
	padding: 4px 10px;
	display: flex;
	justify-content: space-between;
	align-items: center;

	${(props) =>
		props.active &&
		css`
			border-bottom: 1px solid ${(props) => props.theme.color}20;
		`}
`;

const SettingItem = styled.div`
	color: ${(props) => props.theme.color};
	font-size: 0.7rem;
`;

const SettingSub = styled.div`
	color: ${(props) => props.theme.color};
	font-size: 0.7rem;
	width: 100%;
`;

const UpCaret = styled(FaCaretUp)`
	color: ${(props) => props.theme.color};
`;

const DownCaret = styled(FaCaretDown)`
	color: ${(props) => props.theme.color};
`;

const SettingCheckBox = styled.div<ISettingCheckBox>`
	width: 12px;
	height: 12px;
	margin: 0 8px;
	position: relative;
	border-radius: 50%;
	cursor: pointer;
	background-color: ${(props) => (props.active ? props.theme.mainOrange : props.theme.color)};
`;

const InfiniteAnim = keyframes`
  0% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
`;

const UnreadNotificationIcon = styled.div`
	width: 11px;
	height: 11px;
	background-color: ${(props) => props.theme.mainOrange};
	position: absolute;
	bottom: 2px;
	right: 0;
	border-radius: 50%;
	animation: ${InfiniteAnim} 1.5s infinite;

	@media screen and (max-width: 1050px) {
		width: 8px;
		height: 8px;
	}
`;

export {
	NotText,
	NotItem,
	Image,
	Body,
	DropBody,
	DropItem,
	Flex,
	FlexColumn,
	Profile,
	ProfileItem,
	Balance,
	Notification,
	NotWrapper,
	Avatar,
	MText,
	MLink,
	Button,
	SettingWrapper,
	SettingItem,
	SettingSub,
	UpCaret,
	DownCaret,
	SettingCheckBox,
	UnreadNotificationIcon,
};
