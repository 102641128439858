import { Form } from 'antd';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'core/store';
import {
	setHiddenModal,
	setModalData,
	setShowModal,
} from 'core/store/slice/modal/modalSlice';
import { useWalletDepositTransferPreviewQuery } from './react-query/useWalletDepositTransferPreviewQuery';
import { ITransferPayload } from '../../../core/types/models/transferType';
import { useWalletDepositTransferFinalQuery } from './react-query/useWalletDepositTransferFinalQuery';
import { endPointUrls } from 'core/constants/endPointUrls';
import { useQueryClient } from '@tanstack/react-query';
import useModal from 'core/hooks/useModal';
import { useNavigate } from 'react-router-dom';
import { routes } from 'core/constants/routes';
import useQueryUrlParam from 'core/hooks/useQueryUrlParam';

const useWalletDepositTransfer = () => {
	// hooks
	const [walletDepositTransferForm] = Form.useForm();
	const [google2faForm] = Form.useForm();
	const { pathname } = useQueryUrlParam();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const queryClient = useQueryClient();
	const {
		modal: google2faModal,
		hideModal: google2faHideModal,
		showModal: google2faShowModal,
	} = useModal();

	// query
	const {
		mutate: setWalletDepositTransferPreview,
		isLoading: walletDepositTransferPreviewIsLoading,
		data: walletDepositTransferPreviewResponse,
	} = useWalletDepositTransferPreviewQuery(() => showModal());

	const {
		mutate: setWalletDepositTransferFinal,
		isLoading: walletDepositTransferFinalIsLoading,
	} = useWalletDepositTransferFinalQuery(() => {
		hideModal();
		google2faHideModal();
		walletDepositTransferForm.resetFields();
		queryClient.refetchQueries([endPointUrls.ADMIN_USER_WALLETS()]);
		pathname === routes.WALLETS_DEPOSIT_TRANSFER && navigate(routes.TRANSACTION);
	});

	// store
	const { apiCatcherFormErrors } = useSelector((store: RootState) => store.mainInfoStore);
	const { modal, modalData } = useSelector((store: RootState) => store.modalStore);
	const { userDetail } = useSelector((store: RootState) => store.userInfoStore);

	useEffect(() => {
		walletDepositTransferForm.setFields(apiCatcherFormErrors);
	}, [apiCatcherFormErrors]);

	useEffect(() => {
		walletDepositTransferForm.setFieldsValue({ member_id: userDetail?.data?.member_id });
	}, [userDetail]);

	const onFinishWalletDepositTransfer = (values: ITransferPayload) => {
		dispatch(setModalData(values));
		setWalletDepositTransferPreview(values);
	};

	const showModal = () => {
		dispatch(setShowModal());
	};

	const hideModal = () => {
		dispatch(setHiddenModal());
		dispatch(setModalData(null));
	};

	const onFinishGoogle2fa = (values: { one_time_password: string }) => {
		setWalletDepositTransferFinal({
			...modalData,
			one_time_password: values.one_time_password,
		});
	};

	return {
		modal,
		hideModal,
		showModal,
		walletDepositTransferForm,
		onFinishWalletDepositTransfer,
		walletDepositTransferPreviewIsLoading,
		walletDepositTransferPreviewResponse,
		walletDepositTransferFinalIsLoading,
		onFinishGoogle2fa,
		google2faForm,
		google2faModal,
		google2faHideModal,
		google2faShowModal,
	};
};

export default useWalletDepositTransfer;
