import { UseQueryResult } from '@tanstack/react-query';
import { endPointUrls } from 'core/constants/endPointUrls';
import api from 'core/services/fetch-api/useApi';
import useQueryHook from 'core/hooks/masterQuery/useQueryHook';
import { ICityList } from 'core/types/models/cityType';
import { useId } from 'react';
import { useParams } from 'react-router';

export const useProductsList = (params: any = {}) => {

	return useQueryHook([endPointUrls.PRODUCTS(), params], () => api.get(endPointUrls.PRODUCTS(), { params }), {
		select: (res: any) => res?.data,
	}) as UseQueryResult<any, Error>;
};

export const useProductDetails = (id: string) => {
	// const id: any = useId();
	// const { id } = useParams();

	console.log('asdasjdkasdj', { id });

	return useQueryHook(
		[endPointUrls.PRODUCTS(id), id],
		() => api.get(endPointUrls.PRODUCTS(id)),
		{
			select: (res: any) => res?.data,
			enabled: false,
		}
	) as UseQueryResult<any, Error>;
};
