import { UseQueryResult } from '@tanstack/react-query';
import { endPointUrls } from 'core/constants/endPointUrls';
import api from 'core/services/fetch-api/useApi';
import useQueryHook from 'core/hooks/masterQuery/useQueryHook';
// import { IProductList } from '../../../../../../core/types/models/productType';

export const useAdminProductQuery = () => {
	return useQueryHook(
		[endPointUrls.PRODUCTS_LIST],
		(data: any) =>
			api.get(endPointUrls.ADMIN_PRODUCTS_LIST, {
				...data,
				params: {
					...(data?.params || {}),
					product_exactly: true,
				},
			}),
		{
			select: (res: any) => res?.data,
			keepPreviousData: true,
		}
	) as UseQueryResult<any, Error>;
};

export const useAdminProductDetailsQuery = (id: string) => {
	return useQueryHook(
		[endPointUrls.ADMIN_PRODUCTS_DETAILS(id), id],
		(data: any) =>
			api.get(endPointUrls.ADMIN_PRODUCTS_DETAILS(id), {
				...data,
				params: {
					...(data?.params || {}),
					// product_exactly: true,
				},
			}),
		{
			select: (res: any) => res?.data,
			keepPreviousData: true,
		}
	) as UseQueryResult<any, Error>;
};
