import { FC, FormEvent, KeyboardEventHandler, useEffect, useState } from 'react';
import { FlexCenter } from '../../../styles/CommonStyles';
import Text from '../../../core/utils/Text';
import { p2e } from '../../../core/utils/common';
import DgButton from 'styles/UI/Button/DgButton';
import DgInputLabel from 'styles/UI/Input/DgInputLabel';
import { useGoogle2FAEnableQuery } from '../hooks/react-query/useGoogle2FAEnableQuery';

interface ISetupApp {
	onPrevStepHandler: any;
	onNextStepHandler: any;
	setLoading: any;
}

const SetupApp: FC<ISetupApp> = ({
	onPrevStepHandler,
	onNextStepHandler,
	setLoading,
}) => {
	const [code, setCode] = useState({ code: '', state: 'normal' });

	const onSuccess = () => {
		onNextStepHandler(code.state);
	};

	const { mutate: verify2fa, isLoading } = useGoogle2FAEnableQuery(onSuccess);

	useEffect(() => {
		setTimeout(() => {
			setLoading(false);
		}, 300);
	}, []);

	const onInputValueChange = (e: FormEvent<HTMLInputElement>) => {
		const val = p2e(e.currentTarget.value);
		if (val.length === 0) setCode((state) => ({ ...state, state: 'normal' }));
		if (val.length > 0) setCode((state) => ({ ...state, state: 'error' }));
		if (val.length === 6) setCode((state) => ({ ...state, state: 'valid' }));
		setCode((state) => ({ ...state, code: val }));
	};

	const onNextClicked = () => {
		verify2fa({ one_time_password: code.code });
	};

	const onKeyUp = (e: KeyboardEventHandler<HTMLInputElement>) => {
		// if (e.keyCode === 13 && code.state === 'valid') verify2fa({ code: code.code });
	};

	return (
		<FlexCenter style={{ flexDirection: 'column', maxWidth: '450px', margin: '0 auto' }}>
			<div className='text-xl'>
				<Text tid='activate-app' />
			</div>
			<div className='w-full mt-5 mb-2'>
				<DgInputLabel
					label='google-verify'
					onKeyUp={(e) => console.log(e)}
					value={code.code}
					onChange={onInputValueChange}
					maxLength={6}
				/>
			</div>

			<div className='flex justify-between w-full'>
				<DgButton
					type='default'
					className='mt-8'
					onClick={onPrevStepHandler}
					title='prev-step'
				/>
				<DgButton
					isLoading={isLoading}
					disabled={code.state !== 'valid'}
					className='mt-8'
					onClick={onNextClicked}
					title='next-step'
				/>
			</div>
		</FlexCenter>
	);
};

export default SetupApp;
