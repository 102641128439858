import { useEffect, useState } from 'react';
import io from 'socket.io-client';

const useSocket = () => {
	const [socket, setSocket] = useState<SocketIOClient.Socket>();

	useEffect(() => {
		const newSocket = io(process.env.REACT_APP_SOCKET_BASE_URL + '', { transports: ['websocket', 'polling'] });
		setSocket(newSocket);

		return () => {
			newSocket.disconnect();
		};
	}, []);

	return { socket };
};

export default useSocket;
