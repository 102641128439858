import { useEffect, useState } from 'react';
import useMasterQueryUrlParam from 'core/hooks/useMasterQueryUrlParam';
import useTranslate from 'core/utils/useTranslate';
import { useForgetPasswordQuery } from './react-query/useForgetPasswordQuery';
import { useForgetPasswordOtpQuery } from './react-query/useForgetPasswordOtpQuery';
import { Form } from 'antd';
import toastHandler from 'core/helpers/toast/toast';
import { forgetPasswordOtpPayload } from '../types/forgetPasswordOtpType';
import { stringToBoolean } from 'core/utils/utils';

const useForgetPasswordOtp = () => {
	//state
	const [code1, setCode1] = useState<string>('');
	const [code2, setCode2] = useState<string>('');
	const [code3, setCode3] = useState<string>('');
	const [code4, setCode4] = useState<string>('');
	const [code5, setCode5] = useState<string>('');
	const [code6, setCode6] = useState<string>('');
	const [seconds, setSeconds] = useState<number>(63);
	const [resend, setResend] = useState<boolean>(false);
	//code
	const code = code1 + code2 + code3 + code4 + code5 + code6;

	// query
	const { mutate: resetPasswordHandler, isLoading: verifyEmailIsLoading } = useForgetPasswordOtpQuery();
	const { mutate: resendForgetPasswordOtpHandler, isLoading: forgetPasswordIsLoading } = useForgetPasswordQuery();

	// recaptcha
	const isShowCaptcha = stringToBoolean(process.env.REACT_APP_IS_RECAPTCHA_SHOW);
	const [captcha, setCaptcha] = useState(isShowCaptcha ? false : 'true');

	const onChangeCaptcha = (value: any) => {
		setCaptcha(value);
	};

	//hooks
	const { query, setUrlParam } = useMasterQueryUrlParam();
	const [forgetPasswordOtpForm] = Form.useForm();
	const { t } = useTranslate();

	const passChangeHandler = (e: any, code: string) => {
		const isNumber = /^[0-9\b]+$/;
		if (isNumber.test(e.target.value)) {
			switch (code) {
				case 'code1':
					return setCode1(e.target.value);
				case 'code2':
					return setCode2(e.target.value);
				case 'code3':
					return setCode3(e.target.value);
				case 'code4':
					return setCode4(e.target.value);
				case 'code5':
					return setCode5(e.target.value);
				case 'code6':
					return setCode6(e.target.value);
				default:
					return;
			}
		} else {
			switch (code) {
				case 'code1':
					return setCode1('');
				case 'code2':
					return setCode2('');
				case 'code3':
					return setCode3('');
				case 'code4':
					return setCode4('');
				case 'code5':
					return setCode5('');
				case 'code6':
					return setCode6('');
				default:
					return;
			}
		}
	};
	//focus to next input while typing
	const focusHandler = (e: any) => {
		const isNumber = /^[0-9\b]+$/;
		if (isNumber.test(e.target.value)) {
			if (e.target.value.length >= e.target.getAttribute('maxlength')) {
				e.target.nextElementSibling.focus();
			}
		}
	};
	//focus to previous input while deleting
	const onKeyDown = (e: any) => {
		if (e.keyCode === 8) {
			if (e.target.value.length < e.target.getAttribute('maxlength')) {
				e.target.previousElementSibling.focus();
			}
		}
	};
	useEffect(() => {
		let isCancelled = false;
		if (!isCancelled) {
			setCode1('');
			setCode2('');
			setCode3('');
			setCode4('');
			setCode5('');
			setCode6('');
		}
		return () => {
			isCancelled = true;
		};
	}, []);

	//resend otp code
	const resendHandler = (e: any) => {
		e.preventDefault();
		const postData: any = {
			email: query?.email,
		};

		const onSuccess = () => {
			setResend(false);
			setSeconds(63);
		};

		resendForgetPasswordOtpHandler(postData, { onSuccess });
	};

	useEffect(() => {
		let isCancelled = false;
		let initial: any;
		if (!isCancelled) {
			if (seconds > 0) {
				initial = setTimeout(() => setSeconds(seconds - 1), 1000);
			} else {
				setResend(true);
				setSeconds(0);
			}
		}
		return () => {
			isCancelled = true;
			clearTimeout(initial);
		};
	}, [seconds]);

	const goBackToLoginForm = () => {
		setUrlParam({ page: 1 });
	};

	// send forget password otp
	const onFinishForgetPasswordHandler = (values: forgetPasswordOtpPayload) => {
		if (!captcha) {
			return toastHandler('error', t('captcha-required'));
		}

		if (!code1 || !code2 || !code3 || !code4 || !code5 || !code6) {
			return toastHandler('error', t('otp-required'));
		}

		resetPasswordHandler({
			email: query?.email,
			otp: code,
			password: values.password,
			password_confirmation: values.password_confirmation,
		});
	};

	return {
		passChangeHandler,
		goBackToLoginForm,
		code1,
		onFinishForgetPasswordHandler,
		code2,
		code3,
		code4,
		code5,
		code6,
		focusHandler,
		onKeyDown,
		resendHandler,
		seconds,
		resend,
		t,
		onChangeCaptcha,
		isShowCaptcha,
		query,
		isLoadingRegisterVerify: forgetPasswordIsLoading || verifyEmailIsLoading,
		forgetPasswordOtpForm,
	};
};

export default useForgetPasswordOtp;
