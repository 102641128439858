import DgSelectLabel from 'styles/UI/Select/DgSelectLabel';
import usdt from '../../../assets/images/usdt.png';
import DgImage from 'styles/UI/Image/DgImage';

const DgSelectDepositCurrencies = ({ label = '', ...props }) => {
	const options = [
		{
			label: (
				<span className='flex justify-between items-center px-3'>
					<span>USDT</span>
					<div>
						<DgImage src={usdt} width={22} className='rounded-full relative top-1.5' />
					</div>
				</span>
			),
			value: '0',
			filter: 'USDT',
		},
	];

	return <DgSelectLabel value={'0'} label={label} options={options} {...props} />;
};

export default DgSelectDepositCurrencies;
