import DgTable from 'styles/UI/Table/DgTable';
import { useAdminGroupQuery } from '../hooks/react-query/useAdminGroupsQuery';
import AdminGroupTableColumn from './AdminGroupTableColumn';

const AdminGroupTable = ({
	onEditSettingsHandler,
	onDeleteGroupHandler,
}: {
	onEditSettingsHandler?: (val: any) => void;
	onDeleteGroupHandler?: (val: any) => void;
}) => {
	const { data, isFetching } = useAdminGroupQuery();

	return (
		<DgTable
			rowKey='id'
			columns={AdminGroupTableColumn(onEditSettingsHandler, onDeleteGroupHandler)}
			dataSource={data?.data}
			count={data?.data.length}
			loading={isFetching}
		/>
	);
};

export default AdminGroupTable;
