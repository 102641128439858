import UserEarningWallet from 'components/UserEarningWallet/UserEarningWallet';
import UserWallets from 'components/UserWallets/UserWallets';
import Text from 'core/utils/Text';
import { FC, useState } from 'react';
import DgCard from 'styles/UI/Card/DgCard';
import DgCardHeader from 'styles/UI/CardHeader/DgCardHeader';

const UserWalletsPage: FC = () => {
	const tabs = [
		{
			key: 'wallet',
			label: <Text tid='wallet' />,
			children: (
				<>
					<UserWallets />
				</>
			),
		},
		{
			key: 'earning-wallet',
			label: <Text tid='earning-wallet' />,
			children: (
				<>
					<UserEarningWallet />
				</>
			),
		},
	];
	const [activeTab, setActiveTab] = useState(tabs[0].key);

	return (
		<div>
			<DgCard>
				<DgCardHeader
					tabOptions={{ tabs, activeTab, onChange: setActiveTab }}
					// title='package-purchase'
				/>
			</DgCard>
		</div>
	);
};

export default UserWalletsPage;
