import { RootState } from 'core/store';
import useTranslate from 'core/utils/useTranslate';
import { FC } from 'react';
import { useSelector } from 'react-redux';

interface IDgDevider {
	title: string;
	className?: string;
	orientation?: 'right' | 'left' | 'center';
}

const DgDevider: FC<IDgDevider> = ({ className, orientation = 'right', title = '', ...props }) => {
	const { t } = useTranslate();
	const { isEnglish } = useSelector((store: RootState) => store.settingStore);

	return (
		<div className={`flex items-center mt-8 mb-6 ${className}`} {...props}>
			{orientation !== 'right' && <hr className='w-full' />}
			<h4
				className={` ${orientation === 'right' && (isEnglish ? 'mr-4' : 'ml-4')} ${orientation === 'left' && (isEnglish ? 'ml-4' : 'mr-4')} ${
					orientation === 'center' && 'mx-4'
				}  whitespace-nowrap text-dg-primary-color`}
			>
				<b className='text-base'>{t(title)}</b>
			</h4>
			{orientation !== 'left' && <hr className='w-full' />}
		</div>
	);
};

export default DgDevider;
