import styled from 'styled-components';
import { FaCertificate, FaCheck } from 'react-icons/fa';

const AuthWrapper = styled.div`
	width: 100%;
	//height: 80vh;
	margin-top: 40px;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: flex-start;

	@media screen and (max-width: 480px) {
		margin-top: 10px;
		//height: calc(80vh - 10px);
	}
`;

const FinalIcon = styled.div`
	position: relative;
	margin-top: 30px;
	width: fit-content;
	height: fit-content;

	@media screen and (max-width: 480px) {
		margin-top: 40px;
	}
`;

const CheckBg = styled(FaCertificate)`
	color: ${(props) => props.theme.mainGreen};
	position: absolute;
	right: -12px;
	bottom: -4px;
`;

const CheckIcon = styled(FaCheck)`
	color: ${(props) => props.theme.mainBg};
	position: absolute;
	right: -2px;
	bottom: 4px;
`;

export { FinalIcon, CheckIcon, CheckBg, AuthWrapper };
