import DgCard from 'styles/UI/Card/DgCard';
import DgCardHeader from 'styles/UI/CardHeader/DgCardHeader';
import UserInvoiceTable from './components/UserInvoiceTable';

const UserInvoice = () => {
	return (
		<DgCard>
			<DgCardHeader title='invoice-history' tabTitle='my-wallet' />

			<UserInvoiceTable />
		</DgCard>
	);
};

export default UserInvoice;
