import { FC, ReactNode } from 'react';

interface DashboardTitle {
	children: ReactNode;
}

const DashboardTitle: FC<DashboardTitle> = ({ children }) => {
	return (
		<span className='block w-full pb-2 m-0 overflow-hidden text-base font-bold text-center border-b border-[var(--dg-border-color)] overflow-ellipsis whitespace-nowrap'>
			{children}
		</span>
	);
};

export default DashboardTitle;
