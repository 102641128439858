import { RootState } from 'core/store';
import { useSelector } from 'react-redux';
import { useProductsList } from './react-query/products';
import { useMemo } from 'react';

export const useBasket = () => {
	const { data: products } = useProductsList();

	const basket = useSelector((store: RootState) => store.basketStore);

	const basketCount = basket?.items?.length
		? basket.items.reduce((accumulator, currentItem) => {
				return accumulator + currentItem.quantity;
		  }, 0)
		: 0;

	const basketItems = useMemo(() => {
		if (products?.data?.length && basket?.items?.length) {
			return basket.items.map((item) => {
				const product = products.data.find((product: any) => product.id === item.id);
				if (product) {
					product.offPrice = product.price - (product.price * product.discount) / 100;
				}

				return { ...item, product };
			});
		}

		return [];
	}, [products?.data, basket?.items]);

	const basketPrice = basketItems?.length
		? basketItems.reduce((accumulator, currentItem) => {
				// const price =
				// 	currentItem.product.price -
				// 	(currentItem.product.price * currentItem.product.discount) / 100;

				return accumulator + currentItem.product.offPrice * currentItem.quantity;
		  }, 0)
		: 0;

	console.log({ basket, basketCount, products, basketItems });

	return {
		basket,
		basketCount,
		basketItems,
		basketPrice,
	};
};
