import { UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { endPointUrls } from 'core/constants/endPointUrls';
import api from 'core/services/fetch-api/useApi';
import useQueryHook from 'core/hooks/masterQuery/useQueryHook';
import { IPackageCategoryList } from '../../../../../../core/types/models/packageType';

export const useBannersQuery = (options: UseQueryOptions = {}) => {
	return useQueryHook(
		[endPointUrls.GET_BANNERS],
		(data: any) => api.get(endPointUrls.GET_BANNERS, data),
		{
			select: (res: any) => res.data,
			keepPreviousData: true,
			...options,
		}
	) as UseQueryResult<IPackageCategoryList, Error>;
};
