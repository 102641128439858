import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'core/store';

const useCreateCrisp = () => {
	const { lang, isEnglish } = useSelector((store: RootState) => store.settingStore);

	useEffect(() => {
		const d = document;
		const s = document.createElement('script');
		window.$crisp = [];
		window.CRISP_WEBSITE_ID = process.env.REACT_APP_CRISP_ID;
		window.CRISP_RUNTIME_CONFIG = {
			locale: lang,
		};

		(function () {
			s.src = 'https://client.crisp.chat/l.js';
			s.async = true;
			createCrisp(isEnglish);
			d.getElementsByTagName('head')[0].appendChild(s);
		})();
	}, []);
};

export const createCrisp = (isEnglish: boolean) => {
	window.$crisp.push(['do', 'chat:hide']);
	!isEnglish && window.$crisp.push(['config', 'position:reverse', [true]]);
	window.$crisp.push(['config', 'color:theme', ['amber']]);
	window.$crisp.push([
		'on',
		'chat:closed',
		() => window.$crisp.push(['do', 'chat:hide']),
	]);
};

export const resetCrisp = () => {
	//TODO: delete if not needed - useCase in useAuthQuery - logout
};

export default useCreateCrisp;
