import { UseMutationResult } from '@tanstack/react-query';
import { endPointUrls } from 'core/constants/endPointUrls';
import toastHandler from 'core/helpers/toast/toast';
import useMutationHook from 'core/hooks/masterQuery/useMutationHook';
import api from 'core/services/fetch-api/useApi';
import { setApiCatcherFormErrors } from 'core/store/slice/mainInfo/mainInfoSlice';
import { ITransactionMutationResponse } from 'core/types/models/transferPreviewType';
import { useDispatch } from 'react-redux';

export const useWalletDepositTransferPreviewQuery = (callBack?: () => void) => {
	const dispatch = useDispatch();

	return useMutationHook(
		[endPointUrls.WALLET_DEPOSIT_TRANSFER_PREVIEW],
		(data) => api.post(endPointUrls.WALLET_DEPOSIT_TRANSFER_PREVIEW, data),
		{
			onSuccess: (res: any) => {
				if (res?.status === 200) {
					callBack?.();
					toastHandler('success', res?.data.message);
					dispatch(setApiCatcherFormErrors([]));
				}
			},
		}
	) as UseMutationResult<ITransactionMutationResponse>;
};
