import { Form } from 'antd';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'core/store';
import { setHiddenModal } from 'core/store/slice/modal/modalSlice';
import { useAdminWalletSettingsUpdateQuery } from './react-query/useAdminWalletSettingsUpdateQuery';
import { ISettingPayload } from 'core/types/models/settingsType';
import { IWalletSettingPayload } from 'core/types/models/walletSettingsType';

const useAdminWalletSettingsUpdate = () => {
	//hooks
	const dispatch = useDispatch();

	// store
	const { modalData } = useSelector((store: RootState) => store.modalStore);

	// login
	const { apiCatcherFormErrors } = useSelector((store: RootState) => store.mainInfoStore);
	const [updateSettingForm] = Form.useForm();
	const { mutate: adminUpdateSetting, isLoading: isLoadingUpdateSetting } =
		useAdminWalletSettingsUpdateQuery(() => {
			dispatch(setHiddenModal());
		});

	const onFinishUpdateSetting = (values: IWalletSettingPayload) => {
		// adminUpdateSetting({ ...values, value: parseFloat(values.value) });
		adminUpdateSetting(values);
	};

	useEffect(() => {
		updateSettingForm.setFieldsValue({ ...modalData });
	}, [modalData]);

	useEffect(() => {
		updateSettingForm.setFields(apiCatcherFormErrors);
	}, [apiCatcherFormErrors]);

	return {
		updateSettingForm,
		onFinishUpdateSetting,
		isLoadingUpdateSetting,
	};
};

export default useAdminWalletSettingsUpdate;
