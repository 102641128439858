import DgTable from 'styles/UI/Table/DgTable';
import DgPagination from 'styles/UI/Pagination/DgPagination';
import useQueryUrlParam from 'core/hooks/useQueryUrlParam';
import { useUserNotificationsQuery } from 'components/Profile/hooks/react-query/useUserNotificationsQuery';
import ProfileNotificationsColumn from './ProfileNotificationsColumn';
import { useNotificationSeenQuery } from 'components/Profile/hooks/react-query/useNotificationSeenQuery';
import DgModal from 'styles/UI/Modal/DgModal';
import useModal from 'core/hooks/useModal';
import { useState } from 'react';

const ProfileNotificationsTable = () => {
	const { data: notifications, isFetching: notificationsIsFetching } =
		useUserNotificationsQuery();
	const { mutate: setSeenNotification, isLoading: seenNotificationIsLoading } =
		useNotificationSeenQuery();
	const { query, handlePagination } = useQueryUrlParam();

	const { modal, hideModal, showModal } = useModal();
	const [description, setDescription] = useState('');

	return (
		<>
			<DgModal title='description' open={modal} onCancel={hideModal}>
				<div dangerouslySetInnerHTML={{ __html: description }} />
			</DgModal>

			<DgTable
				rowKey='id'
				columns={ProfileNotificationsColumn(
					setSeenNotification,
					showModal,
					setDescription
				)}
				dataSource={notifications?.data?.list}
				count={notifications?.data.pagination.total}
				loading={notificationsIsFetching || seenNotificationIsLoading}
				current={notifications?.data.pagination.current_page}
				per_page={query?.per_page}
			/>
			<DgPagination
				onPaginationHandler={handlePagination}
				responsive={true}
				per_page={query?.per_page}
				current={notifications?.data.pagination.current_page}
				total={notifications?.data.pagination.total}
			/>
		</>
	);
};

export default ProfileNotificationsTable;
