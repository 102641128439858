import { getUserInfoQuery } from 'core/hooks/react-query/getUserInfoQuery';
import React from 'react';
import DgCard from 'styles/UI/Card/DgCard';
import DgCardHeader from 'styles/UI/CardHeader/DgCardHeader';
import DgSpinnerFull from 'styles/UI/Spinner/DgSpinnerFull';
import UserActiveDeActiveStatus from './components/UserActiveDeActiveStatus';
import UserBlockUnBlockStatus from './components/UserBlockUnBlockStatus';
import UserFreezeUnFreezeStatus from './components/UserFreezeUnFreezeStatus';
import UserEmailVerifyStatus from './components/UserEmailVerifyStatus';
import UserCommissionStatus from './components/UserCommissionStatus';
import useUserInfo from '../UserInfo/hooks/useUserInfo';

const UserStatusUpdate = () => {
	const { isLoadingGetUser } = useUserInfo();

	return (
		<DgCard isMarginBottom={false}>
			<DgCardHeader title='user-status' />

			<DgSpinnerFull isLoading={isLoadingGetUser}>
				<UserCommissionStatus />
				<UserBlockUnBlockStatus />
				<UserActiveDeActiveStatus />
				<UserFreezeUnFreezeStatus />
				<UserEmailVerifyStatus />
			</DgSpinnerFull>
		</DgCard>
	);
};

export default UserStatusUpdate;
