import DgCard from 'styles/UI/Card/DgCard';
import DgCardHeader from 'styles/UI/CardHeader/DgCardHeader';
import UserPlanCommissionsTable from './components/UserPlanCommissionsTable';
import UserPlansCommissionsFilter from './components/UserPlansCommissionsFilter';

const UserPlanCommissions = () => {
	return (
		<DgCard>
			<DgCardHeader title='my-profits' />

			<UserPlansCommissionsFilter />
			<UserPlanCommissionsTable />
		</DgCard>
	);
};

export default UserPlanCommissions;
