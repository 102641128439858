import DgButton from 'styles/UI/Button/DgButton';
import { useHijackQuery } from './hooks/react-query/useHijackQuery';
import { useNavigate, useParams } from 'react-router-dom';
import { routes } from 'core/constants/routes';
import { useEffect } from 'react';
import DgSpinnerFull from 'styles/UI/Spinner/DgSpinnerFull';

const Hijack = () => {
	const { mutate, isLoading } = useHijackQuery();
	const { user_id } = useParams();
	const navigate = useNavigate();

	useEffect(() => {
		mutate({ user_id });
	}, [user_id]);

	if (isLoading) return <DgSpinnerFull isLoading><></></DgSpinnerFull>;

	return <DgButton title='back' onClick={() => navigate(routes.DASHBOARD)} />;
};

export default Hijack;
