import { forwardRef, useLayoutEffect, useRef, memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DgInput from './DgInput';
import './inputLabel.css';
import { InputProps, InputRef } from 'antd';
import { RootState } from 'core/store';
import { useSelector } from 'react-redux';

interface DgInputLabel extends InputProps {
	label: string;
	ltr?: boolean;
	className?: string;
	required?: boolean;
	value?: any;
	noLeftRound?: boolean;
	noRightRound?: boolean;
}

const DgInputLabel = forwardRef<InputRef, DgInputLabel>(({ ltr = false, label, className, required, noLeftRound = false, noRightRound = false, ...props }, ref) => {
	const { t } = useTranslation();
	const { lang } = useSelector((store: RootState) => store.settingStore);
	const labelRef = useRef<HTMLLabelElement | null>(null);
	const [value, setValue] = useState<any>('');

	useLayoutEffect(() => {
		if (props.value || value) labelRef.current?.classList?.add('active-float-label');
		else labelRef.current?.classList?.remove('active-float-label');
	}, [props.value, value, lang]);

	return (
		<div className='float-group'>
			<DgInput
				ref={ref}
				value={value}
				setValue={setValue}
				className={`h-[50px] ${noLeftRound ? 'rounded-tl-none rounded-bl-none' : ''} ${noRightRound ? 'rounded-tr-none rounded-br-none' : ''} ${className}`}
				{...props}
			/>
			<label ref={labelRef} className={`float-label ${lang === 'fa' ? (ltr ? 'left-3' : 'right-3') : 'left-3'}`}>
				{required && <span className='text-dg-red-color inline-block relative top-1 mx-1'>*</span>}
				{t(label)}
			</label>
		</div>
	);
});

DgInputLabel.displayName = 'DgInputLabel';

export default memo(DgInputLabel);
