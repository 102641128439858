import { Tooltip } from 'antd';
import { TooltipPropsWithTitle } from 'antd/es/tooltip';
import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

interface IDgTooltip extends Omit<TooltipPropsWithTitle, 'title'> {
	title: string | ReactNode;
}

const DgTooltip: FC<IDgTooltip> = ({ children, title, ...props }) => {
	const { t } = useTranslation();

	return (
		<Tooltip title={typeof title === 'string' ? t(title) : title} {...props}>
			{children}
		</Tooltip>
	);
};

export default DgTooltip;
