import React from 'react';
import DgFormItem from '../FormItem/DgFormItem';
import DgButton from './DgButton';

const DgFilterButtons = ({ resetFilter }: { resetFilter?: () => void }) => {
	return (
		<DgFormItem button>
			{resetFilter && <DgButton type='default' className='mx-4' title='clear-filter' htmlType='button' onClick={resetFilter} />}
			<DgButton title='search' className={resetFilter ? '' : 'w-72'} />
		</DgFormItem>
	);
};

export default DgFilterButtons;
