import { FC, forwardRef, useState, useEffect } from 'react';
import { CgMenuLeftAlt, CgProfile } from 'react-icons/cg';
import { useWindowSize } from '../../core/hooks/useWindowSize';
import { useNavigate } from 'react-router-dom';
import {
	Body,
	DropBody,
	DropItem,
	Flex,
	MLink,
} from '../../styles/layout-styles/HeaderStyles';
import { CLOSE_SIDEBAR_SIZE } from '../../core/constants/common';
import Text from '../../core/utils/Text';
import { MdNotificationsActive } from 'react-icons/md';
import { routes } from 'core/constants/routes';
import DgDropDown from 'styles/UI/DropDown/DgDropDown';
import { useSelector } from 'react-redux';
import { RootState } from 'core/store';
import { MenuProps } from 'antd';
import ProfileWidget from 'components/ProfileWidget/ProfileWidget';
import useMasterQueryUrlParam from 'core/hooks/useMasterQueryUrlParam';
import DgBadge from 'styles/UI/Badge/DgBadge';
import NotificationWidget from 'components/NotificationWidget/NotificationWidget';
import { getNotificationUnreadCount } from 'core/hooks/react-query/getNotificationUnreadCount';
import { useUserNotificationsQuery } from 'components/Profile/hooks/react-query/useUserNotificationsQuery';
import LanguageAndThemeWidget from 'components/LanguageAndThemeWidget/LanguageAndThemeWidget';
import SendNotificationWidget from 'components/SendNotificationWidget/SendNotificationWidget';
import logo from '../../assets/images/logo/logo.png';
import logoDark from '../../assets/images/logo/logo-dark.png';
import logoLight from '../../assets/images/logo/logo-light.png';
import { getCurrentUserInfoQuery } from 'core/hooks/react-query/getCurrentUserInfoQuery';
import { TbX } from 'react-icons/tb';
import { useBasket } from 'core/hooks/basket';
import { Basket } from '../../pages/Products/basket';

interface IMasterHeader {
	isMenuOpen?: any;
	setIsMenuOpen?: any;
}

const MasterHeader: FC<IMasterHeader> = ({ isMenuOpen, setIsMenuOpen }) => {
	const { lang, theme } = useSelector((store: RootState) => store.settingStore);

	// hooks
	const { pathname, query, setUrlParam } = useMasterQueryUrlParam();
	const { width } = useWindowSize();
	const navigate = useNavigate();

	// store
	const { notificationCount } = useSelector(
		(store: RootState) => store.notificationStore
	);

	const { data: currentUserInfo, isFetching } = getCurrentUserInfoQuery();
	const { isAuthenticated, isHijack } = useSelector(
		(store: RootState) => store.mainInfoStore
	);

	// state
	const [profileDropdownStatus, setProfileDropdownStatus] = useState<any>({});

	// query
	getNotificationUnreadCount();
	useUserNotificationsQuery('per_page=100');

	useEffect(() => {
		profileDropdownStatus?.hideDropdown?.();
	}, [pathname]);

	const goToSignin = () => {
		navigate(routes.REGISTER_SIGNING);
	};

	const respMenuClicked = () => {
		setIsMenuOpen(!isMenuOpen);
	};

	const getProfileDropdownStatus = (
		openDropdown: any,
		hideDropdown: any,
		showDropdown: any
	) => {
		setProfileDropdownStatus({ openDropdown, hideDropdown, showDropdown });
	};

	const profileDropdown: MenuProps['items'] = [
		{
			label: <ProfileWidget />,
			key: '0',
		},
	];

	const notificationDropdown: MenuProps['items'] = [
		{
			label: <NotificationWidget />,
			key: '0',
		},
	];

	return (
		<>
			<div className='py-5 sticky top-0 z-[999999999] shadow-dg-header-shadow shadow-[0px_2px_12px] bg-header-bg'>
				<Body className='w-full px-3 xs:px-12 text-white gap-2'>
					<div className='flex flex-1 items-center gap-5 relative top-[1px]'>
						<img
							src={theme === 'dark' ? logoDark : logoLight}
							width={'50px'}
							className='cursor-pointer'
							onClick={() => navigate(routes.DASHBOARD)}
						/>
					</div>
					<div className='flex items-center gap-7'>
						<SendNotificationWidget />

						{isAuthenticated && <Basket />}

						{isAuthenticated && (
							<Flex style={{ position: 'relative' }}>
								<DgDropDown menu={{ items: notificationDropdown }} placement='bottomLeft'>
									<DgBadge count={notificationCount} offset={[5, -5]}>
										<MdNotificationsActive
											color='var(--dg-opposite-color)'
											className='cursor-pointer hover:text-dg-primary-color'
											size={width < CLOSE_SIDEBAR_SIZE ? 20 : 25}
										/>
									</DgBadge>
								</DgDropDown>
							</Flex>
						)}

						<LanguageAndThemeWidget />

						{isAuthenticated ? (
							<DgDropDown
								getDropdownStatus={getProfileDropdownStatus}
								menu={{ items: profileDropdown }}
							>
								<CgProfile
									size={27}
									className='cursor-pointer hover:text-dg-primary-color'
									color='var(--dg-opposite-color)'
								/>
							</DgDropDown>
						) : (
							<div className='flex gap-4'>
								<MLink
									onClick={() =>
										setUrlParam({ page: !query?.page || query?.page === 1 ? 2 : 1 })
									}
									className='text-dg-white-color rounded-xl py-3 px-5 flex items-center gap-1 border border-dg-primary-color'
								>
									{!query?.page || query?.page === 1 ? (
										<Text
											tid='register'
											className='text-sm font-bold text-dg-primary-color'
										/>
									) : (
										query?.page === 2 && (
											<Text
												tid='login'
												className='text-sm font-bold text-dg-primary-color'
											/>
										)
									)}
								</MLink>
							</div>
						)}

						{/* {width < CLOSE_SIDEBAR_SIZE && isAuthenticated && (
							<div className='cursor-pointer' onClick={respMenuClicked}>
								<CgMenuLeftAlt size={28} color='#c3c5b7' />
							</div>
						)} */}
					</div>
				</Body>
			</div>
		</>
	);
};

interface IDropdown {
	options: any;
	active: any;
	onOptionClicked: (option: any) => void;
}

const Dropdown = forwardRef<HTMLDivElement, IDropdown>(
	({ options, active, onOptionClicked }, ref) => {
		return (
			<DropBody className='bg-dg-dropdown-bg' ref={ref}>
				{options.map((option: any) => (
					<DropItem
						key={option}
						onClick={() => onOptionClicked(option)}
						active={active === option}
					>
						<Flex style={{ padding: '0 20px' }}>{option}</Flex>
					</DropItem>
				))}
			</DropBody>
		);
	}
);

Dropdown.displayName = 'Dropdown';

export default MasterHeader;
