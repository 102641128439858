import { Provider } from 'react-redux';
import store, { persistor } from 'core/store';
import { FC, ReactNode } from 'react';
import { PersistGate } from 'redux-persist/integration/react';

interface IReduxProvider {
	children: ReactNode;
}

const ReduxProvider: FC<IReduxProvider> = ({ children }) => {
	return (
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				{children}
			</PersistGate>
		</Provider>
	);
};

export default ReduxProvider;
