import { FC, useEffect } from 'react';
import { FlexCenter } from '../../../styles/CommonStyles';
import { QrBox } from '../../../styles/SecurityStyles';
import { FaRegCopy, FaStop } from 'react-icons/fa';
import Text from '../../../core/utils/Text';
import DgButton from 'styles/UI/Button/DgButton';
import useCopyToClipboard from 'core/hooks/useCopyToClipboard';
import { useGoogle2FASecretQuery } from '../hooks/react-query/useGoogle2FASecretQuery';
import DgImage from 'styles/UI/Image/DgImage';
import { convertSvgToUrl } from 'core/utils/utils';

interface IScanQr {
	onPrevStepHandler: any;
	onNextStepHandler: any;
	setLoading: any;
	setActive2fa: any;
}

const ScanQr: FC<IScanQr> = ({
	onPrevStepHandler,
	onNextStepHandler,
	setLoading,
	setActive2fa,
}) => {
	const { data: active2fa, mutate: activate2fa, isLoading } = useGoogle2FASecretQuery();
	const { copyHandler } = useCopyToClipboard();

	useEffect(() => {
		setActive2fa(active2fa);
	}, [setActive2fa, active2fa]);

	useEffect(() => {
		activate2fa({});
	}, []);

	useEffect(() => {
		setLoading(isLoading);
	}, [isLoading, setLoading]);

	return (
		<FlexCenter style={{ flexDirection: 'column', maxWidth: '700px', margin: '0 auto' }}>
			{active2fa?.data?.data?.secret && (
				<>
					<h3>
						<Text tid='scan-qr-title' />
					</h3>
					<QrBox>
						<DgImage
							preview
							src={convertSvgToUrl(active2fa?.data?.data?.google2fa_url)}
						/>
						<h3 className='mt-2'>SCAN</h3>
					</QrBox>
					<QrBox>
						<FlexCenter>
							<div className='mt-1 text-base'>{active2fa?.data?.data?.secret}</div>
							<FaRegCopy
								onClick={() => copyHandler(active2fa?.data?.data?.secret)}
								style={{ margin: '0 10px', cursor: 'pointer' }}
								size={28}
							/>
						</FlexCenter>
					</QrBox>
					<div className='flex justify-center my-5'>
						<FaStop
							color='#ffc800'
							size={22}
							style={{ transform: 'rotate(45deg)', margin: '0 10px' }}
						/>
						<div className='text-[var(--dg-primary-color)]'>
							<Text tid='scan-qr-desc' />
						</div>
					</div>

					<div className='flex justify-between w-full'>
						<DgButton
							type='default'
							className='mt-8'
							onClick={onPrevStepHandler}
							title='prev-step'
						/>
						<DgButton
							className='mt-8'
							onClick={() => onNextStepHandler('valid')}
							title='next-step'
						/>
					</div>
				</>
			)}
		</FlexCenter>
	);
};

export default ScanQr;
