import TransactionPassword from 'components/Profile/components/ProfileSecurity/components/TransactionPassword';
import SponsorIdCreateForm from 'components/SponsorId/SponsorIdCreateForm';
import Text from 'core/utils/Text';
import React from 'react';
import { FiAlertCircle } from 'react-icons/fi';
import DgAlert from 'styles/UI/Alert/DgAlert';

const TransactionPasswordAlert = () => {
	return (
		<DgAlert
			className='bg-[var(--dg-red-color-2)] border-0 lg-600:flex-row flex-col gap-5'
			message={
				<p className='flex gap-x-3 m-0 h-10 items-center'>
					<span>
						<FiAlertCircle size={22} color='var(--dg-white-color)' />
					</span>
					<Text
						tid='please-enter-your-transaction-password'
						className='text-base text-[var(--dg-white-color)]'
					/>
				</p>
			}
			action={<TransactionPassword type={'raw'} />}
		/>
	);
};

export default TransactionPasswordAlert;
