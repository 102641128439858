import useSocket from 'core/services/socket/useSocket';
import { FC, ReactNode, useEffect, useState } from 'react';
import { getCurrentUserInfoQuery } from '../../hooks/react-query/getCurrentUserInfoQuery';
import { useDispatch, useSelector } from 'react-redux';
import {
	setNotificationSocketData,
	setSocketConnectionStatus,
} from 'core/store/slice/socket/socketSlice';
import {
	setNotificationCount,
	setNotificationList,
} from 'core/store/slice/notification/notificationSlice';
import { INotificationSocketData } from 'core/types/models/notificationType';
import { RootState } from 'core/store';
import useQueryUrlParam from 'core/hooks/useQueryUrlParam';
import { IDepositType } from 'core/types/models/depositType';
import { setDepositDetail } from 'core/store/slice/deposit/depositSlice';
import { setWithdrawDetail } from 'core/store/slice/withdraw/withdrawSlice';

interface INotificationsProvider {
	children: ReactNode;
}

const NotificationsProvider: FC<INotificationsProvider> = ({ children }) => {
	// query
	const { data: currentUserInfo } = getCurrentUserInfoQuery();

	// hooks
	const { socket } = useSocket();
	const { query } = useQueryUrlParam();
	const dispatch = useDispatch();

	// state
	const [notification, setNotification] = useState<INotificationSocketData>();
	const [depositDetailState, setDepositDetailState] = useState<IDepositType>();

	// store
	const { notificationList, notificationCount } = useSelector(
		(store: RootState) => store.notificationStore
	);

	/* latency */
	// useEffect(() => {
	// 	let interval: any;
	// 	let start: any;
	// 	if (socket) {
	// 		interval = setInterval(() => {
	// 			start = Date.now();
	// 			socket.emit('ping');
	// 		}, 1000);

	// 		socket.on('pong', () => {
	// 			setLatency(Date.now() - start);
	// 		});

	// 		socket.on('disconnect', () => {
	// 			setLatency(0);
	// 		});
	// 	}

	// 	return () => {
	// 		if (interval) clearInterval(interval);
	// 		if (socket) socket.off('ping');
	// 	};
	// }, [socket]);

	useEffect(() => {
		let interval: any;
		if (socket) {
			interval = setInterval(() => {
				socket.emit('ping');
			}, 1000);

			socket.on('pong', () => {
				dispatch(setSocketConnectionStatus(true));
			});

			socket.on('disconnect', () => {
				dispatch(setSocketConnectionStatus(false));
			});
		}

		return () => {
			if (interval) clearInterval(interval);
			if (socket) socket.off('ping');
		};
	}, [socket]);

	// get notification
	useEffect(() => {
		if (notification) {
			dispatch(setNotificationSocketData(notification));
			dispatch(
				setNotificationCount(
					notification.type === 'GLOBAL_NOTIFICATION'
						? notificationCount + 1
						: notification.unread_count
				)
			);
			dispatch(
				setNotificationList([
					{
						body: notification.body,
						created_at: notification.created_at,
						id: notification.id,
						is_read: 0,
						title: notification.title,
						type: notification.type,
					},
					...notificationList,
				])
			);
		}
	}, [notification]);
	useEffect(() => {
		let isMounted = true;
		if (currentUserInfo?.data.id && socket && isMounted) {
			socket.on(`user-${currentUserInfo?.data.id}-notifications`, (res: any) =>
				setNotification(JSON.parse(res))
			);
			socket.on('user-global-notifications', (res: any) =>
				setNotification(JSON.parse(res))
			);
		}

		return () => {
			if (socket) {
				socket.off(`user-${currentUserInfo?.data.id}-notifications`);
				socket.off('user-global-notifications');
			}
			isMounted = false;
		};
	}, [currentUserInfo?.data.id, !!socket]);

	// get deposit detail
	useEffect(() => {
		if (depositDetailState) {
			dispatch(setDepositDetail(depositDetailState));
			dispatch(setWithdrawDetail(depositDetailState));
		}
	}, [depositDetailState]);
	useEffect(() => {
		let isMounted = true;
		if (query?.transaction_id && socket && isMounted) {
			socket.on(query?.transaction_id, (res: any) =>
				setDepositDetailState(JSON.parse(res))
			);
		}

		return () => {
			if (socket) {
				socket.off(query?.transaction_id);
			}
			isMounted = false;
		};
	}, [query?.transaction_id, !!socket]);

	return <>{children}</>;
};

export default NotificationsProvider;
