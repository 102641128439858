import { FC } from 'react';
import { ScaleLoader } from 'react-spinners';

interface IDgSpinner {
	width?: number;
	height?: number;
}

const DgSpinner: FC<IDgSpinner> = ({ width = 2, height = 24 }) => {
	return (
		<div className='flex justify-center items-center h-full'>
			<ScaleLoader width={width} height={height} color='#ffc800' />
		</div>
	);
};

export default DgSpinner;
