import { FlexCenter } from '../../../styles/CommonStyles';
import { AppContainer, App } from '../../../styles/SecurityStyles';
import { FaApple, FaGooglePlay, FaFileDownload } from 'react-icons/fa';
import Text from '../../../core/utils/Text';
import DgButton from 'styles/UI/Button/DgButton';
import { FC } from 'react';

interface IAppDownload {
	onNextStepHandler: (value: 'valid') => void;
}

const AppDownload: FC<IAppDownload> = ({ onNextStepHandler }) => {
	return (
		<FlexCenter style={{ flexDirection: 'column', maxWidth: '700px', margin: '0 auto' }}>
			<div>
				<Text tid='download-app-title' />
			</div>
			<AppContainer>
				<App target='blank' href='https://apps.apple.com/us/app/google-authenticator/id388497605'>
					<FaApple color='#c3c5b7' size={74} />
					<div className='mt-3'>App Store</div>
				</App>
				<App target='blank' href='https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en_CA&gl=US'>
					<FaGooglePlay color='#c3c5b7' size={62} />
					<div className='mt-4'>Google Play</div>
				</App>
			</AppContainer>
			<FlexCenter>
				<FaFileDownload color='#ffc800' size={28} style={{ margin: '0 10px' }} />
				<div color='#ffc800'>
					<Text tid='how-to-download' />
				</div>
			</FlexCenter>

			<div className='flex justify-between w-full'>
				<DgButton type='default' className='mt-8' disabled title='prev-step' />
				<DgButton className='mt-8' onClick={() => onNextStepHandler('valid')} title='next-step' />
			</div>
		</FlexCenter>
	);
};

export default AppDownload;
