import { useAdminPackageQuery } from 'components/Admin/Package/List/hooks/react-query/useAdminPackageQuery';
import useTranslate from 'core/utils/useTranslate';
import DgSelectLabel from 'styles/UI/Select/DgSelectLabel';

const DgSelectPackageCategory = ({ ...props }) => {
	// const { data, isFetching } = useAdminPackageQuery();

	const { t } = useTranslate();

	// const options = data?.data?.map((item) => ({
	// 	label: item.name,
	// 	value: item.id,
	// 	filter: item.name,
	// 	allItems: { ...item },
	// }));

	const options = [
		{
			label: t('6month'),
			value: '6month',
			// filter: item.name,
			// allItems: { ...item },
		},
	];

	return (
		<DgSelectLabel
			// disabled
			label='package-type'
			options={options}
			value={options[0].value}
			{...props}
		/>
	);
};

export default DgSelectPackageCategory;
