import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'core/store';
import { getUserInfoQuery } from '../../../../../core/hooks/react-query/getUserInfoQuery';
import DgSpinnerFull from 'styles/UI/Spinner/DgSpinnerFull';
import DgReferralCode from 'styles/UI/RefferalCode/DgReferralCode';
import DgDetailIcon from 'styles/UI/DetailIcon/DgDetailIcon';
import { routes } from 'core/constants/routes';
import UserTableQuickAccess from '../../List/components/UsertableQuickAccess';
import DgDropDown from 'styles/UI/DropDown/DgDropDown';
import { FiMoreVertical } from 'react-icons/fi';
import DgConnectIcon from 'styles/UI/ConnectIcon/DgConnectIcon';

const SidebarUserInfo = () => {
	const { userDetail } = useSelector((store: RootState) => store.userInfoStore);
	const { isFetching } = getUserInfoQuery();

	return (
		<DgSpinnerFull isLoading={isFetching}>
			<div className='bg-[var(--dg-card-bg-1)] rounded-t-xl px-3 py-5 flex flex-col gap-y-2 text-center border-b-0 md:mb-0 mb-5 md:border-b border-[var(--dg-border-color)]'>
				<span>{userDetail?.data?.full_name}</span>
				<DgReferralCode hasHorizontal={false} member_id={userDetail?.data?.member_id} />
			</div>

			<div className='flex justify-between items-center gap-6 p-6'>
				<DgDropDown
					trigger={['hover']}
					menu={{ items: UserTableQuickAccess({ userId: userDetail?.data?.id }) }}
					placement='bottomRight'
				>
					<FiMoreVertical className='cursor-pointer' size={25} />
				</DgDropDown>

				<DgConnectIcon title='hijack' to={routes.HIJACK.link(userDetail?.data?.id)} />
			</div>
		</DgSpinnerFull>
	);
};

export default SidebarUserInfo;
