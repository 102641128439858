import { Form } from 'antd';
import useModal from 'core/hooks/useModal';
import { useRunAfterUpdate } from 'core/hooks/useRunAfterUpdate';
import { onInputValueChangeUtil } from 'core/utils/useInputValueChange';
import { extractNumberFromString } from 'core/utils/utils';
import { FormEvent, useEffect, useState } from 'react';
import { useWithdrawQuery } from './react-query/useWithdrawQuery';
import { useEarningWithdrawQuery } from './react-query/useEarningWithdrawQuery';

const useWithdrawStepOne = () => {
	// hooks
	const { modal, showModal, hideModal } = useModal();
	const [form] = Form.useForm();
	const runAfterUpdate = useRunAfterUpdate();

	// state
	const [amount, setAmount] = useState<string>('');
	const [invoicePreviewDetails, setInvoicePreviewDetails] = useState<{
		transaction_password: string;
		currency: string;
		network: string;
		amount: string;
		address: string;
		one_time_password: string;
		type?: string;
	} | null>(null);

	// query
	// const { mutate: withdraw, isLoading: isLoadingWithdraw } = useWithdrawQuery();
	// 	// const { mutate: earningWithdraw, isLoading: isLoadingEarningWithdraw } =
	// 	// 	useEarningWithdrawQuery();

	const { mutate: withdraw, isLoading: withdrawIsLoading } = useWithdrawQuery();

	// const withdrawIsLoading = isLoadingEarningWithdraw || isLoadingWithdraw;

	const onChangeInputHandler = (e: FormEvent<HTMLInputElement>) => {
		setAmount(onInputValueChangeUtil(e, runAfterUpdate));
	};

	const onFinishWithdrawHandler = (values: {
		amount: string;
		address: string;
		one_time_password: string;
		transaction_password: string;
		type?: string;
	}) => {

		setInvoicePreviewDetails({
			...form.getFieldsValue(true),
			...values,
			amount: extractNumberFromString(values.amount),
			one_time_password: values.one_time_password,
			transaction_password: values.transaction_password,
			currency: 'USDT',
			network: 'TRC20',
		});
		showModal();
	};

	const onWithdrawHandler = () => {
		withdraw(
				{
					amount: invoicePreviewDetails?.amount,
					address: invoicePreviewDetails?.address,
					transaction_password: invoicePreviewDetails?.transaction_password,
					one_time_password: invoicePreviewDetails?.one_time_password,
					type: invoicePreviewDetails?.type === 'main-wallet-withdraw' ? 'deposit' : 'earning'
				},
				{ onSuccess: () => {
						hideModal();
						form.resetFields();
					} }
			);
		// if (invoicePreviewDetails?.type === 'main-wallet-withdraw') {
		// 	withdraw(
		// 		{
		// 			amount: invoicePreviewDetails?.amount,
		// 			address: invoicePreviewDetails?.address,
		// 			one_time_password: invoicePreviewDetails?.one_time_password,
		// 		},
		// 		{ onSuccess: () => hideModal() }
		// 	);
		// } else if (invoicePreviewDetails?.type === 'earning-wallet-withdraw') {
		// 	earningWithdraw(
		// 		{
		// 			amount: invoicePreviewDetails?.amount,
		// 			address: invoicePreviewDetails?.address,
		// 			one_time_password: invoicePreviewDetails?.one_time_password,
		// 		},
		// 		{ onSuccess: () => hideModal() }
		// 	);
		// }
	};

	useEffect(() => {
		form.setFieldsValue({ amount });
	}, [amount]);

	return {
		modal,
		showModal,
		hideModal,
		form,
		amount,
		onChangeInputHandler,
		onFinishWithdrawHandler,
		invoicePreviewDetails,
		onWithdrawHandler,
		withdrawIsLoading,
	};
};

export default useWithdrawStepOne;
