import DashboardCommissions from '../DashboardCommissions/DashboardCommissions';
import DashboardPlans from '../DashboardPlans/DashboardPlans';
import DashboardTRansactions from '../DashboardTransactions/DashboardTRansactions';

const DashboardTables = () => {
	return (
		<div className='grid lg-969:grid-cols-12 grid-cols-1 gap-4'>
			<DashboardPlans />
			<DashboardCommissions />
			<DashboardTRansactions />
		</div>
	);
};

export default DashboardTables;
