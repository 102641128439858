import { useEffect, useState } from 'react';
import useMasterQueryUrlParam from './useMasterQueryUrlParam';

const useQueryUrlParam = () => {
	const { filterQuery, ...props } = useMasterQueryUrlParam();

	const [filterParams, setFilterParams] = useState(filterQuery);

	useEffect(() => {
		setFilterParams(filterQuery);
	}, [filterQuery]);

	return { ...props, filterParams };
};

export default useQueryUrlParam;
