import { FC } from 'react';
import DgPrice from 'styles/UI/Price/DgPrice';

interface IOrderPrice {
	price: number;
	selected: boolean;
	onClick: () => void;
}

const GoldOrderPrice: FC<IOrderPrice> = ({ price, selected, onClick }) => {
	return (
		<div
			onClick={onClick}
			className={`grow border whitespace-nowrap justify-center px-1 h-[35px] flex items-center text-white text-center rounded-lg cursor-pointer hover:shadow-xl transition-all ${
				selected ? 'bg-[var(--dg-orange-color)] text-[var(--dg-primary-color-fixed)]' : ''
			}`}
		>
			<DgPrice
				suffixColor={selected ? 'text-[var(--dg-primary-color-fixed)]' : ''}
				price={price}
			/>
		</div>
	);
};

export default GoldOrderPrice;
