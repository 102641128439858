import useCopyToClipboard from 'core/hooks/useCopyToClipboard';
import Text from 'core/utils/Text';
import { FaRegCopy } from 'react-icons/fa';
import DgTooltip from '../Tooltip/DgTooltip';
import { hiddenText } from 'core/utils/utils';
import { HiQrcode } from 'react-icons/hi';
import DgModal from '../Modal/DgModal';
import useModal from 'core/hooks/useModal';
import QRCode from 'react-qr-code';

const DgCopyAddress = ({
	address,
	hasTitle = true,
	hasHorizontal = true,
	hasQrCode = true,
}: {
	address: string | number;
	hasTitle?: boolean;
	hasHorizontal?: boolean;
	hasQrCode?: boolean;
}) => {
	const { copyHandler } = useCopyToClipboard();
	const { modal, hideModal, showModal } = useModal();

	const { hiddenValue } = hiddenText(address + '');

	return (
		<>
			{!!modal && (
				<DgModal width={270} open={modal} onCancel={hideModal} title='qrcode-scan'>
					<div className='flex flex-col space-y-5 items-center'>
						<div className='p-2 bg-dg-white-color'>
							<QRCode size={200} value={address + ''} />
						</div>

						<Text tid='qrcode-scan' className='font-bold text-xl' />
					</div>
				</DgModal>
			)}

			{hasHorizontal ? (
				<span className='max-w-[330px] flex px-4 py-2.5 rounded-lg bg-[var(--dg-card-bg-2)] justify-center gap-2 md:justify-between items-center'>
					{hasTitle && <Text tid='wallet-address' />}
					<span className='text-[var(--dg-primary-color-fixed)] dark:text-[var(--dg-orange-color)]'>{hiddenValue}</span>
					<DgTooltip title='copy'>
						<div onClick={() => copyHandler(address + '')} className='cursor-pointer'>
							<FaRegCopy size={20} />
						</div>
					</DgTooltip>

					{hasQrCode && (
						<DgTooltip title='scan'>
							<div onClick={showModal} className='cursor-pointer'>
								<HiQrcode size={20} />
							</div>
						</DgTooltip>
					)}
				</span>
			) : (
				<span className='flex px-4 py-2.5 rounded-lg bg-[var(--dg-card-bg-2)] justify-between md:gap-1 max-w-[300px] w-full mx-auto xs:gap-5 gap-2 md:justify-between items-center'>
					<div className='flex-col flex items-start'>
						{hasTitle && <Text tid='wallet-address' />}
						<span className='text-[var(--dg-primary-color-fixed)] dark:text-[var(--dg-orange-color)]'>{hiddenValue}</span>
					</div>
					<DgTooltip title='copy'>
						<div onClick={() => copyHandler(address + '')} className='cursor-pointer'>
							<FaRegCopy size={20} />
						</div>
					</DgTooltip>

					{hasQrCode && (
						<DgTooltip title='scan'>
							<div onClick={showModal} className='cursor-pointer'>
								<HiQrcode size={20} />
							</div>
						</DgTooltip>
					)}
				</span>
			)}
		</>
	);
};

export default DgCopyAddress;
