import React from 'react';
import DgCard from 'styles/UI/Card/DgCard';
import DgCardHeader from 'styles/UI/CardHeader/DgCardHeader';
import DgModal from 'styles/UI/Modal/DgModal';
import useUserRole from './hooks/useUserRole';
import UserRoleUpdate from './components/UserRoleUpdate';
import UserRoleTable from './components/UserRoleTable';

const UserRoles = () => {
	const { hideModalHandler, modal, showModal, isLoadingUserRole, onChangeRoles, onDeselectRole, onFinishUpdateUser, roles } = useUserRole();

	return (
		<>
			<DgModal title='update-user-role' open={modal} onCancel={hideModalHandler}>
				<UserRoleUpdate roles={roles} isLoadingUserRole={isLoadingUserRole} onChangeRoles={onChangeRoles} onDeselectRole={onDeselectRole} onFinishUpdateUser={onFinishUpdateUser} />
			</DgModal>
			<DgCard>
				<DgCardHeader title='user-role' onClick={showModal} buttonText='update-user-role' />

				<UserRoleTable />
			</DgCard>
		</>
	);
};

export default UserRoles;
