import { FC } from 'react';
import { twMerge } from 'tailwind-merge';

interface IUserWithdrawNetworkItem {
	network: string;
	selected: boolean;
	disabled: boolean;
	onClick: () => void;
}

const UserWithdrawNetworkItem: FC<IUserWithdrawNetworkItem> = ({
	network,
	selected,
	onClick,
	disabled,
}) => {
	return (
		<div
			onClick={onClick}
			className={twMerge(
				`border border-[var(--dg-border-color)] whitespace-nowrap justify-center px-5 h-[35px] flex items-center text-[var(--dg-opposite-color)] text-center rounded-lg cursor-pointer hover:shadow-xl transition-all ${
					selected
						? 'bg-[var(--dg-secondary-color)] border border-[var(--dg-primary-color)]'
						: ''
				} ${disabled ? 'cursor-not-allowed' : ''}`
			)}
		>
			{network}
		</div>
	);
};

export default UserWithdrawNetworkItem;
