import { createSlice } from '@reduxjs/toolkit';
import { IWallet } from 'core/types/models/walletsType';

const initialState: { userWallet: IWallet | null } = { userWallet: null };

export const walletSlice = createSlice({
	name: 'wallet',
	initialState: initialState,
	reducers: {
		setUserWallet: (state, { payload }) => {
			state.userWallet = payload?.data.filter(
				(item: IWallet) => item.name === 'Deposit Wallet'
			)?.[0];
		},
	},
});

export const { setUserWallet } = walletSlice.actions;
export default walletSlice.reducer;
