import AdminUserDetail from 'components/Admin/User/Detail/AdminUserDetail';
import React from 'react';

const AdminUserPageDetail = () => {
	return (
		<div className='responsive-layout'>
			<AdminUserDetail />
		</div>
	);
};

export default AdminUserPageDetail;
