const getMainTheme = (theme, lang) => {
	const MainTheme = {
		dark: {
			mainBg: '#212e51',
			secondaryBg: '#17213c',
			primaryBg: '#18223df2',
			color: '#c3c5b7',
			secondary: '#eaecef',
			primary: 'white',
			hover: '#12192d',
			active: '#58a0f8',
			deActive: '#58a0f890',
			exitRed: '#a12356',
			mainOrange: '#58a0f8',
			mainRed: '#ff3636',
			mainGreen: '#1ce087',
			search: '#18223df2',
			input: '#18223df2',
			textArea: '#44464c',
			dropdown: '#36383C',
			black: '#000000',
			tInputBg: '#212e51',
			profileTitle: '#58a0f8',
		},
		light: {
			mainBg: '#ffffff',
			secondaryBg: '#ffffff',
			primaryBg: '#ececec',
			color: '#212e51',
			secondary: '#595e67',
			primary: '#595e67',
			hover: '#dadada',
			active: '#58a0f8',
			deActive: '#58a0f890',
			exitRed: '#a12356',
			mainOrange: '#58a0f8',
			mainRed: '#d54a61',
			mainGreen: '#008e47',
			search: '#f0f1f2',
			input: '#ececec',
			textArea: '#f0f1f2',
			dropdown: '#f0f1f2',
			tradeBg: '#ffffff',
			otcInput: '#f0f1f2',
			black: '#000000',
			orderHover: '#5e6673',
			tInputBg: '#f0f1f2',
			profileTitle: '#50514c',
		},
	};

	const chosenTheme = { ...MainTheme[theme] };
	chosenTheme.english = lang === 'en';
	return chosenTheme;
};

export { getMainTheme };
