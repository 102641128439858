import { createSlice } from '@reduxjs/toolkit';
import { ISubmitOrderPayload } from 'core/types/models/submitOrderType';

const initialState: { currentSubmitOrderCategoryPrice: ISubmitOrderPayload } = { currentSubmitOrderCategoryPrice: { category_id: 1, price: 100 } };

export const submitOrderCategoryPriceSlice = createSlice({
	name: 'submitOrderCategoryPrice',
	initialState: initialState,
	reducers: {
		setSubmitOrderCategoryPricePayload: (state, { payload }: { payload: ISubmitOrderPayload }) => {
			state.currentSubmitOrderCategoryPrice = payload;
		},
	},
});

export const { setSubmitOrderCategoryPricePayload } = submitOrderCategoryPriceSlice.actions;
export default submitOrderCategoryPriceSlice.reducer;
