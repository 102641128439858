import React, { FC } from 'react';
import SponsorIdAlert from './components/SponsorIdAlert';
import { getCurrentUserInfoQuery } from 'core/hooks/react-query/getCurrentUserInfoQuery';
import Google2faAlert from './components/Google2faAlert';
import TransactionPasswordAlert from './components/TransactionPasswordAlert';

const HeaderAlert: FC = () => {
	const { data: currentUserInfo, isFetching } = getCurrentUserInfoQuery();

	return (
		<div className='md:grid hidden grid-cols-2 gap-4'>
			{!isFetching && !currentUserInfo?.data?.sponsor_id && <SponsorIdAlert />}
			{!isFetching && !currentUserInfo?.data?.google2fa_enable && <Google2faAlert />}
			{!isFetching && !currentUserInfo?.data?.has_transaction_password && (
				<TransactionPasswordAlert />
			)}
		</div>
	);
};

export default HeaderAlert;
