import DgCard from 'styles/UI/Card/DgCard';
import UserWithdrawFormStepOne from './components/UserWithdrawFormStepOne';
import useMasterQueryUrlParam from 'core/hooks/useMasterQueryUrlParam';
import UserWithdrawStepTwoForm from './components/UserWithdrawStepTwoForm';
import DgAlert from 'styles/UI/Alert/DgAlert';
import useTranslate from 'core/utils/useTranslate';
import { stringToBoolean } from 'core/utils/utils';

const UserWithdraw = () => {
	const { t } = useTranslate();
	const { query } = useMasterQueryUrlParam();

	return (
		<>
			<DgCard
				hasBackground={false}
				className='border-2 relative border-dashed border-[var(--dg-opposite-color)] max-w-2xl mx-auto'
			>
				{stringToBoolean(process.env.REACT_APP_WITHDRAW_DISABLED) && (
					<div className='flex md:items-center items-start p-5 justify-center absolute w-full h-full top-0 left-0 right-0 z-50 backdrop-blur-sm'>
						<DgAlert
							type='warning'
							message={t('dear-user')}
							description={t('disabled-withdraw-description')}
							className='text-black bg-yellow-100'
						/>
					</div>
				)}

				{query?.tab !== 2 && <UserWithdrawFormStepOne />}
				{/*{query?.tab === 2 && <UserWithdrawStepTwoForm />}*/}
			</DgCard>
		</>
	);
};

export default UserWithdraw;
