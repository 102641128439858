import UserWalletHeader from 'components/UserWallets/components/UserWalletHeader';
import DashboardFake from './components/DashboardFake';
import DashboardTables from './components/DashboardTables/DashboardTables';
import { useBannersQuery } from 'components/Admin/Banner/List/hooks/react-query/useBannersQuery';
import { useBannerPreview } from 'components/Admin/Banner/List/AdminBanner';
import { Link } from 'react-router-dom';
import Text from 'core/utils/Text';
import useUserWallet from 'components/UserWallets/hook/useUserWallet';
import { TbLocationDollar } from 'react-icons/tb';
import { CiSaveDown1, CiSaveUp1 } from 'react-icons/ci';

import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCoverflow, Pagination } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import DgCard from 'styles/UI/Card/DgCard';
import { routes } from 'core/constants/routes';
import { RiHistoryFill } from 'react-icons/ri';
import DgPrice from 'styles/UI/Price/DgPrice';
import { FaUserShield } from 'react-icons/fa';
import { VscLayersActive } from 'react-icons/vsc';
import { AiOutlineDollarCircle } from 'react-icons/ai';
import { BsCalculator, BsFillCartPlusFill } from 'react-icons/bs';
import { MdAdminPanelSettings } from 'react-icons/md';
import { ImTree } from 'react-icons/im';
import { BiRecycle } from 'react-icons/bi';
import { IoIosNotificationsOutline } from 'react-icons/io';

const Dashboard = () => {
	const { deposit_Wallet, userWalletIsFetching } = useUserWallet();

	const { data: { data: banners } = {} as any, isFetching: bannersIsFetching } =
		useBannersQuery() as any;

	const { makePreview } = useBannerPreview();

	return (
		<div className='flex flex-col items-stretch gap-12'>
			<div className='md:hidden flex flex-col gap-4'>
				<div className='flex flex-col gap-2 items-center justify-center'>
					<Text tid='app-slogan' className='text-2xl font-bold' />
					<Text tid='app-title' className='text-dg-orange-color text-2xl font-bold' />
				</div>

				<div className='w-full min-w-0 relative mt-8'>
					<div
						className='absolute w-[100vw] h-full -top-6 left-[50%] -translate-x-[50%] z-0 rounded-t-[4rem]'
						style={{
							background:
								'linear-gradient(to bottom,  rgba(173,21,57,1) 0%,rgba(0,0,0,0) 100%)',
						}}
					></div>

					<div className='flex z-10 relative flex-col gap-2 mb-8 px-6'>
						<Text tid='my-balance' className='text-lg font-bold opacity-75' />
						<DgPrice className='text-2xl' price={deposit_Wallet?.balance} />
					</div>

					<Swiper
						effect={'coverflow'}
						grabCursor={true}
						centeredSlides={true}
						slidesPerView={'auto'}
						coverflowEffect={{
							rotate: 50,
							stretch: 0,
							depth: 100,
							modifier: 1,
							slideShadows: true,
						}}
						pagination={true}
						modules={[EffectCoverflow, Pagination]}
						className='z-10 relative [&>.swiper-button-next]:!text-white [&>.swiper-button-prev]:!text-white [&>.swiper-pagination]:!text-white'
					>
						{[
							{
								label: 'transaction-history',
								link: routes.TRANSACTION,
								icon: RiHistoryFill,
							},
							{
								label: 'increase-balance',
								link: routes.WALLETS_DEPOSIT,
								icon: CiSaveDown1,
							},
							{
								label: 'decrease-balance',
								link: routes.WALLETS_WITHDRAW,
								icon: CiSaveUp1,
							},
							{
								label: 'transaction-history',
								link: routes.TRANSACTION,
								icon: RiHistoryFill,
							},
							{
								label: 'increase-balance',
								link: routes.WALLETS_DEPOSIT,
								icon: CiSaveDown1,
							},
							{
								label: 'decrease-balance',
								link: routes.WALLETS_WITHDRAW,
								icon: CiSaveUp1,
							},
						].map((item, index) => (
							<SwiperSlide
								className='w-full max-w-[200px] !shadow-none'
								key={JSON.stringify(item)}
							>
								<Link to={item.link}>
									<DgCard
										style={{
											background:
												'linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(173,21,57,1) 50%,rgba(0,0,0,0) 100%)',
										}}
										className=' !shadow-none py-6 flex items-center justify-center gap-2 flex-col'
									>
										<div className='bg-header-bg rounded-full p-4 aspect-square'>
											<item.icon size={'2.25rem'} />
										</div>

										<Text
											tid={item.label}
											className='text-lg font-bold text-center leading-tight'
										/>
									</DgCard>
								</Link>
							</SwiperSlide>
						))}
					</Swiper>
				</div>

				<div className='flex z-10 relative flex-col gap-3'>
					<Text tid='other-actions' className='text-lg font-bold mb' />

					<div className='grid grid-cols-3 gap-x-4'>
						{[
							{
								label: 'admin-panel',
								link: routes.ADMIN_USER,
								icon: MdAdminPanelSettings,
							},
							{
								label: 'package-purchase',
								link: routes.ORDER_SUBMIT_STEPS.link(1),
								icon: BsFillCartPlusFill,
							},
							{
								label: 'wallet',
								link: routes.WALLETS_WITHDRAW,
								icon: CiSaveUp1,
							},
							{
								label: 'my-profits',
								link: routes.PLANS_COMMISSIONS(),
								icon: AiOutlineDollarCircle,
							},
							{
								label: 'my-invest',
								link: routes.ORDERS_HISTORY,
								icon: VscLayersActive,
							},
							{
								label: 'binary-tree',
								link: routes.BINARY_TREE,
								icon: ImTree,
							},
							{
								label: 'internal-transfer',
								link: routes.INTERNAL_TRANSFER,
								icon: BiRecycle,
							},
							{
								label: 'accounting',
								link: routes.ACCOUNTING,
								icon: BsCalculator,
							},
							{
								label: 'messages',
								link: routes.NOTIFICATIONS,
								icon: IoIosNotificationsOutline,
							},
						].map((item, index) => (
							<Link key={JSON.stringify(item)} to={item.link}>
								<DgCard className=' !shadow-none mt-8 pt-6 pb-6 flex items-center justify-center gap-2 flex-col'>
									<div className='bg-header-bg text-dg-orange-color rounded-full p-4 aspect-square -mt-16'>
										<item.icon size={'2.25rem'} />
									</div>

									<Text
										tid={item.label}
										className='text-[0.92rem] font-bold text-center leading-tight'
									/>
								</DgCard>
							</Link>
						))}
					</div>
				</div>
			</div>

			<div className='md:block hidden'>
				<UserWalletHeader />
			</div>

			<div className='w-full min-w-0'>
				<Text tid='offers' className='text-lg font-bold mb-6 block md:hidden' />

				<Swiper
					// effect={'coverflow'}
					grabCursor={true}
					// slidesPerView={'auto'}
					spaceBetween={15}
					breakpoints={{
						0: {
							slidesPerView: 1,
						},
						720: {
							slidesPerView: 2,
						},
					}}
					// coverflowEffect={{
					// 	rotate: 50,
					// 	stretch: 0,
					// 	depth: 100,
					// 	modifier: 1,
					// 	slideShadows: true,
					// }}
					pagination={true}
					modules={[Pagination]}
					className='z-10 relative [&>.swiper-button-next]:!text-white [&>.swiper-button-prev]:!text-white [&>.swiper-pagination]:!text-white'
				>
					<SwiperSlide className=''>
						<Link
							to={banners?.first?.link || '#'}
							className='w-full rounded-xl flex items-center justify-center'
						>
							<img
								className={'rounded-lg h-full w-full object-cover aspect-video'}
								src={makePreview(banners?.first?.file)}
								// width={'99.5%'}
								// height={'100%'}
								// alt={'about'}
							/>
						</Link>
					</SwiperSlide>
					<SwiperSlide className=''>
						<Link
							to={banners?.second?.link || '#'}
							className='w-full rounded-xl flex items-center justify-center'
						>
							<img
								className={'rounded-lg h-full w-full object-cover aspect-video'}
								src={makePreview(banners?.second?.file)}
								// width={'99.5%'}
								// height={'100%'}
								// alt={'faq'}
							/>
						</Link>
					</SwiperSlide>
				</Swiper>
			</div>

			<DashboardFake />

			<DashboardTables />
		</div>
	);
};

export default Dashboard;
