import Text from 'core/utils/Text';
import { useRef, memo } from 'react';
import DgDropDown from '../DropDown/DgDropDown';
import DgFilterItem from './DgFilterItem';
import { useSelector } from 'react-redux';
import { RootState } from 'core/store';
import { filterOptionsType } from 'core/types/components/filterType';
import { MenuProps } from 'antd';

const DgFilterItems = ({
	handlers,
	width,
}: {
	handlers: {
		filterOptions: filterOptionsType[];
		selectedFilterHandler: (data: any, index: number) => void;
		selected: { index: number | null; data: any | null; dropdown: any | null };
		getDropdownStatus: (openDropdown: boolean, hideDropdown: () => void, showDropdown: () => void) => void;
		hideDropdownMaster: () => void;
	};
	width: number;
}) => {
	const { filterOptions, selectedFilterHandler, selected, getDropdownStatus, hideDropdownMaster } = handlers;
	const filterOptionRef = useRef<HTMLDivElement>(null);
	const { isEnglish } = useSelector((store: RootState) => store.settingStore);

	const hideAllDropdownHandler = () => {
		selected.dropdown.hideDropdown();
		hideDropdownMaster();
	};

	return (
		<div className='bg-dg-filter-body-bg border border-dg-opposite-primary-color shadow-[0px_0px_2px] shadow-dg-opposite-primary-color  rounded-lg'>
			{filterOptions.map((item, index) => {
				const items: MenuProps['items'] = [
					{
						label: <DgFilterItem width={width} hideAllDropdownHandler={hideAllDropdownHandler} {...item} />,
						key: '0',
					},
				];

				return (
					item.filterTitle && (
						<DgDropDown
							getDropdownStatus={getDropdownStatus}
							align={{ offset: !isEnglish ? [-((filterOptionRef.current?.offsetWidth || 0) + 1) || 0, -47] : [(filterOptionRef.current?.offsetWidth || 0) + 1 || 0, -47] }}
							key={item.filterTitle}
							menu={{ items }}
						>
							<div
								ref={filterOptionRef}
								onClick={() => selectedFilterHandler(item, index)}
								className={`${
									selected.index === index ? 'bg-dg-filter-body-bg-hover' : ''
								} transition-all hover:bg-dg-filter-body-bg-hover border-b border-b-dg-filter-border-color p-3 cursor-pointer last:border-0`}
							>
								<Text tid={item.filterTitle} />
							</div>
						</DgDropDown>
					)
				);
			})}
		</div>
	);
};

export default memo(DgFilterItems);
