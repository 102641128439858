import { Form } from 'antd';
import { useEffect } from 'react';
import useQueryUrlParam from 'core/hooks/useQueryUrlParam';
import DgFilter from 'styles/UI/Filter/DgFilter';
import DgDatePickerLabel from 'styles/UI/DatePicker/DgDatePickerLabel';
import { filterOptionsType } from 'core/types/components/filterType';
import DgSelectBoolean from 'components/Basic/DgSelectBoolean';

const UserOrdersFilter = () => {
	const { query, resetQuery } = useQueryUrlParam();

	const filterOptions: filterOptionsType[] = [
		{
			filterTitle: 'payment-date',
			filterNames: ['is_paid_at_from', 'is_paid_at_to'],
			filterItems: [<DgDatePickerLabel key='payment-from' label='payment-from' />, <DgDatePickerLabel key='payment-to' label='payment-to' />],
			formAttribute: Form.useForm(),
			query,
		},
		{
			filterTitle: 'status',
			filterNames: ['is_canceled', 'is_expired'],
			filterItems: [<DgSelectBoolean key='is_canceled' label='is_canceled' />, <DgSelectBoolean key='is_expired' label='is_expired' />],
			formAttribute: Form.useForm(),
			query,
		},
	];

	useEffect(() => {
		filterOptions.map((item) => {
			const { formAttribute } = item;
			const [form] = formAttribute;

			form.setFieldsValue({ ...query });
		});
	}, []);

	return <DgFilter filterOptions={filterOptions} resetQuery={() => resetQuery(undefined, { tab: query?.tab }, filterOptions)} />;
};

export default UserOrdersFilter;
