import { Form } from 'antd';
import { useEffect } from 'react';
import useQueryUrlParam from 'core/hooks/useQueryUrlParam';
import DgFilter from 'styles/UI/Filter/DgFilter';
import { filterOptionsType } from 'core/types/components/filterType';
import DgSelectPlanCommissionType from 'components/Basic/DgSelectPlanCommissionType';
import DgSelectPlanType from 'components/Basic/DgSelectPlanType';
import DgInputLabel from 'styles/UI/Input/DgInputLabel';
import { useWindowSize } from 'core/hooks/useWindowSize';
import useTranslate from 'core/utils/useTranslate';

const AdminPlansCommissionFilter = () => {
	const { query, resetQuery } = useQueryUrlParam();
	const { width } = useWindowSize();
	const { t } = useTranslate();

	const filterOptions: filterOptionsType[] = [
		...(width < 769
			? [
					{
						filterTitle: 'amount',
						filterNames: ['type'],
						formAttribute: Form.useForm(),
						sortNames: ['amount'],
						query,
					},
			  ]
			: [{ filterTitle: '', formAttribute: Form.useForm(), query, filterNames: [] }]),
		{
			filterTitle: 'profits-type',
			filterNames: ['type'],
			filterItems: [<DgSelectPlanCommissionType key='profits-type' label='profits-type' />],
			formAttribute: Form.useForm(),
			query,
		},
		{
			filterTitle: 'duration',
			filterNames: ['category_type'],
			filterItems: [<DgSelectPlanType key='duration' label='duration' />],
			formAttribute: Form.useForm(),
			query,
		},
		{
			filterTitle: 'invest-release',
			filterNames: ['expire_package'],
			filterItems: [<DgInputLabel key='invest-release' label='invest-release' suffix={t('days')} />],
			formAttribute: Form.useForm(),
			query,
		},
	];

	useEffect(() => {
		filterOptions.map((item) => {
			const { formAttribute } = item;
			const [form] = formAttribute;

			form.setFieldsValue({ ...query });
		});
	}, []);

	return <DgFilter width={width} filterOptions={filterOptions} resetQuery={() => resetQuery(undefined, { tab: query?.tab }, filterOptions)} />;
};

export default AdminPlansCommissionFilter;
