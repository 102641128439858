import { UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { endPointUrls } from 'core/constants/endPointUrls';
import toastHandler from 'core/helpers/toast/toast';
import useMutationHook from 'core/hooks/masterQuery/useMutationHook';
import api from 'core/services/fetch-api/useApi';
import { setApiCatcherFormErrors } from 'core/store/slice/mainInfo/mainInfoSlice';
import { useDispatch } from 'react-redux';

export const useNotificationSeenQuery = () => {
	const queryClient = useQueryClient();
	const dispatch = useDispatch();

	return useMutationHook(
		[endPointUrls.NOTIFICATIONS_SEEN],
		(data) => api.post(endPointUrls.NOTIFICATIONS_SEEN, data),
		{
			onSuccess: (res: any) => {
				queryClient.refetchQueries([endPointUrls.NOTIFICATIONS_LIST()]);
				queryClient.invalidateQueries([endPointUrls.NOTIFICATIONS_UNREAD]);

				if (res?.status === 200) {
					toastHandler('success', res?.data.message);
					dispatch(setApiCatcherFormErrors([]));
				}
			},
		},
		false
	) as UseMutationResult;
};
