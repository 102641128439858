import { createSlice } from '@reduxjs/toolkit';
import { ICountry } from 'core/types/models/countryType';

const initialState: { countrySelectedData: ICountry } = { countrySelectedData: { id: 0, iso2: '', name: '' } };

export const countrySlice = createSlice({
	name: 'country',
	initialState: initialState,
	reducers: {
		setCountrySelectedData: (state, { payload }) => {
			state.countrySelectedData = payload;
		},
	},
});

export const { setCountrySelectedData } = countrySlice.actions;
export default countrySlice.reducer;
