import UserWithdrawTable from '../../components/UserWithdrawsList/UserWithdrawTable';


const UserWithdrawList = () => {

    return (
        <UserWithdrawTable />
    );
};

export default UserWithdrawList;
