import useQueryUrlParam from 'core/hooks/useQueryUrlParam';
import Text from 'core/utils/Text';
import { useState, memo } from 'react';
import DgDeleteButton from '../Button/DgDeleteButton';
import DgDropDown from '../DropDown/DgDropDown';
import DgFilterItem from './DgFilterItem';
import { useSelector } from 'react-redux';
import { RootState } from 'core/store';
import { FC } from 'react';
import { filterOptionsType } from 'core/types/components/filterType';
import { FormInstance, MenuProps } from 'antd';
import { HiOutlineSortAscending, HiOutlineSortDescending } from 'react-icons/hi';
import DgSortResult from './DgSortResult';

interface IDgFilterResult {
	filterOptions: filterOptionsType[];
	isFiltered: boolean;
	width: number;
}

const DgFilterResult: FC<IDgFilterResult> = ({ filterOptions, isFiltered, width }) => {
	// hooks
	const { isEnglish } = useSelector((store: RootState) => store.settingStore);
	const { deleteOneQueryParam, query, deleteSort } = useQueryUrlParam();

	// state
	const [dropdownStatus, setDropdownStatus] = useState<any>({});

	const getDropdownStatus = (openDropdown: boolean, hideDropdown: () => void, showDropdown: () => void) => {
		setDropdownStatus({ openDropdown, hideDropdown, showDropdown });
	};

	const hideAllDropdownHandler = () => {
		dropdownStatus?.hideDropdown();
	};

	const onDeleteFilter = (item: string, formAttribute: [FormInstance<any>]) => {
		const [form] = formAttribute;
		deleteOneQueryParam(item);
		form.setFieldsValue({ [item]: undefined });
		deleteSort();
	};

	return isFiltered ? (
		<div className='my-8'>
			<span className='mb-3 inline-block'>
				<Text tid='filters' />:
			</span>

			<div className='flex flex-wrap gap-5 items-start'>
				{width > 769 ? null : Object.prototype.hasOwnProperty.call(query, query?.sort) ? (
					true
				) : query?.sort && query?.sort_way ? (
					<DgSortResult filterOptions={filterOptions} />
				) : (
					<></>
				)}
				{filterOptions.map((item) => {
					const itemValues = item.filterNames.filter((filterName) => !!(item.query[filterName] === 0 ? item.query[filterName] + '' : item.query[filterName]));

					const items: MenuProps['items'] = [
						{
							label: <DgFilterItem width={width} hideAllDropdownHandler={hideAllDropdownHandler} {...item} />,
							key: '0',
						},
					];

					return (item.filterNames.some((filterName) => !!(item.query[filterName] === 0 ? item.query[filterName] + '' : item.query[filterName])) ? true : false) ? (
						<div key={itemValues?.[0]} className='flex bg-dg-green-color text-dg-opposite-primary-color px-3 py-2 rounded-lg'>
							<DgDropDown getDropdownStatus={getDropdownStatus} align={{ offset: [!isEnglish ? 10 : -10, 10] }} menu={{ items }}>
								{itemValues.length > 0 ? (
									<div className='flex flex-col space-y-3'>
										{itemValues.map((itemValue) => (
											<div key={itemValue} className='flex cursor-pointer'>
												<Text tid={itemValue || '--'} />
												<span className='inline-block mx-1'>:</span>
												{(item.filterResult?.length || 0) > 0 ? (
													item.filterResult?.map((result) => itemValue === result.title && <Text key={result.value} tid={result.value || '--'} />)
												) : (
													<div className='flex items-center gap-2'>
														<Text tid={item.query[itemValue || ''] || '--'} />
														{itemValue === query?.sort && (query?.sort_way === 'ASC' ? <HiOutlineSortAscending size={20} /> : <HiOutlineSortDescending size={20} />)}
													</div>
												)}
											</div>
										))}
									</div>
								) : null}
							</DgDropDown>

							{itemValues.length > 0 ? (
								<div className='flex flex-col space-y-3'>
									{itemValues.map((itemValue) => (
										<DgDeleteButton key={itemValue} onClick={() => onDeleteFilter(itemValue || '', item.formAttribute)} />
									))}
								</div>
							) : null}
						</div>
					) : null;
				})}
			</div>
		</div>
	) : width > 769 ? null : Object.prototype.hasOwnProperty.call(query, query?.sort) ? (
		<DgSortResult filterOptions={filterOptions} />
	) : query?.sort && query?.sort_way ? (
		<DgSortResult filterOptions={filterOptions} />
	) : (
		<></>
	);
};

export default memo(DgFilterResult);
