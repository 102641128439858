import { PrintOrder } from 'components/Admin/Orders/List/components/AdminOrderTableColumn';
import { useWindowSize } from 'core/hooks/useWindowSize';
import Text from 'core/utils/Text';
import useTranslate from 'core/utils/useTranslate';
import DgPrice from 'styles/UI/Price/DgPrice';
import DgTableColumnResponsive from 'styles/UI/TableColumnResponsive/DgTableColumnResponsive';
import DgTimeStamp from 'styles/UI/Timstamp/DgTimeStamp';

const UserOrderTableColumn = ({ showMoreColumn }: { showMoreColumn: boolean }) => {
	const { width } = useWindowSize();
	const { t } = useTranslate();

	const Columns = [
		{
			title: <Text tid='id' />,
			render: (text: any, record: any) => <span className={''}>{record?.id}</span>,
			sorter: false,
		},
		{
			title: <Text tid='product' />,
			render: (text: any, record: any) => (
				<span className={''}>{record?.items?.[0]?.title}</span>
			),
			sorter: false,
		},
		{
			title: <Text tid='price' />,
			render: (text: any, record: any) => <DgPrice price={record?.total_cost_in_pf} />,
			sorter: false,
		},
		// {
		// 	title: <Text tid='user' />,
		// 	render: (text: any, record: any) => {
		// 		return (
		// 			<Link
		// 				to={routes.ADMIN_USER_Detail.link(record.user?.id)}
		// 				className='w-full flex items-center gap-3 group'
		// 			>
		// 				<Text
		// 					tid={record?.user_full_name || record.user?.email || record.user?.username}
		// 				/>
		// 			</Link>
		// 		);
		// 	},
		// 	sorter: false,
		// },
		{
			title: <Text tid='invest-date' />,
			key: 'created_at',
			dataIndex: 'created_at',
			render: (text: any) => <DgTimeStamp hasTime={false} timeStamp={text} />,
			sorter: false,
		},

		{
			title: <Text tid='status' />,
			render: (text: any, record: any) =>
				record.canceled_at ? (
					<span className='text-[var(--dg-red-color-2)]'>
						<DgTimeStamp timeStamp={record.canceled_at || 0} />
					</span>
				) : (
					<span className='text-[var(--dg-green-color)]'>
						<Text tid='active' />
					</span>
				),
			sorter: false,
		},
		{
			title: '',
			sorter: false,
			render: (text: any, record: any) => {
				return (
					<div className='flex items-center gap-2'>
						<PrintOrder record={record} />
					</div>
				);
			},
		},
	];

	const RColumn = [
		{
			title: '',
			sorter: false,
			render: (_text: any, record: any) => {
				return (
					<>
						<DgTableColumnResponsive
							title={t('id')}
							value={<span className={''}>{record?.id}</span>}
						/>
						<DgTableColumnResponsive
							title={t('product')}
							value={<span className={''}>{record?.items?.[0]?.title}</span>}
						/>
						<DgTableColumnResponsive
							title={t('price')}
							value={<DgPrice price={record?.total_cost_in_pf} />}
						/>
						<DgTableColumnResponsive
							title={t('payment-date')}
							value={<DgTimeStamp timeStamp={record?.is_paid_at} />}
						/>
						<DgTableColumnResponsive
							title={t('status')}
							value={
								record.canceled_at ? (
									<span className='text-[var(--dg-red-color-2)]'>
										<DgTimeStamp timeStamp={record.canceled_at || 0} />
									</span>
								) : (
									<span className='text-[var(--dg-green-color)]'>
										<Text tid='active' />
									</span>
								)
							}
						/>
						<DgTableColumnResponsive
							title={''}
							value={
								<div className='flex items-center gap-2'>
									<PrintOrder record={record} />
								</div>
							}
						/>
					</>
				);
			},
		},
	];

	return width > 400 ? Columns : RColumn;
};

export default UserOrderTableColumn;
