import { Form, FormInstance, FormProps } from 'antd';
import { ReactNode, forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

interface IDgForm extends FormProps {
	children: ReactNode;
	className?: string;
	shouldUpdate?: boolean;
}

const DgForm = forwardRef<FormInstance, IDgForm>(
	({ children, layout = 'vertical', className, ...props }, ref) => (
		<Form layout={layout} className={twMerge(`mb-6 ${className}`)} ref={ref} {...props}>
			{children}
		</Form>
	)
);

DgForm.displayName = 'DgForm';

export default DgForm;
