import { FC } from 'react';
import { convertTimeStampToDate } from '../../../core/utils/utils';

interface IDgTimeStamp {
	timeStamp: number;
	className?: string;
	nowrap?: boolean;
	vertical?: boolean;
	hasTime?: boolean;
}

const DgTimeStamp: FC<IDgTimeStamp> = ({ timeStamp, nowrap = true, hasTime = true, className, vertical = true, ...props }) => {
	const { date, time } = convertTimeStampToDate(timeStamp || 0);

	return (
		<span className={`flex ${vertical ? 'flex-col' : 'flex-row'}`}>
			<span className={`${nowrap ? 'whitespace-nowrap' : ''} ${className}`} {...props}>
				{date}
			</span>
			{!vertical && <span className='mx-1'>-</span>}
			{hasTime && (
				<span className={`${nowrap ? 'whitespace-nowrap' : ''} ${className}`} {...props}>
					{time}
				</span>
			)}
		</span>
	);
};

export default DgTimeStamp;
