import { Form } from 'antd';
import { useEffect } from 'react';
import useQueryUrlParam from 'core/hooks/useQueryUrlParam';
import DgFilter from 'styles/UI/Filter/DgFilter';
import DgDatePickerLabel from 'styles/UI/DatePicker/DgDatePickerLabel';
import { filterOptionsType } from 'core/types/components/filterType';
import DgInputLabel from 'styles/UI/Input/DgInputLabel';
import DgSelectNotificationStatus from 'components/Basic/DgSelectNotificationStatus';
import { stringToBoolean } from '../../../../../core/utils/utils';

const ProfileNotificationFilter = () => {
	const { query, resetQuery } = useQueryUrlParam();

	const filterOptions: filterOptionsType[] = [
		{
			filterTitle: 'type',
			filterNames: ['type'],
			filterItems: [<DgInputLabel key='type' label='type' />],
			formAttribute: Form.useForm(),
			query,
		},
		{
			filterTitle: 'title',
			filterNames: ['title'],
			filterItems: [<DgInputLabel key='title' label='title' />],
			formAttribute: Form.useForm(),
			query,
		},
		{
			filterTitle: 'date-time',
			filterNames: ['created_at_from', 'created_at_to'],
			filterItems: [<DgDatePickerLabel key='date-from' label='date-from' />, <DgDatePickerLabel key='date-to' label='date-to' />],
			formAttribute: Form.useForm(),
			query,
		},
		{
			filterTitle: 'status',
			filterNames: ['is_read'],
			filterItems: [<DgSelectNotificationStatus key='status' label='status' />],
			formAttribute: Form.useForm(),
			filterResult: [{ title: 'is_read', value: stringToBoolean(query?.is_read) ? 'read' : 'unread' }],
			query,
		},
	];

	useEffect(() => {
		filterOptions.map((item) => {
			const { formAttribute } = item;
			const [form] = formAttribute;

			form.setFieldsValue({ ...query });
		});
	}, []);

	return <DgFilter filterOptions={filterOptions} resetQuery={() => resetQuery(undefined, { tab: query?.tab }, filterOptions)} />;
};

export default ProfileNotificationFilter;
