import { CheckBg, CheckIcon, FinalIcon } from '../../../styles/AuthenticationStyles';
import { FlexCenter } from '../../../styles/CommonStyles';
import { FaRegAddressCard } from 'react-icons/fa';
import Text from '../../../core/utils/Text';
import DgButton from 'styles/UI/Button/DgButton';
import { useNavigate } from 'react-router-dom';
import { routes } from 'core/constants/routes';

const ProcessCompletion = () => {
	const navigate = useNavigate();

	return (
		<FlexCenter style={{ flexDirection: 'column' }}>
			<h2>
				<Text tid='process-completion-title' />
			</h2>
			<FinalIcon>
				<FaRegAddressCard size={92} color='#c3c5b7' />
				<CheckBg size={42} />
				<CheckIcon size={22} />
			</FinalIcon>
			<div className='mt-8'>
				<Text tid='process-completion-desc' />
			</div>

			<div className='flex justify-end w-full'>
				<DgButton className='mt-8' onClick={() => navigate(routes.PROFILE_SECURITY)} title='security-page' />
			</div>
		</FlexCenter>
	);
};

export default ProcessCompletion;
