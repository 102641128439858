import { getUserWalletsQuery } from 'core/hooks/react-query/getUserWalletsQuery';

const useUserWallet = () => {
	const { data: userWallets, isFetching: userWalletIsFetching } = getUserWalletsQuery();

	const deposit_Wallet = userWallets?.data.filter(
		(item) => item.name === 'Deposit Wallet'
	)?.[0];
	const earning_Wallet = userWallets?.data.filter(
		(item) => item.name === 'Earning Wallet'
	)?.[0];

	return { earning_Wallet, deposit_Wallet, userWalletIsFetching };
};

export default useUserWallet;
