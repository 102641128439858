import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { RootState } from 'core/store';
import Text from 'core/utils/Text';
import { FC } from 'react';

interface IWizard {
	active: number;
	items: any;
}

const Wizard: FC<IWizard> = ({ items, active }) => {
	const { isEnglish } = useSelector((store: RootState) => store.settingStore);

	return (
		<div className='grid-cols-2 sm:grid-cols-3 lg:grid-cols-5 gap-5 grid'>
			{items.map((item: any, idx: number) => (
				<div
					className={`relative bg-[var(--dg-card-bg-1)] rounded-lg transition-all flex flex-col items-center justify-around py-7 px-2 ${
						idx < active ? 'border-2 border-[var(--dg-primary-color)]' : ''
					}`}
					key={idx}
				>
					<div className='absolute top-2 left-2'>{idx + 1}</div>
					<item.icon size={25} />
					<p className='mt-3'>
						<Text tid={item.title} />
					</p>
					{idx + (!isEnglish ? 1 : 0) === active && idx !== items.length - 1 && (
						<div className='absolute top-1/2 -translate-y-1/2 -left-[30px] z-10'>
							{!isEnglish ? (
								<FaAngleLeft size={20} />
							) : (
								<FaAngleRight className='ml-3' size={20} />
							)}
						</div>
					)}
				</div>
			))}
		</div>
	);
};

export default Wizard;
