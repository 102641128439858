import useSelected from 'core/hooks/useSelected';
import useQueryUrlParam from 'core/hooks/useQueryUrlParam';
import { usePackageQuery } from './react-query/usePackageQuery';

const usePackageList = ({ queryType }: { queryType: 'package_id' | 'category_id' }) => {
	const { query } = useQueryUrlParam();
	const { data: packageList, isFetching: isFetchingPackageList } = usePackageQuery();
	const { selectedTypeHandler, selectedType } = useSelected(query[queryType], false);

	return { packageList, isFetchingPackageList, selectedTypeHandler, selectedType };
};

export default usePackageList;
