import Text from 'core/utils/Text';
import { FaClipboardCheck } from 'react-icons/fa';

const GoldOrderSuccessFull = () => {
	return (
		<div className='text-xl text-[var(--dg-green-color-hover)] flex items-center justify-center flex-col gap-10'>
			<FaClipboardCheck size={50} />
			<p className='text-center'>
				<Text tid='your-order-successfully-submitted' />
			</p>
		</div>
	);
};

export default GoldOrderSuccessFull;
