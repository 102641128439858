import { createSlice } from '@reduxjs/toolkit';

const initialState: { modal: boolean; modalData?: any } = { modal: false, modalData: {} };

export const modalSlice = createSlice({
	name: 'modal',
	initialState: initialState,
	reducers: {
		setShowModal: (state) => {
			state.modal = true;
		},
		setHiddenModal: (state) => {
			state.modal = false;
		},
		setModalData: (state, { payload }) => {
			state.modalData = payload;
		},
	},
});

export const { setHiddenModal, setShowModal, setModalData } = modalSlice.actions;
export default modalSlice.reducer;
