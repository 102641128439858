// export const objectToFormData = (data) => {
// 	const formData = new FormData()

// 	for (const [key, value] of Object.entries(data)) {
// 		const isFile = value instanceof File
// 		const isArray = Array.isArray(value)
// 		const isObject = typeof value === 'object' && value !== null && !Array.isArray(value)

// 		if (isFile) {
// 			formData.append(key, value, value.path)
// 		} else if (isArray) {
// 			formData.append(key, JSON.stringify(value))
// 		} else if (isObject) {
// 			formData.append(key, JSON.stringify(value[key]))
// 		} else {
// 			formData.append(key, value)
// 		}
// 	}

// 	return formData
// }

import { serialize } from 'object-to-formdata';

export const objectToFormData = (data) => {
	const options = {
		/**
		 * include array indices in FormData keys
		 * defaults to false
		 */
		indices: true,

		/**
		 * treat null values like undefined values and ignore them
		 * defaults to false
		 */
		nullsAsUndefineds: false,

		/**
		 * convert true or false to 1 or 0 respectively
		 * defaults to false
		 */
		booleansAsIntegers: false,

		/**
		 * store arrays even if they're empty
		 * defaults to false
		 */
		allowEmptyArrays: true,

		/**
		 * don't include array notation in FormData keys for any attributes except Files in arrays
		 * defaults to false
		 */
		noAttributesWithArrayNotation: false,

		/**
		 * don't include array notation in FormData keys for Files in arrays
		 * defaults to false
		 */
		noFilesWithArrayNotation: false,

		/**
		 * use dots instead of brackets for object notation in FormData keys
		 * defaults to false
		 */
		dotsForObjectNotation: false,
	};

	const formData = serialize(
		data,
		options // optional
	);
	return formData;
};
