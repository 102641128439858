import DgTable from 'styles/UI/Table/DgTable';
import DgPagination from 'styles/UI/Pagination/DgPagination';
import useQueryNoLocation from 'core/hooks/useNoQueryUrlParam';
import { paginateDataHandler } from 'core/utils/utils';
import { useUserLoginHistoryQuery } from '../hooks/react-query/useUserLoginHistoryQuery';
import UserLoginHistoryTableColumn from './Column/UserLoginHistoryTableColumn';

const UserLoginHistoryTable = () => {
	const { data, isFetching } = useUserLoginHistoryQuery();
	const { handlePagination, page, per_page } = useQueryNoLocation();

	return (
		<>
			<DgTable
				rowKey='id'
				columns={UserLoginHistoryTableColumn()}
				dataSource={paginateDataHandler(data?.data || [], page, per_page)}
				count={data?.data?.length}
				loading={isFetching}
				current={page}
				per_page={per_page}
			/>
			<DgPagination
				onPaginationHandler={handlePagination}
				responsive={true}
				per_page={per_page}
				current={page}
				total={data?.data?.length}
			/>
		</>
	);
};

export default UserLoginHistoryTable;
