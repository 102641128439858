import { Form } from 'antd';
import { useState } from 'react';
import toastHandler from 'core/helpers/toast/toast';
import useTranslate from 'core/utils/useTranslate';
import { forgetPasswordPayload } from '../types/forgetPasswordType';
import { useForgetPasswordQuery } from './react-query/useForgetPasswordQuery';
import { stringToBoolean } from 'core/utils/utils';

const useForgetPassword = () => {
	const isShowCaptcha =  stringToBoolean(process.env.REACT_APP_IS_RECAPTCHA_SHOW);
	const [captcha, setCaptcha] = useState(isShowCaptcha ? false : 'true');

	// forgetPassword
	const [forgetPasswordForm] = Form.useForm();
	const { mutate, isLoading: isLoadingForgetPassword } = useForgetPasswordQuery();
	const { t } = useTranslate();

	const onFinishForgetPassword = (values: forgetPasswordPayload) => {
		if (!captcha) {
			return toastHandler('error', t('captcha-required'));
		}

		mutate(values);
	};

	const onChangeCaptcha = (value: any) => {
		setCaptcha(value);
	};

	return {
		forgetPasswordForm,
		onFinishForgetPassword,
		isLoadingForgetPassword,
		onChangeCaptcha,
		isShowCaptcha,
	};
};

export default useForgetPassword;
