import { Input } from 'antd';
import { TextAreaProps } from 'antd/lib/input';
import { FC } from 'react';
const { TextArea } = Input;

interface IDgTextArea extends TextAreaProps {
	value?: any;
	setValue?: (val: any) => void;
}

const DgTextArea: FC<IDgTextArea> = ({ value, setValue, ...props }) => {
	return <TextArea value={value} onChange={setValue ? (e) => setValue(e.currentTarget.value) : () => {}} rows={3} {...props} />;
};

export default DgTextArea;
