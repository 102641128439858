import { FC } from 'react';
import UserEarningWalletHeader from './components/UserEarningWalletHeader';
import UserEarningWalletTransactions from './UserEarningWalletTransactions';
// import UserEarnWallets from './components/UserEarnWallets';

const UserEarningWallet: FC = () => {
	return (
		<>
			<div className='grid gap-5'>
				<UserEarningWalletHeader />

				<UserEarningWalletTransactions />
			</div>
		</>
	);
};

export default UserEarningWallet;
