import { useEffect, useState } from 'react';
import useIsMounted from 'core/hooks/useIsMounted';
import useMasterQueryUrlParam from 'core/hooks/useMasterQueryUrlParam';
import { useRegisterVerificationQuery } from './react-query/useRegisterVerificationQuery';
import { useResendEmailVerificationQuery } from './react-query/useResendEmailVerificationQuery';
import useTranslate from 'core/utils/useTranslate';

const useRegisterVerification = () => {
	//state
	const [code1, setCode1] = useState<string>('');
	const [code2, setCode2] = useState<string>('');
	const [code3, setCode3] = useState<string>('');
	const [code4, setCode4] = useState<string>('');
	const [code5, setCode5] = useState<string>('');
	const [code6, setCode6] = useState<string>('');
	const [seconds, setSeconds] = useState<number>(63);
	const [resend, setResend] = useState<boolean>(false);
	//code
	const code = code1 + code2 + code3 + code4 + code5 + code6;

	// query
	const { mutate: verifyEmailHandler, isLoading: verifyEmailIsLoading } = useRegisterVerificationQuery();
	const { mutate: resendOtpEmailHandler, isLoading: resendOtpEmailIsLoading } = useResendEmailVerificationQuery();

	//hooks
	const { query, setUrlParam } = useMasterQueryUrlParam();
	const isMounted = useIsMounted();
	const { t } = useTranslate();

	const verifyPass = () => {
		const postData: any = {
			email: query?.email,
			otp: code,
		};

		verifyEmailHandler(postData);
	};
	const passChangeHandler = (e: any, code: string) => {
		const isNumber = /^[0-9\b]+$/;
		if (isNumber.test(e.target.value)) {
			switch (code) {
				case 'code1':
					return setCode1(e.target.value);
				case 'code2':
					return setCode2(e.target.value);
				case 'code3':
					return setCode3(e.target.value);
				case 'code4':
					return setCode4(e.target.value);
				case 'code5':
					return setCode5(e.target.value);
				case 'code6':
					return setCode6(e.target.value);
				default:
					return;
			}
		} else {
			switch (code) {
				case 'code1':
					return setCode1('');
				case 'code2':
					return setCode2('');
				case 'code3':
					return setCode3('');
				case 'code4':
					return setCode4('');
				case 'code5':
					return setCode5('');
				case 'code6':
					return setCode6('');
				default:
					return;
			}
		}
	};
	//focus to next input while typing
	const focusHandler = (e: any) => {
		const isNumber = /^[0-9\b]+$/;
		if (isNumber.test(e.target.value)) {
			if (e.target.value.length >= e.target.getAttribute('maxlength')) {
				e.target.nextElementSibling.focus();
			}
		}
	};
	//focus to previous input while deleting
	const onKeyDown = (e: any) => {
		if (e.keyCode === 8) {
			if (e.target.value.length < e.target.getAttribute('maxlength')) {
				e.target.previousElementSibling.focus();
			}
		}
	};
	useEffect(() => {
		let isCancelled = false;
		if (!isCancelled) {
			setCode1('');
			setCode2('');
			setCode3('');
			setCode4('');
			setCode5('');
			setCode6('');
		}
		return () => {
			isCancelled = true;
		};
	}, []);

	//resend otp code
	const resendHandler = (e: any) => {
		e.preventDefault();
		const postData: any = {
			email: query?.email,
		};

		const onSuccess = () => {
			setResend(false);
			setSeconds(63);
		};

		resendOtpEmailHandler(postData, { onSuccess });
	};

	useEffect(() => {
		let isCancelled = false;
		let initial: any;
		if (!isCancelled) {
			if (seconds > 0) {
				initial = setTimeout(() => setSeconds(seconds - 1), 1000);
			} else {
				setResend(true);
				setSeconds(0);
			}
		}
		return () => {
			isCancelled = true;
			clearTimeout(initial);
		};
	}, [seconds]);

	useEffect(() => {
		if (isMounted.current) {
			if (code1 && code2 && code3 && code4 && code5 && code6) {
				verifyPass();
			}
		}
	}, [code1, code2, code3, code4, code5, code6, isMounted]);

	const goBackToLoginForm = () => {
		setUrlParam({ page: 2 });
	};

	return {
		passChangeHandler,
		goBackToLoginForm,
		verifyPass,
		code1,
		code2,
		code3,
		code4,
		code5,
		code6,
		focusHandler,
		onKeyDown,
		resendHandler,
		seconds,
		resend,
		t,
		query,
		isLoadingRegisterVerify: resendOtpEmailIsLoading || verifyEmailIsLoading,
	};
};

export default useRegisterVerification;
